import Vue from 'vue'
import localStorage from '@/utils/localstorage'

const SET_INTERSTITIAL_VISIBILITY = 'SET_INTERSTITIAL_VISIBILITY'
const SAVE_INTERSTITIAL_RESOLVE_HOOK = 'SAVE_INTERSTITIAL_RESOLVE_HOOK'
const SET_INTERSTITIAL_EXISTENCE = 'SET_INTERSTITIAL_EXISTENCE'
const SET_DESKTOP_BRANDING_IS_LOADED = 'SET_DESKTOP_BRANDING_IS_LOADED'
const SET_IS_CATFISH_VISIBLE = 'SET_IS_CATFISH_VISIBLE'
const SET_IS_CATFISH_FINISHED = 'SET_IS_CATFISH_FINISHED'
const SET_IS_PAGE_SCROLLED_UP = 'SET_IS_PAGE_SCROLLED_UP'

const CATFISH_SHOWN_COUNT_KEY = 'betting:catfish-shown-count'
const CATFISH_SHOWN_MAX_COUNT = 3
const SESSION_DURATION = '30m'

function shouldShowCatFish () {
    return +localStorage.get(CATFISH_SHOWN_COUNT_KEY) < CATFISH_SHOWN_MAX_COUNT
}

function catfishShownIncrement () {
    const catfishShownCount = localStorage.get(CATFISH_SHOWN_COUNT_KEY) || 0

    if (catfishShownCount) {
        localStorage.setNoTimeUpdate(
            CATFISH_SHOWN_COUNT_KEY,
            catfishShownCount + 1
        )
    } else {
        localStorage.setItemForAWhile(
            CATFISH_SHOWN_COUNT_KEY,
            catfishShownCount + 1,
            SESSION_DURATION
        )
    }
}

export default {
    namespaced: true,

    state () {
        return {
            isDesktopBrandingLoaded: false,
            isCatfishVisible: true,
            isCatfishFinished: false,
            isPageScrolledUp: false,
            isInterstitialVisible: true,
            currentInterstitialResolveHook: null,
            hadInterstitialShown: false,
            hadInterstitialReceived: true
        }
    },

    getters: {
        isDesktopBrandingVisible (state, getters, rootState, rootGetters) {
            return (
                state.isDesktopBrandingLoaded
                && !state.isPageScrolledUp
                && rootGetters['page/isDesktop']
            )
        }
    },

    actions: {
        showCatfish ({ state, commit }) {
            if (!state.isCatfishVisible && !state.isCatfishFinished) {
                if (shouldShowCatFish()) {
                    commit(SET_IS_CATFISH_VISIBLE, true)
                } else {
                    commit(SET_IS_CATFISH_FINISHED, true)
                }
            }
        },

        hideCatfish ({ state, commit }) {
            if (state.isCatfishVisible) {
                catfishShownIncrement()

                commit(SET_IS_CATFISH_VISIBLE, false)
            }
        },

        showInterstitial ({ commit, state }) {
            if (
                !Vue.prototype.$isServer
                && !state.didInterstitialShow
                && state.hadInterstitialReceived
            ) {
                commit(SET_INTERSTITIAL_VISIBILITY, true)

                return new Promise(resolve => {
                    commit(SAVE_INTERSTITIAL_RESOLVE_HOOK, resolve)
                })
            }

            return Promise.resolve()
        },

        closeInterstitial ({ state, commit }) {
            if (state.currentInterstitialResolveHook) {
                state.currentInterstitialResolveHook()

                commit(SAVE_INTERSTITIAL_RESOLVE_HOOK, null)
                commit(SET_INTERSTITIAL_VISIBILITY, false)
            }
        },

        interstitialReceived ({ commit }) {
            commit(SET_INTERSTITIAL_EXISTENCE, true)
        },

        setIsDesktopBrandingLoaded ({ commit }, value = true) {
            commit(SET_DESKTOP_BRANDING_IS_LOADED, value)
        },

        setIsPageScrolledUp ({ commit }, value) {
            commit(SET_IS_PAGE_SCROLLED_UP, value)
        }
    },

    mutations: {
        [SET_INTERSTITIAL_VISIBILITY] (state, isVisible) {
            state.isInterstitialVisible = isVisible
            state.didInterstitialShow = state.didInterstitialShow || isVisible
        },

        [SAVE_INTERSTITIAL_RESOLVE_HOOK] (state, resolve) {
            state.currentInterstitialResolveHook = resolve
        },

        [SET_INTERSTITIAL_EXISTENCE] (state, exist) {
            state.hadInterstitialReceived = exist
        },

        [SET_DESKTOP_BRANDING_IS_LOADED] (state, isVisible) {
            state.isDesktopBrandingLoaded = isVisible
        },

        [SET_IS_CATFISH_VISIBLE] (state, isVisible) {
            state.isCatfishVisible = isVisible
        },

        [SET_IS_CATFISH_FINISHED] (state, isFinished) {
            state.isCatfishFinished = isFinished
        },

        [SET_IS_PAGE_SCROLLED_UP] (state, isScrolledUp) {
            state.isPageScrolledUp = isScrolledUp
        }
    }
}
