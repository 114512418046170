/**
 * Запрос "в избранном ли турнир?"
 *
 * @param {string} tournamentId - id турнира
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function checkTournamentSubscription (tournamentId) {
    return `{
        stat_tournament(id: "${tournamentId}", source: SPORTS) {
            inFavorites
          }
    }`
}

// eslint-disable-next-line import/prefer-default-export
export function statTournamentByWebname (webname) {
    return `{
        stat_tournamentByWebname(webname: "${webname}") {
            title
            metaDescription
        }
    }`
}

export function fetchTable ({ tournamentWebname, tableType }) {
    return `{
        stat_tournamentByWebname(webname:"${tournamentWebname}") {
            id
            currentSeason {
                stages {
                    type
                    name
                    active
                    teamStanding {
                        ${tableType} {
                            rank
                            goalsFor
                            goalsAgainst
                            win
                            loss
                            played
                            draw
                            points
                            team {
                                name
                                logo {
                                    resize (width: "64" height: "64")
                                }
                                ubersetzer {
                                    sportsId
                                }
                            }
                        }
                    }
                }
            }
        }
    }`
}
