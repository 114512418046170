const subheader = () => import('@/views/Euro2020/Subheader.vue')
const right = () => import('@/views/Euro2020/Right.vue')

export default [
    {
        path: 'football/tournament/euro2020',
        name: 'euro2020',
        components: {
            subheader,
            default: () => import('@/views/Euro2020/View.vue'),
            right
        },
        meta: {
            columnRight: true,
            columnFooter: true
        }
    },
    {
        path: 'football/tournament/euro2020/table-standings-uefa-euro-2021',
        name: 'euro2020-standings',
        components: {
            subheader,
            default: () => import('@/views/Euro2020/StandingsView.vue'),
            right
        },
        meta: {
            columnRight: true,
            columnFooter: true
        }
    },
    {
        path: 'football/tournament/euro2020/fixtures-today-uefa-euro-2021',
        name: 'euro2020-schedule',
        components: {
            subheader,
            default: () => import('@/views/Euro2020/ScheduleView.vue'),
            right
        },
        meta: {
            columnRight: true,
            columnFooter: true
        }
    // },
    // {
    //     path: 'football/tournament/euro2020/results-uefa-euro-2021',
    //     name: 'euro2020-results',
    //     components: {
    //         subheader,
    //         default: () => import('@/views/Euro2020/ResultsView.vue'),
    //         right
    //     },
    //     meta: {
    //         columnRight: true,
    //         columnFooter: true
    //     }
    }
]
