import { TEN_MINUTES_CACHE_CONTROL } from '@/constants'

const top = () => import('@/views/blog/top.vue')

const BlogList = () => import('@/views/blog/list/index.vue')
const BlogListRightColumn = () => import('@/views/blog/list/right.vue')
const BlogListSubheader = () => import('@/views/blog/list/subheader.vue')

const BlogItem = () => import('@/views/blog/item/index.vue')
const BlogItemRightColumn = () => import('@/views/blog/item/right.vue')

const sections = 'blog|wiki|hockey|basketball|tennis|ufc-mma-box|euro-2020|express'
const subsections = 'odds|insides|bettingdomik|us|fun|other|terms|strategy'


export default [
    {
        // согласно задаче BIW-54 делаем редирект research -> bettingdomik
        path: `:section(${sections})/research/:alias?`,
        redirect: `:section(${sections})/bettingdomik/:alias?`
    },
    {
        path: ':section(basketball|tennis)',
        redirect: ({ params }) => ({
            name: 'predictions-new-sports',
            params: {
                sport: params.section,
                ...params
            }
        })
    },
    {
        path: ':section(hockey)',
        redirect: ({ params }) => ({
            name: 'predictions-new-sports',
            params: {
                sport: 'ice-hockey',
                ...params
            }
        })
    },
    {
        path: `:section(${sections})/:subsection(${subsections})?`,
        name: 'blog',
        components: {
            top,
            subheader: BlogListSubheader,
            default: BlogList,
            right: BlogListRightColumn
        },
        meta: {
            pageType: 'blog_feed',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    },

    // Убираем дубли page/1, page
    {
        path: `:section(${sections})/:subsection(${subsections})?/page(/1)?`,
        redirect: {
            name: 'blog'
        }
    },
    {
        path: `:section(${sections})/:subsection(${subsections})?/page/:page(\\d{1,3})?`,
        name: 'blog-page',
        components: {
            top,
            default: BlogList,
            right: BlogListRightColumn
        },
        meta: {
            pageType: 'blog_feed',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    },
    {
        path: `:lang(en)?/:section(${sections})/:subsection(${subsections})?/:alias`,
        name: 'blog-post',
        components: {
            top,
            subheader: BlogListSubheader,
            default: BlogItem,
            right: BlogItemRightColumn
        },
        meta: {
            pageType: 'post'
        }
    }
]
