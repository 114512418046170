<template>
    <div
        class="ui-share"
        :class="type"
        @click="share"
    >
        <component :is="icon" />
        <slot />
    </div>
</template>

<script>
// import IconFacebook from '@/ui/icons/social/facebook.vue'
import IconTelegram from '@/ui/icons/social/telegram.vue'
import IconTwitter from '@/ui/icons/social/twitter.vue'
import IconVkontakte from '@/ui/icons/social/vkontakte.vue'
import IconYandex from '@/ui/icons/social/yandex.vue'
import IconYoutube from '@/ui/icons/social/youtube.vue'

import objectToArray from '@/ui/utils/objectToArray'
import { addGetArguments } from '@/ui/utils/url-api'
import config from '@/config.json'

export default {
    name: 'UiShare',

    components: {
        // IconFacebook,
        IconTelegram,
        IconTwitter,
        IconVkontakte,
        IconYandex,
        IconYoutube
    },

    props: {
        /**
         * Тип шаринга [facebook, instagram, telegram, twitter, vkontakte, yandex, youtube]
         */
        type: {
            type: String,
            required: true,
            validator (value) {
                return [
                    // 'facebook',
                    'telegram',
                    'twitter',
                    'vkontakte',
                    'yandex',
                    'youtube'
                ].includes(value)
            }
        },

        /**
         * Для аналитики
         */
        place: {
            type: String,
            default: ''
        },

        eventValue: {
            type: [Number, String],
            default: ''
        },

        /**
         * Заголовок
         */
        title: {
            type: String,
            default: ''
        },

        /**
         * Описание
         */
        description: {
            type: String,
            default: ''
        },

        /**
         * url картинки
         */
        image: {
            type: String,
            default: ''
        },

        /**
         * Share url
         * Default: window.location.href
         */
        url: {
            type: String,
            default: ''
        },

        utmContent: {
            type: String,
            default: ''
        },

        utmSource: {
            type: String,
            default: 'other'
        },

        utmMedium: {
            type: String,
            default: 'share'
        },

        utmCampaign: {
            type: String,
            default: 'web'
        }
    },

    computed: {
        icon () {
            return `icon-${this.type}`
        },

        utm () {
            let utm = `utm_source=${this.utmSource}&utm_medium=${this.utmMedium}&utm_campaign=${this.utmCampaign}`

            if (this.utmContent) {
                utm += `&utm_content=${this.utmContent}`
            }

            return utm
        }
    },

    methods: {
        getTitle () {
            return document.title
        },

        getDescription () {
            return document.querySelector('[name="description"]').content
        },

        share () {
            const shareData = this.getShareData()
            const windowParams = this.getWindowParams()

            // Отправляем событие в аналитику
            if (this.$pivik) {
                this.$pivik.event('share', this.place, this.type, this.eventValue)
            }

            this.$emit('click')
            this[`share-${this.type}`](shareData, windowParams)
        },

        getShareData () {
            let url = window.location.href

            if (this.url) {
                const link = document.createElement('a')

                link.href = this.url
                url = link.href
            }

            return {
                url: encodeURIComponent(addGetArguments(url, this.utm)),
                title: this.title ? encodeURIComponent(this.title) : '',
                description: this.description ? encodeURIComponent(this.description) : '',
                image: this.image || false
            }
        },

        getWindowParams (_params) {
            const params = { width: 630,
                             height: 340,
                             personalbar: 0,
                             toolbar: 'no',
                             scrollbars: 'no',
                             resizable: 'yes',
                             location: 'no',
                             ..._params }

            params.left = Math.floor(window.screen.width / 2 - params.width / 2)
            params.top = Math.floor(window.screen.height / 2 - params.height / 2)

            return objectToArray(params).join(',')
        },

        'share-facebook': function (shareData, windowParams) {
            const link = `https://www.facebook.com/dialog/feed?${objectToArray({
                app_id: config.facebookAppId,
                display: 'popup',
                link: shareData.url,
                redirect_uri: ''
            }).join('&')}`

            window.open(link, 'share_dialog', windowParams)
        },

        'share-vkontakte': function (shareData, windowParams) {
            const data = { url: shareData.url }

            if (shareData.title) {
                data.title = shareData.title
            }

            const link = `https://vk.com/share.php?${objectToArray(data).join('&')}`

            window.open(link, 'share_dialog', windowParams)
        },

        'share-telegram': function (shareData, windowParams) {
            const data = { url: shareData.url }

            if (shareData.title || shareData.description) {
                data.text = (`${shareData.title} ${shareData.description}`).trim()
            }

            const link = `https://t.me/share/url?${objectToArray(data).join('&')}`

            window.open(link, 'share_dialog', windowParams)
        },

        'share-twitter': function (shareData, windowParams) {
            const data = { url: shareData.url }

            if (shareData.description) {
                data.text = shareData.description
            }

            const link = `https://twitter.com/intent/tweet?${objectToArray(data).join('&')}`

            window.open(link, 'share_dialog', windowParams)
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-share
    display inline-flex
    align-items center
    justify-content center
    vertical-align middle
    cursor pointer
</style>
