function getPercentByRate (rate) {
    if (rate < 2) {
        return [5, 25]
    }

    if (rate < 3) {
        return [4, 20]
    }

    if (rate < 5) {
        return [3, 15]
    }

    if (rate < 10) {
        return [3, 12]
    }

    if (rate < 20) {
        return [2, 8]
    }

    if (rate < 50) {
        return [1, 4]
    }

    if (rate < 100) {
        return [0.5, 2]
    }

    return [0.5, 1]
}

export function amountMin (rate, balance) {
    const percent = getPercentByRate(rate)

    return (balance * percent[0]) / 100
}

export function amountMax (rate, balance) {
    const percent = getPercentByRate(rate)

    return (balance * percent[1]) / 100
}
