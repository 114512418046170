<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        {{ $t('text') }}

        <template slot="footer">
            <button
                class="popup-bet-limit__button popup-bet-limit__button--stretch"
                @click="close"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Превышен лимит",
            "text": "На один и тот же матч нельзя поставить больше трех ставок. Выберите другой.",
            "btn": "Окей, понятно"
        },
        "en": {
            "header": "Limit is exceeded",
            "text": "Choose another match",
            "btn": "Okay, got it"
        }
    }
</i18n>

<script>
export default {
    name: 'PopupBetLimit',

    mounted () {
        this.$root.$on('popup-bet-limit', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-bet-limit', this.open)
    },

    methods: {
        open () {
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        }
    }
}
</script>

<style lang="stylus" scoped>
.popup-bet-limit
    &__button
        button(5, 'contained', 'primary')
</style>
