export default [
    {
        path: '/:lang(en|ru)/imaginarium/main',
        name: 'i9m-main',
        component: () => import('@/views/I9m/I9mMainView.vue')
    },
    {
        path: '/:lang(en|ru)/imaginarium/prediction/:id',
        name: 'i9m-prediction',
        component: () => import('@/views/I9m/I9mPredictionView.vue')
    },
    {
        path: '/:lang(en|ru)/imaginarium/profile/:id',
        name: 'i9m-profile',
        component: () => import('@/views/I9m/I9mProfileView.vue')
    }
]
