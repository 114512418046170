import Api from '@/api'
import Vue from 'vue'
import { getOddsFormat, setOddsFormat } from '@/utils/oddsFormatStorage'
import prepareLinesForMatchStat from '@/utils/prepare-lines-for-match-stat'

import select from './select'
import translations from './translations.json'

const SET_LINES = 'SET_LINES'
const SET_ODDS_FORMAT = 'SET_ODDS_FORMAT'

export default {
    namespaced: true,

    modules: {
        select
    },

    state () {
        return {
            oddsFormat: 'european',
            matches: {}
        }
    },

    getters: {
        lines: (state) => ({ id, type }) => {
            const lines = state.matches[id] || []

            return prepareLinesForMatchStat(lines, type)
        },

        length: (state, getters) => ({ id, type }) => getters.lines({ id, type }).length
    },

    actions: {
        async lines ({ commit }, { loc, id }) {
            const locale = loc || Api.getLoc()
            const params = {
                match_ids: id,
                include: 'match',
                bookmaker_id: 'default'
            }

            const result = await Api.trbna({ loc, path: 'odds_list', params })

            // для EN локали нужно заменить названия линий ставок
            // согласно задаче BIW-55
            const data = result.serialized.map(item => {
                const { line } = item

                if (translations[locale] && translations[locale][line.type]) {
                    item.line.name = translations[locale][line.type]
                }

                return item
            })

            commit(SET_LINES, { id, data })
        },

        checkOddsFormat ({ commit }) {
            commit(SET_ODDS_FORMAT, getOddsFormat())
        },

        setOddsFormat ({ commit }, format) {
            commit(SET_ODDS_FORMAT, format)
        }
    },

    mutations: {
        [SET_LINES] (state, { id, data = {} }) {
            if (state.matches[id]) {
                state.matches[id] = data
            } else {
                Vue.set(state.matches, id, data)
            }
        },

        [SET_ODDS_FORMAT] (state, format) {
            state.oddsFormat = format

            setOddsFormat(format)
        }
    }
}
