<template>
    <transition name="fade">
        <div v-if="$slots.placeholder && !loaded">
            <slot name="placeholder" />
        </div>
        <div
            v-else-if="hasBg"
            :style="style"
        />
        <img
            v-else
            :src="source"
        >
    </transition>
</template>

<script>
export default {
    name: 'UiImage',

    props: {
        src: {
            type: String,
            default: ''
        },

        reloadOnImageChange: {
            type: Boolean,
            default: false
        },

        hasBg: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            loaded: false
        }
    },

    computed: {
        source () {
            if (this.loaded) {
                return this.src
            }

            return ''
        },

        style () {
            return {
                'background-image': `url(${this.source})`
            }
        }
    },

    watch: {
        src (newVal) {
            if (this.reloadOnImageChange && newVal) {
                this.load()
            }
        }
    },

    mounted () {
        this.load()
    },

    methods: {
        load () {
            return new Promise(() => {
                const img = new Image()

                img.onload = () => {
                    (this.loaded = true)
                }

                img.src = this.src
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
    .fade-enter-active
        transition opacity .6s

    .fade-enter
        opacity 0
</style>
