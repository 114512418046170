<template>
    <CorePortal>
        <transition name="popup-slide">
            <div
                v-show="show"
                class="bi-fullscreen"
                :class="classes"
            >
                <div
                    class="bi-fullscreen__overlay"
                    @click="close()"
                />

                <div
                    class="bi-fullscreen__body"
                    :class="bodyClasses"
                >
                    <slot />
                </div>
            </div>
        </transition>
    </CorePortal>
</template>

<script>
import CorePortal from '@/ui/core/portal/index.vue'
import setDisableScroll from '@/utils/set-disable-scroll'

export default {
    name: 'BiFullscreen',

    components: {
        CorePortal
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },

        /**
         * Откуда будет выезжать фулскрин
         * [left], [right], [top], [bottom]
         */
        direction: {
            type: String,
            default: 'left'
        },

        /**
         * Вспомогательный класс(ы) на bi-fullscreen__body
         */
        bodyClasses: {
            type: [String, Array, Object],
            default: ''
        }
    },

    computed: {
        classes () {
            return `bi-fullscreen--dir-${this.direction}`
        }
    },

    watch: {
        show (isShow) {
            setDisableScroll({ disabled: isShow })
        }
    },

    beforeDestroy () {
        setDisableScroll({ disabled: false })
        this.$emit('close')
    },

    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus">
    $block = '.bi-fullscreen'
    $animation-name = '.popup-slide'
    $animation-slide = all .45s cubic-bezier(.25, .5, .25, 1)

    .no-scroll {
        overflow hidden
    }

    {$block} {
        position fixed
        top 0
        left 0
        right 0
        bottom 0
        z-index 100

        &__overlay {
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            background-color rgba(#000, 0.5)
            z-index 10
            cursor pointer
        }

        &__body {
            background-color #fff
            position relative
            z-index 20
            box-sizing border-box
            height 100%
        }


        &{$animation-name}-enter-active,
        &{$animation-name}-leave-active {
            transition all .4s cubic-bezier(.25, .5, .25, 1)

            {$block}__overlay {
                transition all .4s cubic-bezier(.25, .5, .25, 1)
            }

            {$block}__body {
                transition all .4s cubic-bezier(.25, .5, .25, 1)
            }
        }

        &{$animation-name}-enter,
        &{$animation-name}-leave-to {

            {$block}__overlay {
                opacity 0
            }

            &{$block}--dir-left {$block}__body {
                transform translate(-100%, 0)
            }

            &{$block}--dir-right {$block}__body  {
                transform translate(100%, 0)
            }

            &{$block}--dir-top {$block}__body {
                transform translate(0, -100%)
            }

            &{$block}--dir-bottom {$block}__body {
                transform translate(0, 100%)
            }
        }

    }

</style>
