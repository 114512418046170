<template>
    <div
        :class="classes"
        class="search-preview-profile"
    >
        <router-link
            :to="profileRoute"
            class="search-preview-profile__main"
            @click.native="onClick"
        >
            <BiUserAvatar
                class="search-preview-profile__avatar"
                size="sm"
                :src="data.avatar"
            />

            <span class="search-preview-profile__description">
                <span class="search-preview-profile__title">
                    <span>{{ data.name }}</span>
                </span>
                <span class="search-preview-profile__info">
                    <span class="search-preview-profile__col">{{ data.winRate }}% WR</span>
                    <span class="search-preview-profile__col">{{ data.roi }}% ROI</span>
                </span>
            </span>
        </router-link>

        <div class="search-preview-profile__aside">
            <a @click="toggle">
                <IconFavorite class="search-preview-profile__icon" />
            </a>
        </div>
    </div>
</template>

<script>
import BiUserAvatar from '@/components/common/bi-user-avatar/index.vue'
import IconFavorite from '@/assets/svg/favorite.icon.svg'
import SubscribeMixin from '@/mixins/subscribe'

export default {
    name: 'SearchResultsProfile',

    components: {
        BiUserAvatar,
        IconFavorite
    },

    mixins: [SubscribeMixin],

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            pendingFavorite: false
        }
    },

    computed: {
        classes () {
            return {
                'search-preview-profile--favorite': this.isFavorite
            }
        },

        isFavorite () {
            return this.isSubscribed(this.data.id)
        },

        profileRoute () {
            return {
                name: 'profile',
                params: {
                    id: this.data.id
                }
            }
        }
    },

    methods: {
        async toggle () {
            if (this.pendingFavorite) {
                return
            }

            this.pendingFavorite = true

            await this.subscribeToggle(this.data.id, 'USER')

            const action = this.isFavorite ? 'subscribe_user' : 'unsubscribe_user'

            this.$pivik.event(action, 'search', null, this.data.id)

            this.pendingFavorite = false
        },

        onClick () {
            this.$pivik.event('menu', 'search', 'user', this.data.id)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .search-preview-profile {
        display flex
        align-items center
        justify-content space-between

        &__main {
            display flex
            flex 1 1
            align-items center
            text-decoration none
        }

        &__avatar {
            font-size 0
            line-height 0
        }

        &__description {
            margin-left 2rem
        }

        &__info {
            display flex
            align-items center
            font-size 1.4rem
            line-height 1.9rem
            color $cl-secondary
        }

        &__title {
            display block
            margin-bottom 0.1rem
            transition color 0.3s ease
            font-size 1.8rem
            line-height 2.4rem
            color $cl-text-dark
        }

        &__icon {
            color transparent
            display block
        }

        &--favorite &__icon {
            color $cl-warning
        }

        &__col {
            display flex
            align-items center
            & + & {
                &:before {
                    content ""
                    background-color $cl-secondary-light
                    width 1px
                    height 1.7rem
                    margin 0 1rem
                }
            }
        }
    }
</style>
