<template>
    <div class="refund-form">
        <slot name="top" />

        <h4 class="bi-overline">
            <slot name="title" />
        </h4>

        <slot />
    </div>
</template>

<script>
export default {
    name: 'PopupRefundForm'
}
</script>

<style lang="stylus" scoped>
.refund-form
    text-align center
    max-width 420px
    margin 0 auto

</style>
