<template>
    <div
        class="md-checkbox"
        :class="checkClasses"
    >
        <div
            class="md-checkbox-container"
            @click="toggleCheck"
        >
            <input
                :id="id"
                :name="name"
                :disabled="disabled"
                :required="required"
                type="checkbox"
                :checked="isSelected"
            >
        </div>

        <component
            :is="labelTag"
            v-if="$slots.default"
            :for="id"
            class="md-checkbox-label"
        >
            <slot />
        </component>
    </div>
</template>

<script>
export default {
    name: 'UiCheckbox',

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: {
            type: [String, Boolean, Object, Number]
        },

        id: {
            type: String,
            default: () => `ui-checkbox-${Math.random().toString(36)
                .slice(4)}`
        },

        name: {
            type: [String, Number],
            default: ''
        },

        required: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        disabledLabel: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isSelected () {
            return !!this.value
        },

        labelTag () {
            return this.disabledLabel ? 'div' : 'label'
        },

        checkClasses () {
            return {
                'is-checked': this.isSelected,
                'is-disabled': this.disabled,
                'is-required': this.required
            }
        }
    },

    methods: {
        handleStringCheckbox () {
            if (!this.isSelected) {
                this.$emit('input', this.value)
            } else {
                this.$emit('input', null)
            }
        },

        handleBooleanCheckbox () {
            this.$emit('input', !this.model)
        },

        toggleCheck () {
            if (!this.disabled) {
                this.$emit('input', !this.isSelected)
            }
        }
    }
}
</script>

<style lang="stylus">
$md-checkbox-size = 20px
$md-checkbox-touch-size = 48px

.md-checkbox {
    width auto
    display flex
    align-items center
    position relative
    user-select none
    max-width 41rem
    font-size 14px
    line-height 19px

    &:not(.is-disabled) {
        cursor pointer

        .md-checkbox-label {
            cursor pointer
        }
    }

    .md-checkbox-container {
        width 1.6rem
        height 1.6rem
        position relative
        box-sizing border-box
        border-radius .3rem
        border 2px solid $cl-text-dark

        transition .4s $swift-ease-out-timing-function
        overflow hidden

        &:focus {
            outline none
        }

        &:before,
        &:after {
            position absolute
            transition $md-transition-drop
            content " "
        }

        &:before {
            width $md-checkbox-touch-size
            height $md-checkbox-touch-size
            top 50%
            left 50%
            z-index 11
            border-radius 50%
            transform translate(-50%, -50%)
        }

        &:after {
            width .6rem
            height .6rem
            left .2rem
            top .2rem
            background-color transparent
            border-radius .3rem
            z-index 12
            border 1px solid #fff
        }

        input {
            position absolute
            left -100px
        }
    }

    .md-checkbox-label {
        min-height $md-checkbox-size
        padding-left 10px
        display flex
        position relative
        transition color .2s linear
        flex-direction column
        justify-content center
    }

    & + & {
        margin-top 20px
        +layout-sm() {
            margin-top 10px
        }
    }
}

.md-checkbox.is-checked {
    .md-checkbox-container {
        border-color $cl-primary

        &:after {
            background-color $cl-primary
            opacity 1
            transition .4s $swift-ease-out-timing-function
        }
    }
}

.md-checkbox.is-disabled.is-checked {
    .md-checkbox-container {
        border-color transparent !important
    }
}

.md-checkbox.is-required {
    label:after {
        position absolute
        top 2px
        right 0
        transform translateX(calc(100% + 2px))
        content "*"
        line-height 1em
        vertical-align top
    }
}
</style>
