<template>
    <div
        class="ui-tooltip"
        @mouseleave="onMouseleave"
        @mouseenter="show"
        @click="show"
    >
        <slot />

        <TooltipDrop
            ref="drop"
            :showed="isShow"
            :drop="drop"
            :inner="inner"
            :is-interactive="isInteractiveTooltip"
            @hover="tooltipHovered"
            @blur="hide"
        >
            <slot name="drop" />
        </TooltipDrop>
    </div>
</template>

<script>
import TooltipDrop from '@/ui/components/Tooltip/inner/drop.vue'

const HOVER_HIDE_TIMEOUT = 300

export default {
    name: 'UiTooltip',

    components: {
        TooltipDrop
    },

    props: {
        /**
         * Направление дропа
         * [bottom], [top]
         */
        drop: {
            type: String,
            default: 'bottom'
        },

        /**
         * Месторасположение указателя относительно контента
         */
        inner: {
            type: Boolean,
            default: false
        },

        /**
         * Нужно ли делать задержку между скрытием и пропаданием hover.
         * А ещё остается ли тултип виден, когда ховер на нем, а не на изначальном элементе
         */
        isInteractiveTooltip: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isShow: false,
            hidingTimeout: null
        }
    },

    beforeDestroy () {
        this.hide()
    },

    mounted () {
        this.$on('update', () => {
            this.$refs.drop.$emit('update')
        })
    },

    methods: {
        onMouseleave () {
            this.hide()
        },

        show () {
            this.isShow = true
        },

        hide () {
            if (this.isInteractiveTooltip) {
                if (this.hidingTimeout) {
                    clearTimeout(this.hidingTimeout)
                }

                this.hidingTimeout = setTimeout(() => {
                    this.isShow = false
                    this.hidingTimeout = null
                }, HOVER_HIDE_TIMEOUT)
            } else {
                this.isShow = false
            }
        },

        tooltipHovered () {
            if (this.isInteractiveTooltip) {
                clearTimeout(this.hidingTimeout)
            }
        }
    }
}
</script>
