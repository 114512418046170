import Api from '@/api'
import logger from '@/utils/logger'
import { createBetEditorial } from '@/api/graphql/match'

export default {
    namespaced: true,

    actions: {
        async saveBetEditorial (ctx, bet) {
            try {
                const query = createBetEditorial(bet)

                await Api.graphql(query, { type: 'mutation' })
            } catch (err) {
                logger.error(err)

                return Promise.reject(err)
            }
        }
    }
}
