<template>
    <div
        class="ui-share-drop"
        :class="{ 'is-open': opened }"
    >
        <div
            ref="container"
            class="ui-share-drop__container"
        >
            <div
                class="ui-share-drop__toggle"
                @click="onToggle"
            >
                <IconShare />
            </div>

            <transition
                name="slide"
                @before-leave="onAnimBeforeHide"
                @before-enter="onAnimBeforeShow"
            >
                <div
                    v-if="opened"
                    class="ui-share-drop__box"
                >
                    <slot />
                </div>
            </transition>
        </div>
        <div
            class="ui-share-drop__bg"
            :style="styleBox"
        />
    </div>
</template>

<script>
import IconShare from '@/ui/icons/share.vue'

export default {
    name: 'UiShareDrop',

    components: {
        IconShare
    },

    data () {
        return {
            opened: false,
            width: 0
        }
    },

    computed: {
        styleBox () {
            return {
                width: `${this.width}px`
            }
        }
    },

    methods: {
        onToggle () {
            this.opened = !this.opened
        },

        onAnimBeforeShow () {
            this.$nextTick(() => {
                this.width = this.$refs.container.scrollWidth
            })
        },

        onAnimBeforeHide () {
            this.width = this.$el.scrollWidth
        }
    }
}
</script>

<style scoped lang="stylus">
    $anim-time = 0.3s
    $anim-time-share-in = 0.28s
    $anim-time-share-out = 0.13s
    $anim-delay-inc = 0.015s

    .ui-share-drop {
        position relative
        display inline-block
        width 3.4rem
        height @width

        &__container {
            position absolute
            top 0
            right 0
            display flex
            align-items center
            height 3.4rem
            z-index 2
        }
        &__bg {
            position absolute
            top 0
            bottom 0
            right 0
            width 3.4rem
            background-color #fff
            border-radius 1.7rem
            z-index 1
            opacity 0
            transition width $anim-time ease, opacity ($anim-time) linear
        }
        .is-open &__bg {
            opacity 1
        }
        &__toggle {
            opacity .6
            color $cl-text-dark
            order 2
            width 2.4rem
            height 2.4rem
            display flex
            align-items center
            justify-content center
            margin 0.5rem
            cursor pointer
            transition opacity, color $anim-time

            &:hover {
                color $cl-primary
                opacity .6
            }
            &:active {
                color $cl-primary
                opacity 1
            }
        }
        .is-open &__toggle {
            opacity 0.4
        }

        >>> .ui-share.facebook svg {
            height 1.8rem
        }

        >>> .ui-share.telegram svg {
            height 1.8rem
        }

        >>> .ui-share.twitter svg {
            height 1.6rem
        }

        >>> .ui-share.vkontakte svg {
            height 1.4rem
        }

        &__box {
            box-sizing border-box
            display flex
            order 1
            color $cl-secondary
            padding 0 0.5rem 0 1.6rem

            > >>> .ui-share {
                margin 0 0.8rem
                width 2.4rem
                height 2.4rem

                &:first-child {
                    margin-left 0
                }
                &:last-child {
                    margin-right 0
                }
            }
        }

        .slide-enter-active {
            transition trnasform $anim-time-share-in
            &.ui-share-drop__box {
                & > >>> .ui-share {
                    transition transform $anim-time-share-in, opacity $anim-time-share-in

                    &:nth-child(1) {
                        transition-delay $anim-delay-inc * 3
                    }
                    &:nth-child(2) {
                        transition-delay $anim-delay-inc * 2
                    }
                    &:nth-child(3) {
                        transition-delay $anim-delay-inc
                    }
                }
            }
        }
        .slide-leave-active {
            transition trnasform $anim-time-share-out
            &.ui-share-drop__box {
                & > >>> .ui-share {
                    transition transform $anim-time-share-out, opacity $anim-time-share-out
                    &:nth-child(4) {
                        transition-delay $anim-delay-inc * 3
                    }
                    &:nth-child(3) {
                        transition-delay $anim-delay-inc * 2
                    }
                    &:nth-child(2) {
                        transition-delay $anim-delay-inc
                    }
                }
            }
        }
        .slide-leave-to,
        .slide-enter {
            &.ui-share-drop__box {
                & > >>> .ui-share {
                    opacity 0

                    &:nth-child(1) {
                        transform translate(4rem, 0)
                    }
                    &:nth-child(2) {
                        transform translate(3rem, 0)
                    }
                    &:nth-child(3) {
                        transform translate(2rem, 0)
                    }
                    &:nth-child(4) {
                        transform translate(1rem, 0)
                    }
                }
            }
        }
    }
</style>
