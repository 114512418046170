<template>
    <time
        :itemprop="itemprop"
        :content="iso"
        :datetime="iso"
        class="ui-date"
    >{{ date }}</time>
</template>

<script>
export default {
    name: 'UiDate',
    props: {
        /**
         * Timestamp в секундах
         */
        timestamp: {
            type: Number,
            required: true
        },

        /**
         * Формат времени
         * `akhmatova, bulgakov, bunin, fulldate, date, time`
         */
        format: {
            type: String,
            default: 'date'
        },

        /**
         * Локализация
         * `ru, en`
         */
        lang: {
            type: String,
            default: 'en'
        },

        /**
         * Обновление времени
         */
        live: {
            type: Boolean,
            default: false
        },

        /**
         * Указать - если время в миллисекундах
         */
        ms: {
            type: Boolean,
            default: false
        },

        /**
         * [itemprop](https://schema.org/)
         */
        itemprop: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            date: '',
            interval: null
        }
    },

    computed: {
        iso () {
            const date = new Date(this.timestampMs)

            return date.toISOString()
        },

        timestampMs () {
            return this.ms ? this.timestamp : this.timestamp * 1000
        }
    },

    watch: {
        timestamp () {
            this.update()
        }
    },

    created () {
        this.update()
    },

    mounted () {
        if (this.live) {
            this.interval = setInterval(() => this.update(), 10000)
        }
    },

    beforeDestroy () {
        if (this.interval) {
            clearInterval(this.interval)
        }
    },

    methods: {
        update () {
            this.date = this.$date.transform(this.timestampMs, this.format)
        }
    }
}
</script>
