import Api from '@/api'

export default {
    namespaced: true,
    state () {
        return {
            matches: [],
            tournament: {}
        }
    },
    actions: {
        fetch ({ commit }, tournamentId) {
            return Api.trbna({
                path: 'matches/with_odds_for_teaser',
                params: { tournament_id: tournamentId, include: 'odds,team', count: 20 },
                maps: ['home_team', 'away_team']
            })
                .then(result => {
                    commit('matches', result.serialized)
                })
                .catch(() => {})
        }
    },
    mutations: {
        matches (state, matches) {
            state.matches = matches
        },
        tournament (state, tournament) {
            state.tournament = tournament
        }
    }
}
