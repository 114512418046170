import Api from '@/api'

export default {
    namespaced: true,

    state () {
        return {
            game: {}
        }
    },

    getters: {
        keys (state) {
            return Object.keys(state.game)
        },
        gameStat (state, getters) {
            return getters.keys.length ? state.game : null
        }
    },

    actions: {
        fetch ({ commit }, { id }) {
            const params = { id }

            return Api.trbna({ path: 'match/stat', params })
                .then(json => commit('game', json.serialized))
                .catch(e => {
                    commit('game', {})

                    return Promise.resolve(e)
                })
        }
    },

    mutations: {
        game (state, data) {
            state.game = data
        }
    }
}
