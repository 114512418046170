<template>
    <div>
        <RefreshableDfp
            :placement="placement"
            @receive="showCreative"
            @render="renderHandler"
        />
        <div
            v-if="isPageScrolledUp && creative.show"
            class="catfish"
        >
            <div
                v-if="creative.disclaimer"
                class="catfish__disclaimer-wrapper"
            >
                <AdDisclaimer
                    v-if="creative.disclaimer"
                    :text="creative.disclaimer"
                    tooltip-drop="top"
                />
            </div>

            <button
                class="catfish__close-button"
                @click="close"
            >
                <IconClose />
            </button>

            <a
                v-if="creative.show"
                :href="creative.link"
                class="catfish__image-wrapper"
                target="_blank"
                @click="creativeClickHandler"
            >
                <img
                    :src="creative.image"
                    :alt="creative.bookmaker"
                    class="catfish__image"
                >
            </a>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'
import AdDisclaimer from '@/components/DFP/AdDisclaimer.vue'

export default {
    name: 'DFPCatfish',

    components: {
        RefreshableDfp,
        AdDisclaimer
    },

    data () {
        return {
            creative: {
                show: false
            }
        }
    },

    computed: {
        ...mapState('ads', ['isPageScrolledUp']),
        isDesktop () {
            return ['md', 'l'].includes(this.$mq)
        },

        placement () {
            return this.isDesktop ? 'catfish_desktop' : 'catfish_mobile'
        }
    },

    methods: {
        ...mapActions({
            hideCatfish: 'ads/hideCatfish'
        }),

        showCreative (message) {
            if (message) {
                this.creative.link = message.link
                this.creative.image = message.imageSrc
                this.creative.disclaimer = message.disclaimer
                this.creative.show = true

                this.$pivik.event('ad', 'catfish', 'view')
            }
        },

        creativeClickHandler () {
            this.$pivik.event('ad', 'catfish', 'click')
        },

        renderHandler () {
            this.creative = {
                link: '',
                image: '',
                bookmaker: '',
                disclaimer: '',
                show: false
            }
        },

        close () {
            this.hideCatfish()

            this.creative.show = false

            this.$pivik.event('ad', 'catfish', 'close')
        }
    }
}
</script>

<style lang="stylus" scoped>
.catfish
    display flex
    justify-content center
    align-items flex-end
    position fixed
    bottom 0
    left 0
    width 100%
    z-index 999
    background-color #686868

    &__image
        display block
        &-wrapper
            display flex
            justify-content center
            align-items center


    &__close-button
        button(6, 'icon', 'white')
        position absolute
        top 1rem
        right 1rem
        border-radius $radius-block
        background rgba($cl-text-dark, .95)
        color $cl-white
        width 3.6rem
        height 3.6rem
        padding .6rem

    &__disclaimer-wrapper
        position absolute
        top 1rem
        left 1rem
        border-radius $radius-block
        background rgba($cl-white, .95)
        color $cl-text-dark
        width 3.6rem
        height 3.6rem
        padding .6rem
        box-sizing border-box
        z-index 2
        font-size 0

    .bi-dfp
        position absolute
        width 0
        height 0

</style>
