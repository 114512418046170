/* eslint-disable */

function utf8Encode (string, maxLength) {
    string = string.replace(/\r\n/g, '\n')
    let utftext = ''; let
        nextChar

    for (let n = 0; n < string.length; n++) {
        const c = string.charCodeAt(n)

        if (c < 128) {
            nextChar = fromCharCode(c)
        } else if ((c > 127) && (c < 2048)) {
            nextChar = fromCharCode((c >> 6) | 192)
            nextChar += fromCharCode((c & 63) | 128)
        } else {
            nextChar = fromCharCode((c >> 12) | 224)
            nextChar += fromCharCode(((c >> 6) & 63) | 128)
            nextChar += fromCharCode((c & 63) | 128)
        }

        if (utftext.length + nextChar.length > maxLength) { break }

        utftext += nextChar
    }

    return utftext
}


function utf8Decode (utftext) {
    let string = ''
    let i = 0
    let c = 0; let
        c2 = 0
    while (i < utftext.length) {
        c = utftext.charCodeAt(i)

        if (c < 128) {
            string += String.fromCharCode(c)
            i++
        } else if ((c > 191) && (c < 224)) {
            c2 = utftext.charCodeAt(i + 1)
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
            i += 2
        } else {
            c2 = utftext.charCodeAt(i + 1)
            const c3 = utftext.charCodeAt(i + 2)

            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
            i += 3
        }
    }

    return string?.replace(/\r\n/g, '')
}


export function encode (input, maxLength) {
    maxLength = maxLength || 1e6
    let output = ''
    let chr1; let chr2; let chr3; let enc1; let enc2; let enc3; let
        enc4
    let i = 0

    input = utf8Encode(input, maxLength * 3 / 4 | 0)

    while (i < input.length) {
        chr1 = input.charCodeAt(i++)
        chr2 = input.charCodeAt(i++)
        chr3 = input.charCodeAt(i++)

        enc1 = chr1 >> 2
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
        enc4 = chr3 & 63

        if (isNaN(chr2)) {
            enc3 = enc4 = 64
        } else if (isNaN(chr3)) {
            enc4 = 64
        }

        output = output
            + keyStr.charAt(enc1) + keyStr.charAt(enc2)
            + keyStr.charAt(enc3) + keyStr.charAt(enc4)
    }

    return output
}


// public method for decoding
export function decode (input) {
    let output = ''
    let chr1; let chr2; let chr3
    let enc1; let enc2; let enc3; let enc4
    let i = 0

    input = input.replace(/[^A-Za-z0-9\-\_\=]/g, '')

    while (i < input.length) {
        enc1 = keyStr.indexOf(input.charAt(i++))
        enc2 = keyStr.indexOf(input.charAt(i++))
        enc3 = keyStr.indexOf(input.charAt(i++))
        enc4 = keyStr.indexOf(input.charAt(i++))

        chr1 = (enc1 << 2) | (enc2 >> 4)
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
        chr3 = ((enc3 & 3) << 6) | enc4

        output += String.fromCharCode(chr1)

        if (enc3 != 64) {
            output += String.fromCharCode(chr2)
        }

        if (enc4 !== 64) {
            output += String.fromCharCode(chr3)
        }
    }

    return utf8Decode(output)
}

// URL Safe Base64: + -> -; / -> _;
// private property
const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_='
const { fromCharCode } = String

