<template>
    <transition name="slide">
        <div
            v-if="show"
            class="the-smart-app-banner"
        >
            <a
                class="the-smart-app-banner__close"
                @click.prevent="close()"
            >
                <IconClose />
            </a>

            <div class="the-smart-app-banner__logo">
                <IconLogo />
            </div>

            <div class="the-smart-app-banner__body">
                <div class="the-smart-app-banner__title">
                    {{ $t('title') }}
                </div>
                <span class="the-smart-app-banner__desc">{{ $t('description') }}</span>
            </div>

            <a
                :href="href"
                class="the-smart-app-banner__download"
                @click="download"
            >
                {{ $t('button') }}
            </a>
        </div>
    </transition>
</template>


<i18n>
{
    "ru": {
        "title": "Бесплатные прогнозы!",
        "description": "Только в приложении",
        "button": "Скачать"
    },
    "en": {
        "title": "Free tips!",
        "description": "Football predictions",
        "button": "Install"
    }
}
</i18n>


<script>
import { mapState } from 'vuex'
import config from '@/config.json'

export default {
    name: 'TheSmartAppBanner',

    data () {
        return {
            show: false
        }
    },

    computed: {
        ...mapState('storage', [
            'smartBannerDismissed',
            'smartBannerInstall'
        ]),

        href () {
            return config.apps.auto
        },

        userDismissed () {
            return this.smartBannerDismissed + (30 * 1000 * 60 * 60 * 24) >= Date.now()
        },

        userInstalled () {
            return this.smartBannerInstall + (30 * 1000 * 60 * 60 * 24) >= Date.now()
        },

        userAgent () {
            const ua = window.navigator.userAgent
            const result = {}

            if (ua.indexOf('Android') > 0) {
                result.os = 'android'
            }

            if ((/(iPhone|iPad|iPod)/gi).test(ua)) {
                result.os = 'ios'
            }

            if ((/Apple/gi).test(window.navigator.vendor)) {
                result.isSafari = true
            }

            return result
        }
    },

    mounted () {
        const runningStandAlone = navigator.standalone
        const supported = !!this.userAgent.os

        console.info(runningStandAlone, !this.userAgent.isSafari)

        if (
            supported
            && !this.userAgent.isSafari
            && !runningStandAlone
            && !this.userDismissed
            && !this.userInstalled
        ) {
            setTimeout(this.toggle, 600)
            this.$pivik.event('smart_app_banner', 'show')
        }
    },

    methods: {
        close () {
            this.$store.commit('storage/set', { smartBannerDismissed: Date.now() })
            this.$pivik.event('smart_app_banner', 'close')
            this.toggle()
        },

        download () {
            this.$store.commit('storage/set', { smartBannerInstall: Date.now() })
            this.$pivik.event('smart_app_banner', 'install')
            this.toggle()
        },

        toggle () {
            this.show = !this.show
            this.$emit('toggle', this.show)
        }
    }
}
</script>


<style rel="stylesheet/stylus" lang="stylus">
    .the-smart-app-banner {
        align-items center
        padding 12px 12px 12px 8px
        position fixed
        top 0
        left 0
        right 0
        z-index 200
        display flex
        background-color #F6F6F6
        border-bottom 1px solid $cl-disabled-light
        box-shadow 0 1px 0 0 #F6F6F6

        &__close {
            color $cl-secondary

            .ui-icon {
                width 14px
                height 14px
            }
        }

        &__body {
            flex 1 1
            margin-right 25px
        }

        &__title {
            font-size $font-size-base
            line-height $font-size-base
            margin-bottom .2rem
        }

        &__desc {
            color $cl-secondary
            font-size 1.4rem
            line-height 1
            display block
        }

        &__logo {
            width 54px
            height 54px
            border-radius 15px
            background-color $cl-text-dark
            display flex
            align-items center
            justify-content center
            color $cl-primary
            margin 0 10px 0 8px

            .ui-icon {
                width 35px
                height 35px
            }
        }

        &__download {
            button(5, 'contained', 'primary')
        }
    }

    .slide-enter-active
    .slide-leave-active {
        transition top 0.25s linear
    }

    .slide-enter,
    .slide-leave-to {
        top -80px
    }

</style>
