<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        {{ $t('text') }}

        <template slot="footer">
            <button
                class="bet-noactive__button bet-noactive__button--stretch"
                @click="onContinue"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Коэффициент не активен",
            "text": "Попробуйте выбрать что-то еще.",
            "btn": "Выбрать другой"
        },
        "en": {
            "header": "Odds is not active",
            "text": "Please try picking something else",
            "btn": "Make another pick"
        }
    }
</i18n>

<script>
export default {
    name: 'PopupBetNoActive',

    data () {
        return {
            matchId: null
        }
    },

    mounted () {
        this.$root.$on('popup-bet-noactive', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-bet-noactive', this.open)
    },

    methods: {
        open ({ matchId }) {
            this.matchId = matchId
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        },

        onContinue () {
            this.$store.commit('bet/select/clean')
            this.close()
        }
    }
}
</script>

<style lang="stylus" scoped>
.bet-noactive
    &__button
        button(5, 'contained', 'primary')
</style>
