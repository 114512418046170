import Api from '@/api'
import Vue from 'vue'
import { FOOTBALL_SPORT_ID } from '@/constants'
import { checkTournamentSubscription, statTournamentByWebname } from '@/api/graphql/tournaments'
import { dateFormatter } from '@/utils/pagination-date'
import logger from '@/utils/logger'
import fetchMatches from './fetch'
import table from './table'
import schedule from './schedule'

const UPDATE_TOURNAMENT_FAVORITES = 'UPDATE_TOURNAMENT_FAVORITES'

function sortMatchesByTime (matches) {
    return matches.sort((a, b) => a.time - b.time)
}

export default {
    namespaced: true,
    modules: {
        schedule,
        table
    },
    state () {
        return {
            date: 0,
            tournaments: [],
            matches: {},
            fetchedTournaments: [],
            tournament: {},

            liveFetched: false,
            liveCount: 0
        }
    },

    getters: {
        date (state) {
            const date = new Date(state.date)

            return dateFormatter(date)
        },
        matches: (state) => (tournamentId) => {
            const res = []

            Object.values(state.matches).forEach(match => {
                if (match.tournament_id === tournamentId) {
                    res.push(match)
                }
            })

            return sortMatchesByTime(res)
        },
        isFetched: (state) => (tournamentId) => state.fetchedTournaments.indexOf(tournamentId) > -1
    },

    actions: {
        /**
         * Запрашивает матчи и турниры по указанной дате
         * @param commit
         * @param dispatch
         * @param state
         * @param timestamp
         * @returns {Promise}
         */
        date ({ commit, dispatch }, timestamp = Date.now()) {
            commit('date', timestamp)
            commit('clearMatches')

            return Promise.all([
                dispatch('fetchTournaments'),
                dispatch('fetchMatches', { top: 1 })
            ])
        },
        async fetchLiveCount ({ commit }) {
            try {
                const result = await fetchMatches({ isLive: 1, top: 0 })

                commit('liveCount', result.serialized.length)
            } catch (e) {}
        },
        async fetchLive ({ commit }) {
            try {
                const result = await fetchMatches({ isLive: 1, top: 0 })

                commit('liveCount', result.serialized.length)
                commit('live', {
                    matches: result.serialized,
                    tournaments: result.include.tournament
                })
            } catch (e) {}
        },
        async fetchTournament ({ commit, dispatch }, webname) {
            try {
                const result = await dispatch('getTournamentRequests', webname)
                const [mainInfo, metaData] = result

                if (mainInfo.serialized.sport_id === FOOTBALL_SPORT_ID) {
                    const tournament = mainInfo.serialized

                    tournament.title = metaData.title || ''
                    tournament.metaDescription = metaData.metaDescription || ''

                    commit('tournament', tournament)

                    return tournament
                }

                return null
            } catch (err) {
                return null
            }
        },
        getTournamentRequests (context, webname) {
            const queryString = statTournamentByWebname(webname)

            return Promise.all([
                Api.trbna({ path: 'tournament/by_webname', params: { webname } }),
                Api.graphql(queryString)
            ])
        },
        async fetchTournaments ({ commit, getters }) {
            const params = {
                date: getters.date,
                category_id: parseInt(FOOTBALL_SPORT_ID),
                bookmaker_id: 'default',
                include: 'tournament'
            }

            try {
                const result = await Api.trbna({ path: 'tournaments/ids_by_day', params })

                commit('tournaments', result.serialized.tournaments)
            } catch (err) {
                logger.error(err)
            }
        },
        async fetchMatches ({ commit, getters }, { tournamentId, isLive, state, top }) {
            try {
                const result = await fetchMatches({
                    date: getters.date,
                    tournamentId,
                    isLive,
                    state,
                    top
                })

                commit('matches', result.serialized)
                commit('fetchedTournaments', result.include.tournament.map(tournament => tournament.id))
            } catch (err) {
                logger.error(err)
            }
        },

        checkTournamentSubscription ({ commit }, id) {
            return Api.graphql(checkTournamentSubscription(id)).then((resp) => {
                commit(UPDATE_TOURNAMENT_FAVORITES, {
                    id,
                    inFavorites: resp.inFavorites
                })

                return resp.inFavorites
            })
        },

        favoriteToggle ({ state, dispatch, commit }, { id }) {
            const type = 'TOURNAMENT'
            const isTournamentInFavorites = state.tournament.inFavorites

            commit(UPDATE_TOURNAMENT_FAVORITES, { id, inFavorites: !state.tournament.inFavorites })

            if (isTournamentInFavorites) {
                return dispatch('favorites/unsubscribeFavorites', { id, type }, { root: true })
                    .catch(() => {
                        commit(UPDATE_TOURNAMENT_FAVORITES, { id, inFavorites: true })
                    })
            }

            return dispatch('favorites/subscribeFavorites', { id, type }, { root: true })
                .catch(() => {
                    commit(UPDATE_TOURNAMENT_FAVORITES, { id, inFavorites: false })
                })
        }
    },

    mutations: {
        date (state, timestamp) {
            state.date = timestamp
        },
        tournaments (state, tournaments) {
            state.tournaments = tournaments
        },
        tournament (state, tournament) {
            state.tournament = {
                inFavorites: false,
                ...tournament
            }
        },
        matches (state, matches) {
            matches.forEach(match => {
                if (!state.matches[match.id]) {
                    Vue.set(state.matches, match.id, match)
                } else {
                    state.matches[match.id] = match
                }
            })
        },
        clearMatches (state) {
            state.matches = {}
            state.fetchedTournaments = []
        },
        fetchedTournaments (state, tournaments) {
            tournaments.forEach(tournamentId => {
                if (state.fetchedTournaments.indexOf(tournamentId) === -1) {
                    state.fetchedTournaments.push(tournamentId)
                }
            })
        },
        liveCount (state, count) {
            state.liveFetched = true
            state.liveCount = count
        },
        live (state, { matches = [], tournaments = [] }) {
            state.matches = matches
            state.tournaments = tournaments
            state.fetchedTournaments = tournaments.map(tournament => tournament.id)
        },
        [UPDATE_TOURNAMENT_FAVORITES] ({ tournament }, { id, inFavorites }) {
            if (tournament.id === id) {
                tournament.inFavorites = inFavorites
            }
        }
    }
}
