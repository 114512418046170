export default [
    {
        path: 'create-password/:token',
        name: 'account-create-password',
        component: () => import('@/views/account/create-password/index.vue')
    },

    {
        path: 'confirm-email/:token',
        name: 'account-confirm-email',
        components: {
            default: () => import('@/views/account/confirm-email/index.vue')
        }
    },

    {
        path: 'settings',
        name: 'account-settings',
        components: {
            right: () => import('@/views/account/settings/right.vue'),
            default: () => import('@/views/account/settings/index.vue')
        }
    }
]
