<template>
    <CorePortal>
        <transition
            name="drop-show"
            @before-enter="beforeEnter"
        >
            <div
                v-if="showed"
                ref="box"
                :class="classes"
                :style="position"
                class="ui-tooltip-box"
                @mouseenter="$emit('hover')"
                @mouseleave="$emit('blur')"
            >
                <span
                    :style="tailStyle"
                    class="ui-tooltip-box__tail"
                />
                <slot />
            </div>
        </transition>
    </CorePortal>
</template>

<script>
import CorePortal from '@/ui/core/portal/index.vue'
import throttle from 'throttleit'

export default {
    name: 'TooltipDrop',

    components: {
        CorePortal
    },

    props: {
        drop: {
            type: String,
            default: ''
        },

        showed: {
            type: Boolean,
            default: false
        },

        inner: {
            type: Boolean,
            default: false
        },

        isInteractive: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            position: null,
            tailStyle: null
        }
    },

    computed: {
        classes () {
            return {
                'is-top': this.drop === 'top',
                'is-bottom': this.drop === 'bottom',
                'ui-tooltip-box--interactive': this.isInteractive
            }
        }
    },

    mounted () {
        this.$on('update', this.updatePosition)

        this.$nextTick(() => {
            this.scrollEventHandler = throttle(this.hideDrop.bind(this), 100)

            document.addEventListener('scroll', this.scrollEventHandler)
        })
    },

    beforeDestroy () {
        if (this.scrollEventHandler) {
            document.removeEventListener('scroll', this.scrollEventHandler)

            this.scrollEventHandler = null
        }
    },


    methods: {
        updatePosition () {
            if (!this.$refs.box) {
                return
            }

            this.position = {
                top: `${this.getTop()}px`,
                left: `${this.getLeft()}px`
            }

            this.tailStyle = {
                transform: `translateX(${this.getLeftOffset()}px)`
            }
        },

        beforeEnter () {
            setTimeout(() => (this.updatePosition()), 0)
        },

        getBoxRect () {
            return this.$refs.box.getBoundingClientRect()
        },

        getParentRect () {
            return this.$parent.$el.getBoundingClientRect()
        },

        getTempLeft () {
            const { scrollLeft } = document.scrollingElement
            const parentRect = this.getParentRect()
            const boxRect = this.getBoxRect()

            return scrollLeft + parentRect.left + (parentRect.width / 2) - (boxRect.width / 2)
        },

        getLeftOffset () {
            const screenWidth = document.documentElement.scrollWidth
            let offset = 0
            const boxRect = this.getBoxRect()
            const tempLeft = this.getTempLeft()

            if (tempLeft + boxRect.width > screenWidth) {
                offset = tempLeft + boxRect.width + 15 - screenWidth
            } else if (tempLeft < 15) {
                offset = tempLeft - 15
            }

            return offset
        },

        getLeft () {
            return this.getTempLeft() - this.getLeftOffset()
        },

        getTop () {
            const { scrollTop } = document.scrollingElement
            let top = 0
            const parentRect = this.getParentRect()
            const boxRect = this.getBoxRect()

            if (this.drop === 'top') {
                top = scrollTop + parentRect.top - boxRect.height
                top -= this.inner ? 0 : 10
            } else {
                top = scrollTop + parentRect.bottom
                top += this.inner ? 0 : 10
            }

            return top
        },

        hideDrop () {
            this.$emit('blur')
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-tooltip-box {
    position absolute
    top 1rem
    left 0
    display inline-block
    background-color rgba($cl-text-dark, .98)
    border-radius 8px
    padding .3rem 1rem .5rem 1rem
    color #fff
    z-index 1000
    pointer-events none
    bottom auto

    &--interactive {
        pointer-events unset
    }

    &__tail {
        position absolute
        content ""
        border-left 5px solid transparent
        border-right 5px solid transparent
        border-bottom 5px solid rgba($cl-text-dark, .9)
        bottom 100%
        left 0
        right 0
        margin 0 auto
        width 0
    }

    &.is-top &__tail {
        border-top 5px solid rgba($cl-text-dark, .9)
        border-bottom 0 none
        bottom auto
        top 100%
    }

    &.drop-show-enter-active {
        transition-delay 0.03s
    }
    &.drop-show-enter-active, &.drop-show-leave-active {
        transition transform .3s, opacity .3s
    }

    &.drop-show-enter, &.drop-show-leave-to {
        opacity 0
        transform translate(0, -10px)
    }

    &.is-top.drop-show-enter, &.is-top.drop-show-leave-to {
        transform translate(0, 10px)
    }
}
</style>
