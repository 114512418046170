/**
 * ПАМЯТКА
 *
 * Логика получения составов команд:
 * Одним запросом идем за предварительными составами и текущими составами,
 * если не удалось получить ни один из вышеперечисленных,
 * то идем за составами из предыдущего матча обеих команд
 */

import Vue from 'vue'
import Api from '@/api'
import _get from 'lodash.get'
import {
    ProbableMatchLineups,
    MatchLineups,
    HomeTeamLastMatchesLineups,
    AwayTeamLastMatchesLineups
} from '@/api/graphql/lineups'

import {
    teamAdapter,
    getLineupsFromMatchStat,
    parseLastMatchesStat
} from '@/utils/lineups'

const SET_MATCH_ID = 'SET_MATCH_ID'
const SET_TEAM = 'SET_TEAM'
const SET_TEAM_PLAYERS = 'SET_TEAM_PLAYERS'
const SET_META = 'SET_META'
const SET_LOADING_STATE = 'SET_LOADING_STATE'
const RESET_ALL_DATA = 'RESET_ALL_DATA'

const initialState = () => ({
    matchId: null,
    isLoading: false,

    homeTeam: {},
    awayTeam: {},
    homeTeamPlayers: {},
    awayTeamPlayers: {},

    hasProbableLineup: false,
    hasMainLineup: false,
    hasLastMatchLineup: false
})

export default {
    namespaced: true,

    state: initialState,

    getters: {
        hasLineups: state => (
            state.hasMainLineup
            || state.hasProbableLineup
            || state.hasLastMatchLineup
        ),
        isLoading: state => state.isLoading,
        hasMainLineup: state => state.hasMainLineup,
        hasProbableLineup: state => state.hasProbableLineup,
        hasLastMatchLineup: state => state.hasLastMatchLineup,

        homeTeamId: state => state.homeTeam.id,
        awayTeamId: state => state.awayTeam.id,
        homeTeamLastMatchId: state => _get(state, 'homeTeam.lastFive[0].sportsId', '') || '',
        awayTeamLastMatchId: state => _get(state, 'awayTeam.lastFive[0].sportsId', '') || '',

        teamLineups: (state, getters) => [
            {
                type: 'home',
                playersSquads: getters.teamLineup('home'),
                ...state.homeTeam
            },
            {
                type: 'away',
                playersSquads: getters.teamLineup('away'),
                ...state.awayTeam
            }
        ],

        getPlayerData: (state) => (teamType, playerId) => {
            const teamTypeKey = `${teamType}TeamPlayers`

            if (teamTypeKey in state && state[teamTypeKey][playerId]) {
                return state[teamTypeKey][playerId]
            }

            return {}
        },

        teamLineup: (state) => (teamType) => {
            const teamTypeKey = `${teamType}TeamPlayers`
            const playersIds = Object.keys(state[teamTypeKey])

            const main = []
            const substitutedOut = []
            const subs = []
            const outsiders = []

            playersIds.forEach(playerId => {
                const player = state[teamTypeKey][playerId]

                if (player.isInjured || player.isSuspended) {
                    return outsiders.push(playerId)
                }

                if (player.lineupStarting && player.substitutedOut) {
                    return substitutedOut.push(playerId)
                }

                if (player.lineupStarting === false && !player.substitutedIn) {
                    return subs.push(playerId)
                }

                return main.push(playerId)
            })

            return {
                main: main.concat(substitutedOut),
                subs,
                outsiders
            }
        }
    },

    actions: {
        async fetch ({ commit, dispatch, getters, state }, { matchId }) {
            if (state.isLoading) {
                return Promise.resolve()
            }

            commit(SET_MATCH_ID, matchId)
            commit(SET_LOADING_STATE, true)

            const probableMatchLineups = ProbableMatchLineups(matchId)
            const matchLineups = MatchLineups(matchId)

            let results = null

            try {
                results = await Promise.all([
                    Api.graphql(probableMatchLineups),
                    Api.graphql(matchLineups)
                ])
            } catch (err) {
                results = []
            }

            // сетим дланные о командах
            const [probableMatch, statMatch] = results

            let homeTeam = {}
            let awayTeam = {}

            if (statMatch && statMatch.home && statMatch.away) {
                homeTeam = statMatch.home.team || {}
                awayTeam = statMatch.away.team || {}
            } else if (!homeTeam.id && probableMatch && probableMatch.firstTeam && probableMatch.secondTeam) {
                homeTeam = probableMatch.firstTeam.team || {}
                awayTeam = probableMatch.secondTeam.team || {}
            }

            commit(SET_TEAM, { type: 'home', data: teamAdapter(homeTeam) })
            commit(SET_TEAM, { type: 'away', data: teamAdapter(awayTeam) })

            // парсим ответ и получаем составы
            const { lineups, meta } = getLineupsFromMatchStat(results)

            // если не пришел основной или предварительный состав
            // идем за составами из прошлых матчей
            if (!meta.hasMainLineup && !meta.hasProbableLineup && getters.homeTeamLastMatchId) {
                await dispatch('fetchLastMatchesLineups')
            } else {
                lineups.forEach(item => {
                    commit(SET_TEAM_PLAYERS, item)
                })

                commit(SET_META, meta)
                commit(SET_LOADING_STATE, false)
            }

            return Promise.resolve()
        },

        async fetchLastMatchesLineups ({ commit, getters }) {
            const homeMatchId = getters.homeTeamLastMatchId
            const awayMatchId = getters.awayTeamLastMatchId
            const { homeTeamId } = getters
            const { awayTeamId } = getters

            const homeTeamQuery = HomeTeamLastMatchesLineups(homeMatchId)
            const awayTeamQuery = AwayTeamLastMatchesLineups(awayMatchId)

            let results = null

            try {
                results = await Promise.all([
                    Api.graphql(homeTeamQuery),
                    Api.graphql(awayTeamQuery)
                ])
            } catch (err) {
                results = []
            }

            const lastMatchesStat = parseLastMatchesStat(results, homeTeamId, awayTeamId)

            // сетим команды
            const homeTeam = (lastMatchesStat.home && lastMatchesStat.home.team) || {}
            const awayTeam = (lastMatchesStat.away && lastMatchesStat.away.team) || {}

            commit(SET_TEAM, { type: 'home', data: teamAdapter(homeTeam) })
            commit(SET_TEAM, { type: 'away', data: teamAdapter(awayTeam) })

            // парсим ответ и получаем составы
            const { lineups, meta } = getLineupsFromMatchStat([lastMatchesStat])

            lineups.forEach(item => {
                commit(SET_TEAM_PLAYERS, item)
            })

            commit(SET_META, meta)
            commit(SET_LOADING_STATE, false)
        },

        resetAllData ({ commit }) {
            commit(RESET_ALL_DATA)
        }
    },

    mutations: {
        [SET_MATCH_ID] (state, matchId) {
            state.matchId = matchId
        },

        [SET_LOADING_STATE] (state, value) {
            state.isLoading = !!value
        },

        [SET_TEAM] (state, { type, data }) {
            if (`${type}Team` in state) {
                state[`${type}Team`] = data
            }
        },

        [SET_TEAM_PLAYERS] (state, { type, players }) {
            const propertyName = `${type}TeamPlayers`

            if (propertyName in state) {
                players.forEach(player => {
                    if (state[propertyName][player.id]) {
                        state[propertyName][player.id] = {
                            ...state[propertyName][player.id],
                            ...player
                        }
                    } else {
                        Vue.set(state[propertyName], player.id, player)
                    }
                })
            }
        },

        [SET_META] (state, metaData) {
            Object.keys(metaData).forEach(key => {
                if (key in state) {
                    state[key] = metaData[key]
                } else {
                    Vue.set(state, key, metaData[key])
                }
            })
        },

        [RESET_ALL_DATA] (state) {
            const freshState = initialState()

            Object.keys(state).forEach(stateKey => {
                state[stateKey] = freshState[stateKey]
            })
        }
    }
}
