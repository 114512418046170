import Api from '@/api'
import logger from '@/utils/logger'
import { fetchFooterList } from '@/api/graphql/blog'
import bookmakers from './bookmakers'
import tipsters from './tipsters'
import tournaments from './tournaments'

function getNameMatch (match) {
    const home = ((match.home_team || {}).team || {}).name || ''
    const away = ((match.away_team || {}).team || {}).name || ''

    return `${home} – ${away}`
}

function matchAdapter (match) {
    return {
        title: getNameMatch(match),
        id: match.id,
        webname: match.webname,
        time: match.time,
        status: (match.status || {}).id,
        route: {
            name: 'match',
            params: {
                id: match.id
            }
        }
    }
}

export default {
    namespaced: true,

    state () {
        return {
            tipsters,
            tournaments,
            bookmakers,
            live: [],
            matches: [],
            blogs: []
        }
    },

    getters: {
        tipstersLink: state => lang => state.tipsters[lang] || {},
        tournamentsLink: state => lang => state.tournaments[lang] || {},
        bookmakersLink: state => lang => state.bookmakers[lang] || {},
        blogsLink: state => state.blogs,
        matchesLink: ({ matches }) => matches,
        liveLink: ({ live }) => live
    },

    actions: {
        async fetchMatches ({ commit, state }, options = {}) {
            // не перезапрашиваем, если матчи уже есть
            if (!state.live.length && !state.matches.length) {
                const params = {
                    include: 'odds,team',
                    count: 5,
                    count_live: 0
                }

                if (options.count_live) {
                    params.count_live = options.count_live
                }

                if (options.hour_range) {
                    params.hour_range = options.hour_range
                }

                try {
                    const result = await Api.trbna({
                        path: 'matches/with_odds_for_teaser',
                        params,
                        maps: ['home_team', 'away_team']
                    })

                    if (params.count_live) {
                        commit('live', result.serialized)
                    } else {
                        commit('matches', result.serialized)
                    }
                } catch (err) {
                    logger.error(err)

                    return Promise.reject(err)
                }
            }

            return Promise.resolve()
        },
        async fetchPost ({ state, commit }) {
            if (!state.blogs.length) {
                const queryString = fetchFooterList({
                    section: 'blog',
                    first: 4,
                    fields: []
                })

                try {
                    const result = await Api.graphql(`{${queryString}}`)

                    if (result && result.list) {
                        commit('post', result.list)
                    }
                } catch (err) {
                    logger.error(err)

                    return Promise.reject(err)
                }
            }
        }
    },
    mutations: {
        live (state, matches) {
            state.live = matches.map(matchAdapter)
        },
        matches (state, matches) {
            state.matches = matches.map(matchAdapter)
        },
        post (state, posts = []) {
            state.blogs = posts.map(post => ({
                title: post.title,
                route: {
                    name: 'blog-post',
                    params: {
                        alias: post.id,
                        section: post.section
                    }
                }
            }))
        }
    }
}
