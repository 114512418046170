<template>
    <component
        :is="tagName"
        :href="href"
        :to="to"
        :target="target"
        :class="{ 'fill': fill }"
        class="ui-social-button"
    >
        <div
            :class="type"
            class="ui-social-button__wrapper"
        />

        <component
            :is="icon"
            class="ui-social-button__icon"
        />
    </component>
</template>

<script>
export default {
    name: 'UiSocialButton',

    props: {
        /**
         * Тип шаринга [facebook, instagram, telegram, twitter, vkontakte, yandex, youtube]
         */
        type: {
            type: String,
            required: true,
            validator (value) {
                return [
                    // 'facebook',
                    'telegram',
                    'twitter',
                    'vkontakte',
                    'yandex',
                    'youtube'
                ].includes(value)
            }
        },

        /**
         * Заливка кнопки
         */
        fill: {
            type: Boolean,
            default: false
        },

        /**
         * Ссылка. В случае передачи аргумента, будет сгенерирован тег `<a>`. Востальных случаях тег `<button>`
         */
        href: {
            type: String,
            default: ''
        },

        to: {
            type: Object,
            default: null
        },

        /**
         * Пирменяется только к ссылкам.
         */
        target: {
            type: String,
            default: ''
        }
    },

    computed: {
        tagName () {
            if (this.to) {
                return 'router-link'
            }

            if (this.href) {
                return 'a'
            }

            return 'button'
        },

        icon () {
            return `icon-${this.type}`
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-social-button
    width 5rem
    height @width
    position relative
    display inline-flex
    align-items center
    justify-content center
    vertical-align middle
    cursor pointer
    color $cl-white

    &__wrapper
        border-radius 50%
        transform scale(.8)
        transition transform .3s cubic-bezier(.5, .86, .4, 1.33)

        &.telegram
            background-color #34ABE3

        &.facebook
            background-color #4268B2

        &.vkontakte
            background-color #4A76A8

        &.twitter
            background-color #40ABE1

        &.yandex
            background-color #FF0200

        &.youtube
            background-color #FF0000

        +layout-xs()
            display none

    svg
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%) scale(1)
        transition transform .2s ease

    &:hover
        .ui-social-button__wrapper
            width 100%
            height @width
            transform scale(1)

        svg
            transform translate(-50%, -50%) scale(.85)

            +layout-xs()
                transform translate(-50%, -50%) scale(1)

.fill
    .ui-social-button__wrapper
        width 100%
        height @width
        transform scale(.85)

    svg
        transform translate(-50%, -50%) scale(0.7)
</style>
