<template>
    <nav class="ui-breadcrumbs">
        <span
            v-for="(item, index) in items"
            :key="item.id"
            class="ui-breadcrumbs__item"
        >
            <router-link
                v-if="item.to"
                :key="'route' + index"
                :to="item.to"
                active-class="ui-breadcrumbs__active"
                @click.native="onClick(item)"
            >
                <span>{{ item.title }}</span>
            </router-link>

            <span
                v-else
                :key="'simple' + index"
            >
                {{ item.title }}
            </span>
        </span>
    </nav>
</template>

<script>
export default {
    name: 'UiBreadcrumbs',

    props: {
        /**
         * type: Array
         * item.title - Заголовок
         * item.to - Route
         */
        items: {
            type: Array,
            default: () => []
        },

        disableMicrodata: {
            type: Boolean,
            default: false
        }
    },

    head () {
        if (this.disableMicrodata) {
            return {}
        }

        return {
            script: [{
                type: 'application/ld+json',
                json: {
                    '@context': 'http://schema.org/',
                    '@type': 'BreadcrumbList',
                    itemListElement: this.breadcrumbsMicrodata
                }
            }]
        }
    },

    computed: {
        breadcrumbsMicrodata () {
            return this.items
                .filter(item => !!(item && item.to))
                .map((item, index) => {
                    const route = this.$router.resolve(item.to)

                    if (route && route.resolved && route.resolved.path) {
                        return {
                            '@type': 'ListItem',
                            position: index + 1,
                            item: {
                                '@id': `${process.env.DOMAIN_URL}${route.resolved.path}`,
                                name: item.title
                            }
                        }
                    }

                    return {}
                })
        }
    },

    methods: {
        onClick (item) {
            this.$emit('click', item)
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-breadcrumbs
    display block
    font-size 1.2rem
    line-height 1.6rem

    &__item
        word-break break-word

        & a
            color $cl-text

        &:last-child
            color $cl-secondary

        &:not(:last-child)::after
            content '/'
            margin 0 .3rem
            color $cl-secondary
</style>
