import logger from '@/utils/logger'

const SET_ODD = 'SET_ODD'

export default {
    namespaced: true,

    state () {
        return {
            odd: {
                name: '',
                matchName: ''
            }
        }
    },

    actions: {
        setOddFromPrediction ({ commit }, prediction) {
            try {
                const name = prediction.odds.fullName
                const homeTeamName = prediction.match.homeTeam.team.name
                const awayTeamName = prediction.match.awayTeam.team.name
                const matchName = `${homeTeamName} - ${awayTeamName}`

                commit(SET_ODD, { name, matchName })
            } catch (err) {
                logger.error(err)
            }
        }
    },

    mutations: {
        [SET_ODD] (state, odd) {
            state.odd = odd
        }
    }
}
