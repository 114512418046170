export default {
    image: {
        resolver (el) {
            return `<img src="${el.value.source}" alt="sb_image"/>`
        }
    },
    twitter: {
        resolver (el) {
            return `<div class="" data-id="${el.value.id}"></div>`
        }
    },
    iframe: {
        resolver (el) {
            return `<iframe class="" width="${el.value.width || 320}" height="${el.value.height || 180}" src="${el.value.source}" allowfullscreen></iframe>`
        }
    },
    youtube: {
        resolver (el) {
            return `<iframe class="" width="${el.value.width || 320}" height="${el.value.height || 180}" src="${el.value.source}" allowfullscreen></iframe>`
        }
    },
    link: {
        resolver (el) {
            if (el.value && el.value.href && !el.value.href.match(/betting\.team/)) {
                return `<a rel="nofollow" href="${el.value.href}">${el.value.text}</a>`
            }

            return el.value ? `<a href="${el.value.href}">${el.value.text}</a>` : ''
        }
    }
}

