<template>
    <div class="ui-zero">
        <div class="ui-zero__content">
            <!-- @slot Слот для контента над бородатым мужиком -->
            <slot />
        </div>

        <IconMan />
    </div>
</template>

<script>
import IconMan from '@/ui/components/Zero/inner/man.vue'

export default {
    name: 'UiZero',

    components: {
        IconMan
    }
}
</script>

<style lang="stylus" scoped>
    .ui-zero
        padding 2.5rem 2.5rem 0 2.5rem
        font-size $font-size-base
        text-align center

        +layout-sm-and-up()
            font-size $font-size-heading-5

        &__content
            max-width 55rem
            margin 0 auto
            animation zero-data-slide .6s ease-out forwards

        .ui-icon
            margin-top 2.5rem
            vertical-align top

    @keyframes zero-data-slide
        0%
            opacity 0
            transform translate3d(0, -2rem, 0)

        100%
            opacity 1
            transform translate3d(0, 0, 0)
</style>
