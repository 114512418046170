<template>
    <ul class="the-footer-menu">
        <li
            v-for="item in footerLinks"
            :key="item.key"
            class="the-footer-menu__item"
        >
            <router-link
                :to="item.to"
                rel="nofollow"
                class="the-footer-menu__link"
                @click.native="sendAnalytic(item.key)"
            >
                {{ item.title }}
            </router-link>
        </li>
    </ul>
</template>

<i18n>
{
    "ru": {
        "rules": "Правила",
        "policy": "Политика конфиденциальности",
        "agreement": "Соглашение",
        "contacts": "Контакты"
    },
    "en": {
        "rules": "Rules",
        "policy": "Privacy policy",
        "agreement": "User agreement",
        "contacts": "Contacts"
    }
}
</i18n>

<script>
export default {
    name: 'TheFooterMenu',

    data () {
        return {
            links: [
                {
                    title: this.$t('rules'),
                    key: 'rules',
                    to: {
                        name: 'rules'
                    }
                },
                {
                    title: this.$t('policy'),
                    key: 'policy',
                    to: {
                        name: 'privacy-policy'
                    }
                },
                {
                    title: this.$t('agreement'),
                    key: 'agreement',
                    languages: ['ru'],
                    to: {
                        name: 'user-agreement'
                    }
                },
                {
                    title: this.$t('contacts'),
                    key: 'contacts',
                    languages: ['ru'],
                    to: {
                        name: 'contacts'
                    }
                }
            ]
        }
    },

    computed: {
        footerLinks () {
            return this.links
                .filter(item => !item.languages || item.languages.some(lang => lang === this.$i18n.locale))
        }
    },

    methods: {
        sendAnalytic (key) {
            this.$pivik.event('footer', 'click', key)
        }
    }
}
</script>

<style lang="stylus" scoped>
.the-footer-menu
    margin 0
    padding 0
    list-style none

    display flex
    flex-direction row
    flex-wrap wrap
    justify-content center

    &__item
        color #666D74

        &:not(:last-child)::after
            margin 0 1.2rem
            content ''
            font-family sans-serif

            +layout-xs()
                content '•'

    &__link
        line-height 1.7
        color #666D74
        text-decoration none
        transition color .3s

        &:hover,
        &:focus
            color $cl-white
</style>
