import matchevents from './match-events'
import teaser from './teaser'
import headToHead from './head-to-head'
import bookmakers from './bookmakers'
import lineups from './lineups'

export default {
    namespaced: true,
    modules: {
        bookmakers,
        matchevents,
        teaser,
        headToHead,
        lineups
    }
}

