<template>
    <div class="ui-rails">
        <div class="ui-rails__station">
            <slot />
        </div>

        <div
            ref="rails"
            class="ui-rails__rails"
        >
            <div
                ref="train"
                class="ui-rails__train"
                :style="trainStyle"
            >
                <slot name="train" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'UiRails',

    props: {
        additionalOffsetTop: {
            type: Number,
            default: 0
        }
    },

    computed: {
        ...mapGetters('ads', ['isDesktopBrandingVisible']),
        trainStyle () {
            return {
                top: `${this.offsetTop}px`
            }
        },

        offsetTop () {
            return this.defaultOffsetTop + this.additionalOffsetTop
        },

        defaultOffsetTop () {
            return this.isDesktopBrandingVisible ? 150 : 60
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-rails
    display flex
    flex-direction column
    height 100%

    &__rails
        position relative
        flex 1 1

    &__train
        position sticky

        &::before,
        &::after
            display table
            content ''
            clear both
</style>
