<template>
    <PopupRefundForm>
        <img
            slot="top"
            class="popup-refund-offer__img"
            src="https://pictures.betting.team/image/17c2b0f3-0cb8-46d6-b487-962ce928798c"
            alt="refund image"
            srcset="
                https://pictures.betting.team/image/17c2b0f3-0cb8-46d6-b487-962ce928798c 2x
            "
        />

        <div slot="title">
            {{ $t("header") }}
        </div>
        <div class="popup-refund-offer__text">
            {{ $t("text") }}
        </div>

        <button
            :pending="pending"
            class="popup-refund-offer__button popup-refund-offer__button--stretch"
            @click="refund"
        >
            {{ $t("btn") }}
        </button>
    </PopupRefundForm>
</template>

<i18n>
{
    "ru": {
        "header": "Получите бесплатно 5000 В!",
        "text": "У вас очень мало беттингов, мы хотим подарить вам еще.",
        "btn": "Получить 5000 В"
    },
    "en": {
        "header": "Get more virtual currency free!",
        "text": "If your balance is below 1000 and you have no active tips, get more virtual currency",
        "btn": "Get 5000 В"
    }
}
</i18n>

<script>
import PopupRefundForm from "@/components/Popups/Refund/PopupRefundForm.vue";

export default {
    name: "PopupRefundOffer",

    components: {
        PopupRefundForm,
    },

    data() {
        return {
            pending: false,
            error: "",
        };
    },

    methods: {
        refund() {
            this.$emit("refund");
        },

        close() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="stylus" scoped>
.popup-refund-offer
    &__img
        margin-bottom 3rem
        max-width 40rem

        +layout-xs()
            display none

    &__text
        margin-bottom 3.5rem

    &__button
        button(5, 'contained', 'primary')
        margin-bottom 2rem
</style>
