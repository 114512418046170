<template>
    <div
        class="burger"
        :class="burgerClass"
        @click="toggle"
    >
        <span />
        <span />
        <span />
    </div>
</template>

<script>
export default {
    name: 'TheHeaderBurger',

    model: {
        prop: 'isOpen',
        event: 'toggle'
    },

    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        burgerClass () {
            return {
                active: this.isOpen
            }
        }
    },

    methods: {
        toggle () {
            this.$emit('toggle', !this.isOpen)
        }
    }
}
</script>

<style lang="stylus" scoped>
.burger
    position relative
    width 3rem
    height @width
    cursor pointer
    transition transform .3s
    -webkit-tap-highlight-color transparent

    span
        position absolute
        left .4rem
        right .4rem
        width 2.2rem
        height .2rem
        background-color #fff
        transform-origin center center
        transition transform .3s, opacity .3s, background-color .3s

        &:nth-child(1)
            top .8rem

        &:nth-child(2)
            top 1.4rem

        &:nth-child(3)
            top 2rem

    &.active
        transform rotate(-90deg)

    &.active span:nth-child(1)
        transform translate(0, .6rem) rotate(-45deg)

    &.active span:nth-child(2)
        opacity 0

    &.active span:nth-child(3)
        transform translate(0, -0.6rem) rotate(45deg)

</style>
