<template>
    <div class="adfox__wrapper">
        <div
            v-show="true"
            :id="adFoxPlacementId"
        />
    </div>
</template>

<script>
import { decode } from '@/utils/base64Functions'

import configs from '@/adv.config.json'

export default {
    name: 'AdFoxBanner',
    props: {
        placement: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            id: Date.now(),
            adFoxPlacementId: `adfox_${this.id}`,
            bannerData: null
        }
    },

    computed: {
        getConfigParams () {
            return configs?.[this.placement]?.adFoxParams
        }
    },

    mounted () {
        this.adFoxPlacementId = `adfox_${this.id}__${this.placement}`

        this.$nextTick(() => {
            console.info(this.placement, { getConfigParams: this.getConfigParams })
            if (this.getConfigParams) {
                window.yaContextCb.push(() => {
                    window.Ya.adfoxCode.create({
                        ownerId: 400319,
                        containerId: this.adFoxPlacementId,
                        params: { ...this.getConfigParams },
                        // onLoad: (e) => {
                        //     console.info({ e }, this.placement)
                        // },
                        onLoad: ({ bundleParams: { htmlBase64 } }) => {
                            try {
                                console.info('render Yandex')
                                this.$emit('render')

                                const bannerData = JSON.parse(decode(htmlBase64))

                                this.bannerData = bannerData
                                this.$emit('receive', { ...bannerData, imageSrc: bannerData.image || bannerData.imageSrc })

                                console.info({ bannerData }, 'onLoad', this.adFoxPlacementId)
                            } catch (err) {
                                console.info({ err }, 'onLoad', this.adFoxPlacementId)
                            }
                        },
                        onRender: () => {
                            console.info(this.placement, 'Rendered')
                        },
                        onError: (err) => {
                            console.error(this.placement, { err })
                        }
                    })
                })
            }
        })
    }
}
</script>

<style lang="stylus">
.adfox {
    &__wrapper {
        position absolute
        display flex
        align-items center
        justify-content center

        overflow hidden

        width 5px

        z-index -10

        top -100000px
        left -100000px
    }
}
</style>
