<template>
    <div class="password-input__wraper">
        <input
            :id="inputId"
            :value="value"
            :class="{ 'password-input--error': hasError }"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            class="password-input"
            :type="visibility"
            name="password"
            @blur="$emit('blur', $event)"
            @input="$emit('input', $event.target.value)"
        >
        <button
            class="password-input__eye-button"
            tabindex="-1"
            @click.prevent="toggleVisibility"
        >
            <HidePasswordEyeIcon v-if="visibility === 'password'" />
            <ShowPasswordEyeIcon v-else />
        </button>
    </div>
</template>

<script>
import ShowPasswordEyeIcon from '@/assets/svg/eye-show.icon.svg'
import HidePasswordEyeIcon from '@/assets/svg/eye-hide.icon.svg'

export default {
    name: 'PasswordInput',

    components: {
        ShowPasswordEyeIcon,
        HidePasswordEyeIcon
    },

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            type: String,
            required: true
        },

        inputId: {
            type: String,
            default: ''
        },

        hasError: {
            type: Boolean,
            default: false
        },

        autocomplete: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            visibility: 'password'
        }
    },

    methods: {
        toggleVisibility () {
            if (this.visibility === 'text') {
                this.visibility = 'password'
            } else {
                this.visibility = 'text'
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.password-input
    input()
    width 100%

    &__wraper
        position relative

    &__eye-button
        button(5, 'icon', 'text')
        position absolute
        height 1.6rem
        top 50%
        transform translateY(-50%)
        right .8rem

</style>
