<template>
    <div
        class="ui-user-card"
        :class="userCardClass"
    >
        <router-link
            v-if="userId"
            :to="pofileRoute"
            class="ui-user-card__avatar"
        >
            <UiAvatar
                :size="size"
                :src="avatar"
            />
        </router-link>
        <div
            v-else
            class="ui-user-card__avatar"
        >
            <UiAvatar
                :size="size"
                :src="avatar"
            />
        </div>

        <div class="ui-user-card__main">
            <slot name="header" />
            <slot name="content" />
        </div>
    </div>
</template>

<script>
import UiAvatar from '@/ui/components/Avatar/index.vue'

export default {
    name: 'UiUserCard',

    components: {
        UiAvatar
    },

    props: {
        /**
         * URL аватарки
         */
        avatar: {
            type: String,
            default: ''
        },

        /**
         * Размер аватарки карточки
         * `xxs, xs, sm, normal, md, l`
         */
        size: {
            type: String,
            default: 'normal'
        },

        /**
         * id профиля, если передать, то будут созданы ссылки на профиль
         */
        userId: {
            type: [Number, String],
            default: ''
        },

        /**
         * Позиционирует аватарку справа от контенда
         */
        rightAvatar: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        userCardClass () {
            return {
                'is-right': this.rightAvatar
            }
        },

        pofileRoute () {
            return {
                name: 'profile',
                params: {
                    id: this.userId
                }
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-user-card
    display flex
    align-items flex-start

    &__avatar
        order 0
        margin-right 15px
        font-size 0

    &__main
        order 1
        flex 1
        align-self center

    &.is-right &__avatar
        order 2
        margin-left 15px
        margin-right 0
</style>
