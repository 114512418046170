import { THIRTY_MINUTES_CACHE_CONTROL } from '@/constants'

const top = () => import('@/views/profile/layout/top.vue')

export default [
    {
        path: 'profile',
        redirect: {
            name: 'home'
        },
        components: {
            top,
            default: () => import('@/views/profile/layout/index.vue'),
            right: () => import('@/views/profile/layout/right.vue')
        },
        children: [
            {
                path: ':id(\\d+)',
                name: 'profile',
                component: () => import('@/views/profile/predictions/index.vue'),
                meta: {
                    pageType: 'user',
                    cacheControl: THIRTY_MINUTES_CACHE_CONTROL
                }
            },
            {
                path: ':id(\\d+)/stat',
                name: 'profile-stat',
                component: () => import('@/views/profile/stat/index.vue'),
                meta: {
                    pageType: 'user_stats',
                    cacheControl: THIRTY_MINUTES_CACHE_CONTROL
                }
            }
        ]
    }
]
