function pageInfo () {
    return `{
                totalCount
                hasNextPage
                endCursor
            }`
}

/**
 * Получение ленты блога
 * @param {Number} first - количество публикаций
 * @param {String} after - endCursor
 * @param {String} section - раздел
 * @param {String} subsection - подраздел
 * @param fields ['subtitle', 'image', 'ctime', 'mtime', 'commentsCount']
 * @returns {string}
 */
export function fetchList ({ first, after, section, subsection, fields = ['subtitle', 'image', 'ctime', 'mtime', 'commentsCount'] }) {
    return `postsList(
                first: ${first},
                after: ${after ? `"${after}"` : null},
                section: "${section}"
                ${subsection ? `subsection: "${subsection}"` : ''}
            ) {
                list {
                    id
                    title
                    section
                    subsection
                    h1
                    ${fields.join(' ')}
                }
                pageInfo ${pageInfo()}
            }`
}

export function fetchFooterList ({ first, section, subsection }) {
    return `postsList(
            first: ${first},
            section: "${section}"
            ${subsection ? `subsection: "${subsection}"` : ''}
        ) {
            list {
                id
                title
                section
            }
        }`
}

export function fetchListIds ({ first, after, section, subsection }) {
    return `{
        postsList(
            first: ${first},
            after: ${after ? `"${after}"` : null},
            section: "${section}"
            ${subsection ? `subsection: "${subsection}"` : ''}
        ) {
            list {
                id
            }
            pageInfo ${pageInfo()}
        }
    }`
}

/**
 * Получение поста
 * @param {String} postId id поста
 * @returns {string}
 */
export function fetchItem ({ postId }) {
    return `{
        post(id: "${postId}") {
            id
            title
            h1
            metaDescription
            section
            subsection
            subtitle
            image
            html
            ctime
            mtime
            disableComments
            author
            authorUrl
        }
    }`
}

/**
 * Получение новостей
 * @param {Number} first - количество публикаций
 * @param {String} section - раздел
 * @returns {string}
 */
export function fetchNews ({ first, section }) {
    return `{
        postsList(
            first: ${first},
            after: null,
            section: "${section}"
        ) {
            list {
                id
                section
                subsection
                h1
                mtime
            }
        }
    }`
}
