<template>
    <div class="socials">
        <UiSocialButton
            v-for="({ name, href }) in items"
            :key="name"
            :href="href"
            :type="name"
            target="_blank"
            rel="nofollow"
            class="socials__btn"
            @click.native="sendEvent(name)"
        />
    </div>
</template>

<script>
import config from '@/config.json'

export default {
    name: 'TheFooterSocials',

    computed: {
        socialsByLang () {
            return config.socials[this.$i18n.locale] || {}
        },

        items () {
            return Object.keys(this.socialsByLang)
                .map(name => ({
                    name,
                    href: this.socialsByLang[name]
                }))
        }
    },

    methods: {
        sendEvent (name) {
            this.$pivik.event('footer', 'click', name)
        }
    }
}
</script>

<style lang="stylus" scoped>
.socials
    display flex

    +layout-xs()
        width 100%
        justify-content center

        >>> .ui-share-button
            width 4rem
            height @width

    &__btn
        margin 0 1rem

        &:first-child
            margin-left 0

        &:last-child
            margin-right 0

</style>
