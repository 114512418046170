import * as icons from './icons'
import * as components from './components'


// eslint-disable-next-line func-names
export default function (Vue) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const name in icons) {
        Vue.component(name, icons[name])
    }

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const name in components) {
        Vue.component(name, components[name])
    }
}
