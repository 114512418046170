/* eslint-disable no-console */
import Api from '@/api'
import logger from '@/utils/logger'
import {
    fetchBookmaker,
    fetchRatings,
    fetchRatingsList,
    getBookmakerReview,
    createBookmakerReview,
    updateBookmakerReview
} from '@/api/graphql/bookmakers-rating'
import crossFetch from 'cross-fetch'

import { ArticleResolver } from '@frontend/structured-article-resolver'
import schema from '@/utils/sb-schemas'
import _escape from '@/utils/escape'

import reviews from './reviews'
import comments from './comments'
import bonuses from './bonuses'


const resolver = new ArticleResolver(schema)
const COUNTRY_CODE_KEY = 'geo-ip-country-code'

function bookmakerAdapter (bookmaker, locale) {
    const features = bookmaker.featureRatings.map(feature => ({
        name: feature.feature.name,
        score: feature.score / 20
    }))

    // BIW-317 Добавить "оценку пользователей" в список оценок букмекера в РБ
    if (bookmaker.statistics.reviewScoreAvg) {
        features.splice(1, 0, {
            name: locale === 'ru' ? 'Оценка пользователей' : 'User rating',
            score: bookmaker.statistics.reviewScoreAvg / 20
        })
    }

    // BI-3200 на основное место в рейтинге, шапке бука и в карточке бука в рейтинге выносим "Оценку Betting Insider"
    [bookmaker.biRatings, ...bookmaker.featureRatings] = features

    return bookmaker
}

const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE'
const SET_MENU_ITEMS = 'SET_MENU_ITEMS'
const SET_RATING = 'SET_RATING'
const SET_RATING_TEXT = 'SET_RATING_TEXT'
const SET_BOOKMAKER = 'SET_BOOKMAKER'
const SET_BOOKMAKER_TEXT = 'SET_BOOKMAKER_TEXT'
const SET_REVIEW = 'SET_REVIEW'
const SET_HAS_ERROR = 'SET_HAS_ERROR'


export default {
    namespaced: true,

    modules: {
        reviews,
        comments,
        bonuses
    },

    state () {
        return {
            geo: '',
            menu: [],
            rating: {},
            review: {},
            bookmaker: {},
            errors: {
                rating: false,
                bookmaker: false,
                reviews: false,
                myReviews: false,
                bonuses: false
            }
        }
    },

    actions: {
        getRatings ({ commit, state, dispatch }) {
            const queryString = fetchRatings({ countryCode: state.geo })

            return Api.graphql(queryString)
                .then(data => {
                    const menuItems = data.items

                    commit(SET_MENU_ITEMS, menuItems)

                    dispatch('setHasError', { key: 'rating', value: false })

                    return menuItems
                })
                .catch(err => {
                    logger.error(err)

                    return Promise.reject(err)
                })
        },

        getBookmaker ({ commit, state, dispatch }, { webname }) {
            const queryString = fetchBookmaker({ webname, countryCode: state.geo })

            return Api.graphql(queryString)
                .then(bookmaker => Promise.resolve(
                    resolver.resolve(JSON.parse(bookmaker.text))
                        .then((html) => {
                            const locale = Api.getLoc()

                            commit(SET_BOOKMAKER, bookmakerAdapter(bookmaker, locale))
                            commit(SET_BOOKMAKER_TEXT, html)
                            dispatch('setHasError', { key: 'bookmaker', value: false })
                        })
                        .catch(console.error)
                ))
                .catch(err => {
                    logger.error(err)

                    commit(SET_BOOKMAKER, {})

                    return Promise.reject(err)
                })
        },

        getBookmakers ({ commit, state }, { webname }) {
            const queryString = fetchRatingsList({ webname, countryCode: state.geo })

            return Api.graphql(queryString)
                .then(data => Promise.resolve(
                    resolver.resolve(JSON.parse(data.text))
                        .then((html) => {
                            const locale = Api.getLoc()

                            data.bookmakers = data.bookmakers.map(el => bookmakerAdapter(el.bookmaker, locale))

                            commit(SET_RATING, data)
                            commit(SET_RATING_TEXT, html)

                            return data
                        })
                        .catch(console.error)
                ))
                .catch(err => {
                    logger.error(err)

                    commit(SET_RATING, {})

                    return Promise.reject(err)
                })
        },

        getReview ({ commit, state, dispatch }, { webname }) {
            const queryString = getBookmakerReview({ webname, countryCode: state.geo })

            return Api.graphql(queryString)
                .then(data => {
                    dispatch('setHasError', { key: 'myReview', value: false })
                    commit(SET_REVIEW, data.viewerReview || {})
                })
                .catch(err => {
                    logger.error(err)

                    commit(SET_REVIEW, {})

                    return Promise.reject(err)
                })
        },

        createReview ({ commit, state }, { bookmakerId, score, description }) {
            const queryString = createBookmakerReview({
                bookmakerId,
                score,
                countryCode: state.geo,
                description: _escape(description)
            })

            return Api.graphql(queryString, { type: 'mutation' })
                .then(data => {
                    const { review } = data.createBookmakerReview

                    if (review) {
                        commit(SET_REVIEW, review)
                        commit('reviews/review', { review })
                    }
                })
        },

        updateReview ({ commit, state }, { id, score, description }) {
            const queryString = updateBookmakerReview({
                id,
                score,
                countryCode: state.geo,
                description: _escape(description)
            })

            return Api.graphql(queryString, { type: 'mutation' })
                .then(data => {
                    const { review } = data.updateBookmakerReview

                    if (review) {
                        commit(SET_REVIEW, review)
                        commit('reviews/update', { review })
                    }
                })
        },

        updateGeo ({ commit }) {
            const url = `${process.env.DOMAIN_URL}/current-geo`

            crossFetch(url)
                .then((res) => res.json())
                .then(res => {
                    const countryCode = (res && res[COUNTRY_CODE_KEY]) || ''

                    commit(SET_COUNTRY_CODE, countryCode)
                })
        },

        setHasError ({ commit }, { key, value }) {
            commit(SET_HAS_ERROR, { key, value })
        }
    },

    mutations: {
        [SET_COUNTRY_CODE] (state, countryCode) {
            state.geo = countryCode
        },

        [SET_MENU_ITEMS] (state, items) {
            state.menu = items
        },

        [SET_RATING] (state, data) {
            state.rating = data
        },

        [SET_BOOKMAKER] (state, bookmaker = {}) {
            state.bookmaker = bookmaker
        },

        [SET_BOOKMAKER_TEXT] (state, description) {
            state.bookmaker.resolvedBody = description
        },

        [SET_RATING_TEXT] (state, description) {
            state.rating.resolvedBody = description
        },

        [SET_REVIEW] (state, data) {
            state.review = data
        },

        [SET_HAS_ERROR] (state, { key, value }) {
            state.errors[key] = value
        }
    }
}
