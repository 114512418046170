<template>
    <div class="report">
        <span class="report__header">{{ $t('header') }}</span>

        <ReportSelect
            v-model="selectedReason"
            :items="reasons"
            class="report__select"
        />

        <div
            v-show="!selectedReason"
            class="report__helper"
        >
            {{ $t('helper') }}
        </div>

        <button
            :pending="pending"
            :disabled="selectedReason === null"
            class="report__button report__button--stretch"
            @click="$emit('report', selectedReason)"
        >
            {{ $t('send') }}
        </button>
    </div>
</template>

<i18n>
{
    "ru": {
        "header": "Что случилось?",
        "helper": "Выберите причину жалобы",
        "send": "Отправить жалобу",
        "spam": "Спам",
        "censor": "Мат",
        "swears": "Оскорбления",
        "fraud": "Продажа прогнозов / мошенничество",
        "other": "Другое"
    },
    "en": {
        "header": "What's wrong?",
        "helper": "Select ban reason",
        "send": "Send report",
        "spam": "Spam",
        "censor": "Hate speech",
        "swears": "Swears",
        "fraud": "Fraud",
        "other": "Other"
    }
}
</i18n>

<script>
import ReportSelect from '@/components/Popups/Report/PopupReportSelect.vue'

export default {
    name: 'PopupReportContent',

    components: {
        ReportSelect
    },

    props: {
        pending: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            reasons: [
                { text: this.$t('spam'), value: 'SPAM' },
                { text: this.$t('censor'), value: 'USER_CENSOR' },
                { text: this.$t('swears'), value: 'SWEARS' },
                { text: this.$t('fraud'), value: 'FRAUD' },
                { text: this.$t('other'), value: 'OTHER' }
            ],

            selectedReason: null
        }
    }
}
</script>


<style lang="stylus" scoped>
.report
    display flex
    flex-direction column
    position relative

    &__header
        margin-bottom 3rem
        font-size 2.4rem
        font-weight $font-weight-bold
        line-height 3.2rem
        letter-spacing .5px
        text-transform uppercase

        +layout-xs()
            margin-bottom 1rem

    &__select
        max-width 35rem
        margin 0 auto 4rem auto

    &__helper
        position absolute
        bottom 4rem
        left 0
        right 0
        color $cl-secondary
        font-style italic
        font-family $font-family-content
        font-size 1.2rem

    &__button
        button(5, 'contained', 'primary')

</style>
