<template>
    <div>
        <RefreshableDfp
            :placement="placement"
            @receive="showCreative"
            @render="renderHandler"
        />
        <div
            v-if="creative.link && !isPageScrolledUp"
            class="desktop-branding"
        >
            <div
                v-if="creative.disclaimer"
                class="desktop-branding__disclaimer-wrapper"
            >
                <AdDisclaimer
                    v-if="creative.disclaimer"
                    :text="creative.disclaimer"
                />
            </div>

            <a
                v-if="creative.link"
                :href="creative.link"
                target="_blank"
                @click="creativeClickHandler"
            >
                <img
                    v-show="isImageLoaded"
                    :src="creative.image"
                    :alt="creative.bookmaker"
                    class="desktop-branding__image"
                    @load="onImageLoaded"
                >
            </a>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'
import AdDisclaimer from '@/components/DFP/AdDisclaimer.vue'

export default {
    name: 'DFPDesktopBranding',

    components: {
        RefreshableDfp,
        AdDisclaimer
    },

    data () {
        return {
            creative: {},
            placement: 'desktop_branding',
            isImageLoaded: false
        }
    },

    computed: {
        ...mapGetters('ads', ['isDesktopBrandingVisible']),
        ...mapState('ads', ['isPageScrolledUp'])

    },

    methods: {
        ...mapActions({
            setIsDesktopBrandingLoaded: 'ads/setIsDesktopBrandingLoaded'
        }),

        showCreative (message) {
            if (message) {
                this.creative.link = message.link
                this.creative.image = message.imageSrc
                this.creative.bookmaker = message.bookmakerName || 'unknown'
                this.creative.disclaimer = message.disclaimer

                this.$pivik.event('dfp_show', 'branding', this.creative?.bookmaker)
            }
        },

        creativeClickHandler () {
            this.$pivik.event('dfp_click', 'branding', this.creative?.bookmaker)
        },

        renderHandler () {
            this.creative = {
                link: '',
                image: '',
                bookmaker: '',
                disclaimer: ''
            }
        },

        onImageLoaded () {
            this.setIsDesktopBrandingLoaded(true)
            this.isImageLoaded = true
        }
    }
}
</script>

<style lang="stylus" scoped>
.desktop-branding
    position absolute
    top 0
    display block
    height 9rem
    width 100%
    margin 0 -1.2rem

    &__image
        position absolute
        top 0
        left 50%
        transform translateX(-50%)
        z-index 1

    &__disclaimer-wrapper
        position absolute
        top 1rem
        left 1rem
        border-radius $radius-block
        background rgba($cl-white, .95)
        color $cl-text-dark
        width 3.6rem
        height 3.6rem
        padding .6rem
        box-sizing border-box
        z-index 2
        font-size 0
</style>
