<template>
    <div>
        <button
            :class="`${swiperClass}__button-prev`"
            class="swiper-nav__button-prev"
        >
            <IconArrowLeft class="arrow-icon" />
        </button>

        <button
            :class="`${swiperClass}__button-next`"
            class="swiper-nav__button-next"
        >
            <IconArrowLeft class="arrow-icon" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'SwiperNavButtons',

    props: {
        swiperClass: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="stylus" scoped>
.swiper-nav
    &__button-prev,
    &__button-next
        position absolute
        top 50%
        z-index 9
        transform translateY(-50%)

        width 3.2rem
        height @width
        display flex
        justify-content center
        align-items center
        background-color $cl-white
        border 1px solid $cl-secondary-light
        cursor pointer
        border-radius 100rem
        color $cl-text-dark
        outline none

        +layout-sm()
            display none

        &:hover .arrow-icon
            opacity .6

    &__button
        &-prev
            left -1rem

        &-next
            right -1rem

            & .arrow-icon
                transform rotate(180deg)

        &--disabled
            display none

</style>
