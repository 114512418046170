<template>
    <div class="footer-links">
        <TheFooterLinksItem
            v-for="(item, index) in links"
            :key="item.type"
            :type="item.type"
            :list="item.list"
            :class="'footer-links__' + (index + 1)"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheFooterLinksItem from '@/components/TheFooter/TheFooterLinksItem.vue'
import { predictionsPagesLinks } from '@/components/TheFooter/constants'

export default {
    name: 'TheFooterLinks',

    components: {
        TheFooterLinksItem
    },

    computed: {
        ...mapGetters('footer', ['tipstersLink', 'matchesLink', 'liveLink', 'tournamentsLink', 'blogsLink', 'bookmakersLink']),
        lang () {
            return this.$i18n.locale
        },

        isEn () {
            return this.lang === 'en'
        },

        links () {
            const links = [
                {
                    type: 'matches',
                    list: this.matchesLink
                },
                {
                    type: 'tipsters',
                    list: this.tipstersLink(this.lang)
                },
                {
                    type: 'tournaments',
                    list: this.tournamentsLink(this.lang)
                }
            ]

            if (this.isEn) {
                links.unshift({
                    type: 'live',
                    list: this.liveLink
                })

                links.push({
                    type: 'blogs',
                    list: this.blogsLink
                })
            } else {
                links.push({
                    type: 'bookmakers',
                    list: this.bookmakersLink(this.lang)
                })

                links.push({
                    type: 'predictions-pages',
                    list: predictionsPagesLinks
                })
            }

            return links
        }
    }
}
</script>

<style lang="stylus" scoped>
.footer-links
    margin 0 auto
    max-width 118rem
    padding 5rem 3rem
    font-size 1.4rem
    display grid
    grid-column-gap 3rem
    grid-row-gap 3rem
    grid-template-areas "live matches tipsters tournaments blog blog"

    >>> .ui-accordion
        margin-top 0!important

    &__1
        grid-area live

    &__2
        grid-area matches

    &__3
        grid-area tipsters

    &__4
        grid-area tournaments

    &__5, &__6
        grid-area blog

    @media(max-width 1100px)
        grid-template-areas "live     matches     blog blog"\
        "tipsters tournaments blog blog"

    +layout-sm()
        padding 2rem

    +layout-xs()
        grid-row-gap 0
        padding 0 2rem

+layout-xs()
    .footer-links
        grid-template-areas "live"\ "matches"\ "tipsters"\ "tournaments"\ "blog"

    >>>.ui-accordion__content
        transition-delay .2s
        transition all

    >>>.ui-accordion__header-content
        border-bottom 1px solid #30343E
        transition-delay .2s
        transition all

    >>>.is-open .ui-accordion__header-content
        border-bottom none

    >>>.is-open .ui-accordion__content
        border-bottom 1px solid #30343E
</style>
