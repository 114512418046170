<template>
    <div />
</template>

<script>
import { getCrossDomainUrl } from '@/utils/get-cross-domain-auth-url'
import { getSessionId } from '@/utils/session-storage'

export default {
    name: 'CrossAuth',

    mounted () {
        this.postSessionIdToParent()
    },

    methods: {
        getCrossDomainUrl,

        postSessionIdToParent () {
            const event = {
                event: 'user:auth',
                sessionId: getSessionId()
            }

            window.parent.postMessage(event, this.getCrossDomainUrl())
        }
    }
}
</script>
