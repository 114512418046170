import Api from '@/api'
import logger from '@/utils/logger'
import { isActive } from '@/utils/match'

const SET_TEASER_MATCHES = 'SET_TEASER_MATCHES'
const SET_HAS_ERROR = 'SET_HAS_ERROR'

export default {
    namespaced: true,

    state () {
        return {
            teaser: [],
            hasError: false
        }
    },

    actions: {
        async fetch ({ commit, dispatch }, params = {}) {
            const { matchId, ...otherParams } = params
            const defaultParams = {
                include: 'odds,team',
                count: matchId ? 11 : 10,
                count_live: 0
            }

            try {
                const result = await Api.trbna({
                    path: 'matches/with_odds_for_teaser',
                    maps: ['home_team', 'away_team'],
                    params: {
                        ...defaultParams,
                        ...otherParams
                    }
                })

                const matchList = (result && result.serialized) || []

                if (matchId) {
                    const index = matchList.findIndex(item => item.webname === matchId || item.id === matchId)
                    const startIndex = index === -1 ? 10 : index

                    matchList.splice(startIndex, 1)
                }

                // Перемещаем live матчи в начало массива с сохранением сортировки
                matchList.sort((matchA, matchB) => {
                    if (isActive(matchA.state)) {
                        if (isActive(matchB.state)) {
                            return 0
                        }

                        return -1
                    }

                    if (isActive(matchB.state)) {
                        return 1
                    }

                    return 0
                })

                const stadiumIds = matchList.map(match => match.stadium_id)

                const stadiums = await dispatch('fetchStadiums', stadiumIds)

                matchList.forEach(match => {
                    const stadium = stadiums.find(item => item.id === match.stadium_id) || {}

                    match.stadium = { id: match.stadium_id, ...stadium }
                })

                if (matchList.length >= 36) {
                    commit('footer/matches', matchList.splice(36, 42), { root: true })
                }

                commit(SET_TEASER_MATCHES, matchList)

                commit(SET_HAS_ERROR, false)

                return Promise.resolve()
            } catch (err) {
                logger.error(err)

                commit(SET_HAS_ERROR, true)

                return Promise.reject(err)
            }
        },

        async fetchStadiums (context, stadiumIds) {
            if (!Array.isArray(stadiumIds)) {
                return []
            }

            try {
                const result = await Api.trbna({
                    path: 'stadiums/by_ids',
                    params: {
                        ids: stadiumIds.join(',')
                    }
                })

                return result.serialized || []
            } catch (err) {
                logger.error(err)

                return []
            }
        }
    },

    mutations: {
        [SET_TEASER_MATCHES] (state, matches) {
            state.teaser = matches
        },

        [SET_HAS_ERROR] (state, value) {
            state.hasError = value
        }
    }
}

