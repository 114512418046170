<template>
    <span :class="['ui-roi', { 't-color-accent': value > 0, 't-color-warn': value < 0 }]">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'UiRoi',

    props: {
        value: {
            type: Number,
            required: true
        }
    }
}
</script>
