<template>
    <svg
        :height="getSize"
        :width="getSize"
        :viewBox="viewBox"
        class="ui-star-rating"
        @mousemove="mouseMoving"
        @click="selected"
    >

        <linearGradient
            :id="grad"
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
        >
            <stop
                :offset="getFill"
                :stop-color="activeColor"
            />
            <stop
                :offset="getFill"
                :stop-color="inactiveColor"
            />
        </linearGradient>

        <polygon
            :points="starPointsToString"
            :fill="getGradId"
            :stroke="borderColor"
            :stroke-width="borderWidth"
        />

        <polygon
            :points="starPointsToString"
            :fill="getGradId"
        />
    </svg>
</template>

<script>
    /**
     * Генерация рандомного id
     * @returns {string}
     */
function getRandomId () {
    return Math.random().toString(36)
        .substring(7)
}

export default {
    name: 'UiStarRatingStar',

    props: {
        fill: {
            type: Number,
            default: 0
        },

        points: {
            type: Array,
            default () {
                return []
            }
        },

        size: {
            type: Number,
            default: 50
        },

        starId: {
            type: Number,
            required: true
        },

        activeColor: {
            type: String,
            required: true
        },

        inactiveColor: {
            type: String,
            required: true
        },

        borderColor: {
            type: String,
            default: ''
        },

        borderWidth: {
            type: Number,
            default: 0
        }
    },

    data () {
        return {
            starPoints: [8.5, 12.9, 13.8, 16, 12.4, 10, 17, 6.1, 10.9, 5.6, 8.5, 0, 6.1, 5.6, 0, 6.1, 4.6, 10.1, 3.2, 16],
            grad: ''
        }
    },

    computed: {
        starPointsToString () {
            return this.starPoints.join(',')
        },

        getGradId () {
            return `url(#${this.grad})`
        },

        getSize () {
            return this.size + this.borderWidth
        },

        getFill () {
            return `${this.fill}%`
        },

        maxSize () {
            return this.starPoints.reduce((a, b) => Math.max(a, b))
        },

        viewBox () {
            return `0 0 ${this.maxSize} ${this.maxSize}`
        }
    },

    created () {
        if (this.points.length) {
            this.starPoints = this.points
        }

        this.calculatePoints()
        this.grad = getRandomId()
    },

    methods: {
        mouseMoving ($event) {
            this.$emit('star-mouse-move', {
                event: $event,
                position: this.getPosition($event),
                id: this.starId
            })
        },

        getPosition ($event) {
            const starWidth = (92 / 100) * this.size
            const offset = Math.max($event.offsetX, 1)
            const position = Math.round((100 / starWidth) * offset)

            return Math.min(position, 100)
        },

        selected ($event) {
            this.$emit('star-selected', {
                id: this.starId,
                position: this.getPosition($event)
            })
        },

        calculatePoints () {
            this.starPoints = this.starPoints.map((point) => ((this.size / this.maxSize) * point) + (this.borderWidth * 1.5))
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-star-rating
    overflow visible !important
</style>
