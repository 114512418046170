export default function loadScript (src) {
    if (!src) {
        return Promise.reject('Required argument 0')
    }

    return new Promise((resolve, reject) => {
        const script = document.createElement('script')

        script.onload = resolve
        script.onerror = reject
        script.src = src
        document.head.appendChild(script)
    })
}
