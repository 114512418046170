import { render, staticRenderFns } from "./DFPPromoGetFreebetPopup.vue?vue&type=template&id=1b3e6364&scoped=true&"
import script from "./DFPPromoGetFreebetPopup.vue?vue&type=script&lang=js&"
export * from "./DFPPromoGetFreebetPopup.vue?vue&type=script&lang=js&"
import style0 from "./DFPPromoGetFreebetPopup.vue?vue&type=style&index=0&id=1b3e6364&prod&rel=stylesheet%2Fstylus&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b3e6364",
  null
  
)

export default component.exports