const team = `{
  score
  result
  team {
    id
    name
    logo {
        resize(width: "60", height: "60")
    }
  }
}`

/**
 * Запрос списка популярных ставок на матч (топ-5 ставок по-умолчанию)
 *
 * @param {string|number} matchId - id матча
 * @param {number} count - количество запрашиваемых ставок
 * @returns {string}
 */
export function fetchMatchTopBets (matchId, count = 5) {
    return `{
        matchBetsStat(matchID: "${matchId}") {
            popularOdds(first: ${count}) {
              name
              odds {
                id
                line {
                  type
                  name
                }
                link
                type
                rate
                name
                fullName
                matchID
              }
              count
              state
              percent
            }
          }
    }`
}

/**
 * Запрос списка популярных ставок (топ-5 ставок по-умолчанию)
 *
 * @param {number} count - количество запрашиваемых ставок
 * @param {number} logoSize - размер лого команд
 * @returns {string}
 */
export function fetchTopBets (count = 5, logoSize = 48) {
    return `{
      topOddsStat(input: {first: ${count}}) {
        count
        match {
          id
          webname
          scheduledAtStamp
          ubersetzer {
              sportsId
              sportradarId
              sportsTag
          }
          away {
            team {
              name
              logo {
                  resize(width: "${logoSize}", height: "${logoSize}")
              }
            }
          }
          home {
            team {
              name
              logo {
                  resize(width: "${logoSize}", height: "${logoSize}")
              }
            }
          }
        }
        percent
        odds {
          id
          rate
          name
          fullName
        }
      }
    }`
}

/**
 * Запрос списка популярных ставок на матч (топ-5 ставок по-умолчанию)
 *
 * @param {string|number} matchId - id матча
 * @param {number} count - количество запрашиваемых ставок
 * @returns {string}
 */
export function fetchSameTournamentMatches (matchId, count = 6) {
    return `{
      stat_match(id: "${matchId}" source: SPORTS) {
        id
        season {
          teaser (next: ${count}) {
            next {
              id
              webname
              scheduledAtStamp
              betsCount
              matchStatus
              ubersetzer {
                sportsId
              }
              away ${team}
              home ${team}
            }
            current {
              id
              webname
              scheduledAtStamp
              betsCount
              matchStatus
              ubersetzer {
                sportsId
              }
              away ${team}
              home ${team}
            }
          }
        }
      }
    }`
}
