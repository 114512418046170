<template>
    <div
        :class="commentClass"
        class="ui-comment"
    >
        <div class="ui-comment__message">
            <!-- @slot Слот для контента внутри сообщения -->
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiComment',

    props: {
        /**
         * Мой комментарий
         */
        my: {
            type: Boolean,
            default: false
        },

        /**
         * тип отображения коммента
         */
        viewType: {
            type: String,
            default: ''
        }
    },

    computed: {
        commentClass () {
            return {
                'is-my': this.my,
                'ui-comment--chat': this.isChatViewType
            }
        },

        isChatViewType () {
            return this.viewType === 'chat'
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-comment
    position relative
    padding 1.2rem 1.6rem
    color $cl-text-dark
    background-color $cl-secondary-light
    border-radius 2rem 2rem 2rem 0

    &.is-my
        justify-self flex-end
        color $cl-white
        background-color $cl-primary
        border-radius 2rem 2rem 0 2rem

    &.is-my&--chat
        color $cl-text-dark
        background-color #E5F2F8

    &__message
        position relative
        line-height 1.6rem
        font-size $font-size-base-sub
        font-family $font-family-content
        word-break break-word
</style>
