const dataByTypes = {
    USER: ' __typename ... on User { id }',
    MATCH: ' __typename ... on Match { id }',
    TOURNAMENT: ' __typename ... on Tournament { id }'
}

export function fetchFavorites (userId, type = 'USER') {
    return `{userFavorites(userID: "${userId}", type: ${type}, source: SPORTS) {
        list {
            ${dataByTypes[type]}
        }
    }}`
}

export function subscribe (id, type) {
    return `
    userFavoriteMutations{
        add(
            favorites: {
                type: ${type},
                favoriteIDs: ["${id}"]
            }
        ) {
            favorites(source: SPORTS) {
                ${dataByTypes[type]}
            }
        }
    }`
}

export function unsubscribe (id, type) {
    return `userFavoriteMutations{
        remove(
            favorites: {
                type: ${type},
                favoriteIDs: ["${id}"]
            }
        ) {
            favorites(source: SPORTS) {
                ${dataByTypes[type]}
            }
        }
    }`
}
