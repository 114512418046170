<template>
    <div
        class="bet bi-hide-scroll"
        @click.stop
    >
        <div class="bet__header">
            <BetRate
                :rate="odd.rate"
                class="bet__rate"
            />
            <span class="bet__sep" />
            <span class="bet__title">{{ odd.name }}</span>
        </div>

        <div class="bet-editor">
            <div class="bet-editor__bet">
                <div class="bet-editor__amount">
                    <span class="bet-editor__label">{{ $t('bet') }}</span>

                    <input
                        ref="amountInput"
                        :value="odd.amount"
                        type="number"
                        class="bet-editor__amount-input"
                        @input="onInputUpdateAmount"
                    >
                </div>

                <div class="bet-editor__buttons">
                    <button
                        v-for="(fraction, index) in betFractions"
                        :key="index"
                        :class="{ 'bet-editor__btn--active': activeFraction === index }"
                        class="bet-editor__btn"
                        @click="onFractionalUpdateAmount(index)"
                    >
                        {{ fraction.text }}
                    </button>
                </div>
            </div>

            <UiSlider
                :value="odd.amount"
                :max="odd.amountMax"
                :min="odd.amountMin"
                class="bet-editor__slider"
                @input="onUpdateAmount"
            />

            <div class="bet-editor__return bi-body-2">
                <span class="bi-body-3">{{ win }} <span class="bi-betty" /></span>{{ $t('win') }}
            </div>

            <textarea
                :value="odd.comment"
                :placeholder="$t('placeholder')"
                class="bet-editor__comment-input"
                @input="onUpdateComment"
            />

            <div class="bet-editor__counter bi-subtitle-1">
                <div
                    v-show="commentStatus"
                    class="bet-editor__status"
                >
                    {{ commentStatus }}
                </div>

                <div
                    v-show="hasWordsCount"
                    ref="wordsStatus"
                    class="bet-editor__words"
                >
                    {{ wordsCount }} / 50
                </div>
            </div>

            <button
                class="bet-editor__bet-btn t-bold"
                @click="onBetClick"
            >
                {{ $t('betting') }}
            </button>

            <button
                class="bet-editor__close-btn"
                @click="onClose"
            >
                <IconBasket />
                {{ $t('close') }}
            </button>
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "bet": "Сумма ставки",
        "bet-rel": "Относительно банка",
        "win": " возможный выигрыш",
        "placeholder": "Добавьте описание, чтобы участвовать в розыгрыше призов",
        "betting": "Сделать ставку",
        "close": "Сбросить",
        "progress-0": "Хорошее начало, продолжайте",
        "progress-1": "Неплохо, но люди любят чуть более подробный анализ",
        "progress-2": "Классно, игроки Betting Insider благодарны тебе!"
    },
    "en": {
        "bet": "Total Stake",
        "bet-rel": "",
        "win": " potential Return",
        "placeholder": "Share your thoughts / Add your predictions",
        "betting": "Place a bet",
        "close": "Close",
        "progress-0": "A great start! Go on sharing your thoughts",
        "progress-1": "Good, but users like more detailed tips",
        "progress-2": "It looks like a record! You're the real tipster!"
    }
}
</i18n>

<script>
import { mapActions, mapState } from 'vuex'
import BetRate from '@/components/Bet/BetRate.vue'
import IconBasket from '@/assets/svg/basket.icon.svg'
import debounce from 'lodash.debounce'
import betMixin from './mixin'

const BET_FRACTIONS = [
    {
        text: '1/4',
        value: 1 / 4
    },
    {
        text: '1/3',
        value: 1 / 3
    },
    {
        text: '2/3',
        value: 2 / 3
    }
]

export default {
    name: 'BetEditor',

    components: {
        BetRate,
        IconBasket
    },

    mixins: [betMixin],

    data () {
        return {
            betFractions: BET_FRACTIONS,
            activeFraction: null,
            isUpdatedComment: false,
            isUpdatedAmount: false,
            width: 0
        }
    },

    computed: {
        ...mapState('auth', ['isAuth']),

        colorAccentClass () {
            return { 't-color-accent': this.hasColorAccent }
        },

        win () {
            return (this.odd.amount * this.odd.rate).toFixed()
        },

        betId () {
            return this.odd.id
        },

        hasWordsCount () {
            return this.wordsCount && this.wordsCount < 51
        },

        wordsCount () {
            return this.odd.comment
                .replace(/\r|\n/g, ' ')
                .split(' ')
                .filter(el => el.length > 1)
                .length
        },

        commentStatus () {
            if (this.wordsCount >= 50) {
                return this.$t('progress-2')
            }

            if (this.wordsCount >= 10) {
                return this.$t('progress-1')
            }

            if (this.wordsCount) {
                return this.$t('progress-0')
            }

            return ''
        }
    },

    watch: {
        betId () {
            this.isUpdatedComment = false
            this.isUpdatedAmount = false
        }
    },

    mounted () {
        this.width = this.$refs.wordsStatus.offsetWidth
        this.$root.$emit('popup-bet-init')
    },

    methods: {
        ...mapActions({
            setBet: 'bet/select/set',
            curtainChangeToPrevMode: 'curtain/changeToPrevMode'
        }),

        onUpdateComment (event) {
            this.debounceOnUpdate({ comment: event.target.value })

            if (!this.isUpdatedComment) {
                this.isUpdatedComment = true

                this.$pivik.event('bet', 'discription_change', 'editor')
            }
        },

        onFractionalUpdateAmount (index) {
            this.activeFraction = index

            const amountDiff = this.odd.amountMax - this.odd.amountMin
            const amount = Math.ceil(amountDiff * this.betFractions[index].value) + this.odd.amountMin

            this.onUpdateAmount(amount)
        },

        onInputUpdateAmount (event) {
            this.activeFraction = null

            let { value } = event.target

            value = this.validateAmount(value)

            this.onUpdateAmount(value)
        },

        validateAmount (value) {
            let validatedValue = parseInt(value)

            validatedValue = Math.max(value, this.odd.amountMin)
            validatedValue = Math.min(validatedValue, this.odd.amountMax)

            return validatedValue
        },

        onUpdateAmount (value) {
            this.debounceOnUpdate({ amount: +value })

            if (!this.isUpdatedAmount) {
                this.isUpdatedAmount = true

                this.$pivik.event('bet', 'sum_change', 'editor')
            }
        },

        debounceOnUpdate: debounce(function (updateData) {
            if (!this.isAuth) {
                this.$pivik.event('social', 'start', 'bet')

                return this.$root.$emit('auth')
            }

            this.setBet(updateData)
            this.$refs.amountInput.value = updateData.amount

            // TODO отрефакторить изменения через action
            return this.$store.commit('bet/select/save', true)
        }, 200),

        onBetClick () {
            const place = this.betOpenedFromReminder ? 'reminder' : 'first'

            this.onBet(place)
        },

        onClose () {
            // TODO отрефакторить изменения через action
            this.$store.commit('bet/select/clean')
            this.curtainChangeToPrevMode()

            this.$pivik.event('bet', 'delete', 'editor')
        }
    }
}
</script>

<style lang="stylus" scoped>
.bet
    box-sizing border-box
    width 100%
    height 100%
    padding 2rem 1.5rem
    background-color #fff
    overflow-y auto

    +layout-sm()
        padding 1.2rem

    &__header
        padding-bottom 3rem
        font-size 1.8rem
        font-weight $font-weight-bold

        +layout-sm()
            padding-bottom 2rem
            padding-right 2rem

    &__rate
        color $cl-primary

    &__sep:before
        margin 0 0.5rem
        color #E0E1E2
        content "\2022"
        font-family sans-serif

.bet-editor
    &__bet
        display flex
        align-items flex-end
        margin-bottom 1.2rem

    &__label
        display block
        font-size 1.4rem
        margin-bottom .7rem

    &__buttons
        display grid
        grid-template-columns 1fr 1fr 1fr
        grid-column-gap .6rem

    &__btn
        height 4rem
        border 1px solid $cl-secondary-dark
        border-radius .8rem
        color $cl-secondary-dark
        background $cl-white
        font-family $font-family-content
        font-size 1.4rem
        outline none
        cursor pointer
        transition all .4s
        padding 0 1.4rem

        &:hover
            opacity .6

        &:active
            border 1px solid $cl-text-dark
            color $cl-text-dark

        &--active
            border 1px solid $cl-primary
            color $cl-primary

    &__counter
        display flex
        justify-content space-between
        padding .4rem 0
        color $cl-text

    &__slider
        font-size 1.2rem
        margin-bottom .7rem

    &__amount
        width calc(100% - 16.6rem)
        margin-right .8rem

        &-input
            input()
            width 100%

    &__return
        margin-bottom 1rem

        +layout-md-and-up()
            margin-bottom 3rem

    &__bet-btn
        button(6, 'contained', 'primary')
        letter-spacing .05rem
        width 100%
        margin-top 1.2rem
        margin-bottom .4rem

    &__words
        white-space nowrap

    &__close-btn
        button(6, 'text', 'text')
        display flex
        align-items center
        justify-content center
        width 100%
        letter-spacing .05rem

        .ui-icon
            width 2rem
            height @width
            margin-right .5rem

            +layout-sm-and-up()
                display none

    &__comment-input
        input()
        resize none

        &:not(input)
            min-height 12rem

</style>
