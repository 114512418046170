<template>
    <div class="profile flip-wrap">
        <transition name="flip">
            <div
                v-if="isAuth"
                key="card"
                class="profile__card"
            >
                <UiRoi
                    :value="myProfile.roi"
                    class="profile__roi"
                >
                    ROI {{ myProfile.roi }}%
                </UiRoi>

                <div class="profile__balance">
                    {{ balance | number }} <span class="bi-betty" />
                </div>

                <button
                    v-if="myProfile.canRefund"
                    class="profile__refund"
                    @click="openRefundPopup"
                >
                    <IconPlus />
                </button>

                <div
                    class="profile__avatar"
                    @click="sendAnalytic('profile')"
                >
                    <UiAvatar
                        :src="myProfile.avatar"
                        :to="profileRoute"
                    />
                </div>
            </div>

            <div
                v-else
                class="profile__auth"
            >
                <button
                    :disabled="!checked"
                    class="profile__signin"
                    @click="openAuthPopup"
                >
                    {{ $t('signin') }}
                </button>

                <button
                    :disabled="!checked"
                    class="profile__register"
                    @click="openRegisterPopup"
                >
                    {{ $t('register') }}
                </button>
            </div>
        </transition>

        <TheHeaderDropMenu
            v-if="isAuth"
            direction="right"
            class="profile__menu"
        >
            <template v-for="item in menu">
                <router-link
                    v-if="item.to"
                    :key="item.key"
                    :to="item.to"
                    :class="item.class"
                    @click.native="item.callback"
                >
                    {{ $t(item.key) }}
                </router-link>

                <span
                    v-else
                    :key="item.key"
                    class="profile__menu-item--has-icon"
                    :class="item.class"
                    @click="item.callback"
                >
                    {{ $t(item.key) }}

                    <component
                        :is="item.icon"
                        v-if="item.icon"
                        class="profile__menu-icon"
                    />
                </span>
            </template>
        </TheHeaderDropMenu>
    </div>
</template>

<i18n>
{
    "ru": {
        "profile": "Профиль",
        "my-tips": "Мои прогнозы",
        "my-subscriptions": "Лента подписок",
        "confirm-email": "Подтвердить почту",
        "confirm-phone": "Подтвердить телефон",
        "my-stats": "Моя статистика",
        "signout": "Выйти",
        "balance": "Пополнить баланс",
        "signin": "Войти",
        "register": "Зарегистрироваться"
    },
    "en": {
        "profile": "Profile",
        "my-tips": "My tips",
        "my-subscriptions": "My subscriptions",
        "confirm-email": "Confirm email",
        "confirm-phone": "Confirm phone",
        "my-stats": "My stats",
        "signout": "Sign out",
        "balance": "Refill",
        "signin": "Sign in",
        "register": "Register"
    }
}
</i18n>

<script>
import { mapState, mapGetters } from 'vuex'
import TheHeaderDropMenu from '@/components/TheHeader/TheHeaderDropMenu.vue'
import IconLock from '@/components/icons/lock.icon.vue'
import IconSignOut from '@/assets/svg/sign-out.icon.svg'
import auth from '@/mixins/auth'
import number from '@/filters/number'

export default {
    name: 'TheHeaderProfileDesktop',

    filters: {
        number
    },

    components: {
        TheHeaderDropMenu,
        IconLock,
        IconSignOut
    },

    mixins: [auth],

    computed: {
        ...mapState('auth', ['checked', 'account', 'isAuth']),
        ...mapGetters({ myProfile: 'profile/my' }),

        menu () {
            const menu = [
                {
                    key: 'my-subscriptions',
                    callback: this.sendAnalytic.bind(this, 'subscriptions'),
                    to: this.mySubscriptionsRoute
                },
                {
                    key: 'my-tips',
                    callback: this.sendAnalytic.bind(this, 'my_tips'),
                    to: this.profileRoute
                },
                {
                    key: 'my-stats',
                    callback: this.sendAnalytic.bind(this, 'my_stats'),
                    to: this.myStatRoute
                }
            ]

            if (this.myProfile.canRefund) {
                menu.push({
                    key: 'balance',
                    callback: this.openRefundPopup
                })
            }

            if (!this.account.confirmed) {
                menu.push({
                    key: 'confirm-email',
                    callback: () => {},
                    to: this.settingsRoute
                })
            }

            menu.push({
                key: 'signout',
                callback: this.authSignOut,
                class: 'profile__menu-signout',
                icon: IconSignOut
            })

            return menu
        },

        balance () {
            return parseInt(this.myProfile.balance)
        },

        mySubscriptionsRoute () {
            return {
                name: 'predictions-my'
            }
        },

        profileRoute () {
            return {
                name: 'profile',
                params: {
                    id: this.myProfile.id
                }
            }
        },

        myStatRoute () {
            return {
                name: 'profile-stat',
                params: {
                    id: this.myProfile.id
                }
            }
        },

        settingsRoute () {
            return {
                name: 'account-settings'
            }
        },

        isRu () {
            return this.$i18n.locale === 'ru'
        }
    },

    methods: {
        sendAnalytic (key) {
            this.$pivik.event('menu', 'click', key)
        },

        openAuthPopup () {
            return this.$root.$emit('auth')
        },

        openRegisterPopup () {
            return this.$root.$emit('register')
        },

        openConfirmPhonePopup () {
            return this.$root.$emit('popup-confirm-phone')
        },

        openRefundPopup () {
            return this.$root.$emit('refund')
        }
    }
}
</script>

<style lang="stylus" scoped>
.profile
    position relative
    width auto
    display flex
    justify-content flex-end
    align-items stretch
    height 100%

    &__auth,
    &__card
        top 0
        display flex
        height 100%
        align-items center

        +layout-sm-and-up()
            min-width 22rem

    &__card
        background-color $cl-text-dark
        font-weight $font-weight-bold

    &__name
        flex 0 0 100%
        font-size 1.8rem
        font-weight $font-weight-bold
        text-align center

    &__roi
        display flex
        align-items center

        &::after
            margin 0 1.2rem
            content "|"
            font-size 2rem
            color rgba(#fff, .2)
            transform translate(0, -0.2rem)

    &__refund
        button(4, 'contained', 'success')
        margin-left 1rem
        height 2rem
        width 2rem
        padding .5rem

        .ui-icon
            display block
            height 1rem
            width 1rem

    &__avatar
        margin-left 1.2rem

    &__register
        button(4, 'contained', 'primary')
        margin-left 1rem

    &__signin
        button(4, 'outlined', 'primary')
        color $cl-white

    &__menu
        position absolute
        top 100%
        right 0
        opacity 0
        transform translate(0, -100%)
        transition transform .3s cubic-bezier(.25, .46, .45, .94), opacity .3s
        z-index -1

        &-signout
            color $cl-error

            &:hover
                color rgba($cl-error, .7)

        &-item--has-icon
            display flex
            justify-content flex-end
            align-items center
            padding 0 1.5rem

        &-icon
            margin-left .5rem

    &:hover &__menu
        opacity 1
        transform translate(0, 0)
</style>
