<template>
    <div
        v-scroll.window="onScroll"
        class="header-wrap"
        :class="{ 'header-wrap--has-ad': isDesktopBrandingVisible }"
    >
        <RefreshableDfp
            placement="notification_desktop"
            @receive="receiveMessage"
        />

        <UiPusher
            v-model="showMobileMenu"
            portal-prepend
        >
            <div class="header__pusher">
                <TheHeaderProfileMobile
                    @close="closeMobileMenu"
                />

                <TheHeaderMenuMobile
                    :items="menuItems"
                    :promo="promo"
                    :current-daily-bonus="currentDailyBonus"
                    @close="closeMobileMenu"
                />

                <TheHeaderSocials />
            </div>
        </UiPusher>

        <CorePortal prepend>
            <header
                class="header"
                :class="headerClass"
            >
                <DFPDesktopBranding v-if="isDesktop" />

                <router-link
                    :to="menuItems[0].to"
                    class="header__logo"
                >
                    <IconLogo />
                </router-link>

                <TheHeaderBurger
                    v-model="showMobileMenu"
                    class="header__burger"
                />

                <TheHeaderMenuDesktop
                    :items="menuItems"
                    :promo="promo"
                    class="header__menu"
                />

                <div class="header__search">
                    <Search />
                </div>

                <div class="header__title">
                    <router-link
                        :to="homeRoute"
                        class="header__title-link"
                        @click.native="onHomeLinkClick"
                    >
                        Betting Insider
                    </router-link>
                </div>

                <DFPPromoGiftButton
                    v-if="promo.color"
                    :text="promo.text"
                    :text-color="promo.textColor"
                    :gift-color="promo.color"
                    :link="promo.link"
                    :bookmaker="promo.bookmaker"
                    class="header__gift"
                />

                <DailyBonusesButton
                    v-if="currentDailyBonus.link"
                    class="header__daily-button"
                />
                <TheHeaderProfileDesktop class="header__right" />
            </header>
        </CorePortal>
    </div>
</template>

<i18n>
{
    "ru": {
        "title": "Betting Insider — прогнозы и ставки",
        "feed": "Прогнозы",
        "match-center": "Матчи",
        "match-center-mob": "Матч-центр",
        "rating": "Рейтинг",
        "rating-mob": "Рейтинг",
        "blog": "Блог",
        "bookmakers": "Букмекеры",
        "faq": "FAQ",
        "wiki": "База знаний"
    },
    "en": {
        "title": "Betting Insider — tips and bets",
        "feed": "Tips",
        "match-center": "Matches",
        "match-center-mob": "Matches",
        "rating": "Rankings",
        "rating-mob": "Rankings",
        "blog": "Blog",
        "bookmakers": "Bookmakers",
        "faq": "FAQ"
    }
}
</i18n>

<script>
import DailyBonusesButton from '@/components/DailyBonuses/DailyBonusesButton.vue'
import DFPDesktopBranding from '@/components/DFP/DFPDesktopBranding.vue'
import DFPPromoGiftButton from '@/components/DFP/DFPPromoGiftButton.vue'
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'
import Search from '@/components/Search/Search.vue'
import TheHeaderBurger from '@/components/TheHeader/TheHeaderBurger.vue'
import TheHeaderMenuDesktop from '@/components/TheHeader/TheHeaderMenuDesktop.vue'
import TheHeaderMenuMobile from '@/components/TheHeader/TheHeaderMenuMobile.vue'
import TheHeaderProfileDesktop from '@/components/TheHeader/TheHeaderProfileDesktop.vue'
import TheHeaderProfileMobile from '@/components/TheHeader/TheHeaderProfileMobile.vue'
import TheHeaderSocials from '@/components/TheHeader/TheHeaderSocials.vue'
import { AVAILABLE_DAILY_BONUSES_EVENT } from '@/constants'
import scroll from '@/directives/scroll'
import resize from '@/mixins/resize'
import CorePortal from '@/ui/core/portal/index.vue'
import adaptColor from '@/utils/adapt-color'
import { mapActions, mapGetters, mapState } from 'vuex'


export default {
    name: 'TheHeader',

    components: {
        CorePortal,
        DailyBonusesButton,
        RefreshableDfp,
        DFPDesktopBranding,
        TheHeaderProfileMobile,
        TheHeaderMenuMobile,
        TheHeaderSocials,
        TheHeaderBurger,
        TheHeaderMenuDesktop,
        TheHeaderProfileDesktop,
        DFPPromoGiftButton,
        Search
    },

    directives: {
        scroll
    },

    mixins: [resize],

    data () {
        return {
            layoutWidth: 0,
            showMobileMenu: false,
            lastScrollPosition: 0,
            lastScrollDirection: 1,
            promo: {
                link: '',
                text: '',
                color: '',
                textColor: '',
                secondaryImage: '',
                secondaryText: '',
                secondaryTextColor: '',
                buttonTitle: '',
                buttonTitleColor: '',
                buttonBackgroundColor: '',
                thirdText: '',
                marker: '',
                bookmaker: ''
            },

            currentDailyBonus: {}
        }
    },

    computed: {
        ...mapState('auth', ['checked', 'account']),
        ...mapGetters('ads', ['isDesktopBrandingVisible']),

        isHideContainer () {
            return (this.lastScrollDirection > 0)
                && (this.lastScrollPosition > 280)
                && !this.disabledScrollHide
        },

        headerClass () {
            return {
                'is-hide': this.isHideContainer,
                'header--has-ad': this.isDesktopBrandingVisible
            }
        },

        disabledScrollHide () {
            return this.showMobileMenu || this.layoutWidth > 600
        },

        menuItems () {
            return this.menu.filter(
                item => !item.languages || item.languages.some(lang => lang === this.$i18n.locale)
            )
        },

        menu () {
            const menuList = [
                {
                    title: 'Betting Insider',
                    key: 'home',
                    isHome: true,
                    to: this.homeRoute
                },
                {
                    title: this.$t('match-center'),
                    titleMobile: this.$t('match-center-mob'),
                    key: 'center',
                    to: {
                        name: 'match-center',
                        params: {
                            sport: 'football'
                        }
                    }
                },
                {
                    title: this.$t('feed'),
                    key: 'prog',
                    to: {
                        name: 'predictions',
                        params: {
                            sport: 'football'
                        }
                    }
                },
                {
                    title: this.$t('rating'),
                    titleMobile: this.$t('rating-mob'),
                    key: 'rating',
                    to: {
                        name: 'rating'
                    }
                },
                {
                    title: this.$t('blog'),
                    key: 'blog',
                    to: {
                        name: 'blog',
                        params: {
                            section: 'blog'
                        }
                    }
                },
                {
                    title: this.$t('faq'),
                    key: 'faq',
                    to: {
                        name: 'blog-post',
                        params: {
                            section: 'blog',
                            alias: 'guide'
                        }
                    },

                    languages: ['ru']
                },
                {
                    title: this.$t('faq'),
                    key: 'faq',
                    to: {
                        name: 'blog-post',
                        params: {
                            section: 'blog',
                            alias: '5reasonswhy'
                        }
                    },

                    languages: ['en']
                },
                {
                    title: this.$t('wiki'),
                    key: 'wiki',
                    to: {
                        name: 'blog',
                        params: {
                            section: 'wiki'
                        }
                    },

                    languages: ['ru']
                }
            ]

            return menuList
        },

        homeRoute () {
            return {
                path: '/'
            }
        },

        pageIsLoading () {
            return this.$router.$isLoading
        },

        isDesktop () {
            return ['md', 'l'].includes(this.$mq)
        }
    },

    watch: {
        showMobileMenu (newVal) {
            const eventName = newVal ? 'open' : 'close'

            this.$pivik.event('menu', eventName)
        },

        pageIsLoading (isLoading) {
            if (isLoading) {
                this.showMobileMenu = false
            }
        }
    },

    mounted () {
        this.onResize()
        this.$root.$on(AVAILABLE_DAILY_BONUSES_EVENT, this.setCurrentDailyBonus)

        this.checkOddsFormat()
    },

    methods: {
        ...mapActions({
            checkOddsFormat: 'bet/checkOddsFormat'
        }),

        onScroll () {
            const { scrollTop } = document.scrollingElement

            // Для того что бы шапка не уезжала при закрытии мобильного меню
            if (scrollTop !== 0) {
                this.lastScrollDirection = scrollTop > this.lastScrollPosition ? 1 : -1
                this.lastScrollPosition = scrollTop
            }
        },

        onResize () {
            this.layoutWidth = window.innerWidth
        },

        receiveMessage (data) {
            this.promo.link = data.link
            this.promo.color = adaptColor(data.color)
            this.promo.text = data.text
            this.promo.textColor = adaptColor(data.textColor)
            this.promo.image = data.image
            this.promo.secondaryImage = data.secondaryImage
            this.promo.secondaryText = data.secondaryText
            this.promo.secondaryTextColor = adaptColor(data.secondaryTextColor)
            this.promo.buttonTitle = data.buttonTitle
            this.promo.buttonTitleColor = adaptColor(data.buttonTitleColor)
            this.promo.buttonBackgroundColor = adaptColor(data.buttonBackgroundColor)
            this.promo.thirdText = data.thirdText
            this.promo.marker = data.marker
            this.promo.bookmaker = data.bookmakerName || 'unknown'
            this.promo.disclaimer = data.disclaimer
        },

        setCurrentDailyBonus (bonus) {
            this.currentDailyBonus = bonus
        },

        closeMobileMenu () {
            this.showMobileMenu = false
        },

        onHomeLinkClick () {
            this.$pivik.event('menu', 'click', 'home')
        }
    }
}
</script>

<style lang="stylus" scoped>
.header
    position fixed
    top 0
    left 0
    right 0
    z-index 30
    background $cl-text-dark
    color #fff
    height $header-height
    display flex
    align-items center
    padding 0 1.2rem
    font-size 1.6rem
    letter-spacing .025rem
    font-size 1.4rem
    line-height 1.9rem
    transition transform .4s

    &.is-hide
        transform translate(0, -100%)


    &--has-ad
        padding-top 9rem

    &-wrap
        height $header-height

        &--has-ad
            padding-top 9rem

    &__pusher
        display flex
        flex-direction column
        align-items center
        justify-content flex-start
        min-height 100%

        .menu-mobile
            flex 1 1

    &__logo
        color $cl-primary
        padding 0 1rem

        svg
            width 3rem
            height auto

    &__menu
        align-self stretch

    &__burger
        display none

    &__title
        flex 1 1
        display none
        text-align center

        &-link
            color $cl-white

            &:hover, &:active, &:focus
                text-decoration none

    &__search
        height 100%
        flex 1 1

    &__right
        display flex
        align-self stretch
        padding-right 1rem
        align-items center


    @media(max-width 800px)
        height $header-height

        &__logo,
        &__menu,
        &__right,
        &__daily-button
            display none

        &__search
            flex none

        &__burger,
        &__title
            display block

        &-wrap
            height $header-height

    @media(min-width 800px)
        &__gift
            display none

</style>
