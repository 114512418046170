import Api from '@/api'
import logger from '@/utils/logger'
import { fetchTable } from '@/api/graphql/euro2020'

const SET_TABLE = 'SET_TABLE'

const EURO2020_CHAMPIONSHIP_ID = 'european_championship_2021'

const adaptTable = (table) => {
    const tmp = {}

    table.forEach(team => {
        if (tmp[team.groupName]) {
            tmp[team.groupName].push(team)
        } else {
            tmp[team.groupName] = [team]
        }
    })

    const result = ['A', 'B', 'C', 'D', 'E', 'F'].map((groupName) => {
        const rows = tmp[groupName].sort((a, b) => a.rank - b.rank)

        return { groupName, rows }
    })

    return result
}

export default {
    namespaced: true,

    state () {
        return {
            table: []
        }
    },

    actions: {
        async fetch ({ commit }) {
            try {
                const queryString = fetchTable({ championshipId: EURO2020_CHAMPIONSHIP_ID })
                const result = await Api.graphql(queryString)

                commit(SET_TABLE, adaptTable(result[0].stages[0].teamStanding.total))
            } catch (err) {
                logger.error(err)

                commit(SET_TABLE, [])

                return Promise.reject(err)
            }

            return Promise.resolve()
        }
    },

    mutations: {
        [SET_TABLE] (state, table) {
            state.table = table
        }
    }
}
