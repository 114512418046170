<template>
    <section
        :style="`position: ${isOpen ? 'absolute': 'relative'}`"
    >
        <RefreshableDfp
            placement="daily-bonuses"
            class="daily-bonuses-popup"
            @receive="creativeReceived"
        />
        <RefreshableDfp
            v-for="item of fakeBonusesItemsCount"
            :key="item"
            :placement="`daily-bonuses__fake-${item}`"
        />

        <section
            v-if="isOpen"
            class="daily-bonuses-popup__overlay"
        >
            <div
                v-if="isOpen"
                class="daily-bonuses-popup__popup"
            >
                <div class="daily-bonuses-popup__header">
                    <img
                        src="@/components/DailyBonuses/images/header.svg"
                        class="daily-bonuses-popup__header-back"
                        alt=""
                    >
                    <span class="daily-bonuses-popup__title">{{ $t('bonus') }}</span>
                    <span class="daily-bonuses-popup__bonus-title">{{ currentBonus.amount }}</span>
                    <CloseIcon
                        class="daily-bonuses-popup__close"
                        @click.native="close"
                    />
                </div>

                <p
                    class="daily-bonuses-popup__description"
                    v-html="$t('everyday-bonuses')"
                />

                <div class="daily-bonuses-popup__bonuses-wrapper">
                    <ul class="daily-bonuses-popup__bonuses">
                        <DailyBonusItem
                            v-for="(bonus, index) in bonuses"
                            :key="`bonus_${index}`"
                            :bonus="iterateBonus(bonus, index)"
                            @click="bonusClick(index)"
                        />
                    </ul>
                </div>

                <div
                    class="daily-bonuses-popup__bookmaker-info"
                >
                    <img
                        :src="currentBonus.sponsorLogo"
                        class="daily-bonuses-popup__bookmaker-logo"
                    >
                    <p class="daily-bonuses-popup__bookmaker-text">
                        {{ currentBonus.sponsorText }}
                    </p>
                </div>

                <a
                    :href="currentBonus.link"
                    class="daily-bonuses-popup__link"
                    target="_blank"
                    @click="getBonus"
                >
                    {{ $t('get-bonus') }}
                </a>
                <span
                    class="bi-subtitle-3"
                >
                    {{ currentBonus.marker }}
                </span>
                <a
                    :href="appLink"
                    class="daily-bonuses-popup__link daily-bonuses-popup__link--app"
                    target="_blank"
                    @click="getApp"
                >
                    {{ $t('more-in-app') }}
                </a>
            </div>
        </section>
    </section>
</template>

<i18n>
{
    "ru": {
        "day": "%{n} день",
        "get-bonus": "Получить бонус",
        "more-in-app": "Больше бонусов в приложении",
        "bonus": "Бонус",
        "everyday-bonuses": "Получайте бонус за посещение Betting Insider —<br>каждый день новый бонус!"
    },
    "en": {
        "day": "day %{n}",
        "get-bonus":  "Get bonus",
        "more-in-app": "Find more bonuses in app",
        "bonus": "Bonus",
        "everyday-bonuses":  "Get bonus for visiting Betting Insider —<br>new bonus each day"
    }
}
</i18n>

<script>
import DailyBonusItem from '@/components/DailyBonuses/DailyBonusItem.vue'
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'
import CloseIcon from '@/assets/svg/close-bold.icon.svg'
import { OPEN_DAILY_BONUSES_EVENT, AVAILABLE_DAILY_BONUSES_EVENT, APP_STORE_URL } from '@/constants'
import localStorage from '@/utils/localstorage'

const LOCAL_STORAGE_BONUSES_KEY = 'betting-insider:daily-bonuses'
const ONE_DAY_TIME_MS = 24 * 60 * 60 * 1000

const BONUSES_TYPES = ['cash', 'gift', 'wallet', 'profile']

function prepareBonusesForLS ({ list, currentDay }) {
    return {
        list,
        currentDay,
        updatedAt: Date.now()
    }
}

export default {
    name: 'DailyBonuses',

    components: {
        DailyBonusItem,
        CloseIcon,
        RefreshableDfp
    },

    data () {
        return {
            activeBonusIndex: 0,
            updatedAt: 0,
            currentDay: 0,
            isOpen: false,
            dfpIsActive: true,
            showDfpBlock: true,
            bonuses: [],
            fakeBonusesItemsCount: 3
        }
    },

    computed: {
        currentBonus () {
            return this.bonuses.length ? this.bonuses[this.activeBonusIndex] : null
        },

        appLink () {
            return APP_STORE_URL
        }
    },

    mounted () {
        const bonuses = localStorage.get(LOCAL_STORAGE_BONUSES_KEY)

        if (bonuses) {
            this.currentDay = bonuses.currentDay

            if (bonuses.updatedAt + ONE_DAY_TIME_MS > Date.now()) {
                this.bonuses = bonuses.list
                this.updatedAt = bonuses.updatedAt
                this.initialize()
            } else {
                this.currentDay += 1
                this.loadBonuses()
            }
        } else {
            this.loadBonuses()
        }

        this.$root.$on(OPEN_DAILY_BONUSES_EVENT, this.menuClickHandler)

        // this.$nextTick(() => {
        //     this.dfpIsActive = false
        // })

        // setTimeout(() => {
        //     this.showDfpBlock = false
        // }, 1000)
    },

    methods: {
        bonusClick (index) {
            if (index <= this.currentDay) {
                this.activeBonusIndex = index
                this.$pivik.event('daily_bonus', 'switch', 'click', this.currentBonus.day)
            }

            this.dfpIsActive = false
        },

        creativeReceived (message) {
            if (message && message.json) {
                let bonuses = null

                try {
                    bonuses = message.json
                } catch (e) {
                    console.log('Дейли бонусы повреждены', e)
                    this.dfpIsActive = false

                    return
                }

                bonuses.forEach((bonus) => {
                    const randomIndex = Math.floor(Math.random() * (BONUSES_TYPES.length))

                    bonus.type = BONUSES_TYPES[randomIndex]

                    return bonus
                })

                this.bonuses = bonuses

                this.saveCurrentState()

                // this.dfpIsActive = false

                this.initialize()
            }
        },

        close () {
            this.isOpen = false
            this.dfpIsActive = false
        },

        open () {
            this.isOpen = true
            this.showDfpBlock = true
        },

        loadBonuses () {
            this.dfpIsActive = true
        },

        initialize () {
            this.activeBonusIndex = Math.min(this.currentDay, this.bonuses.length - 1)

            const moreThanDayHavePassed = Date.now() - this.updatedAt > ONE_DAY_TIME_MS
            const currentDayHasBonus = this.currentDay === this.activeBonusIndex

            if (currentDayHasBonus && moreThanDayHavePassed) {
                this.open()
                this.$pivik.event('daily_bonus', 'show', 'default', this.currentBonus.day)
            }

            this.$root.$emit(AVAILABLE_DAILY_BONUSES_EVENT, {
                ...this.currentBonus,
                day: this.currentDay
            })
        },

        saveCurrentState () {
            const bonusesForLS = prepareBonusesForLS({
                list: this.bonuses,
                currentDay: this.currentDay
            })

            localStorage.set(LOCAL_STORAGE_BONUSES_KEY, bonusesForLS)
        },

        getBonus () {
            this.$pivik.event(
                'daily_bonus',
                'get',
                this.currentBonus.bookmaker || 'unknown',
                this.currentBonus.day
            )
        },

        menuClickHandler () {
            this.open()
            this.$pivik.event('daily_bonus', 'show', 'menu_click', this.currentBonus.day)
            this.dfpIsActive = true
        },

        getApp () {
            this.$pivik.event('daily_bonus', 'download_app', 'click', this.currentBonus.day)
        },

        iterateBonus (bonus, index) {
            return {
                ...bonus,
                current: this.activeBonusIndex === index,
                available: this.currentDay >= index,
                day: index + 1
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
@keyframes rotate
    0%
        transform rotate3d(0, 0, 1, 0)

    100%
        transform rotate3d(0, 0, 1, 360deg)

.daily-bonuses-popup

    &__wrapper {
        position absolute
        height 100%
        width 100%
    }
    &__overlay
        position fixed
        height 100%
        width 100%
        background-color rgba(0, 0, 0, .65)
        z-index 999
        display flex
        align-items center
        justify-content center

    &__popup
        width 52rem
        background $cl-white
        border-radius 1rem
        overflow hidden
        letter-spacing .025rem

        +layout-sm()
            align-self flex-end
            border-radius 2rem 2rem 0 0

    &__header
        height 10rem
        width 100%
        background linear-gradient(180deg, #FF8C3D 0%, #FFC931 100%)
        overflow hidden
        position relative

        &-back
            position absolute
            animation rotate infinite 30s linear
            top -23rem
            left -2.8rem

            +layout-sm()
                left calc(50vw - 28.5rem)

    &__title
        display block
        position relative
        margin-top 1.6rem
        color $cl-white
        text-align center
        z-index 2
        font-size 2.1rem // такого нет в ui-kit
        font-weight $font-weight-medium
        letter-spacing .025rem

    &__bonus-title
        display block
        position relative
        color $cl-white
        text-align center
        z-index 2
        font-size $font-size-heading-1
        font-weight $font-weight-bold

    &__description
        margin-top 2.4rem
        font-size $font-size-base-sub
        text-align center

    &__bonuses
        text-align center
        white-space nowrap
        margin 0
        padding 2rem 1rem 2rem 1.5rem
        height 11rem

    &__bonuses-wrapper
        overflow-x auto

        +layout-sm()
            padding-left 1.6rem
            padding-right 1.6rem

    &__bookmaker-info
        display flex
        justify-content center
        align-items center
        margin-left 1.6rem
        margin-right 1.6rem
        border-top .1rem solid $cl-secondary
        padding-top 1.6rem
        padding-bottom 1.6rem

        +layout-sm()
            flex-direction column

    &__bookmaker-logo
        height 4rem

        +layout-sm-and-up()
            margin-right 2.4rem

        +layout-sm()
            margin-bottom 1rem

    &__bookmaker-text
        max-width 20rem
        margin 0

        +layout-sm()
            text-align center

    &__link
        display block
        margin-left 1.6rem
        margin-right 1.6rem
        margin-bottom 1.6rem
        font-size $font-size-base-sub
        background #72B65A
        color $cl-white
        height 4rem
        border-radius .8rem
        line-height 4rem
        text-align center
        text-decoration none

        &:hover
            background #53ab3a

        &:active
            background $cl-success-light

        &--app
            background transparent
            border .1rem solid #72B65A
            box-sizing border-box
            color $cl-text-dark
            line-height 3.8rem

            +layout-sm-and-up()
                display none

            &:hover
                background transparent

            &:active
                background rgba(111, 207, 151, .15)

    &__close
        position absolute
        right .8rem
        top .8rem
        fill $cl-white
        z-index 3
        cursor pointer

</style>
