import Api from '@/api'
import { FOOTBALL_SPORT_ID } from '@/constants'

export default function fetchMatches ({ tournamentId = 'default', isLive = 0, state = null, top = 1, date }) {
    const params = {
        date,
        category_id: parseInt(FOOTBALL_SPORT_ID),
        bookmaker_id: 'default',
        include: 'tournament,odds,team,stadium',
        tournament_id: tournamentId,
        is_live: isLive,
        line_type: 'default',
        with_top_tournaments: top,
        state
    }

    return Api.trbna({ path: 'matches/with_odds', params, maps: ['home_team', 'away_team'] })
}
