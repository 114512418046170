<template>
    <div
        v-if="showStick"
        v-show="isRendered"
        class="pr-stick-float"
    >
        <button
            class="pr-stick-float__close"
            @click="close"
        >
            <svg
                width="26px"
                height="26px"
                viewBox="0 0 26 26"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <g transform="translate(0.3, 0.3)">
                    <path
                        d="M13.3639977,2.05028917 L12.6568909,2.75689089 L11.9497841,2.05028917 C9.21611406,-0.683380874 4.78395921,-0.683380874 2.05028917,2.05028917 L1.86813863,2.23926783 C-0.681968854,4.98454348 -0.621252009,9.27824293 2.05028917,11.9497841 L2.75689089,12.6568909 L2.05026204,13.3640248 L1.82534894,13.6009201 L1.7380656,13.6971238 C-0.69051786,16.4628512 -0.56266548,20.650538 2.05028917,23.2634926 L2.28721163,23.4884328 L2.38341532,23.5757162 L2.58334513,23.7448397 C5.34766362,25.9968026 9.39904266,25.814234 11.9497841,23.2634926 L12.6568909,22.5558909 L13.3641414,23.2636363 C16.0976677,25.9971626 20.5298226,25.9971626 23.2634926,23.2634926 L23.4456431,23.0745139 C25.9957506,20.3292383 25.9350338,16.0355388 23.2634926,13.3639977 L22.5558909,12.6558909 L23.2637529,11.9495238 L23.4884328,11.7128616 L23.5757162,11.616658 C26.0042996,8.85093061 25.8764473,4.66324382 23.2634926,2.05028917 L23.0265701,1.82534894 L22.9303664,1.7380656 C20.1646391,-0.69051786 15.9769523,-0.56266548 13.3639977,2.05028917 Z"
                        fill="#FFFFFF"
                    />
                    <path
                        id="Path"
                        d="M9.12135698,4.87871629 L12.6570366,8.41403664 L16.1924248,4.87871629 C17.3171348,3.75400633 19.1126977,3.70901793 20.2910759,4.7437511 L20.4350655,4.87871629 C21.5597754,6.00342625 21.6047638,7.79898919 20.5700307,8.97736738 L20.4350655,9.12135698 L16.9000366,12.6570366 L20.4350655,16.1924248 C21.6066384,17.3639977 21.6066384,19.2634926 20.4350655,20.4350655 C19.2634926,21.6066384 17.3639977,21.6066384 16.1924248,20.4350655 L12.6570366,16.9000366 L9.12135698,20.4350655 C7.99664702,21.5597754 6.20108409,21.6047638 5.02270589,20.5700307 L4.87871629,20.4350655 C3.75400633,19.3103555 3.70901793,17.5147926 4.7437511,16.3364144 L4.87871629,16.1924248 L8.41403664,12.6570366 L4.87871629,9.12135698 C3.70714342,7.94978411 3.70714342,6.05028917 4.87871629,4.87871629 C6.05028917,3.70714342 7.94978411,3.70714342 9.12135698,4.87871629 Z"
                        fill="#383E4A"
                    />
                </g>
            </svg>
        </button>

        <div
            ref="banner-container"
            class="bi-dfp pr-stick-float__img"
        >
            <div
                :id="id"
                class="bi-dfp__ad"
            />
            <a
                v-if="creative.show"
                :href="creative.link"
                class="pr-stick-float--vue"
                target="_blank"
                @click="creativeClickHandler"
            >
                <img
                    :src="creative.image"
                    :alt="creative.bookmaker"
                    class="stick-ad__img"
                >
                <img
                    v-if="creative.trackView"
                    :src="creative.trackView"
                    class="stick-ad__track-pixel"
                >
                <img
                    v-if="creative.trackViewCustom"
                    :src="creative.trackViewCustom"
                    class="stick-ad__track-pixel stick-ad__track-pixel--custom"
                >
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import dfp from '@/mixins/dfp'

const STICK_REFRESH_COUNTDOWN_DURATION = 2000000000

export default {
    name: 'DFPStick',

    mixins: [dfp],

    data () {
        return {
            showStick: false,
            creative: {
                show: false
            }
        }
    },

    computed: {
        ...mapState('storage', ['showMobileStick'])
    },

    mounted () {
        this.showStick = new Date(this.showMobileStick) < Date.now()

        if (this.showStick) {
            this.init()
            this.$on('receive', this.showCreative)
            this.$on('render', this.renderHandler)
        }
    },

    beforeDestroy () {
        clearTimeout(this.timeout)
    },

    methods: {
        close () {
            const date = new Date()

            this.$store.commit('storage/set', { showMobileStick: new Date(date.setDate(date.getDate() + 7)) })
            this.showStick = false
        },

        showCreative (message) {
            if (message) {
                this.creative.link = message.link
                this.creative.trackView = message.trackView
                this.creative.trackViewCustom = message.trackViewCustom
                this.creative.image = message.imageSrc
                this.creative.bookmaker = message.bookmakerName || 'unknown'
                this.creative.show = true
                this.$pivik.event('dfp_show', this.placement, this.creative.bookmaker)
            }
        },

        creativeClickHandler () {
            this.$pivik.event('dfp_click', this.placement, this.creative.bookmaker)
        },

        initRefreshCountdown () {
            this.timeout = setTimeout(() => {
                const hardBanners = this.$refs['banner-container']
                    .querySelectorAll('a:not(.pr-stick-float--vue)')

                Array.prototype.forEach.call(hardBanners, (el) => {
                    this.$refs['banner-container'].removeChild(el)
                })

                this.refresh()
            }, STICK_REFRESH_COUNTDOWN_DURATION)
        },

        renderHandler () {
            this.creative = {
                link: '',
                trackView: '',
                trackViewCustom: '',
                image: '',
                bookmaker: '',
                show: false
            }

            this.initRefreshCountdown()
        },

        refresh () {
            if (document.hidden) {
                this.waitForFocus(this.update)
            } else {
                this.update()
            }
        },

        waitForFocus (cb) {
            const handler = () => {
                cb()
                document.removeEventListener('visibilitychange', handler)
            }

            document.addEventListener('visibilitychange', handler)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .pr-stick-float
        position fixed!important
        bottom 3rem
        left 2.5rem
        z-index 40

        .ui-icon
            width 2.4rem
            height @width

        &__close
            position absolute
            top 0
            right 0
            transform translate(50%, -50%)
            filter drop-shadow(2px 2px 3px rgba(0, 0, 0, .2))
            outline none
            border none
            background none
            cursor pointer

        &__img
            >>> img
                width 7.5rem
                height @width
                filter drop-shadow(2px 2px 3px rgba(0, 0, 0, .2))

    .stick-ad
        &__img
            width 7.5rem
            height @width
            filter drop-shadow(2px 2px 3px rgba(0, 0, 0, .2))

        &__track-pixel
            opacity 0
            z-index -1
            position absolute
            width 0
            height 0
</style>
