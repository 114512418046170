import { render, staticRenderFns } from "./app.vue?vue&type=template&id=bf5e7fbe&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"
import style0 from "./app.vue?vue&type=style&index=0&id=bf5e7fbe&prod&rel=stylesheet%2Fstylus&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/base.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbetting-insider%2Fbi-frontend%2Fsource%2Fapp.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports