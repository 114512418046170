export default {
    namespaced: true,
    // Отменяет серверный рендеринг модуля
    ssr: false,
    state () {
        return {
            smartBannerDismissed: 0,
            smartBannerInstall: 0,
            showClosePreventing: 0,
            showMobileStick: 0
        }
    },

    actions: {
        setInStoreByKeys ({ commit }, data) {
            commit('set', data)
        }
    },

    mutations: {
        set (state, data) {
            Object.keys(data).forEach(key => {
                state[key] = data[key]
            })
        }
    }
}
