import Api from '@/api'
import { amountMax, amountMin } from '@/utils/bet-amount'
import { createFootballBet } from '@/api/graphql/predictions-football'
import { createNewSportsBet } from '@/api/graphql/new-sports'
import _escape from '@/utils/escape'

import { UPDATE_MY_PROFILE_BALANCE } from '@/store/modules/profile'

const getCreateBetQuery = odd => {
    const params = {
        oddsID: odd.id,
        amount: odd.amount,
        rate: odd.rate,
        description: _escape(odd.comment.trim())
    }

    if (odd.parent_id) {
        params.parentID = odd.parent_id
    }

    if (odd.isFootball) {
        return createFootballBet(params)
    }

    return createNewSportsBet(params)
}

export default {
    namespaced: true,

    state () {
        return {
            saved: false,
            editMode: false,
            betOpenedFromReminder: false,
            odd: {
                id: null,
                isFootball: false,
                name: '',
                rate: 0,
                amount: 0,
                amountMin: 0,
                amountMax: 0,
                parent_id: 0,
                comment: '',
                promo: ''
            }
        }
    },

    actions: {
        /**
         * Устанавливает параметры ставки
         */
        set ({ dispatch, commit, state, rootGetters }, odd) {
            if (typeof odd === 'object' && odd.id) {
                // Если выберается другая ставка, то очищаем все поля
                commit('clean')

                // Если сменился кэф и не передали значение ставки,
                // тогда устанавливаем значение ставки
                if (state.odd.id !== odd.id && !odd.amount && odd.rate) {
                    const { balance } = rootGetters['profile/my']
                    const min = parseInt(amountMin(odd.rate, balance))
                    const max = parseInt(amountMax(odd.rate, balance))

                    odd.amount = parseInt((max + min) / 2)

                    commit('update', { amountMin: min, amountMax: max })
                }

                if (odd.id !== state.odd.id && !odd.parent_id) {
                    state.odd.parent_id = 0
                }
            }

            commit('update', odd)
            commit('edit', true)

            dispatch('curtain/changeMode', { mode: 'bet' }, { root: true })
        },

        /**
         * Подтверждает выбранную ставку
         * @returns {Promise<void>}
         */
        bet ({ commit, state, rootGetters }) {
            commit('edit', false)

            const query = getCreateBetQuery(state.odd)

            return Api.graphql(query, { type: 'mutation' })
                .then(() => {
                    const balance = rootGetters['profile/my'].balance - state.odd.amount

                    commit(`profile/${UPDATE_MY_PROFILE_BALANCE}`, { balance }, { root: true })
                    commit('clean')
                })
        },

        updateBetOpenedFromReminder ({ commit }, val) {
            commit('setBetOpenedFromReminder', val)
        }
    },

    mutations: {
        clean (state) {
            state.odd.id = null
            state.odd.name = ''
            state.odd.rate = 0
            state.odd.amount = 0
            state.odd.amountMin = 0
            state.odd.amountMax = 0
            state.odd.amount = 0
            state.odd.comment = ''
            state.odd.parent_id = 0
            state.odd.promo = ''

            state.editMode = false
            state.saved = false
            state.betOpenedFromReminder = false
        },

        update (state, odd) {
            if (typeof odd === 'object') {
                Object.keys(odd).forEach(key => {
                    state.odd[key] = odd[key]
                })
            }
        },

        edit (state, val) {
            state.editMode = val
        },

        save (state, val) {
            state.saved = val
        },

        setBetOpenedFromReminder (state, val) {
            state.betOpenedFromReminder = val
        }
    }
}
