// https://developers.google.com/doubleclick-gpt/reference

import loadScript from '@/utils/loadScript'


const EVENTS = {
    slotOnload: [],
    slotRenderEnded: [],
    slotVisibilityChanged: [],
    impressionViewable: []
}

let gptPromise = null
// время задержки показа рекламы в мс
const gptDelay = 0
// баннеры которые загружались на страницу
const banners = []

// задержка перед показом рекламы
function delay (ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms)
    })
}

function onEvent (eventName, e) {
    EVENTS[eventName].forEach(fn => fn(e))
}

function receiveMessage (event) {
    try {
        const data = JSON.parse(event.data) || {}

        if (data.frameId) {
            const frame = document.getElementById(data.frameId)
            const banner = banners.find((el) => el.$el.contains(frame))

            if (banner) {
                banner.$emit('receive', data)
            }
        } else if (data.adunitPath) {
            // для баннеров, что не обновились на использование frameId
            const banner = banners.find((el) => el.config.unit === data.adunitPath)

            if (banner) {
                banner.$emit('receive', data)
            }
        }
    } catch (err) {}
}


export function cmd (fn) {
    window.googletag.cmd.push(fn)
}

export function GPT () {
    if (!gptPromise) {
        Object.defineProperty(window, 'googletag', { writable: false, configurable: false, value: window.googletag || {} })
        window.googletag.cmd = window.googletag.cmd || []

        gptPromise = delay(gptDelay).then(() => loadScript('//www.googletagservices.com/tag/js/gpt.js').then(() => {
            cmd(() => {
                window.googletag.pubads().enableSingleRequest()
                window.googletag.enableServices()

                window.googletag.pubads().addEventListener('slotOnload', e => onEvent('slotOnload', e))
                window.googletag.pubads().addEventListener('slotRenderEnded', e => onEvent('slotRenderEnded', e))
                window.googletag.pubads().addEventListener('slotVisibilityChanged', e => onEvent('slotVisibilityChanged', e))
                window.googletag.pubads().addEventListener('impressionViewable', e => onEvent('impressionViewable', e))
                window.addEventListener('message', receiveMessage)
            })
        }))
    }

    return gptPromise
}

export function destroySlots (slots) {
    window.googletag.destroySlots(slots)
}

export function addEventListener (eventName, cb) {
    EVENTS[eventName].push(cb)
}

export function removeEventListener (eventName, cb) {
    const index = EVENTS[eventName].findIndex(item => item === cb)

    if (index > -1) {
        EVENTS[eventName].splice(index, 1)
    }
}

export function addBanner (instance) {
    if (banners.indexOf(instance) === -1) {
        banners.push(instance)
    }
}

export function removeBanner (instance) {
    const index = banners.indexOf(instance)

    if (index > -1) {
        banners.splice(index, 1)
    }
}
