<template>
    <div v-if="betEditMode">
        <div
            v-if="!isEditorOpen"
            class="bet-float"
            @click="open"
        >
            <IconOdds class="bet-float__odds-icon" />
            {{ $t('coupon') }}
        </div>

        <BiFullscreen
            :show="isEditorOpen"
            direction="bottom"
        >
            <div class="bet-fullscreen">
                <button
                    class="bet-fullscreen__close"
                    @click="close"
                >
                    <IconArrow2 />
                </button>

                <BetEditor />
            </div>
        </BiFullscreen>
    </div>
</template>

<i18n>
{
    "ru": {
        "coupon": "Купон"
    },
    "en": {
        "coupon": "Coupon"
    }
}
</i18n>

<script>
import { mapState, mapActions } from 'vuex'
import BiFullscreen from '@/components/common/bi-fullscreen/index.vue'
import BetEditor from '@/components/Bet/BetEditor.vue'
import IconArrow2 from '@/assets/svg/arrow-round2.icon.svg'
import IconOdds from '@/assets/svg/odds.icon.svg'

export default {
    name: 'BetFloat',

    components: {
        BiFullscreen,
        BetEditor,
        IconOdds,
        IconArrow2
    },

    computed: {
        ...mapState({
            betEditMode: state => state.bet.select.editMode,
            isEditorOpen: state => state.curtain.isOpened
        })
    },

    methods: {
        ...mapActions({
            toggleCurtain: 'curtain/changeOpenStatus'
        }),

        open () {
            this.toggleCurtain(true)
        },

        close () {
            this.toggleCurtain(false)
        }
    }
}
</script>

<style lang="stylus" scoped>
.bet-float
    position fixed!important
    right 0
    bottom 0
    z-index 40
    width 100%
    height 4.8rem
    display flex
    justify-content center
    align-items center
    background #fff
    border-radius 16px 16px 0 0
    box-shadow 0 0 15px 0 rgba(0, 0, 0, .08)
    text-transform uppercase
    font-weight $font-weight-bold

    .ui-icon
        width 2rem
        height @width
        margin-right .5rem

.bet-fullscreen
    position relative

    &__close
        button(5, 'icon', 'text')
        position absolute
        right 1rem
        top 1rem

    >>> .bet
        background-color initial

.icon
    width 2.4rem
    height @width

>>> .bi-fullscreen__body
    position fixed
    bottom 0
    z-index 20
    width 100%
    height initial
    border-radius 16px 16px 0 0
    box-shadow 0 0 15px 0 rgba(0, 0, 0, .08)
</style>
