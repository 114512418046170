const bookmakerBonus = `
id
type
name
shortDescription
description
promocode
link
priority
... on BookmakerMoneyBonus {
  amount
  currencyCode
}
... on BookmakerPercentBonus {
  percent
}`

const bookmaker = `
id
name
webname
title
metaDescription
description
h1
logo
primaryColor
text
iOS
android
pros
cons
isPartner
bonuses(first: 100, sort: PRIORITY_DESC) {
  ${bookmakerBonus}
}
featureRatings(sort: FEATURE_PRIORITY_DESC) {
  id
  feature {
    id
    name
  }
  score
}
websites {
  type
  url
}
statistics {
  reviewScoreAvg
  reviewCount
}`

const bookmakerReview = `
id
createTime
user {
  id
  name
  avatar
  roi
  winRate
  betsForm {
    state
  }
  isViewer
}
score
description`

/**
 * Список отзывов букмекера с возможностью пагинации
 * @param {String} webname - webname букмекера
 * @param {String} countryCode - код страны
 * @param {Number} first - количество отзывов
 * @param {Number} offset - смещение
 * @returns {String}
 */
export function fetchReviews ({ webname, countryCode, first = 10, offset }) {
    return `
      bookmakerByWebname(webname: "${webname}" ${
    countryCode ? `, countryCode: "${countryCode}"` : ''
}) {
        reviews(first: ${first} offset: ${offset ? `${offset}` : null}) {
          pageInfo {
            totalCount
            hasNextPage
            endCursor
          }
          items {
            ${bookmakerReview}

            comments(first: 3) {
              id
              list {
                id
                text
                ctime
                user {
                  id
                  name
                  avatar
                  roi
                  winRate
                  betsForm {
                    state
                  }
                }
                parentComment {
                  id
                  text
                  user {
                    id
                    name
                    avatar
                    roi
                    winRate
                    betsForm {
                      state
                    }
                  }
                }
              }
              pageInfo {
                totalCount
                hasNextPage
                endCursor
              }
            }
          }
        }
      }`
}

/**
 * Получение списка рейтингов букмекеров
 * @param {String} countryCode - код страны
 * @returns {String}
 */
export function fetchRatings ({ countryCode }) {
    let filter = 'isDefault: true'

    if (countryCode) {
        filter += `, countryCode: "${countryCode}"`
    }

    return `{
      bookmakerRatings (first: 15, filter: {${filter}}, sort: PRIORITY_DESC) {
        items {
          id
          name
          webname
        }
      }
    }`
}

/**
 * Получение букмекера по webname
 * @param {String} webname - webname букмекера
 * @param {String} countryCode - код страны
 * @returns {String}
 */
export function fetchBookmaker ({ webname, countryCode }) {
    return `{
      bookmakerByWebname(webname: "${webname}"${
    countryCode ? `, countryCode: "${countryCode}"` : ''
}) {
        ${bookmaker}
      }
    }`
}

/**
 * Получение рейтинга букмекеров по webname
 * @param {String} webname - webname букмекера
 * @param {String} countryCode - код страны
 * @returns {String}
 */
export function fetchRatingsList ({ webname, countryCode }) {
    return `{
      bookmakerRatingByWebname(webname: "${webname}", countryCode: "${countryCode}") {
        id
        name
        webname
        description
        title
        metaDescription
        text
        h1
        bookmakers {
          bookmaker {
            ${bookmaker}
          }
        }
      }
    }`
}

/**
 * Создание отзыва букмекера
 * @param {String} countryCode - код страны
 * @param {String} bookmakerId - идентификатор букмекера
 * @param {Number} score - значение рейтинга
 * @param {String} description - текстовое описание отзыва
 * @returns {String}
 */
export function createBookmakerReview ({
    countryCode,
    bookmakerId,
    score,
    description
}) {
    return `{
      createBookmakerReview (
        review: {
          bookmakerID: "${bookmakerId}"
          score: ${score}
          description: "${description}"
        }
        countryCode: "${countryCode}"
      ) {
        review {
          ${bookmakerReview}
        }
      }
    }`
}

/**
 * Редактирование отзыва букмекера
 * @param {String} id - идентификатор отзыва букмекера
 * @param {Number} score - значение рейтинга
 * @param {String} description - текстовое описание отзыва
 * @returns {String}
 */
export function updateBookmakerReview ({ id, score, description }) {
    let review = `id: "${id}"`

    if (score) {
        review += ` score: ${score}`
    }

    if (description) {
        review += ` description: "${description}"`
    }

    return `{
      updateBookmakerReview (review: {${review}}) {
        review {
          ${bookmakerReview}
        }
      }
    }`
}

/**
 * Отзыв букмекера автора запроса
 * @param {String} webname - webname букмекера
 * @param {String} countryCode - код страны
 * @returns {String}
 */
export function getBookmakerReview ({ webname, countryCode }) {
    return `{
      bookmakerByWebname(webname: "${webname}", countryCode: "${countryCode}") {
        viewerReview {
          ${bookmakerReview}
        }
      }
    }`
}

/**
 * Получение бонусов букмекера
 * @param {String} webname - webname букмекера
 * @param {String} countryCode - код страны
 * @param {Number} first - количество бонусов
 * @returns {String}
 */
export function getBookmakerBonuses ({ webname, countryCode, first = 100 }) {
    return `{
      bookmakerByWebname(webname: "${webname}", countryCode: "${countryCode}") {
        bonuses (sort: PRIORITY_DESC, first: ${first}) {
          ${bookmakerBonus}
        }
      }
    }`
}

/**
 * Получение всех бонусов всех букмекеров по гео-локали
 * @param {String} countryCode - код страны
 * @param {Number} first - количество бонусов
 * @returns {String}
 */
export function getBookmakerBonusesAll ({ countryCode, first = 1000 }) {
    return `{
      bookmakerBonusesConnection(filter: {countryCode: "${countryCode}"}, first: ${first}) {
        edges {
          cursor
          node {
            ${bookmakerBonus}
            bookmaker {
              id
              webname
              logo
              primaryColor
            }
          }
        }
      }
    }`
}

/**
 * Запрос данных для виджета букмекеров
 * @param {String} countryCode - код страны
 * @returns {String}
 */
export function getBookmakersWidget (countryCode) {
    let filter = 'isDefault: true'

    if (countryCode) {
        filter += ` countryCode: "${countryCode}"`
    }

    return `{
  bookmakerRatings(
    first: 1
    filter: {${filter}}
    sort: PRIORITY_DESC
  ) {
    items {
      bookmakers {
        bookmaker {
          primaryColor
          isPartner
          logo
          id
          webname
          statistics {
            reviewCount
          }
          websites {
            url
            type
          }
          featureRatings(sort: FEATURE_PRIORITY_DESC) {
            id
            feature {
              id
              name
            }
            score
          }
          bonuses(first: 10, sort: PRIORITY_DESC) {
            type
            name
            link
            priority
            ... on BookmakerMoneyBonus {
              amount
              currencyCode
            }
            ... on BookmakerPercentBonus {
              percent
            }
          }
        }
      }
    }
  }
}`
}
