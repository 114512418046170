<template>
    <a
        v-if="text && link"
        :href="link"
        class="pr-mobile-menu"
        target="_blank"
        @click="goToPromo"
    >
        <div :style="{ color: textColor }">
            {{ text }}
        </div>

        <GiftBoxIcon
            :style="{ color: giftColor }"
            class="pr-mobile-menu__icon"
        />
    </a>
</template>

<script>
import GiftBoxIcon from '@/assets/svg/giftbox.icon.svg'

export default {
    name: 'DFPPromoGiftButton',

    components: {
        GiftBoxIcon
    },

    props: {
        text: {
            type: String,
            default: ''
        },

        textColor: {
            type: String,
            default: '#fff'
        },

        giftColor: {
            type: String,
            default: ''
        },

        link: {
            type: String,
            required: true
        },

        bookmaker: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            placement: 'notification_desktop'
        }
    },

    mounted () {
        this.$pivik.event('dfp_show', this.placement, this.bookmaker)
    },

    methods: {
        goToPromo () {
            window.open(this.link, '_blank')
            this.$pivik.event('dfp_show', this.placement, this.bookmaker)
        }
    }
}
</script>

<style lang="stylus" scoped>
.pr-mobile-menu
    display flex
    align-items center
    border-radius .8rem
    padding .1rem .8rem
    height 4rem
    overflow hidden
    font-size 1.2rem
    line-height 1.6rem
    text-align center
    background rgba($cl-secondary-light, .1)

    &:active,
    &:hover,
    &:focus
        text-decoration none

    &__icon
        margin-left .5rem

</style>
