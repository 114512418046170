function profile() {
    return `{
        id
        name
        avatar
        about
        balance
        roi
        rank
        weeklyRoi
        weeklyRank
        subscribedCount
        subscribersCount
        canRefund
        hasRoles {
            moderator
            communityModerator
            pro
            user
            banned
        }
        viewerHasInFavorites
        ctime
        noindex
    }`;
}

/**
 * Получение профиля
 * @param id - индентификатор профиля
 * @return {string}
 */
export function fetchProfile({ id }) {
    return `userProfile(id: "${id}") ${profile()}`;
}

/**
 * Изменение профиля
 * @param userProfile {Object} - данные профиля пользователя
 * @param userProfile.id {string} - индентификатор профиля
 * @param userProfile.name {string} - имя профиля
 * @param userProfile.about {string} - описание профиля
 * @param userProfile.avatar {string} - ссылка на аватар
 * @return {string}
 */
export function updateProfile(userProfile) {
    const about = Object.hasOwnProperty.call(userProfile, "about")
        ? `about: "${userProfile.about}"`
        : "";

    return `updateUserProfile(userProfile: {id: "${userProfile.id}" ${
        userProfile.name ? `name: "${userProfile.name}"` : ""
    } ${
        userProfile.avatar ? `avatar: "${userProfile.avatar}"` : ""
    } ${about}}) ${profile()}`;
}

/**
 * Пополнение баланса текущего пользователя
 * @return {string}
 */
export function fetchUserRefund() {
    return `userRefund { profile ${profile()}}`;
}

export function fetchUserUploadPhotoUrl() {
    return `imageMutations {
        upload(in: {imageType: CONTENT}) {
          uploadURL
        }
      }`;
}
