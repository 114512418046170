import localStorage from '@/utils/localstorage'

const ODDS_FORMAT_KEY = 'bi_odds_format'

/**
 *  Установка типа кэфов в localStorage
 * @param oddsFormat {string} - тип кэфов
 */
export const setOddsFormat = (oddsFormat = '') => {
    localStorage.set(ODDS_FORMAT_KEY, oddsFormat)
}

/**
 *  Чтение авторизационного токена из localStorage
 * @returns {string} - токен
 */
export const getOddsFormat = () => localStorage.get(ODDS_FORMAT_KEY)
