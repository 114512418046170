<template>
    <span
        class="ui-progress"
        :class="classes"
    >
        <IconLoading />
    </span>
</template>

<script>
import IconLoading from '@/ui/icons/loading.vue'

export default {
    name: 'UiProgress',

    components: {
        IconLoading
    },

    props: {
        /**
         * [primary], [accent], [warn], [gray], [dark], [transparent]
         */
        theme: {
            type: String,
            default: ''
        },

        /**
         * Размер компонента
         * [sm], [md], [l]
         */
        size: {
            type: String,
            default: ''
        }
    },

    computed: {
        classes () {
            const classes = []

            if (this.theme) {
                classes.push(`ui-progress--theme-${this.theme}`)
            }

            if (this.size) {
                classes.push(`ui-progress--size-${this.size}`)
            }

            return classes
        }
    }
}
</script>

<style lang="stylus" scoped>
    $block = ".ui-progress"

    @keyframes spin {
        100% {
            transform rotate(360deg)
            stroke-width 0
        }
    }

    theme($color-fill) {
        .ui-icon {
            fill $color-fill
        }
    }

    size($width, $height) {
        height $height
        .ui-icon {
            width $width
            height $height
        }
    }

    {$block} {
        display inline-block
        user-select none
        font-size 0

        /deep/ .spinner {
            transform-origin 50% 50% 0
            transform-style preserve-3d
            animation spin 1s cubic-bezier(0.74, 0.4, 0.51, 0.81) infinite
        }

        &--theme {
            &-primary {
                theme($cl-primary)
            }
            &-accent {
                theme($cl-success-light)
            }
            &-warn {
                theme($cl-error)
            }
            &-gray {
                theme($cl-secondary)
            }
            &-dark {
                theme($cl-text-dark)
            }
            &-transparent {
                theme(transparent)
            }
        }

        &--size {
            &-xs {
                size(10px, 10px)
            }
            &-sm {
                size(20px, 20px)
            }
            &-md {
                size(26px, 26px)
            }
            &-l {
                size(30px, 40px)
            }
        }
    }
</style>
