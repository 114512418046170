<template>
    <div class="gambling">
        <div class="gambling__logos">
            <Icon18 class="gambling__item" />
            <a
                class="gambling__item"
                href="//www.gamcare.org.uk"
                target="_blank"
                rel="nofollow"
            >
                <IconGt />
            </a>
            <a
                class="gambling__item"
                href="//www.gambleaware.co.uk"
                target="_blank"
                rel="nofollow"
            >
                <IconGamble />
            </a>
        </div>
        <div class="gambling__text">
            Gambling can be addictive. Please know your <br>limits and gamble responsibly.
        </div>
    </div>
</template>

<script>
import Icon18 from '@/assets/svg/18+.icon.svg'
import IconGt from '@/assets/svg/gt.icon.svg'
import IconGamble from '@/assets/svg/gamble.icon.svg'

export default {
    name: 'TheFooterGambling',

    components: {
        Icon18,
        IconGamble,
        IconGt
    }
}
</script>

<style lang="stylus" scoped>
    .gambling {
        display flex
        align-items center
        flex-direction row
        +layout-md() {
            flex-direction column
        }
        &__text {
            color rgba($cl-secondary, 0.4)
            opacity .5
            +layout-md() {
                margin-top 0.4rem
                font-size 1.5rem
                text-align center
            }
        }
        &__logos {
            display flex
            align-items center
            a {
                opacity .5
                &:hover {
                    opacity 1
                }
            }
        }
        &__item {
            margin 0 1rem
            >>> .ui-icon {
                width auto
                height 3rem
            }
        }
    }
</style>
