<template>
    <CorePortal :prepend="portalPrepend">
        <transition name="push">
            <div
                v-if="value"
                class="pusher"
                :style="style"
                @click.stop
            >
                <div class="pusher__content">
                    <slot />
                </div>
            </div>
        </transition>
    </CorePortal>
</template>

<script>
import CorePortal from '@/ui/core/portal/index.vue'
import setDisableScroll from '@/utils/set-disable-scroll'

export default {
    name: 'UiPusher',

    components: {
        CorePortal
    },

    props: {
        layout: {
            type: String,
            default: '#app'
        },

        portalPrepend: {
            type: Boolean,
            default: false
        },

        value: {
            type: Boolean,
            default: false
        },

        top: {
            type: String,
            default: '5rem'
        },

        width: {
            type: String,
            default: '80%'
        }
    },

    data () {
        return {
            closeElement: {}
        }
    },

    computed: {
        layoutElement () {
            return document.querySelector(this.layout)
        },

        style () {
            return {
                paddingTop: this.top,
                width: this.width
            }
        }
    },

    watch: {
        value (stateShow) {
            if (stateShow) {
                this.show()
            } else {
                this.hide()
            }
        }
    },

    beforeDestroy () {
        this.hide()
    },

    methods: {
        show () {
            setDisableScroll({ disabled: true })
            this.layoutElement.style.transition = 'transform 0.2s linear, filter 0.2s'
            this.layoutElement.style['-moz-transition'] = 'transform 0.2s linear, filter 0.2s'
            this.layoutElement.style['-webkit-transition'] = 'transform 0.2s linear, filter 0.2s'
            this.layoutElement.style.transform = `translate(${this.width}, 0)`
            this.layoutElement.style['-moz-transform'] = `translate(${this.width}, 0)`
            this.layoutElement.style['-webkit-transform'] = `translate(${this.width}, 0)`
            this.layoutElement.style.filter = 'blur(5px)'
            this.layoutElement.style['-webkit-filter'] = 'blur(5px)'
            this.layoutElement.style['-moz-filter'] = 'blur(5px)'
            this.createCloseElement()
        },

        hide () {
            setDisableScroll({ disabled: false })
            this.layoutElement.style.transform = null
            this.layoutElement.style['-moz-transform'] = null
            this.layoutElement.style['-webkit-transform'] = null
            this.layoutElement.style.filter = null
            this.layoutElement.style['-webkit-filter'] = null
            this.layoutElement.style['-moz-filter'] = null
            this.removeCloseElement()
        },

        createCloseElement () {
            this.closeElement = document.createElement('span')
            this.closeElement.style.position = 'absolute'
            this.closeElement.style.top = '0'
            this.closeElement.style.left = '0'
            this.closeElement.style.right = '0'
            this.closeElement.style.bottom = '0'
            this.closeElement.style.zIndex = 100
            this.closeElement.addEventListener('click', this.onHide)
            this.layoutElement.appendChild(this.closeElement)
        },

        removeCloseElement () {
            if (this.closeElement?.removeEventListener) {
                this.closeElement.removeEventListener('click', this.onHide)
                this.layoutElement.removeChild(this.closeElement)
            }
        },

        onHide () {
            this.$emit('input', false)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .pusher {
        position fixed
        top 0
        left 0
        bottom 0
        z-index 2
        background-color $cl-text-dark
        color #fff

        &__content {
            height 100%
            width 100%
            border-top 0.4rem solid rgba(#000000, .15)
            overflow auto
            -webkit-overflow-scrolling touch
        }
    }
    .push-enter-active,
    .push-leave-active {
        transition transform .18s linear
    }
    .push-enter,
    .push-leave-to {
        transform translate(-100%, 0)
    }
</style>
