import Api from '@/api'
import { fetchFootballPrediction } from '@/api/graphql/predictions-football'
import { predictionPageIs404, shouldReirectToProfile } from '@/utils/has-prediction-page'
import { fetchNewSportPrediction } from '@/api/graphql/predictions-new-sports'

export const SET_PREDICTION_ID = 'SET_PREDICTION_ID'

export default {
    namespaced: true,

    state () {
        return {
            predictionId: ''
        }
    },

    getters: {
        prediction (state, getters, rootState) {
            return rootState.predictions.predictions[state.predictionId]
        }
    },

    actions: {
        async fetch ({ commit, dispatch }, { id }) {
            try {
                const predictionQuery = fetchFootballPrediction({ id })
                const prediction = await Api.graphql(predictionQuery)

                if (predictionPageIs404(prediction)) {
                    return Promise.reject({ code: 404 })
                }

                if (shouldReirectToProfile(prediction)) {
                    return Promise.reject({
                        code: 301,
                        route: {
                            name: 'profile',
                            params: {
                                id: prediction.user.id
                            }
                        }
                    })
                }

                dispatch('predictions/updatePredictionsMap', {
                    predictions: [prediction]
                }, { root: true })

                commit(SET_PREDICTION_ID, prediction.id)
            } catch (err) {
                return Promise.reject({ code: 404 })
            }

            return null
        },

        async fetchNewSport ({ commit, dispatch }, { id }) {
            try {
                const predictionQuery = fetchNewSportPrediction({ id })
                const prediction = await Api.graphql(predictionQuery)

                if (prediction.description.length < 100) {
                    return Promise.reject({
                        code: 301,
                        route: {
                            name: 'profile',
                            params: {
                                id: prediction.user.id
                            }
                        }
                    })
                }

                dispatch('predictions/updatePredictionsMap', {
                    predictions: [prediction]
                }, { root: true })

                commit(SET_PREDICTION_ID, prediction.id)
            } catch (err) {
                return Promise.reject({ code: 404 })
            }

            return null
        }
    },

    mutations: {
        [SET_PREDICTION_ID] (state, id) {
            state.predictionId = id
        }
    }
}
