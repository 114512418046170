<template>
    <CorePortal :attach-to-parent="attachToParent">
        <transition name="ui-calendar-fade">
            <div
                v-show="isOpen"
                class="ui-calendar-dropdown"
                :style="positionStyles"
                @click.stop
            >
                <UiCalendarDay
                    :value="value"
                    :route="route"
                    :route-date-key="routeDateKey"
                    class="ui-calendar-dropdown__calendar"
                    @input="onChooseDate"
                />

                <UiOverlay
                    global
                    :show="isOpen"
                    class="ui-calendar-dropdown__overlay"
                    @click.native.stop="onClose"
                />
            </div>
        </transition>
    </CorePortal>
</template>

<script>
import CorePortal from '@/ui/core/portal/index.vue'
import UiCalendarDay from '@/ui/components/Calendar/inner/calendar.vue'
import UiOverlay from '@/ui/components/Overlay/index.vue'

export default {
    name: 'UiCalendarPopup',

    components: {
        CorePortal,
        UiCalendarDay,
        UiOverlay
    },

    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: Number,
        route: {
            type: Object,
            default: null
        },

        routeDateKey: {
            type: String,
            default: 'date'
        },

        position: {
            type: String,
            default: 'bottom'
        },

        attachToParent: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isOpen: false,
            left: 0,
            top: 0,
            bottom: 0
        }
    },

    computed: {
        isMobile () {
            return ['xxs', 'xs', 'sm'].includes(this.$mq)
        },

        positionStyles () {
            if (this.isMobile) {
                return {}
            }

            const result = {
                left: `${this.left}px`
            }

            if (this.top) {
                result.top = `${this.top}px`
            } else if (this.bottom) {
                result.bottom = `${this.bottom}px`
            }

            return result
        }
    },

    beforeDestroy () {
        this.close()
    },

    methods: {
        open () {
            this.isOpen = true
            this.resize()
            setTimeout(() => (document.addEventListener('click', this.onClose)), 100)
        },

        close () {
            this.isOpen = false
            setTimeout(() => (document.removeEventListener('click', this.onClose)), 100)
        },

        onClose () {
            this.close()
            this.$emit('close')
        },

        onChooseDate (timestamp) {
            this.$emit('input', timestamp)
        },

        resize () {
            const rect = this.$parent.$el.getBoundingClientRect()

            if (this.position === 'top') {
                this.left = rect.left + document.scrollingElement.scrollLeft
                this.bottom = window.innerHeight - (rect.top + document.scrollingElement.scrollTop) + 10
            } else {
                this.left = rect.left + document.scrollingElement.scrollLeft
                this.top = rect.bottom + document.scrollingElement.scrollTop + 10
            }
        }
    }
}
</script>

<style lang="stylus">
.ui-calendar-dropdown
    position fixed
    z-index 1000
    bottom 0
    left 0
    right 0
    background-color #fff
    box-shadow 0 0 12px 0 rgba(0, 0, 0, 0.20)
    transition 0.3s transform cubic-bezier(0.01, -0.01, 0.29, 1)

    &::before
        content ""
        position absolute
        z-index 1
        top 0
        left 0
        right 0
        height 92px
        background-color $cl-secondary-light

    &__calendar
        position relative
        z-index 2
        max-width 345px
        margin 0 auto

    &.ui-calendar-fade-enter,
    &.ui-calendar-fade-leave-to
        transform translate(0, 100%)

    +layout-xs()
        top auto !important
        left 0 !important
        right 0 !important

    +layout-sm-and-up()
        position absolute
        left auto
        right auto
        bottom auto
        width 345px
        transition 0.25s transform cubic-bezier(0.01, -0.01, 0.29, 1), 0.25s opacity cubic-bezier(0.01, -0.01, 0.29, 1)

        &__overlay
            display none

        &.ui-calendar-fade-enter,
        &.ui-calendar-fade-leave-to
            opacity 0
            transform translate(0, 2rem)
</style>
