// doc: https://router.vuejs.org/ru/

import Vue from 'vue'
import Router from 'vue-router'

import LayoutMainApp from '@/layouts/main-app/index.vue'
import LayoutChat from '@/layouts/main-app/chat.vue'
import CrossAuth from '@/layouts/main-app/cross-auth.vue'


import { IS_BETTING_COM } from '@/constants'
import HomeRoutes from './home'
import MatchRoutes from './match'

import PredictionsRoutes from './predictions'
import AccountRoutes from './account'
import DocsRoutes from './docs'
import RatingRoutes from './rating'
import ProfileRoutes from './profile'
import SearchRoutes from './search'
import I9mRoutes from './i9m'
import BlogRoutes from './blog'
import ChatRoutes from './chat'
import Tournament from './tournament'
import BookmakersRatingRoutes from './bookmakers-rating'
import EuroRoutes from './Euro2020'
import Redirects from './redirects'

Vue.use(Router)

export default function createRouter () {
    const redirects = IS_BETTING_COM
        ? Redirects.EN
        : Redirects.RU

    let routes = [
        ...redirects,
        {
            path: '/chat',
            component: LayoutChat,
            children: [
                ...ChatRoutes
            ]
        },
        {
            path: '/cross-auth',
            name: 'cross-auth',
            component: CrossAuth
        },
        {
            path: '/',
            component: LayoutMainApp,
            children: [
                ...HomeRoutes,
                ...MatchRoutes,
                ...RatingRoutes,
                ...SearchRoutes,
                ...ProfileRoutes,
                ...PredictionsRoutes,
                ...BlogRoutes,
                ...DocsRoutes,
                ...AccountRoutes,
                ...EuroRoutes,
                ...Tournament,
                ...BookmakersRatingRoutes
            ]
        },
        {
            path: '*',
            name: '404',
            component: LayoutMainApp
        }
    ]

    if (!IS_BETTING_COM) {
        routes = [
            ...I9mRoutes,
            ...routes,
            {
                path: '/chat/euro2020',
                component: LayoutChat,
                children: [
                    {
                        path: '/',
                        name: 'chat-euro',
                        component: () => import('@/views/chat/index.vue')
                    }
                ]
            }
        ]
    }

    return new Router({
        mode: 'history',
        fallback: false,
        base: '/',
        routes,
        scrollBehavior (to, from, savedPosition) {
            if (to.hash) {
                return { selector: to.hash }
            }

            if (savedPosition) {
                return savedPosition
            }

            return { x: 0, y: 0 }
        }
    })
}
