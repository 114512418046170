<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        {{ $t('lacks') }} {{ lacks }}<span class="bi-betty" /> {{ $t('lacks-more') }}<br>
        {{ $t('balance') }} — <span class="t-bold">{{ balance }} <span class="bi-betty" /></span>.<br>
        {{ $t('add') }}

        <template slot="footer">
            <button
                class="ban-popup__button ban-popup__button--stretch"
                @click="onContinue"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Недостаточно средств",
            "lacks": "Вам не хватает",
            "lacks-more": "для такой ставки.",
            "balance": "На вашем счету",
            "add": "Измените сумму или пополните свой счет.",
            "btn": "Изменить сумму ставки"
        },
        "en": {
            "header": "Low funds",
            "lacks": "You don't have enough money",
            "lacks-more": "for this bet",
            "balance": "Your balance is",
            "add": "Please change your bet or add more funds.",
            "btn": "Change the bet"
        }
    }
</i18n>

<script>
export default {
    name: 'PopupBetInsufficientFunds',

    data () {
        return {
            lacks: 0,
            balance: 0
        }
    },

    mounted () {
        this.$root.$on('popup-bet-balance', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-bet-balance', this.open)
    },

    methods: {
        open ({ lacks, balance }) {
            this.lacks = lacks
            this.balance = balance
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        },

        onContinue () {
            this.$store.commit('bet/select/edit', true)
            this.close()
        }
    }
}
</script>

<style lang="stylus" scoped>
.ban-popup
    &__button
        button(5, 'contained', 'primary')
</style>
