<template>
    <button
        class="daily-bonuses-button"
        @click="openDailyBonuses"
    >
        <img
            src="@/components/DailyBonuses/images/button.svg"
        >
    </button>
</template>

<script>
import { OPEN_DAILY_BONUSES_EVENT } from '@/constants'

export default {
    name: 'DailyBonusesButton',

    methods: {
        openDailyBonuses () {
            this.$root.$emit(OPEN_DAILY_BONUSES_EVENT)
        }
    }
}
</script>

<style lang="stylus">
.daily-bonuses-button
    background transparent
    border none
    outline none
    cursor pointer
    border-radius 50%
    height 3.4rem
    margin-right 2.4rem
</style>
