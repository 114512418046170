<template>
    <div
        class="ui-star-rating"
        @mouseleave="resetRating"
    >
        <span
            v-for="n in maxRating"
            :key="n"
            :class="['ui-star-rating__star', { 'ui-star-rating--pointer': !readOnly }]"
            :style="{ 'margin-right': margin + 'px' }"
        >
            <UiStarRatingStar
                :star-id="n"
                :fill="fillLevel[n - 1]"
                :points="starPoints"
                :size="starSize"
                :active-color="activeColor"
                :inactive-color="inactiveColor"
                :border-color="borderColor"
                :border-width="borderWidth"
                @star-selected="setRating($event, true)"
                @star-mouse-move="setRating"
            />
        </span>
    </div>
</template>

<script>
import UiStarRatingStar from '@/ui/components/StarRating/inner/star.vue'

export default {
    name: 'UiStarRating',

    components: {
        UiStarRatingStar
    },

    model: {
        prop: 'rating',
        event: 'rating-selected'
    },

    props: {
        /**
         * Шаг рейтинга
         */
        increment: {
            type: Number,
            default: 1
        },

        /**
         * Значение рейтинга
         */
        rating: {
            type: Number,
            default: 0
        },

        /**
         * Округление первоначального значения рейтинга
         */
        roundStartRating: {
            type: Boolean,
            default: true
        },

        /**
         * Цвет активной звезды
         */
        activeColor: {
            type: String,
            default: '#F6C247'
        },

        /**
         * Цвет неактивной звезды
         */
        inactiveColor: {
            type: String,
            default: '#fff'
        },

        /**
         * Максимальное значение рейтинга
         */
        maxRating: {
            type: Number,
            default: 5
        },

        /**
         * Points для полигона звезды
         */
        starPoints: {
            type: Array,
            default () {
                return []
            }
        },

        /**
         * Размер звезды в пикселях
         */
        starSize: {
            type: Number,
            default: 25
        },

        /**
         * Отключает возможность выбирать значение рейтинга
         */
        readOnly: {
            type: Boolean,
            default: false
        },

        /**
         * Цвет бордера звезды
         */
        borderColor: {
            type: String,
            default: '#F6C247'
        },

        /**
         * Ширина бордера звезды
         */
        borderWidth: {
            type: Number,
            default: 0
        },

        /**
         * Расстояние между звездами
         */
        padding: {
            type: Number,
            default: 0
        }
    },

    data () {
        return {
            fillLevel: [],
            currentRating: 0,
            selectedRating: 0,
            ratingSelected: false
        }
    },

    computed: {
        shouldRound () {
            return this.ratingSelected || this.roundStartRating
        },

        margin () {
            return this.padding + 2 * this.borderWidth
        }
    },

    watch: {
        rating (val) {
            this.currentRating = val
            this.selectedRating = val
            this.createStars(this.shouldRound)
        }
    },

    created () {
        this.currentRating = this.rating
        this.selectedRating = this.currentRating
        this.createStars(this.roundStartRating)
    },

    methods: {
        setRating ($event, persist) {
            if (!this.readOnly) {
                const position = $event.position / 100

                this.currentRating = (($event.id + position) - 1).toFixed(2)
                this.currentRating = (this.currentRating > this.maxRating) ? this.maxRating : this.currentRating
                this.createStars()
                if (persist) {
                    this.selectedRating = this.currentRating
                    this.$emit('rating-selected', this.selectedRating)
                    this.ratingSelected = true
                } else {
                    this.$emit('current-rating', this.currentRating)
                }
            }
        },

        resetRating () {
            if (!this.readOnly) {
                this.currentRating = this.selectedRating
                this.createStars(this.shouldRound)
            }
        },

        createStars (round = true) {
            if (round) {
                this.round()
            }

            for (let i = 0; i < this.maxRating; i += 1) {
                let level = 0

                if (i < this.currentRating) {
                    level = (this.currentRating - i > 1) ? 100 : (this.currentRating - i) * 100
                }

                this.$set(this.fillLevel, i, Math.round(level))
            }
        },

        round () {
            const inv = 1.0 / this.increment

            this.currentRating = Math.min(this.maxRating, Math.ceil(this.currentRating * inv) / inv)
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-star-rating
    display flex
    align-items center

    &--pointer
        cursor pointer

    &__star
        display inline-block
</style>
