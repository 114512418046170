import Api from '@/api'
import logger from '@/utils/logger'
import { fetchTable } from '@/api/graphql/tournaments'
import { PAGE_STATUSES } from '@/constants'

const SET_TABLE = 'SET_TABLE'
const SET_STATUS = 'SET_STATUS'

export default {
    namespaced: true,

    state () {
        return {
            table: [],
            status: PAGE_STATUSES.loading
        }
    },

    actions: {
        async fetchTable ({ commit, dispatch }, { tournamentWebname, tableType = 'total' }) {
            try {
                if (!tournamentWebname) {
                    commit(SET_TABLE, [])

                    return
                }

                dispatch('setStatus', PAGE_STATUSES.loading)

                const query = fetchTable({ tournamentWebname, tableType })
                const result = await Api.graphql(query)
                const table = result.currentSeason.stages[0].teamStanding[tableType]

                table.forEach(team => {
                    team.goals = `${team.goalsFor}:${team.goalsAgainst}`
                })

                commit(SET_TABLE, table)
            } catch (err) {
                commit(SET_TABLE, [])

                logger.error(err)
            }

            dispatch('setStatus', PAGE_STATUSES.ready)
        },

        setStatus ({ commit }, status) {
            commit(SET_STATUS, status)
        }
    },

    mutations: {
        [SET_TABLE] (state, table) {
            state.table = table
        },

        [SET_STATUS] (state, status) {
            state.status = status
        }
    }
}
