import Api from '@/api'
import { isFinished } from '@/utils/match'

export default {
    namespaced: true,

    state () {
        return {
            matches: [],
            tournament: {}
        }
    },

    getters: {
        getMatches (state) {
            return state.matches.filter((match) => !isFinished(match.status))
        },
        getFinishedMatches (state) {
            return state.matches.filter((match) => isFinished(match.status))
        }
    },

    actions: {
        fetch ({ commit }, tournamentId) {
            return Api.trbna({
                path: 'matches/with_odds_for_teaser',
                params: { tournament_id: tournamentId, include: 'odds,team', count: 20 },
                maps: ['home_team', 'away_team']
            })
                .then(result => {
                    const matches = result.serialized

                    commit('matches', matches.sort((a, b) => a.time - b.time))
                })
                .catch(() => {})
        }
    },

    mutations: {
        matches (state, matches) {
            state.matches = matches
        },
        tournament (state, tournament) {
            state.tournament = tournament
        }
    }
}
