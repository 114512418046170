import Api from '@/api'
import logger from '@/utils/logger'
import { getBookmakerBonuses, getBookmakerBonusesAll } from '@/api/graphql/bookmakers-rating'

const SET_BOOKMAKER_BONUSES_LIST = 'SET_BOOKMAKER_BONUSES_LIST'
const SET_BOOKMAKER_BONUSES_ALL_LIST = 'SET_BOOKMAKER_BONUSES_ALL_LIST'

export default {
    namespaced: true,

    state () {
        return {
            bookmakersBonuses: [],
            bookmakersBonusesAll: []
        }
    },

    actions: {
        async fetchBookmakersBonuses ({ rootState, commit, dispatch }, options = {}) {
            let result

            const queryString = getBookmakerBonuses({
                countryCode: rootState.bookmakersRating.geo,
                webname: options.webname
            })

            try {
                const data = await Api.graphql(queryString)

                result = (data && data.bonuses) || []

                dispatch('bookmakersRating/setHasError', { key: 'bonuses', value: false }, { root: true })
            } catch (err) {
                logger.error(err)

                return Promise.reject(err)
            }

            commit(SET_BOOKMAKER_BONUSES_LIST, result)

            return Promise.resolve()
        },

        async fetchAllBookmakersBonuses ({ rootState, commit, dispatch }) {
            let result

            const queryString = getBookmakerBonusesAll({
                countryCode: rootState.bookmakersRating.geo
            })

            try {
                const data = await Api.graphql(queryString)

                result = (data && data.edges) || []

                result = result.map(edge => {
                    const { bookmaker, ...bonus } = edge.node

                    return {
                        ...bonus,
                        logo: bookmaker.logo,
                        color: bookmaker.primaryColor,
                        webname: bookmaker.webname,
                        bookmakerId: bookmaker.id
                    }
                })

                dispatch('bookmakersRating/setHasError', { key: 'bonuses', value: false }, { root: true })
            } catch (err) {
                logger.error(err)

                return Promise.reject(err)
            }

            commit(SET_BOOKMAKER_BONUSES_ALL_LIST, result)

            return Promise.resolve()
        }
    },

    mutations: {
        [SET_BOOKMAKER_BONUSES_LIST] (state, bonusesList = []) {
            state.bookmakersBonuses = bonusesList
        },

        [SET_BOOKMAKER_BONUSES_ALL_LIST] (state, bonusesList = []) {
            state.bookmakersBonusesAll = bonusesList
        }
    }
}
