import Api from '@/api'
import logger from '@/utils/logger'
import { contestWidgetById } from '@/api/graphql/euro2020'
import { participantsAdapter } from '@/store/modules/rating/helpers'

const SET_RATING = 'SET_RATING'
const SET_NUMBER_OF_BETS_TO_GET = 'SET_NUMBER_OF_BETS_TO_GET'

const EURO2020_CONTEST_ID = '1'

const setParticipantsPrizes = (participants, prizes) => {
    participants.forEach(user => {
        const { prize } = (prizes.find(item => +item.place === user.rank) || {})

        user.prize = prize
    })

    return participants
}


export default {
    namespaced: true,

    state () {
        return {
            rating: [],
            numberOfBetsToGetPrize: 0
        }
    },

    actions: {
        async fetchList ({ commit }) {
            try {
                const queryString = contestWidgetById({ contestId: EURO2020_CONTEST_ID })
                const result = await Api.graphql(queryString)
                const participants = participantsAdapter(result.participants)
                const rating = setParticipantsPrizes(participants, result.prizes)

                commit(SET_RATING, rating)
                commit(SET_NUMBER_OF_BETS_TO_GET, result.params.numberOfBetsToGetPrize)

                return rating
            } catch (err) {
                logger.error(err)

                commit(SET_RATING, [])
                commit(SET_NUMBER_OF_BETS_TO_GET, 0)

                return Promise.reject(err)
            }
        }
    },

    mutations: {
        [SET_RATING] (state, rating) {
            state.rating = rating
        },

        [SET_NUMBER_OF_BETS_TO_GET] (state, numberOfBetsToGetPrize) {
            state.numberOfBetsToGetPrize = numberOfBetsToGetPrize
        }
    }
}
