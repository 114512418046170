<template>
    <div class="swiper-carousel">
        <Swiper
            ref="swiper"
            :options="options"
            auto-update
            auto-destroy
            delete-instance-on-destroy
            cleanup-styles-on-destroy
            @slide-change="onChange"
            @slide-next-transition-start="slideNextTransitionEnd"
            @slide-prev-transition-end="slidePrevTransitionEnd"
        >
            <SwiperSlide
                v-for="(el, index) in slides"
                :key="index"
                class="swiper-carousel__swiper-slide"
            >
                <slot
                    :item="el"
                    :index="index"
                />
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script>
import { Swiper as SwiperClass, Navigation } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

SwiperClass.use([Navigation])

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)


export default {
    name: 'UiSwiper',

    components: {
        Swiper,
        SwiperSlide
    },

    props: {
        options: {
            type: Object,
            default: () => ({})
        },

        slides: {
            type: Array,
            default: () => ([])
        }
    },

    mounted () {
        this.swiperInstance = this.$refs.swiper.swiperInstance
    },

    beforeDestroy () {
        this.swiperInstance = null
    },

    methods: {
        getSwiperActiveIndex () {
            return this.swiperInstance?.activeIndex
        },

        onChange () {
            const currentIndex = this.getSwiperActiveIndex()

            this.$emit('change-index', currentIndex)
        },

        slideNextTransitionEnd () {
            this.$emit('click-right')
        },

        slidePrevTransitionEnd () {
            this.$emit('click-left')
        }
    }
}
</script>

<style lang="stylus" scoped>
.swiper-carousel
    overflow hidden

    &__swiper-slide
        height auto
        width auto

</style>
