<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 19"
        width="20"
        height="19"
        :class="{ 'star--active': active }"
        class="star"
    >
        <path
            :fill="active ? '#FDB333' : 'none'"
            stroke="#FDB333"
            d="M10.0000032,1.28119122 L7.53383605,7.09940252 L1.22165821,7.64101382 L6.01033248,11.7906153 L4.57509266,17.9607386 L10,14.6891329 L15.4249073,17.9607386 L13.9896675,11.7906153 L18.7783424,7.64101332 L12.4662048,7.09940252 L10.0000032,1.28119122 Z"
        />
    </svg>
</template>

<script>
export default {
    name: 'UiStar',

    props: {
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus" scoped>
.star
    cursor pointer
    opacity .6

    &--active
        opacity 1
</style>
