const prediction = () => `
  id
  description
  rate
  amount
  amountWin
  state
  ctime
  facts
  top
  topInMatch
  fullName
  sport {
    code
    logoUrl
  }
  match {
    id
    title
    dateStart
    currentMinute
    sport {
      code
    }
    result {
      homeScore
      awayScore
      summaryText
      winType
      winnerType
    }
    homeTeam { team { name } }
    awayTeam { team { name } }
  }
  user {
    id
    name
    avatar
    roi
    winRate
    betsForm {
      state
    }
  }
  odds {
    id
    line {
      type
      name
      description
    }
    disabled
    name
    type
    resultType
    oddValue
    feedType
    rate
    fullName
  }
`

export function fetchNewSportsPredictions ({ first, sportId, userIds, matchId, offset }) {
    return `{
      newSportBetConnection(
        sportIDs:["${sportId}"]
        first: ${first}
        offset: ${offset}
        ${userIds ? `userIDs: ["${userIds}"]` : ''}
        ${matchId ? `matchIDs: ["${matchId}"]` : ''}
      ) {
        edges {
          node {
            ${prediction()}
          }
        }
        pageInfo {
            totalCount
        }
      }
    }`
}

export function fetchNewSportPrediction ({ id }) {
    return `
      {newSportBet (id: "${id}") {
        ${prediction()}
      }}
    `
}
