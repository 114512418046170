<template>
    <div class="radio-button__wrapper">
        <div
            v-for="item in items"
            :key="item.value"
            class="radio-button"
        >
            <input
                :id="getRadioButtonId(item.value)"
                :checked="item.value === value"
                :value="item.value"
                name="state"
                type="radio"
                class="bet-edit__radio"
                @change="$emit('input', item.value)"
            >
            <label
                :for="getRadioButtonId(item.value)"
                class="bi-body-2"
            >
                {{ item.text }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiRadio',

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        items: {
            type: Array,
            requred: true
        },

        value: {
            type: [String, Number],
            default: ''
        }
    },

    data () {
        const randomName = `radio-name__${+new Date()}`

        return {
            name: randomName
        }
    },

    methods: {
        getRadioButtonId (state) {
            return `radio-state_${state}`
        }
    }
}
</script>

<style lang="stylus" scoped>
.radio-button
    display flex
    align-items center
    margin-right 4.4rem
    flex-wrap wrap
    margin-bottom .8rem

    &__wrapper
        display flex

    & input[type=radio]
        display none

    & label
        display inline-block
        cursor pointer
        position relative
        padding-left 2.8rem
        margin-right 0
        user-select none

        &::before
            content ""
            display inline-block
            box-sizing border-box
            width 1.6rem
            height 1.6rem
            position absolute
            top .3rem
            left 0
            border .2rem solid $cl-text-dark
            border-radius .3rem

    & input[type=radio]:checked + label
        &::before
            border .2rem solid $cl-primary

        &::after
            content ""
            display inline-block
            box-sizing border-box
            width .6rem
            height .6rem
            position absolute
            left .5rem
            top .8rem
            border .2rem solid $cl-primary
            background-color $cl-primary
            border-radius .2rem

</style>
