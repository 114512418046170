import Vue from 'vue'
import { timeMinutesToString } from '@/utils/calendar'

const SET_STATUS_CODE = 'SET_STATUS_CODE'
const SET_HEADER = 'SET_HEADER'
const SET_MOUNT_OFF = 'SET_MOUNT_OFF'
const SET_MEDIA_QUERY = 'SET_MEDIA_QUERY'
const SET_TODAY_TIMESTAMP = 'SET_TODAY_TIMESTAMP'
const SET_TIMEZONE = 'SET_TIMEZONE'

/**
 * Возвращает таймзону удобную для бэкенда
 * @param shift
 * @returns {string}
 */
function formatTimezone (shift) {
    let timezone = shift >= 0 ? '-' : '+'

    timezone += timeMinutesToString(Math.abs(shift))

    return timezone
}

export default {
    namespaced: true,

    state () {
        return {
            mountOff: false,
            code: 200,
            mediaQuery: 'sm',
            todayTimestamp: 0,
            timezone: '+03:00',
            headers: {
                'Last-Modified': undefined
            }
        }
    },

    getters: {
        isDesktop (state) {
            return ['md', 'l'].includes(state.mediaQuery)
        }
    },

    actions: {
        setStatusCode ({ commit }, code) {
            commit(SET_STATUS_CODE, code)
        },

        setMediaQuery ({ commit }, mediaQuery) {
            commit(SET_MEDIA_QUERY, mediaQuery)
        },

        setMountOff ({ commit }) {
            commit(SET_MOUNT_OFF)
        },

        setHeader ({ commit }, { name, value }) {
            commit(SET_HEADER, { name, value })
        },

        setCacheControlHeader ({ commit }, { value }) {
            commit(SET_HEADER, { name: 'Cache-Control', value })
        },

        updateTodayTimestampAndZone ({ commit }) {
            commit(SET_TODAY_TIMESTAMP, Date.now())
            commit(SET_TIMEZONE, formatTimezone(new Date().getTimezoneOffset()))
        }
    },

    mutations: {
        [SET_STATUS_CODE] (state, code) {
            if (code === 404) {
                state.code = 410
            } else {
                state.code = code
            }
        },

        /**
         * отключает инициализацию js
         * применяется на странице ошибки 500
         * @param state
         */
        [SET_MOUNT_OFF] (state) {
            state.mountOff = true
        },

        [SET_MEDIA_QUERY] (state, mediaQuery) {
            state.mediaQuery = mediaQuery
        },

        [SET_TODAY_TIMESTAMP] (state, timestamp) {
            state.todayTimestamp = timestamp
        },

        [SET_TIMEZONE] (state, shift) {
            state.timezone = shift
        },

        [SET_HEADER] (state, { name, value }) {
            if (Object.prototype.hasOwnProperty.call(state.headers, name)) {
                state.headers[name] = value
            } else {
                Vue.set(state.headers, name, value)
            }
        }
    }
}

