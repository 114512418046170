// example: https://developers.facebook.com/docs/facebook-login/web#example

import loadScript from '@/utils/loadScript'

export default {
    sdk: 'https://connect.facebook.net/en_US/sdk.js',

    appID: 1095513947147781,

    SDKKey: 'FB',

    asyncInitKey: 'fbAsyncInit',

    appPermissions: 'public_profile,email',

    version: 'v2.11',

    ready () {
        if (!this.internal_readyPromise) {
            this.internal_readyPromise = new Promise((resolve, reject) => {
                loadScript(this.sdk).catch(reject)

                window[this.asyncInitKey] = () => {
                    try {
                        window[this.SDKKey].init({
                            appId: this.appID,
                            xfbml: true, // parse social plugins on this page
                            version: this.version
                        })

                        resolve(window[this.SDKKey])
                    } catch (e) {
                        reject(e)
                    }
                }
            })
        }

        return this.internal_readyPromise
    },

    async logout () {
        const SDK = await this.ready()
        const state = await new Promise(resolve => SDK.getLoginStatus(resolve))

        if (state.status === 'connected') {
            return SDK.logout()
        }

        return null
    },

    async login () {
        const SDK = await this.ready()
        const loginData = await new Promise((resolve, reject) => {
            SDK.login((res) => {
                if (res.authResponse) {
                    resolve({
                        token: res.authResponse.accessToken,
                        uid: res.authResponse.userID
                    })
                } else {
                    reject(res)
                }
            }, { scope: this.appPermissions })
        })

        return loginData
    },

    async getProfileInfo () {
        const SDK = await this.ready()
        const userData = await new Promise(resolve => {
            SDK.api('/me?fields=id,first_name,name,last_name,email', resolve)
        })

        userData.avatar = await this.getAvatar(userData.id)

        return userData
    },

    async getAvatar (id, size = 360) {
        const SDK = await this.ready()

        return new Promise((resolve) => {
            SDK.api(`/picture?width=${size}&height=${size}&id=${id}`, (res) => {
                resolve(res.data && res.data.url ? res.data.url : '')
            })
        })
    }
}
