<template>
    <CorePortal v-if="global">
        <transition name="ui-overlay-fade">
            <div
                v-if="show"
                class="ui-overlay is-fixed"
            />
        </transition>
    </CorePortal>

    <transition
        v-else
        name="ui-overlay-fade"
    >
        <div
            v-if="show"
            class="ui-overlay"
        />
    </transition>
</template>

<script>
import CorePortal from '@/ui/core/portal/index.vue'

export default {
    name: 'UiOverlay',

    components: {
        CorePortal
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },

        global: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus" scoped>
    .ui-overlay {
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        z-index 110
        overflow hidden
        background rgba(#000, .3)
        transition opacity .35s ease-in
        will-change opacity

        &.is-fixed {
            position fixed
        }

        &.ui-overlay-fade-enter,
        &.ui-overlay-fade-leave-to {
            opacity 0
        }
    }
</style>
