let disabled = false
let project = ''

/**
 * Отправляет событие
 * @param eventCategory
 * @param eventName
 * @param eventLabel
 * @param eventValue
 */
export function analyticsEvent (eventCategory = '', eventName = '', eventLabel = '', eventValue = '') {
    if (!disabled) {
        if (!window.dataLayer) {
            window.dataLayer = []
        }

        window.dataLayer.push({
            event: project,
            eventCategory,
            eventName,
            eventLabel,
            eventValue
        })
    }
}

const $pivik = {
    setProject (name) {
        if (typeof name === 'string') {
            project = name
        }
    },

    disabled (val) {
        disabled = !!val
    },

    event: analyticsEvent

}

export default function pivikPlugin (Vue) {
    Vue.prototype.$pivik = $pivik
}
