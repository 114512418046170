function prediction () {
    return `id
    name
    fullName
    description
    amount
    amountWin
    rate
    path
    ctime
    amountWin
    sponsored
    state
    facts
    user {
      id
      name
      avatar
      roi
      winRate
      betsForm {
        state
      }
    }
    odds {
      id
      rate
      fullName
      link
    }
    match {
        id
        webname
        state
        startTime
        tournament {
          id
          name
          webname
        }
        homeTeam {
          team {
            id
            name
            logoSmall
          }
          score
          penaltyWin
        }
        awayTeam {
          team {
            id
            name
            logoSmall
          }
          score
          penaltyWin
        }
    }
    description
    isPaywalled
    top
    topInMatch`
}


/**
* Создание прогноза
* @param oddsID -  ID коэффициента
* @param amount - размер ставки
* @param rate - значение коэффициента
* @param description - описание прогноза
* @param parentID - ID повторяемого прогноза
* @return {string}
*/
export function createFootballBet ({ oddsID, amount, rate, description, parentID }) {
    return `{betMutations {create(
      bet: {
        oddsID: "${oddsID}"
        amount: ${amount}
        rate: ${rate}
        ${description ? `description: "${description}"` : ''}
        ${parentID ? `parentID: "${parentID}"` : ''}
      }) {
        id user {id balance}
      }
    }}`
}

/**
* Обновить прогноз
* @param id -  ID прогноза
* @param description - описание прогноза
* @return {string}
*/
export function updateFootballBet ({ id, description, top, topInMatch, facts = [] }) {
    return `{
      betMutations {
        update(bet: {
          id: "${id}",
          description: "${description}",
          top: ${top},
          topInMatch: ${topInMatch},
          facts: [${facts.length ? `"${facts.join('","')}"` : ''}]
        }) {id description top topInMatch facts}
      }
    }`
}


/**
* Получение прогноза
* @param id - id прогноза
* @return {string}
*/
export function fetchFootballPrediction ({ id }) {
    return `{bet(id: "${id}") {${prediction()}}}`
}


/**
* Получение списка прогнозов
* @param first - количество запрашиваемых прогнозов
* @param after - id последнего прогноза
* @param offset - смещение для пагинации на вебе
* @param hasDescription - выдавать только прогнозы с комментарием
* @param objectId
* @return {string}
*/
export function fetchPredictions ({ first, after = 0, offset, hasDescription, tournamentId, userIds, matchId }) {
    return `bets(
              first: ${first}
              after: "${after}"
              ${offset ? `offset: ${offset}` : ''}
              hasDescription: ${hasDescription}
              ${tournamentId ? `tournamentID: "${tournamentId}"` : ''}
              ${userIds ? `userIDs: "${userIds}"` : ''}
              ${matchId ? `matchIDs: ["${matchId}"]` : ''}
              ) {
                  list{${prediction()}}
                  pageInfo {
                      totalCount
                      endCursor
                      hasNextPage
                  }
              }`
}

/**
* Получение умной ленты прогнозов
* @param first - количество запрашиваемых прогнозов
* @param offset - смещение для пагинации на вебе
* @param matchId
* @return {string}
*/
export function fetchSmartPredictions ({ first, offset, matchId, tournamentId, topPredictionsFirst }) {
    return `betsSmartFeed(
      first: ${first}
      ${offset ? `offset: ${offset}` : ''}
      ${matchId ? `matchID: "${matchId}"` : ''}
      testID: "12"
      ${tournamentId ? `tournamentID: "${tournamentId}"` : ''}
      isOld: ${!topPredictionsFirst}
    ) {
      list{${prediction()}}
      pageInfo {totalCount endCursor hasNextPage}
    }`
}

/**
* Получение ленты прогнозов подписчиков
* @param first - количество запрашиваемых прогнозов
* @param after - id последнего прогноза
* @return {string}
*/
export function fetchSubscriptionsPredictions ({ first, after }) {
    return `betsSubscriptionsFeed(first: ${first} after: "${after}") {list{${prediction()}}pageInfo {totalCount endCursor hasNextPage}}`
}

export function fetchTomorrowPredictions ({ first, timezone, offset, testId = 12 }) {
    return `{
        betsTomorrow(
            timezone:"${timezone}"
            testID: "${testId}"
            first: ${first}
            offset: ${offset}
        ) {
            list{${prediction()}}
            pageInfo {totalCount endCursor hasNextPage}
        }
    }`
}

export function fetchWeekendPredictions ({ first, timezone, offset, testId = 12 }) {
    return `{
        betsWeekend(
            timezone:"${timezone}"
            testID: "${testId}"
            first: ${first}
            offset: ${offset}
        ) {
            list{${prediction()}}
            pageInfo {totalCount endCursor hasNextPage}
        }
    }`
}

export function fetchByOddId ({ first, oddId, offset, testId = 12 }) {
    return `{
        betsSmartFeed(
            oddID:"${oddId}"
            testID: "${testId}"
            first: ${first}
            offset: ${offset}
        ) {
            list{${prediction()}}
            pageInfo {totalCount endCursor hasNextPage}
        }
    }`
}
