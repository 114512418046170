import Api from '@/api'
import {
    createComplaint
} from '@/api/graphql/report'

export default {
    namespaced: true,

    actions: {
        async create (context, { id, type, objectType }) {
            // Получаем строку запроса для graphql
            const queryString = createComplaint({
                id,
                type,
                objectType
            })

            try {
                await Api.graphql(`{${queryString}}`, { type: 'mutation' })
            } catch (e) {}
        }
    }
}
