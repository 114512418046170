<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        {{ $t('text') }}

        <template slot="footer">
            <button
                class="popup__button popup__button--stretch"
                @click="close"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Письмо отправлено",
            "text": "Теперь все получится. Осталось совсем чуть-чуть.",
            "btn": "Ок"
        },
        "en": {
            "header": "Email sent",
            "text": " ",
            "btn": "Ok"
        }
    }
</i18n>

<script>
export default {
    name: 'PopupConfirmEmailSuccess',

    mounted () {
        this.$root.$on('popup-confirm-email-success', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-confirm-email-success', this.open)
    },

    methods: {
        open () {
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus">
.popup
    &__button
        button(5, 'contained', 'primary')

</style>
