<template>
    <UiPopup ref="popup">
        <div class="the-popup-refund">
            <div class="the-popup-refund__animation">
                <transition
                    name="slide-block"
                    @leave="leave"
                    @enter="enter"
                    @after-enter="afterEnter"
                >
                    <component
                        :is="componentName"
                        :error="error"
                        @open="openForm"
                        @close="close"
                        @refund="refund"
                    />
                </transition>
            </div>
        </div>
    </UiPopup>
</template>

<script>
import PopupRefundError from '@/components/Popups/Refund/PopupRefundError.vue'
import PopupRefundSuccess from '@/components/Popups/Refund/PopupRefundSuccess.vue'
import PopupRefundOffer from '@/components/Popups/Refund/PopupRefundOffer.vue'
import ErrorMessage from '@/mixins/error-message'

export default {
    name: 'PopupRefund',

    components: {
        PopupRefundError,
        PopupRefundSuccess,
        PopupRefundOffer
    },

    mixins: [ErrorMessage],

    props: {
        show: {
            type: Boolean
        }
    },

    data () {
        return {
            openType: 'offer',
            error: ''
        }
    },

    computed: {
        componentName () {
            return `popup-refund-${this.openType}`
        }
    },

    mounted () {
        this.$root.$on('refund', this.openPopup)
    },

    beforeDestroy () {
        this.$root.$off('refund', this.openPopup)
    },

    methods: {
        async refund () {
            if (this.pending) {
                return
            }

            this.pending = true

            try {
                await this.$store.dispatch('profile/fetchUserRefund')
                this.error = ''
                this.pending = false
                this.openType = 'success'
            } catch (error) {
                try {
                    this.error = error.extensions.code
                } catch (e) {
                    this.error = 'default'
                }

                this.pending = false
                this.openType = 'error'
            }
        },

        openPopup () {
            this.openType = 'offer'
            this.$refs.popup.open()
        },

        openForm (type) {
            this.openType = type
        },

        close () {
            this.$refs.popup.close()
        },

        leave (el) {
            el.parentElement.style.height = `${el.scrollHeight}px`
        },

        enter (el) {
            setTimeout(() => {
                el.parentElement.style.height = `${el.scrollHeight}px`
            }, 16)
        },

        afterEnter (el) {
            el.parentElement.style.height = null
        }
    }
}
</script>

<style lang="stylus" scoped>
    .the-popup-refund {
        overflow hidden
        text-align center
        padding 30px 12px
        box-sizing border-box
        width 100%

        &__animation {
            position relative
            transition 0.5s height
        }

        +layout-sm-and-up() {
            padding 40px
            width 500px
        }
    }

    .slide-block-enter-active,
    .slide-block-leave-active {
        transition 0.5s linear
    }

    .slide-block-leave-active {
        position absolute
        top 0
        left 0
        right 0
    }

    .slide-block-enter {
        transform translate(110%, 0)
        opacity 1
    }

    .slide-block-leave-to {
        transform translate(-110%, 0)
        opacity 0
    }
</style>
