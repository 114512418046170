import { BETTING_TEAM_EN_URLS } from '@/constants'

const DOMAIN_MAP = {
    ru: 'https://betting.team',
    en: 'https://betting-insider.com'
}
let HOSTNAME = ''

const $canonical = {
    get (path, lang) {
        let host = HOSTNAME

        if (lang && DOMAIN_MAP[lang]) {
            host = DOMAIN_MAP[lang]
        }

        if (BETTING_TEAM_EN_URLS.indexOf(path.replace('/en/', '/')) !== -1) {
            // это костыль, и продуктово и технически.
            host = 'https://betting.team/en'

            return (host + path.replace('/en/', '/'))
                .replace(/\/$/, '')
        }

        return (host + path)
            .replace(/\/$/, '')
    }
}

export default function canonicalPlugin (Vue, options = {}) {
    Vue.prototype.$canonical = $canonical
    HOSTNAME = options.hostname
}
