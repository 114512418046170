<template>
    <div
        v-if="isInitialized"
        class="pr-freebet"
    >
        <div class="pr-freebet__wrap">
            <div
                v-if="logo"
                class="pr-freebet__image"
            >
                <img
                    :src="logo"
                    :alt="text"
                >
            </div>

            <span
                v-if="!closed"
                class="pr-freebet__close"
                @click.stop="close"
            >
                <CloseIcon />
            </span>
        </div>

        <span
            :style="{ color: textColor }"
            class="pr-freebet__price bi-header-4"
        >
            {{ price }}
        </span>

        <div class="pr-freebet__info">
            <AdDisclaimer
                v-if="disclaimer"
                :text="disclaimer"
                class="pr-freebet__disclaimer"
            />

            <span
                :style="{ color: textColor }"
                :class="{ 'pr-freebet__text--centered': !disclaimer }"
                class="bi-body-2"
            >
                {{ text }}
            </span>
        </div>

        <slot />
        <span
            :style="{ color: textColor }"
            class="bi-subtitle-3"
        >
            {{ marker }}
        </span>
    </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close-bold.icon.svg'
import AdDisclaimer from '@/components/DFP/AdDisclaimer.vue'

export default {
    name: 'DFPPromoGetFreebetPopup',

    components: {
        AdDisclaimer,
        CloseIcon
    },

    props: {
        closed: {
            type: Boolean,
            default: false
        },

        logo: {
            type: String,
            default: ''
        },

        text: {
            type: String,
            default: ''
        },

        marker: {
            type: String,
            default: ''
        },

        textColor: {
            type: String,
            default: ''
        },

        price: {
            type: String,
            default: ''
        },

        link: {
            type: String,
            default: ''
        },

        disclaimer: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            isInitialized: false
        }
    },

    mounted () {
        this.isInitialized = true
    },

    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus" scoped>
    .pr-freebet
        box-sizing border-box
        padding 2rem 1.6rem 1.6rem
        display flex
        flex-direction column
        background $cl-text-dark
        box-shadow inset 0 4px 0 0 rgba(0, 0, 0, .15)
        user-select none
        color $cl-white

        &__wrap
            position relative
            display flex
            align-items center

        &__image
            margin 0 auto
            width 12rem
            display flex
            justify-content center

            img
                width 12rem
                height 4rem
                object-fit contain

        &__close
            position absolute
            right -.8rem
            top 0
            transform translateY(-50%)
            padding .6rem
            display flex
            color #7C7C7C
            cursor pointer

            svg
                width 1.6rem
                height @width

        &__text
            &--centered
                text-align center
                width 100%

        &__disclaimer
            margin-right .8rem

        &__info
            display flex
            align-items center
            margin-top 1.8rem

        &__price
            margin-top 1.6rem
            text-align center
</style>
