<!-- Устаревший попап, не использовать -->

<template>
    <CorePortal>
        <transition name="ui-popup">
            <div
                v-if="isOpen"
                class="ui-popup-wrap"
            >
                <div
                    class="ui-popup"
                    :class="classes"
                >
                    <a
                        v-if="!isCloseSignHidden"
                        class="ui-popup__close"
                        @click="onClose"
                    >
                        <IconClose />
                    </a>

                    <div class="ui-popup__container">
                        <h5
                            v-if="!!$slots['header']"
                            class="ui-popup__header"
                        >
                            <slot name="header" />
                        </h5>

                        <div class="ui-popup__body">
                            <slot />
                        </div>

                        <div
                            v-if="!!$slots['footer']"
                            class="ui-popup__footer"
                        >
                            <slot name="footer" />
                        </div>
                    </div>
                </div>

                <UiOverlay
                    v-if="overlay"
                    :show="isOpen"
                    class="is-fixed"
                    @click.native="onClick"
                />
            </div>
        </transition>
    </CorePortal>
</template>

<script>
import CorePortal from '@/ui/core/portal/index.vue'
import UiOverlay from '@/ui/components/Overlay/index.vue'
import IconClose from '@/ui/icons/close/close.vue'
import setDisableScroll from '@/utils/set-disable-scroll'

export default {
    name: 'UiPopup',

    components: {
        CorePortal,
        IconClose,
        UiOverlay
    },

    props: {
        /**
         * Тип попапа
         * [message]
         */
        type: {
            type: String,
            default: ''
        },

        disabled: {
            type: Boolean,
            default: false
        },

        isCloseSignHidden: {
            type: Boolean,
            default: false
        },

        overlay: {
            type: Boolean,
            default: true
        },

        closeOnEsc: {
            type: Boolean,
            default: true
        },

        clickOutsideToClose: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            isOpen: false
        }
    },

    computed: {
        classes () {
            return {
                'is-type-message': this.type === 'message'
            }
        }
    },

    mounted () {
        if (this.closeOnEsc) {
            window.addEventListener('keyup', this.onEscEvent)
        }
    },

    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscEvent)
        this.close()
    },

    methods: {
        open () {
            this.isOpen = true
            setDisableScroll({ disabled: true })
        },

        close () {
            this.isOpen = false
            setDisableScroll({ disabled: false })
            this.$emit('close')
        },

        onClose () {
            if (!this.disabled) {
                this.close()
            }
        },

        onClick () {
            if (this.clickOutsideToClose && !this.disabled) {
                this.close()
            }
        },

        onEscEvent (e) {
            if (e.keyCode === 27 && !this.disabled) {
                this.close()
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
    .ui-popup-wrap {
        text-align center
        position fixed
        top 0
        left 0
        overflow auto
        -webkit-overflow-scrolling touch
        right 0
        bottom 0
        z-index 115
        transition .2s
        line-height 100%
        vertical-align middle

        &::after {
            display inline-block
            width 1px
            vertical-align middle
            height 100%
            line-height 100%
            content ""
        }
    }

    .ui-popup {
        position relative
        vertical-align middle
        display inline-block
        z-index 120
        text-align center
        top 0
        bottom 0
        border-radius 8px
        background-color #fff
        transform-origin center center
        transition opacity .15s, transform .2s
        backface-visibility hidden
        will-change opacity, transform, left, top
        min-width 280px
        box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.08)
        margin-bottom 2.5rem
        margin-top 2.5rem
        width 96%
        max-width 50rem

        &__close {
            position absolute
            right .6rem
            top .6rem
            z-index 1
            display flex
            align-items center
            justify-content center
            cursor pointer
            color #DADDDE
        }

        &__container {

        }

        &__header {
            text-align center
            text-transform uppercase
            font-size $font-size-heading-5
            line-height 2.8rem
            font-weight $font-weight-bold
            margin-bottom 5rem
            letter-spacing 0.1rem
        }

        &__content {
            font-size $font-size-base
        }

        &__footer {
            text-align center
            margin-top 4rem
        }

        &.is-type-message &__container {
            width 100%
            box-sizing border-box
            padding 3rem 2rem 2rem

            +layout-sm-and-up() {
                min-width 50rem
                padding 4rem
            }
        }

        +layout-sm-and-up() {
            width auto
            max-width 80%
        }
    }

    .ui-popup-enter-active .ui-popup,
    .ui-popup-leave-active .ui-popup {
        opacity 0
        transform scale(.85)
    }

    .no-scroll {
        overflow hidden
        position fixed
        width 100%
        height 100%
    }
</style>
