
export function fetchUsers (query, count) {
    return `
    search(query: "${query}", type: USER, first: ${count}) {
        list {
            __typename
            ... on User {
                id
                name
                roi
                avatar
                winRate
                viewerHasInFavorites
                betsForm {
                    state
                }
            }
        }
    }`
}

export function fetchTeams (query, count) {
    return `
    search(query: "${query}", type: TEAM, first: ${count}) {
        list {
            __typename
            ... on Team {
                id
                name
                logo
            }
        }
    }`
}

export function fetchMatches (query, count) {
    return `
    search(query: "${query}", type: MATCH, first: ${count}) {
        list {
            __typename
            ... on Match {
                id
                webname
                startTime
                viewerHasInFavorites
                homeTeam {
                    team {
                        id
                        name
                        logo
                        logoSmall
                    }
                }
                awayTeam {
                    team {
                        id
                        name
                        logo
                        logoSmall
                    }
                }
                tournament {
                    name
                    webname
                }
            }
        }
    }`
}

export function fetchTournaments (query, count) {
    return `
    search(query: "${query}", type: TOURNAMENT, first: ${count}) {
        list {
            __typename
            ... on Tournament {
                id
                name
                webname
                logo
            }
        }
    }`
}
