import Api from '@/api'
import { updateFootballBet } from '@/api/graphql/predictions-football'
import { updateNewSportBet } from '@/api/graphql/new-sports'
import _escape from '@/utils/escape'

const getUpdateBetQuery = params => {
    if (params.isFootball) {
        return updateFootballBet(params)
    }

    return updateNewSportBet(params)
}

export default {
    namespaced: true,
    actions: {
        update ({ dispatch }, updatedBet) {
            const params = {
                ...updatedBet,
                description: _escape(updatedBet.description.trim())
            }

            const query = getUpdateBetQuery(params)

            return Api.graphql(query, { type: 'mutation' })
                .then(res => {
                    let resultBet = null

                    if (params.isFootball) {
                        resultBet = res.betMutations.update
                    } else {
                        resultBet = res.betMutations.updateNewSportBet
                    }

                    dispatch(
                        'predictions/updatePrediction',
                        resultBet,
                        { root: true }
                    )
                })
        }
    }
}
