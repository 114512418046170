import _escape from '@/utils/escape'

const inFavoritesQuery = 'inFavorites'
const matchTimeQuery = `
scheduledAtStamp
season {
  startDate
  endDate
}
events (radarType: ["match_ended"]) {
  type
  value {
    ... on statMatchEnded {
      time
    }
  }
}`
const betEditorialQuery = `
betEditorial {
  matchID
  user {
    id
    name
    avatar
    roi
  }
  userProfile {
    about
  }
  createTime
  userSocLink
  description
  title
}`

export function getStatMatch ({ matchId, shouldFetch }) {
    return `{
      stat_match(id: "${matchId}", source: SPORTS) {
        ubersetzer {sportsId}
        ${shouldFetch.inFavorites ? inFavoritesQuery : ''}
        ${shouldFetch.matchTime ? matchTimeQuery : ''}
        ${shouldFetch.betEditorial ? betEditorialQuery : ''}
      }
    }`
}

export function createBetEditorial ({
    matchId,
    userId,
    title,
    description,
    createTime,
    userSocLink
}) {
    return `{
      betMutations {
        saveEditorial(bet: {
            matchID: "${matchId}"
            userID: "${userId}"
            title: "${_escape(title.trim())}"
            description: "${_escape(description.trim())}"
            createTime: ${createTime}
            userSocLink: "${userSocLink}"
        })
      }
    }`
}
