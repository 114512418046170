/**
 * Преобразование объекта в массив
 * @param object - входной объект
 * @param separator - разделитель
 * @returns {[]}
 */
// eslint-disable-next-line func-names
export default function (object = {}, separator = '=') {
    const array = []

    // eslint-disable-next-line no-restricted-syntax
    for (const key in object) {
        if (object[key]) {
            array.push(`${key}${separator}${object[key]}`)
        }
    }

    return array
}
