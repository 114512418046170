import Errors from '@/i18n/errors.json'

export default {
    methods: {
        /**
         * Получает текст ошибки по ответу от сервера
         * @param {Object} res - ответ сервера
         * @return {String}
         */
        getErrorMessageByResponse (res) {
            let code

            try {
                code = res.data.error.code
            } catch (e) {}

            return this.getErrorMessageByCode(code)
        },

        getErrorMessageGQ (errors) {
            const code = (((errors || []) || {}).extensions || {}).code || ''

            return this.getErrorMessageByCode(code)
        },

        /**
         * Получает текст ошибки по коду
         * @param {String} code - код ошибки
         * @return {String}
         */
        getErrorMessageByCode (code) {
            const LocaleErrors = Errors[this.$i18n.locale]

            return LocaleErrors[code] || LocaleErrors.default
        }
    }
}
