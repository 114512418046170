import { THIRTY_MINUTES_CACHE_CONTROL } from '@/constants'

const bookmakersList = () => import('@/views/rb/bookmakers/index.vue')
const subheader = () => import('@/views/rb/subheader.vue')
const RightColumn = () => import('@/views/rb/right.vue')

export default [
    {
        path: 'bookmakers',
        name: 'bookmakers-default',
        components: {
            default: bookmakersList,
            right: RightColumn,
            subheader
        },
        meta: {
            pageType: 'bookmakers_default',
            cacheControl: THIRTY_MINUTES_CACHE_CONTROL
        }
    },
    {
        path: 'bookmakers/rating',
        redirect: {
            name: 'bookmakers-default'
        }
    },
    {
        path: 'bookmakers/rating/:webname?',
        name: 'bookmakers',
        components: {
            default: bookmakersList,
            right: RightColumn,
            subheader
        },
        meta: {
            pageType: 'bookmakers',
            cacheControl: THIRTY_MINUTES_CACHE_CONTROL
        }
    },
    {
        path: 'bookmakers/bonuses',
        name: 'bookmaker-bonuses-all',
        components: {
            default: () => import('@/views/rb/bookmakersBonusesAll/index.vue'),
            right: () => import('@/views/rb/right.vue'),
            subheader
        },
        meta: {
            cacheControl: THIRTY_MINUTES_CACHE_CONTROL
        }
    },
    {
        path: 'bookmakers/bonuses/:webname',
        name: 'bookmaker-bonuses',
        components: {
            default: () => import('@/views/rb/bookmakersBonuses/index.vue'),
            right: () => import('@/views/rb/right.vue'),
            subheader
        },
        meta: {
            pageType: 'bookmaker_page',
            cacheControl: THIRTY_MINUTES_CACHE_CONTROL
        }
    },
    {
        path: 'bookmakers/:webname?',
        name: 'bookmaker',
        components: {
            default: () => import('@/views/rb/item/index.vue'),
            right: () => import('@/views/rb/right.vue'),
            subheader
        },
        meta: {
            pageType: 'bookmaker_page',
            cacheControl: THIRTY_MINUTES_CACHE_CONTROL
        }
    },
    {
        path: 'bookmakers/:webname/reviews',
        name: 'bookmaker-reviews',
        components: {
            default: () => import('@/views/rb/reviews/index.vue'),
            right: () => import('@/views/rb/right.vue'),
            subheader
        },
        meta: {
            pageType: 'bookmaker_page',
            cacheControl: THIRTY_MINUTES_CACHE_CONTROL
        }
    }
]
