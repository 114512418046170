<template>
    <nav
        v-if="hasRoute"
        class="ui-pagination"
    >
        <button
            :disabled="isPrevDisabled"
            :class="{ 'ui-pagination__item--disabled': isPrevDisabled }"
            class="ui-pagination__item ui-pagination__button"
            @click="changePage(jumpPrevValue)"
        >
            <span>&lt;</span>
        </button>

        <router-link
            v-if="hasFixedMin"
            :to="routeTo(min)"
            class="link-dark-inner ui-pagination__item"
            @click.native="select(min)"
        >
            <span>{{ min }}</span>
        </router-link>

        <div
            v-if="hasStartDots"
            class="ui-pagination__item ui-pagination__item--dots"
        >
            <span>&hellip;</span>
        </div>

        <router-link
            v-for="num in pageNumbers"
            :key="num"
            :to="routeTo(num)"
            class="link-dark-inner ui-pagination__item"
            @click.native="select(num)"
        >
            <span>{{ num }}</span>
        </router-link>

        <div
            v-if="hasEndDots"
            class="ui-pagination__item ui-pagination__item--dots"
        >
            <span>&hellip;</span>
        </div>

        <router-link
            v-if="hasFixedMax"
            :to="routeTo(max)"
            class="link-dark-inner ui-pagination__item"
            @click.native="select(max)"
        >
            <span>{{ max }}</span>
        </router-link>

        <button
            :disabled="isNextDisabled"
            :class="{ 'ui-pagination__item--disabled': isNextDisabled }"
            class="ui-pagination__item ui-pagination__button"
            @click="changePage(jumpNextValue)"
        >
            <span>&gt;</span>
        </button>
    </nav>

    <nav
        v-else
        class="ui-pagination"
    >
        <button
            :class="{ 'ui-pagination__item--disabled': isPrevDisabled }"
            class="ui-pagination__item ui-pagination__button link-dark-inner "
            @click="select(jumpPrevValue)"
        >
            <span>&lt;</span>
        </button>
        <button
            v-if="hasFixedMin"
            :class="{ 'router-link-exact-active': min === value }"
            class="ui-pagination__item ui-pagination__button link-dark-inner"
            @click="select(min)"
        >
            <span>{{ min }}</span>
        </button>
        <span
            v-if="hasStartDots"
            class="ui-pagination__item ui-pagination__item--dots"
        >&hellip;</span>
        <button
            v-for="num in pageNumbers"
            :key="num"
            :class="{ 'router-link-exact-active': num === value }"
            class="ui-pagination__item ui-pagination__button link-dark-inner"
            @click="select(num)"
        >
            <span>{{ num }}</span>
        </button>
        <span
            v-if="hasEndDots"
            class="ui-pagination__item ui-pagination__item--dots"
        >&hellip;</span>
        <button
            v-if="hasFixedMax"
            :class="{ 'router-link-exact-active': max === value }"
            class="ui-pagination__item ui-pagination__button link-dark-inner"
            @click="select(max)"
        >
            <span>{{ max }}</span>
        </button>
        <button
            :class="{ 'ui-pagination__item--disabled': isNextDisabled }"
            class="ui-pagination__item ui-pagination__button link-dark-inner"
            @click="select(jumpNextValue)"
        >
            <span>&gt;</span>
        </button>
    </nav>
</template>

<script>
export default {
    name: 'UiPagination',

    props: {
        /**
         * Передайте объект роута для автоматической генерации урла,
         * params.id подставится автоматически
         * {name: 'route-name'}
         */
        route: {
            type: Object,
            default: null
        },

        /**
         * Максимальное значение пагинации
         */
        max: {
            type: Number,
            default: 1
        },

        pageKey: {
            type: String,
            default: 'id'
        },

        firstTo: {
            type: Object,
            default: null
        },

        /**
         * Минимальное значение пагинации
         */
        min: {
            type: Number,
            default: 1
        },

        /**
         * Текущее значение
         * допускается использование v-model
         */
        value: {
            type: Number,
            default: 1
        },

        /**
         * Количество отображаемых кнопок
         */
        view: {
            type: Number,
            default: 5
        }
    },

    computed: {
        hasRoute () {
            return !!this.route
        },

        currentPage () {
            if (this.value > this.max) {
                return this.max
            }

            if (this.value < this.min) {
                return this.min
            }

            return this.value
        },

        pageNumbers () {
            let offsetLeft = this.tail
            let offsetRight = this.tail

            // Если меньше минимального значения
            if (this.leftValue < this.min) {
                offsetLeft = this.min + this.leftValue
                offsetRight += this.tail - offsetLeft
            }

            // Если больше максимального значения
            if (this.rightValue > this.max) {
                offsetRight = this.max - this.currentPage
                offsetLeft += this.tail - offsetRight
            }

            if (this.currentPage + offsetRight > this.max) {
                offsetRight = this.max - this.currentPage
            }

            if (this.currentPage - offsetLeft < this.min) {
                offsetLeft = this.currentPage - this.min
            }

            const pageNumbers = [this.currentPage]

            for (let i = 0; offsetLeft > i; i += 1) {
                pageNumbers.unshift(this.currentPage - i - 1)
            }

            for (let i = 0; offsetRight > i; i += 1) {
                pageNumbers.push(this.currentPage + i + 1)
            }

            return pageNumbers
        },

        tail () {
            return Math.floor(this.view / 2)
        },

        firstItem () {
            return this.pageNumbers[0]
        },

        lastItem () {
            return this.pageNumbers[this.pageNumbers.length - 1]
        },

        hasFixedMin () {
            return this.firstItem > this.min
        },

        hasFixedMax () {
            return this.lastItem < this.max
        },

        hasStartDots () {
            return this.firstItem > (this.min + 1)
        },

        hasEndDots () {
            return this.lastItem < (this.max - 1)
        },

        isPrevDisabled () {
            return this.currentPage === this.min
        },

        isNextDisabled () {
            return this.currentPage === this.max
        },

        leftValue () {
            return this.currentPage - this.tail
        },

        rightValue () {
            return this.currentPage + this.tail
        },

        jumpPrevValue () {
            return Math.max(this.min, this.currentPage - 1)
        },

        jumpNextValue () {
            return Math.min(this.max, this.currentPage + 1)
        }
    },

    methods: {
        routeTo (pageNumber) {
            if (this.firstTo && pageNumber === 1) {
                return this.firstTo
            }

            const route = { ...this.route }

            route.params = { ...route.params || {} }
            route.params[this.pageKey] = pageNumber

            return route
        },

        changePage (pageNumber) {
            const route = this.routeTo(pageNumber)

            this.select(pageNumber)
            this.$router.push(route)
        },

        select (pageNumber) {
            this.$emit('input', pageNumber)
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-pagination
    display flex
    justify-content center
    font-family $font-family-content
    margin-bottom 1.6rem

    &__item
        width 3.5rem
        text-align center
        cursor pointer

        &.router-link-exact-active
            color $cl-primary

        +layout-sm-and-up()
            width 5rem

    &__button
        padding 0
        border 0
        background transparent
        outline none

    &__item&__item--dots
        color $cl-text-dark
        cursor default

    &__item&__item--disabled
        color $cl-text-dark
        text-decoration none
        opacity .5
        cursor default
        pointer-events none
</style>
