<template>
    <div class="odds-format-selector">
        <div class="odds-format-selector__btn">
            <span class="odds-format-selector__wrap">Odds format</span>
        </div>

        <div class="odds-format-selector__drop">
            <OddsFormatSelectorButton
                v-for="item in odds"
                :key="item.name"
                :name="item.name"
                :value="item.numbers"
                :active="oddsFormat === item.name"
                @click.native="changeOddsFormat(item.name)"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OddsFormatSelectorButton from '@/components/common/OddsFormatSelector/OddsFormatSelectorButton.vue'

export default {
    name: 'OddsFormatSelector',

    components: {
        OddsFormatSelectorButton
    },

    data () {
        return {
            odds: [
                {
                    name: 'european',
                    numbers: '2,25'
                },
                {
                    name: 'american',
                    numbers: '+125'
                }
            ]
        }
    },

    computed: {
        ...mapState('bet', ['oddsFormat'])
    },

    methods: {
        ...mapActions({
            setOddsFormat: 'bet/setOddsFormat'
        }),

        changeOddsFormat (name) {
            this.setOddsFormat(name)

            this.$pivik.event('menu', 'switch_odds_format', name)
        }
    }
}
</script>

<style lang="stylus" scoped>
.odds-format-selector
    position relative
    display flex
    align-items center
    justify-content center
    user-select none
    background $cl-text-dark

    &__btn
        z-index 2
        width 100%
        height 5.2rem
        display flex
        align-items center

    &__wrap
        width 100%
        margin 0 1.2rem
        padding .8rem 2rem
        font-size 1.6rem
        border-radius 4px
        background #23262C
        text-align center
        line-height 1.6rem
        color $cl-white

    &:hover &__wrap
        background initial
        color rgba($cl-white, .3)

    &:hover &__drop
        opacity 1
        transform translate(0, 0)

    &__drop
        position absolute
        top 100%
        left 0
        opacity 0
        transform translate(0, -100%)
        transition transform .3s cubic-bezier(.25, .45, .45, .94), opacity .3s
        z-index -2
        width 100%
        display flex
        flex-direction column
        background-color $cl-text-dark
</style>
