export function isActive (statusId = '') {
    return ['2', '3', '4', '6', '7', '31', 'LIVE'].indexOf(statusId) > -1
}

export function isFinished (statusId = '') {
    return ['5', '23', 'ENDED', 'CLOSED'].indexOf(statusId) > -1
}

export function isNoStarted (statusId = '') {
    return ['1', '22', 'NOT_STARTED', 'POSTPONED'].indexOf(statusId) > -1
}

export function isPostponed (statusId = '') {
    return ['22', 'POSTPONED'].indexOf(statusId) > -1
}
