import localStorage from '@/utils/localstorage'

const SESSION_ID_KEY = 'bi_session_id'

/**
 *  Установка авторизационного токена в localStorage
 * @param sessionId {string} - токен
 * @returns {boolean}
 */
export const setSessionId = (sessionId = '') => {
    localStorage.set(SESSION_ID_KEY, sessionId)
}

/**
 *  Чтение авторизационного токена из localStorage
 * @returns {string} - токен
 */
export const getSessionId = () => localStorage.get(SESSION_ID_KEY)
