import loadScript from '@/utils/loadScript'

// https://vk.com/dev/account.getProfileInfo

export default {
    sdk: 'https://vk.com/js/api/openapi.js',

    appID: 5965292,

    SDKKey: 'VK',

    // https://vk.com/dev/permissions
    // appPermissions: 0 + 4194304 + 65536,
    appPermissions: 'email',

    authByPopup () {
        return new Promise((resolve, reject) => {
            const redirectUri = `${window.location.origin}/service/closed`
            const opened = window.open(`https://oauth.vk.com/authorize?client_id=${this.appID}&scope=${this.appPermissions}&redirect_uri=${redirectUri}&display=popup&response_type=code`)
            let resolved = false

            opened.onerror = reject

            const interval = setInterval(() => {
                if (!opened || opened.closed) {
                    if (!resolved) {
                        reject()
                    }

                    return clearInterval(interval)
                }

                // Check accessing
                try {
                    const search = opened.location.search.replace('?', '')
                    const searchArray = search.split('&')
                    const params = {
                        redirect_uri: redirectUri
                    }

                    searchArray.forEach(item => {
                        const [key, value] = item.split('=')

                        params[key] = value
                    })

                    if (params.code) {
                        resolve(params)
                    } else {
                        reject()
                    }

                    resolved = true
                    opened.close()
                } catch (e) {
                    // empty
                }

                return null
            }, 1000)
        })
    },

    ready () {
        if (!this.internal_readyPromise) {
            this.internal_readyPromise = new Promise((resolve, reject) => {
                loadScript(this.sdk).catch(reject)

                window.vkAsyncInit = () => {
                    try {
                        window[this.SDKKey].init({ apiId: this.appID })
                        resolve(window[this.SDKKey])
                    } catch (e) {
                        reject(e)
                    }
                }
            })
        }

        return this.internal_readyPromise
    },

    async logout () {
        const SDK = await this.ready()
        const state = await new Promise(resolve => SDK.Auth.getLoginStatus(resolve))

        if (state.status === 'connected') {
            return SDK.Auth.logout()
        }

        return null
    },

    login () {
        return this.authByPopup()
    },

    loginBySDK () {
        return this.ready()
            .then((SDK) => new Promise((resolve, reject) => {
                SDK.Auth.login(response => {
                    if (response.session) {
                        resolve({
                            token: response.session.sid,
                            uid: response.session.user.id
                        })
                    } else {
                        reject(response)
                    }
                }, this.appPermissions)
            }))
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error('VK Auth login', err)

                return Promise.reject(err)
            })
    },

    getProfileInfo (id) {
        return this.ready()
            .then((SDK) => new Promise(resolve => {
                SDK.api('users.get', { fields: 'photo_200,email,contacts', user_ids: id }, res => {
                    let data = {}

                    if (res.response && res.response[0]) {
                        [data] = res.response
                        data.avatar = data.photo_200
                        data.name = [data.first_name, data.last_name].join(' ')
                        data.id = data.uid
                    }

                    resolve(data)
                })
            }))
    }
}
