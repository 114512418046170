import {
    TEN_MINUTES_CACHE_CONTROL,
    IS_BETTING_COM,
    NEW_SPORT_JOINED
} from '@/constants'

const RightColumn = () => import('@/views/predictions/right.vue')
const subheader = () => import('@/views/predictions/subheader.vue')


const predictionRoute = {
    components: {
        top: () => import('@/views/predictions/smart/top.vue'),
        default: () => import('@/views/predictions/item/index.vue'),
        right: RightColumn
    },
    meta: {
        pageType: 'bet',
        cacheControl: TEN_MINUTES_CACHE_CONTROL
    }
}

const BETS_TOMORROW_URL = `:sport(football)/${IS_BETTING_COM ? 'tomorrow' : 'prognozy-na-zavtra'}`
const BETS_WEEKEND_URL = `:sport(football)/${IS_BETTING_COM ? 'weekend' : 'prognozy-na-vyhodnie'}`

const routes = [
    {
        path: 'predictions/:id(\\d+)',
        name: 'predictions-item-old',
        ...predictionRoute
    },
    {
        path: 'match/:matchId/:id(\\d+)',
        name: 'predictions-item',
        ...predictionRoute
    },

    {
        path: BETS_TOMORROW_URL,
        name: 'predictions-tomorrow',
        components: {
            subheader,
            top: () => import('@/views/predictions/smart/top.vue'),
            default: () => import('@/views/predictions/tomorrow/index.vue'),
            right: RightColumn
        },
        meta: {
            pageType: 'bets_feed',
            pageId: 'tomorrow',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    },

    {
        path: '/match/:matchId/odd/:oddId',
        name: 'predictions-by-odd-id',
        components: {
            subheader,
            top: () => import('@/views/predictions/smart/top.vue'),
            default: () => import('@/views/predictions/PredictionsByOddId/index.vue'),
            right: RightColumn
        },
        meta: {
            pageType: 'bets_feed',
            pageId: 'bets_by_odd_id',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    },

    {
        path: BETS_WEEKEND_URL,
        name: 'predictions-weekend',
        components: {
            subheader,
            top: () => import('@/views/predictions/smart/top.vue'),
            default: () => import('@/views/predictions/weekend/index.vue'),
            right: RightColumn
        },
        meta: {
            pageType: 'bets_feed',
            pageId: 'weekend',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    },

    {
        path: ':sport(football)/',
        name: 'predictions',
        components: {
            subheader,
            top: () => import('@/views/predictions/smart/top.vue'),
            default: () => import('@/views/predictions/smart/index.vue'),
            right: RightColumn
        },
        meta: {
            pageType: 'bets_feed',
            pageId: 'all',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    },

    // Убираем дубли page/1, page
    {
        path: ':sport(football)/page(/1)?',
        redirect: {
            name: 'predictions'
        }
    },
    {
        path: ':sport(football)/page/:page(\\d{1,3})?',
        name: 'predictions-page',
        redirect: {
            name: 'predictions'
        }
    },

    {
        path: 'predictions',
        redirect: 'football'
    },

    // Убираем дубли page/1, page
    {
        path: 'predictions/page(/1)?',
        redirect: {
            name: 'predictions-old'
        }
    },

    {
        path: 'predictions/page/:page(\\d{1,3})?',
        name: 'predictions-page-old',
        redirect: {
            name: 'predictions'
        }
    },

    {
        path: ':sport(football)/pro',
        redirect: {
            name: 'home'
        }
    },

    {
        path: ':sport(football)/pro/page/:page(\\d{1,3})?',
        redirect: {
            name: 'home'
        }
    },

    {
        path: 'predictions/subscriptions',
        name: 'predictions-my',
        components: {
            top: () => import('@/views/predictions/top.vue'),
            subheader,
            default: () => import('@/views/predictions/my/index.vue'),
            right: RightColumn
        },
        meta: {
            pageType: 'bets_feed',
            pageId: 'my',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    }
]

if (!IS_BETTING_COM) {
    routes.push(
        {
            path: 'football/prognozy-professionalov',
            redirect: {
                name: 'home'
            }
        },

        {
            path: `predictions/:sport(${NEW_SPORT_JOINED})/:id(\\d+)`,
            name: 'new-sport-predictions-item',
            components: {
                top: () => import('@/views/predictions/smart/top.vue'),
                default: () => import('@/views/predictions/NewSportsItem/index.vue'),
                right: RightColumn
            },
            meta: {
                pageType: 'new-sports-bet',
                cacheControl: TEN_MINUTES_CACHE_CONTROL
            }
        },

        {
            path: `:sport(${NEW_SPORT_JOINED})/predictions`,
            name: 'predictions-new-sports',
            components: {
                subheader,
                top: () => import('@/views/predictions/smart/top.vue'),
                default: () => import('@/views/predictions/smart/index.vue'),
                right: RightColumn
            },
            meta: {
                pageType: 'bets_feed_new_sports',
                cacheControl: TEN_MINUTES_CACHE_CONTROL
            }
        }
    )
}

export default routes
