<template>
    <div class="profile">
        <div
            v-if="isAuth"
            key="card"
            class="profile__card"
        >
            <div
                class="profile__avatar"
                @click="clickAvatar"
            >
                <UiAvatar
                    :src="my.avatar"
                    :to="profileRoute"
                    size="l"
                />
            </div>

            <div class="profile__name">
                {{ my.name }}
            </div>

            <UiRoi
                :value="my.roi"
                class="profile__roi"
            >
                ROI {{ my.roi }}%
            </UiRoi>

            <div class="profile__balance">
                {{ balance | number }} <span class="bi-betty" />
            </div>

            <button
                v-if="my.canRefund"
                class="profile__refund"
                @click="openRefundPopup"
            >
                <IconPlus />
            </button>
        </div>

        <div
            v-else
            class="profile__auth"
        >
            <button
                class="profile__signin"
                @click="openAuthPopup"
            >
                {{ $t('signin') }}
            </button>

            <button
                class="profile__register"
                @click="openRegisterPopup"
            >
                {{ $t('register') }}
            </button>
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "signin": "Войти",
        "register": "Зарегистрироваться"
    },
    "en": {
        "signin": "Sign in",
        "register": "Register"
    }
}
</i18n>

<script>
import { mapState, mapGetters } from 'vuex'
import auth from '@/mixins/auth'
import number from '@/filters/number'

export default {
    name: 'TheHeaderProfileMobile',

    filters: {
        number
    },

    mixins: [auth],

    computed: {
        ...mapState('auth', ['checked', 'account', 'isAuth']),
        ...mapGetters('profile', ['isAdmin', 'my']),

        balance () {
            return parseInt(this.my.balance)
        },

        profileRoute () {
            return {
                name: 'profile',
                params: {
                    id: this.my.id
                }
            }
        }
    },

    methods: {
        openAuthPopup () {
            this.$emit('close')

            return this.$nextTick(() => {
                this.$root.$emit('auth')
            })
        },

        openRegisterPopup () {
            this.$emit('close')

            return this.$nextTick(() => {
                this.$root.$emit('register')
            })
        },

        openRefundPopup () {
            return this.$root.$emit('refund')
        },

        clickAvatar () {
            this.$pivik.event('menu', 'click', 'profile')
        }
    }
}
</script>

<style lang="stylus" scoped>
.profile
    box-sizing border-box
    width 100%
    padding 1.6rem
    display flex
    justify-content center
    font-size 1.4rem

    &__auth,
    &__card
        width 100%
        display flex
        align-items center
        justify-content center

    &__auth
        flex-direction column
        margin-bottom 2.4rem

        & .ui-avatar
            width 6.1rem
            height @width
            border 2px solid #3a3f50
            background-color #30343E

            >>> .default
                opacity .3!important

    &__card
        flex-wrap wrap

    &__name
        flex 0 0 100%
        margin-bottom .3rem
        font-size 1.8rem
        font-weight $font-weight-bold
        line-height 2.4rem
        text-align center
        letter-spacing .05rem

    &__roi
        display flex
        align-items center

        &::after
            content '|'
            margin 0 .8rem
            font-size 2rem
            color rgba(#fff, .2)
            transform translate(0, -0.2rem)

    &__refund
        margin-left 1rem
        button(4, 'contained', 'success')
        margin-left 1rem
        height 2rem
        width 2rem
        padding .5rem

        .ui-icon
            display block
            height 1rem
            width 1rem

    &__signin
        button(5, 'outlined', 'primary')
        width 100%
        color $cl-white

    &__register
        button(5, 'contained', 'primary')
        width 100%
        margin-top .8rem

</style>
