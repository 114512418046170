/**
 * Адаптер участника конкурса
 *
 * @param {Object} data - объект с данными об участнике (GQL - Participant)
 * @returns {Object}
 */
export function participantAdapter (data) {
    if (data) {
        if (data.points) {
            data.points = parseFloat(data.points.toFixed(2))
        }

        if (data.user.id) {
            data.id = data.user.id
        }
    }

    return data
}

/**
 * Адаптор для преобразования списка участников из данных сервера
 *
 * @param {Object} data - данные с сервера (GQL - ParticipantsCOnection)
 * @param {Object[]} data.edges - массив с объектами участников (GQL - Participant)
 * @returns {Object[]} массив преобразованных данных об участниках
 */
export function participantsAdapter (data) {
    if (data.edges) {
        return data.edges.map(item => participantAdapter(item.node))
    }

    return null
}

/**
 * Адаптер конкурса
 * - добавляет webname на верхний уровень вложенности
 * - преобразует структуру с призами из массива в объект
 *
 * @param {Object} contest - объект с данными о конкурсе
 * @returns {Object} преобразованный объект с данными о конкурсе
 */
export function contestDataAdapter (contest) {
    if (!contest) {
        return null
    }

    const webname = (contest.web && contest.web.webname) || `contest-${contest.ID}`

    if (contest.prizes && contest.prizes.length) {
        contest.prizes = contest.prizes.reduce((acc, prize) => {
            const [key, value] = Object.values(prize)

            acc[key] = value

            return acc
        }, {})
    }

    if (contest.viewerParticipantStat) {
        contest.viewerParticipantStat = participantAdapter(contest.viewerParticipantStat)
    }

    return {
        ...contest,
        webname
    }
}

/**
 * Функция для получения нужной структуры для хранения списка участников
 *
 * @param {Object} config
 * @param {Object[]} config.participants - массив с участниками
 * @param {Object} config.currentContestViewer - данные о авторизованном пользователе
 * @param {Boolean} config.isViewerReached - флаг указывающий достиг ли список пользователей текущего (авторизованного пользователя)
 *                                              необходим для правильной разбивки списка пользователей (для отображения авторизованного пользователя)
 * @returns {Object} возвращает структуру для сохранения в хранилище
 */
export function getParticipantsStructure ({
    participants,
    currentContestViewer,
    isViewerReached = false
}) {
    const result = {
        list: [],
        listAfterViewer: [],
        isViewerReached
    }

    if (!participants) {
        return result
    }

    if (isViewerReached) {
        result.listAfterViewer = participants

        return result
    }

    if (currentContestViewer) {
        const viewerIndex = participants.findIndex(item => item.id === currentContestViewer.id)

        if (viewerIndex > -1) {
            result.list = participants.slice(0, viewerIndex)
            result.listAfterViewer = participants.slice(viewerIndex + 1)

            result.isViewerReached = true

            return result
        }
    }

    result.list = participants

    return result
}


/**
 * Конструктор для элемента меню
 *
 * @param {Object} config
 * @param {String} config.id - id конкурса
 * @param {String} config.webname - webname конкурса
 * @param {String} config.title - title конкурса
 * @returns {Object} представление объекта меню
 */
export function MenuItem ({ id, webname, title, name }) {
    return {
        id,
        webname,
        title,
        to: { name, params: { webname } }
    }
}

/**
 * Функция для итерирования по массиву данных о конкурсах
 * - достает массив из структуры данных пришедшей с сервера
 * - итерируя по массиву конкурсов преобразует данные с помощью адаптера и передает их в callback-функцию
 * - callback-функция вызывается только при условии наличия флага isActive в данных о конкурсе
 *
 * @param {Object} rawData - объект с данными о конкурсах
 * @param {Object[]} rawData.edges - массив с конкурсами
 * @param {Function} callback - callback-функция вызываемая на каждой итерации по массиву конкурсов
 */
export function iterateOverContests (rawData, callback) {
    if (rawData && rawData.edges && rawData.edges.length) {
        rawData.edges.forEach(item => {
            const contest = contestDataAdapter(item.node)

            callback(contest)
        })
    }
}
