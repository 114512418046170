<template>
    <section
        :class="{ 'interstitial--visible': false }"
        class="interstitial"
    >
        <div
            v-if="isInterstitialVisible && isShow"
            class="interstitial__body"
        >
            <button
                class="interstitial__close-btn"
                @click="close"
            >
                <CloseIcon />
            </button>
            <a
                :href="link"
                target="_blank"
                @click="analyticsClick"
            >
                <img
                    :src="image"
                    :alt="bookmaker"
                    class="interstitial__image"
                >
            </a>
        </div>
        <RefreshableDfp
            v-show="!isShow"
            :placement="placement"
            @receive="showCreative"
        />
    </section>
</template>

<script>
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'
import CloseIcon from '@/assets/svg/close-bold.icon.svg'
import { mapState, mapActions } from 'vuex'

const INTERSTITIAL_CLOSE_TIMEOUT = 10000

export default {
    name: 'DFPInterstitial',

    components: {
        RefreshableDfp,
        CloseIcon
    },

    data () {
        return {
            placement: 'interstitial',
            secondsLeft: 0,
            link: '',
            image: '',
            bookmaker: '',
            isShow: false,
            closeTimeout: 0
        }
    },

    computed: {
        ...mapState('ads', ['isInterstitialVisible'])
    },

    watch: {
        isInterstitialVisible (isVisible) {
            if (isVisible) {
                this.closeTimeout = setTimeout(this.close, INTERSTITIAL_CLOSE_TIMEOUT)
            }
        }
    },

    methods: {
        ...mapActions('ads', ['closeInterstitial', 'interstitialReceived']),

        close () {
            clearTimeout(this.closeTimeout)

            this.closeTimeout = 0
            this.isShow = false

            this.closeInterstitial()
        },

        showCreative (message) {
            if (message) {
                this.link = message.link
                this.image = message.imageSrc
                this.bookmaker = message.bookmakerName
                // this.isShow = true
                this.interstitialReceived()
                this.$pivik.event('dfp', this.bookmaker, this.placement, 0)
            }
        },

        analyticsClick () {
            this.$pivik.event('dfp', this.bookmaker, this.placement, 1)
        }
    }
}
</script>

<style lang="stylus">
.interstitial
    position fixed
    z-index 1000
    top 0
    left 0
    display none
    width 100%
    height 100%
    background-color rgba(0, 0, 0, .65)
    flex-direction column
    opacity 0
    pointer-events none
    align-items center
    justify-content center

    &--visible
        display flex
        opacity 1
        pointer-events auto

    &__close-btn
        position absolute
        top 0
        right 0
        display flex
        box-sizing border-box
        align-items center
        justify-content center
        padding .8rem
        border none
        background rgba(255,255,255, .6)

    &__body
        flex 0 1
        position relative

    &__link
        display flex
        max-width 100%
        align-items center
        justify-content center

    &__image
        max-width 100%

</style>
