const POST_FRAGMENT = [
    'id',
    'h1',
    'title',
    'image',
    'mtime',
    'commentsCount',
    'section',
    'subsection'
].join(' ')

function createInteractions (contentIdList) {
    const interactions = contentIdList.map(contentId => `{
            contentID: "${contentId}",
            contentType: POST,
            action: VIEW
        }`)

    return `[${interactions.join(',')}]`
}

export function fetchRecommendations ({ clientId, top = 20, first = 10 }) {
    return `{
        recommendations(
            deviceID: "${clientId}",
            filter: {contentTypes: [POST]},
            top: ${top}, first: ${first}
        ) {
            ... on Post {
                ${POST_FRAGMENT}
            }
        }
      }`
}

export function fetchPostIdsRecommendations ({ clientId, top, first }) {
    return `{
        recommendations(
            deviceID: "${clientId}",
            filter: {contentTypes: [POST]},
            top: ${top}, first: ${first}
        ) {
            ... on Post {
                id
            }
        }
    }`
}

export function sendUserInteractionsAndFetch ({ clientId, contentIds = [], top = 20, first = 10 }) {
    return `{
        sendUserInteractions(input: {
            deviceID: "${clientId}",
            platform: WEB,
            interactions: ${createInteractions(contentIds)}
        }) {
            status
            recommendations(
                filter: {contentTypes: [POST]},
                top: ${top},
                first: ${first}
            ) {
                ... on Post {
                    ${POST_FRAGMENT}
                }
            }
        }
    }`
}

export function sendUserInteractions ({ clientId, contentIds = [] }) {
    return `{
        sendUserInteractions(input: {
            deviceID: "${clientId}",
            platform: WEB,
            interactions: ${createInteractions(contentIds)}
        }) {
            status
        }
    }`
}
