/**
 * Возвращает строку даты для пагинаций
 * @param {Date} dateObj дата, которую мы проводим к строке
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function dateFormatter (dateObj) {
    const day = (`0${dateObj.getDate()}`).slice(-2)
    const month = (`0${dateObj.getMonth() + 1}`).slice(-2)
    const year = dateObj.getFullYear()

    return `${year}-${month}-${day}`
}
