import {
    IS_BETTING_COM,
    NEW_SPORT_JOINED
} from '@/constants'

const matchPageConfig = {
    components: {
        default: () => import('@/views/match/football/index.vue'),
        right: () => import('@/views/match/right.vue'),
        top: () => import('@/views/match/top.vue')

    },
    meta: {
        pageType: 'match'
    }
}

const mcPath = `:sport${IS_BETTING_COM ? '(football)' : ''}/match/:date(20\\d{2}-\\d{2}-\\d{2}|results|tomorrow|live)?`

const routes = [
    {
        path: mcPath,
        name: 'match-center',
        components: {
            default: () => import('@/views/MatchCenter/MatchCenterView.vue'),
            right: () => import('@/views/MatchCenter/MatchCenterRightView.vue'),
            top: () => import('@/views/MatchCenter/MatchCenterTopView.vue')
        },
        meta: {
            pageType: 'mc'
        }
    },
    {
        path: 'match/:date(20\\d{2}-\\d{2}-\\d{2})?',
        name: 'match-center-old',
        redirect: ({ params }) => ({
            name: 'match-center',
            params: {
                sport: 'football',
                ...params
            }
        })
    },
    {
        path: 'match/:id',
        name: 'match',
        ...matchPageConfig
    },

    {
        path: 'match/:id/page/:page(\\d{1,3})?',
        name: 'match-page',
        redirect: {
            name: 'match'
        }
    },

    // old routes
    {
        path: 'match/:id/(popular|new|statistics|odds)',
        redirect: {
            name: 'match'
        }
    },
    {
        path: 'match/:id/(new|popular)/page/:page(\\d{1,3})?',
        redirect: {
            name: 'match'
        }
    }
]

if (!IS_BETTING_COM) {
    routes.push({
        path: `:sport(${NEW_SPORT_JOINED})/match/:id`,
        name: 'match-new-sports',
        components: {
            default: () => import('@/views/match/newSports/index.vue'),
            right: () => import('@/views/match/right.vue'),
            top: () => import('@/views/match/top.vue')
        },
        meta: {
            pageType: 'match'
        }
    })
}


export default routes
