<template>
    <button
        class="odds-format-selector-button"
        :class="{ 'odds-format-selector-button--active': active }"
    >
        <span>{{ name }}</span>
        <span>{{ value }}</span>
    </button>
</template>

<script>
export default {
    name: 'OddsFormatSelectorButton',

    props: {
        name: {
            type: String,
            default: ''
        },

        value: {
            type: String,
            default: ''
        },

        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus" scoped>
.odds-format-selector-button
    width 100%
    height 5.2rem
    display flex
    align-items center
    justify-content space-between
    padding 1.5rem 2rem
    line-height @height
    color $cl-white
    transition color .3s
    cursor pointer
    text-decoration none
    text-transform capitalize
    border none
    background $cl-text-dark
    outline none

    &--active
        background #23262C
        cursor default

    &:hover:not(.odds-format-selector-button--active)
        color rgba(@color, .7)
</style>
