import Api from '@/api'
import logger from '@/utils/logger'
import { fetchSameTournamentMatches } from '@/api/graphql/statistics'

const SET_SAME_TOURNAMENT_MATCHES = 'SET_SAME_TOURNAMENT_MATCHES'

export default {
    namespaced: true,

    state () {
        return {
            sameTournamentMatches: []
        }
    },

    actions: {
        async fetchMatches ({ commit }, { id }) {
            try {
                const queryString = fetchSameTournamentMatches(id)

                const data = await Api.graphql(queryString)
                const { teaser } = data.season

                const matchesList = teaser.current.concat(teaser.next)
                    .filter((match) => match.ubersetzer.sportsId !== +id)
                    .slice(0, 5)

                commit(SET_SAME_TOURNAMENT_MATCHES, matchesList)
            } catch (err) {
                logger.error(err)

                commit(SET_SAME_TOURNAMENT_MATCHES)
            }
        }
    },

    mutations: {
        [SET_SAME_TOURNAMENT_MATCHES] (state, sameTournamentMatches = []) {
            state.sameTournamentMatches = sameTournamentMatches
        }
    }
}
