import Api from '@/api'
import {
    fetchTournaments,
    fetchMatches,
    fetchUsers
} from '@/api/graphql/search'

const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'

export default {
    namespaced: true,

    state () {
        return {
            search: {
                users: [],
                teams: [],
                tournaments: [],
                matches: []
            }
        }
    },

    actions: {
        /**
         * Запрашивает данные и сохраняет результат в store
         * @param {object} context
         * @param {function} context.commit
         * @param {function} context.dispatch
         * @param {string} query - строка поиска
         * @param {object} counts - количестко данных по определенному типу тип данных для поиска
         * @param {Number} counts.users
         * @param {Number} counts.matches
         * @param {Number} counts.tournaments
         * @returns {Promise}
         */
        makeSearch ({ commit, dispatch }, { query, counts = {} }) {
            return dispatch('fetchSearchResults', { query, counts })
                .then(result => {
                    Object.keys(result).forEach(type => {
                        commit(SET_SEARCH_RESULTS, { type, result: result[type] })
                    })
                })
                .catch(() => Promise.reject({ code: 404 }))
        },

        /**
         * Запрашивает данные
         * @param {object} context
         * @param {function} context.commit
         * @param {function} context.dispatch
         * @param {string} query - строка поиска
         * @param {object} counts - количестко данных по определенному типу тип данных для поиска
         * @param {Number} counts.users
         * @param {Number} counts.matches
         * @param {Number} counts.tournaments
         */
        fetchSearchResults (context, { query, counts }) {
            const searchTypes = Object.keys(counts)

            return Promise.all(searchTypes.map((type) => {
                let graphqlQuery = ''

                if (type === 'users') {
                    graphqlQuery = fetchUsers(query, counts[type])
                } else if (type === 'tournaments') {
                    graphqlQuery = fetchTournaments(query, counts[type])
                } else if (type === 'matches') {
                    graphqlQuery = fetchMatches(query, counts[type])
                }

                return Api.graphql(`{ ${graphqlQuery} }`)
                    .then(result => ({
                        type,
                        result: (result.list || [])
                    }))
                    .catch(() => Promise.resolve({ type, result: [] }))
            }))
                .then((results) => results.reduce((acc, current) => {
                    acc[current.type] = current.result

                    return acc
                }, {}))
        }
    },

    mutations: {
        SET_SEARCH_RESULTS (state, { result = [], type }) {
            state.search[type] = result
        }
    }
}
