const SET_OPEN_STATUS = 'SET_OPEN_STATUS'
const SET_CURTAIN_MODE = 'SET_CURTAIN_MODE'

export default {
    namespaced: true,

    state () {
        return {
            mode: 'public-chat',
            custom: false,
            isOpened: false,
            prevMode: '',
            prevOpenStatus: false,

            // Храним этот ключ в persisted state
            savedMatchSelector: false
        }
    },

    getters: {
        isEuroChat: state => state.mode === 'euro-chat',
        isPublicChat: state => state.mode === 'public-chat',
        isMatchChat: state => state.mode === 'match-chat',
        isMatchSelector: state => state.mode === 'match-selector',
        isBetEditor: state => state.mode === 'bet'
    },

    actions: {
        changeOpenStatus ({ commit }, status) {
            commit(SET_OPEN_STATUS, status)
        },

        changeMode ({ commit }, options) {
            const {
                mode = 'public-chat',
                forceOpen = true
            } = options

            commit(SET_CURTAIN_MODE, { mode, forceOpen })
        },

        changeToPrevMode ({ state, commit }) {
            const mode = state.prevMode || 'public-chat'

            commit(SET_OPEN_STATUS, state.prevOpenStatus)
            commit(SET_CURTAIN_MODE, { mode, forceOpen: false })
        }
    },

    mutations: {
        [SET_OPEN_STATUS] (state, status) {
            if (!state.custom) {
                state.custom = true
            }

            state.prevOpenStatus = state.isOpened
            state.isOpened = status
        },

        [SET_CURTAIN_MODE] (state, { mode, forceOpen = true }) {
            if (state.mode !== mode) {
                state.prevMode = state.mode
            }

            state.mode = mode
            state.savedMatchSelector = mode === 'match-selector'
            state.prevOpenStatus = state.isOpened

            if (forceOpen && !state.isOpened) {
                state.isOpened = true
            }
        }
    }
}
