<template>
    <div
        class="ui-accordion"
        :class="{ 'is-open': isOpen }"
    >
        <div
            class="ui-accordion__header"
            @click="onToggle"
        >
            <div class="ui-accordion__header-content">
                <slot name="header" />
            </div>

            <span class="ui-accordion__header-aside">
                <span class="ui-accordion__arrow">
                    <component
                        :is="iconComponent"
                        :class="iconClass"
                    />
                </span>
            </span>
        </div>

        <transition
            name="accordion-toggle"
            @enter="onEnter"
            @leave="onLeave"
            @after-enter="onEnterTo"
        >
            <div
                v-if="isOpen"
                ref="content"
                class="ui-accordion__content"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
import ArrowIcon from '@/ui/icons/arrow/top.vue'
import ArrowRoundedIcon from '@/ui/icons/arrow/top-rounded.vue'

export default {
    name: 'UiAccordion',

    components: {
        ArrowIcon,
        ArrowRoundedIcon
    },

    props: {
        value: {
            type: Boolean,
            default: undefined
        },

        opened: {
            type: Boolean,
            default: false
        },

        roundedArrow: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            innerValue: false
        }
    },

    computed: {
        useModel () {
            return typeof this.value !== 'undefined'
        },

        isOpen () {
            return this.useModel ? this.value : this.innerValue
        },

        iconComponent () {
            return this.roundedArrow ? 'ArrowRoundedIcon' : 'ArrowIcon'
        },

        iconClass () {
            return {
                rounded: this.roundedArrow
            }
        }
    },

    watch: {
        opened () {
            this.init()
        }
    },

    created () {
        this.init()
    },

    methods: {
        init () {
            if (!this.useModel) {
                this.innerValue = !!this.opened
            }
        },

        onToggle () {
            let value

            if (this.useModel) {
                value = !this.value
            } else {
                this.innerValue = !this.innerValue
                value = this.innerValue
            }

            this.$emit('change', value)
            this.$emit('input', value)
        },

        onEnter () {
            this.$refs.content.style.height = `${this.$refs.content.scrollHeight}px`
        },

        onEnterTo () {
            this.$refs.content.style.height = null
        },

        onLeave () {
            this.$refs.content.style.height = `${this.$refs.content.scrollHeight}px`
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-accordion
    transition margin-top 0.4s

    &__header
        position relative
        margin-bottom 1.8rem
        display flex
        align-items center
        min-height 2.4rem

        +layout-sm()
            margin-bottom 1.4rem

        &-content
            flex 1 1

        &-aside
            position absolute
            right 0
            top 50%
            transform translate(0, -50%)
            margin-left 1.5rem

    &__handler
        cursor pointer
        user-select none

    &__arrow
        display block
        width 2.4rem
        height @width

        .ui-icon
            transform rotate(-180deg)
            color $cl-text-dark
            transition 0.4s transform
            transform-origin center center

        .ui-icon.rounded
            color $cl-primary

    &.is-open &__arrow .ui-icon
        transform rotate(0deg)

    &.is-open &__arrow .ui-icon.rounded
        color #DADDDE

    &__content
        &.accordion-toggle-leave-active,
        &.accordion-toggle-enter-active
            transition .4s opacity, .4s height
            overflow hidden

        &.accordion-toggle-enter-active,
        &.accordion-toggle-leave-to
            opacity 0
            height 0

        &.accordion-toggle-enter-to
            opacity 1

        &.accordion-toggle-leave-to
            height 0 !important
</style>
