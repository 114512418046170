import matches from './matches'
import news from './news'
import table from './table'
import contestWidget from './contestWidget'

export default {
    namespaced: true,

    modules: {
        matches,
        news,
        table,
        contestWidget
    }
}
