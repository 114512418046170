import Api from '@/api'
import {
    FOOTBALL_SPORT_ID,
    FIFTEEN_MINUTES_CACHE_CONTROL,
    ONE_HOUR_CACHE_CONTROL
} from '@/constants'
import { getStatMatch } from '@/api/graphql/match'

// modules
import topBets from './stat/top-bets'
import game from './stat/game'
import sameTournamentMatches from './stat/same-tournament-matches'

const DEFAULT_CACHE_CONTROL_VALUE = 'max-age=60'
const ONE_HOUR_MS = 1 * 60 * 60 * 1000
const ONE_DAY_TIME_MS = 24 * 60 * 60 * 1000

function getCacheControlValue ({
    startTimeMS,
    endTimeMS,
    isCurrentSeasonMatch
}) {
    const currentDateMS = Date.now()
    const timeUntilStartMS = startTimeMS - currentDateMS

    if (!startTimeMS && !endTimeMS && !isCurrentSeasonMatch) {
        return ONE_HOUR_CACHE_CONTROL
    }

    if (timeUntilStartMS > ONE_DAY_TIME_MS) {
        return ONE_HOUR_CACHE_CONTROL
    }

    if (ONE_DAY_TIME_MS >= timeUntilStartMS && timeUntilStartMS > ONE_HOUR_MS) {
        return FIFTEEN_MINUTES_CACHE_CONTROL
    }

    if (
        (endTimeMS && ((currentDateMS - endTimeMS) > ONE_HOUR_MS))
        || (!endTimeMS && (currentDateMS - startTimeMS) > ONE_HOUR_MS * 3) // ловим прошедшие матчи без времени окончания
    ) {
        return ONE_HOUR_CACHE_CONTROL
    }

    return DEFAULT_CACHE_CONTROL_VALUE
}

// adapters
function matchStatTimeAdapter ({ events, scheduledAtStamp, season }) {
    const parsedEvents = events.reduce((acc, event = {}) => {
        if (event.type && event.value && event.value.time) {
            acc[event.type] = (new Date(event.value.time)).getTime()
        }

        return acc
    }, {})

    const currentDateMS = Date.now()
    const startSeasonMS = (new Date(season.startDate)).getTime()
    const endSeasonMS = (new Date(season.endDate)).getTime()

    const isCurrentSeasonMatch = startSeasonMS <= currentDateMS && currentDateMS < endSeasonMS

    return {
        startTimeMS: scheduledAtStamp * 1000,
        endTimeMS: parsedEvents.MATCH_ENDED,
        isCurrentSeasonMatch
    }
}

function teamAdapter (team) {
    const teamData = team.team || {}

    return {
        id: team.team_id,
        score: team.score,
        logo: teamData.logo,
        logo_small: teamData.logo_small,
        name: teamData.name
    }
}

function tournamentAdapter (match) {
    const tournamentData = match.tournament || {}

    return {
        id: tournamentData.id,
        logo: tournamentData.logo,
        name: tournamentData.name,
        webname: tournamentData.webname
    }
}


const SET_MATCH_DATA = 'SET_MATCH_DATA'
const UPDATE_MATCH_DATA = 'UPDATE_MATCH_DATA'
const SET_NEXT_MATCH_DATA = 'SET_NEXT_MATCH_DATA'
const UPDATE_MATCH_FAVORITES = 'UPDATE_MATCH_FAVORITES'

export default {
    namespaced: true,

    modules: {
        topBets,
        game,
        sameTournamentMatches
    },

    state () {
        return {
            match: {},
            next: null
        }
    },

    getters: {
        nextMatch (state) {
            return state.next
        },

        team1 (state) {
            const team = state.match.home_team || {}

            return teamAdapter(team)
        },

        team2 (state) {
            const team = state.match.away_team || {}

            return teamAdapter(team)
        },

        tournament (state) {
            const match = state.match || {}

            return tournamentAdapter(match)
        },

        status (state) {
            const status = state.match.status || {}

            return {
                id: status.id,
                name: status.name
            }
        },

        nextMatchTeam1 (state) {
            const team = (state.next && state.next.home_team) || {}

            return teamAdapter(team)
        },

        nextMatchTeam2 (state) {
            const team = (state.next && state.next.away_team) || {}

            return teamAdapter(team)
        },

        nextMatchTournament (state) {
            const next = state.next || {}

            return tournamentAdapter(next)
        }
    },

    actions: {
        async fetch ({ commit }, { id }) {
            try {
                const requestOptions = {
                    path: 'match',
                    params: {
                        id,
                        include: 'team,tournament,stadium'
                    },
                    maps: [
                        'home_team',
                        'away_team',
                        'next_match'
                    ]
                }

                const json = await Api.trbna(requestOptions)
                const queryString = getStatMatch({
                    matchId: json.result.id,
                    shouldFetch: {
                        inFavorites: true,
                        betEditorial: true
                    }
                })

                const {
                    inFavorites,
                    ubersetzer,
                    betEditorial
                } = await Api.graphql(queryString)

                if (json.serialized && json.serialized.sport_id === FOOTBALL_SPORT_ID) {
                    json.serialized.inFavorites = inFavorites
                    json.serialized.statId = ubersetzer.sportsId
                    json.serialized.seo_text = json.state.seo_text ? json.state.seo_text : ''
                    json.serialized.betEditorial = betEditorial

                    commit(SET_MATCH_DATA, json.serialized)
                    commit(SET_NEXT_MATCH_DATA, json.state.next_match)

                    return Promise.resolve(json.serialized)
                }
            } catch (err) {
                return Promise.reject({
                    ...err,
                    code: 404
                })
            }

            commit(SET_MATCH_DATA, {})

            return Promise.reject({ code: 404 })
        },

        async fetchMatchTime (ctx, { matchId }) {
            const queryString = getStatMatch({
                matchId,
                shouldFetch: {
                    matchTime: true
                }
            })

            try {
                const result = await Api.graphql(queryString)

                return matchStatTimeAdapter(result)
            } catch (err) {
                return {}
            }
        },

        async setMatchCacheControl ({ dispatch }, { matchId }) {
            let cacheControl = ''

            try {
                const matchStatTime = await dispatch('fetchMatchTime', { matchId })

                cacheControl = getCacheControlValue(matchStatTime)
            } catch (err) {
                cacheControl = DEFAULT_CACHE_CONTROL_VALUE
            }

            dispatch('page/setCacheControlHeader', { value: cacheControl }, { root: true })
        },

        checkMatchInFavorites ({ commit, state }) {
            return Api.graphql(getStatMatch({
                matchId: state.match.id,
                shouldFetch: {
                    inFavorites: true
                }
            })).then((data) => {
                commit(UPDATE_MATCH_DATA, { inFavorites: data.inFavorites })
            })
        },

        favoriteToggle ({ commit, state, dispatch }) {
            const type = 'MATCH'
            const { id } = state.match
            const isMatchInFavorites = state.match.inFavorites

            commit(UPDATE_MATCH_FAVORITES, { inFavorites: !state.match.inFavorites })

            if (isMatchInFavorites) {
                return dispatch('favorites/unsubscribeFavorites', { id, type }, { root: true })
                    .catch(() => {
                        commit(UPDATE_MATCH_FAVORITES, { inFavorites: true })
                    })
            }

            return dispatch('favorites/subscribeFavorites', { id, type }, { root: true })
                .catch(() => {
                    commit(UPDATE_MATCH_FAVORITES, { inFavorites: false })
                })
        }
    },

    mutations: {
        [SET_MATCH_DATA] (state, data = {}) {
            state.match = data
        },

        [UPDATE_MATCH_DATA] (state, updates) {
            state.match = {
                ...state.match,
                ...updates
            }
        },

        [SET_NEXT_MATCH_DATA] (state, data = null) {
            state.next = data
        },

        [UPDATE_MATCH_FAVORITES] (state, { inFavorites }) {
            state.match.inFavorites = inFavorites
        }
    }
}
