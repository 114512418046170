import Vue from 'vue'
import Api from '@/api'
import {
    addComment,
    fetchComments,
    removeComment
} from '@/api/graphql/comments'

import _escape from '@/utils/escape'


const filter = {
    objectClass: 'BOOKMAKER_REVIEW',
    first: 10,
    order: 'NEW'
}

const SAVE_COMMENTS = 'SAVE_COMMENTS'
const UPDATE_PAGE_INFO = 'UPDATE_PAGE_INFO'
const SET_COMMENTS = 'SET_COMMENTS'
const SET_COMMENT = 'SET_COMMENT'
const REMOVE_COMMENT = 'REMOVE_COMMENT'


export default {
    namespaced: true,

    state () {
        return {
            entities: {}
        }
    },

    getters: {
        comments: (state) => (id) => {
            if (state.entities[id]) {
                return state.entities[id].comments || []
            }

            return []
        },

        isAllComments: (state) => (id) => {
            const { pageInfo } = state.entities[id] || {}

            if (pageInfo) {
                return !pageInfo.hasNextPage
            }

            return true
        }
    },

    actions: {
        /**
         * Добавление нового комментария
         *
         * @param {object} vuexContext
         * @param {object} payload
         * @param {Number} payload.id - id сущности
         * @param {String} payload.comment - текст комментария
         * @param {Number} payload.parentId - id родительского комментария
         *
         * @returns {Promise}
         */

        async send ({ commit, rootGetters }, { id, comment, parentId }) {
            const myProfile = rootGetters['profile/my']
            const queryString = addComment({
                objectClass: filter.objectClass,
                objectId: id,
                userId: myProfile.id,
                comment: _escape(comment),
                parentId
            })

            try {
                const result = await Api.graphql(`{${queryString}}`, { type: 'mutation' })

                if (result.addComment) {
                    commit(SET_COMMENT, { id, commentData: result.addComment })
                }
            } catch (err) {
                return Promise.reject(err)
            }
        },

        async fetch ({ commit, state }, { id }) {
            const object = state.entities[id]
            const queryString = fetchComments({
                objectClass: filter.objectClass,
                objectId: id,
                order: filter.order,
                first: filter.first,
                after: object.pageInfo.endCursor
            })

            try {
                const result = await Api.graphql(`{${queryString}}`)

                commit(SET_COMMENTS, { id, comments: result.list })
                commit(UPDATE_PAGE_INFO, { id, updates: result.pageInfo })
            } catch (e) {
                return Promise.reject()
            }

            return Promise.resolve()
        },

        remove ({ commit }, { id, commentId }) {
            Api.graphql(removeComment({ commentId }), { type: 'mutation' })
                .then(() => commit(REMOVE_COMMENT, { id, commentId }))
        },

        report ({ commit }, id) {
            commit(REMOVE_COMMENT, id)
        }
    },

    mutations: {
        [SAVE_COMMENTS] (state, { id, comments, pageInfo }) {
            Vue.set(state.entities, id, {
                comments,
                pageInfo
            })
        },

        [UPDATE_PAGE_INFO] (state, { id, updates = {} }) {
            Object.keys(updates).forEach(key => {
                Vue.set(state.entities[id].pageInfo, key, updates[key])
            })
        },

        [SET_COMMENTS] (state, { id, comments }) {
            if (!state.entities[id]) {
                Vue.set(state.entities, id, { comments: [] })
            }

            state.entities[id].comments = state.entities[id].comments.concat(comments)
        },

        [SET_COMMENT] (state, { id, commentData }) {
            if (!state.entities[id]) {
                Vue.set(state.entities, id, { comments: [] })
            }

            state.entities[id].comments.splice(0, 0, commentData)
        },

        [REMOVE_COMMENT] (state, { id, commentId }) {
            if (state.entities[id].comments.length) {
                const index = state.entities[id].comments.findIndex(item => item.id === commentId)

                if (index > -1) {
                    state.entities[id].comments.splice(index, 1)
                }
            }
        }
    }
}
