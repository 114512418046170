let scroll = 0
let isDisabled = false

// Todo переделать на классы
export default function setDisableScroll ({ disabled }) {
    if (disabled && !isDisabled) {
        scroll = document.scrollingElement.scrollTop
        document.documentElement.style.top = `${-scroll}px`
        document.documentElement.style.left = 0
        document.documentElement.style.right = 0
        document.documentElement.style.position = 'fixed'
        document.documentElement.style.overflow = 'hidden'
        isDisabled = true
    } else if (isDisabled) {
        document.documentElement.style.position = null
        document.documentElement.style.top = null
        document.documentElement.style.left = null
        document.documentElement.style.right = null
        document.documentElement.style.overflow = null
        document.scrollingElement.scrollTop = scroll
        isDisabled = false

        setTimeout(() => {
            document.scrollingElement.scrollTop = scroll
        }, 30)
    }
}
