import Api from "@/api";
import isArray from "@/utils/isArray";
import Vue from "vue";

import {
    fetchProfile,
    fetchUserRefund,
    fetchUserUploadPhotoUrl,
    updateProfile,
} from "@/api/graphql/profile";

import _escape from "@/utils/escape";
import translations from "./translations.json";

/**
 * Функция для обновления переводов внутри объекта статистики пользователя
 *
 * @param {Object} data - объект с данными о статистике пользователя
 * @param {String[]} keys - ключи по которым нужно пройтись и заменить внутри переводы
 */
function changeLineNames(locale, data, keys) {
    keys.forEach((key) => {
        if (isArray(data[key])) {
            data[key].forEach((item) => {
                const { line } = item;

                if (translations[locale] && translations[locale][line.type]) {
                    item.line.name = translations[locale][line.type];
                }
            });
        }
    });
}

export const RESET_MY_PROFILE_ID = "RESET_MY_PROFILE_ID";
export const UPDATE_MY_PROFILE_BALANCE = "UPDATE_MY_PROFILE_BALANCE";

const SET_SUBSCRIPTION_ITEM = "SET_SUBSCRIPTION_ITEM";
const SET_USER_STATISTICS = "SET_USER_STATISTICS";
const UPDATE_ITEM_IN_FAVORITES = "UPDATE_ITEM_IN_FAVORITES";

export default {
    namespaced: true,

    state() {
        return {
            myId: null,
            stats: {},
            items: {},
        };
    },

    getters: {
        my(state, getters) {
            return getters.item({ id: state.myId });
        },
        stat:
            (state) =>
            ({ id }) =>
                state.stats[id] || {},
        item:
            (state) =>
            ({ id }) =>
                state.items[id] || {},
        isModerator(state, getters) {
            return (getters.my.hasRoles || {}).communityModerator || false;
        },
        isAdmin(state, getters) {
            return (getters.my.hasRoles || {}).moderator || false;
        },
    },

    actions: {
        getMyProfile({ state, dispatch }) {
            if (state.myId) {
                return dispatch("fetchProfile", { id: state.myId, isMy: true });
            }

            return Promise.resolve();
        },

        async fetchProfile({ commit }, { id, isMy }) {
            try {
                const result = await Api.graphql(`{${fetchProfile({ id })}}`);

                commit(SET_SUBSCRIPTION_ITEM, { id, data: result, isMy });

                return result;
            } catch (err) {
                return Promise.reject({ code: 404 });
            }
        },

        async uploadPhoto({ commit }) {
            try {
                console.info("asdasdas");
                const result = await Api.graphql(
                    `{${fetchUserUploadPhotoUrl()}}`,
                    {
                        type: "mutation",
                    }
                );
                console.info({ result });

                // commit(SET_SUBSCRIPTION_ITEM, { id, data: result, isMy });

                return result?.imageMutations?.upload?.uploadURL;
            } catch (err) {
                return Promise.reject({ code: 404 });
            }
        },

        toggleFavorites({ dispatch, commit, state }, { id }) {
            const item = state.items[id];

            if (item) {
                const inFavorites = item.viewerHasInFavorites;

                commit(UPDATE_ITEM_IN_FAVORITES, {
                    id,
                    inFavorites: !inFavorites,
                });
                dispatch(
                    "favorites/toggleFavorites",
                    { type: "USER", id },
                    { root: true }
                ).catch(() => {
                    commit(UPDATE_ITEM_IN_FAVORITES, { id, inFavorites });
                });
            }
        },

        async fetchUserRefund({ commit }) {
            try {
                const result = await Api.graphql(`{${fetchUserRefund()}}`, {
                    type: "mutation",
                });

                commit(UPDATE_MY_PROFILE_BALANCE, {
                    balance: result.userRefund.profile.balance,
                });

                return result;
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async updateProfile({ commit, state }, { id, name, avatar, about }) {
            const profile = state.items[id];

            if (id && profile) {
                const params = {};
                const parsedName = name.replace(/\r|\n/g, "");

                if (profile.name !== parsedName) {
                    params.name = _escape(parsedName);
                }

                if (profile.about !== about) {
                    params.about = _escape(about);
                }

                if (profile.avatar !== avatar) {
                    params.avatar = avatar;
                }

                if (Object.keys(params).length) {
                    params.id = id;
                    const result = await Api.graphql(
                        `{${updateProfile(params)}}`,
                        { type: "mutation" }
                    );

                    commit(SET_SUBSCRIPTION_ITEM, {
                        id,
                        data: result.updateUserProfile,
                    });
                }
            }
        },

        fetchUserStatistics({ commit }, { loc, id }) {
            return Api.trbna({ path: "user/stat", loc, params: { id } }).then(
                ({ serialized }) => {
                    const locale = loc || Api.getLoc();
                    const keys = ["allusers_top_lines", "user_top_lines"];

                    // для EN локали нужно заменить названия линий ставок
                    // согласно задаче BIW-55
                    changeLineNames(locale, serialized, keys);

                    commit(SET_USER_STATISTICS, { id, data: serialized });
                }
            );
        },
    },

    mutations: {
        [RESET_MY_PROFILE_ID](state) {
            state.myId = null;
        },

        [UPDATE_MY_PROFILE_BALANCE](state, { balance }) {
            if (state.items[state.myId] && balance) {
                state.items[state.myId].balance = balance;
                state.items[state.myId].canRefund = false;
            }
        },

        [SET_SUBSCRIPTION_ITEM](state, { id, data, isMy }) {
            Vue.set(state.items, id, data);

            if (isMy) {
                state.myId = id;
            }
        },

        [UPDATE_ITEM_IN_FAVORITES](state, { id, inFavorites }) {
            const item = state.items[id];

            if (item) {
                item.subscribersCount += inFavorites ? 1 : -1;
                item.viewerHasInFavorites = inFavorites;
            }
        },

        [SET_USER_STATISTICS](state, { id, data }) {
            Vue.set(state.stats, id, data);
        },
    },
};
