<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        <div class="popup-bet-updated__table">
            <span class="popup-bet-updated__col">
                <span class="popup-bet-updated__label">{{ $t('rate-from') }}</span>
                <span class="popup-bet-updated__val bi-header-5">{{ rateFrom }}</span>
            </span>

            <span class="popup-bet-updated__col">
                <span class="popup-bet-updated__label">{{ $t('rate-to') }}</span>
                <span class="popup-bet-updated__val bi-header-5 t-color-warn">{{ rateTo }}</span>
            </span>

            <span class="popup-bet-updated__col bi-flex">
                <span class="popup-bet-updated__label">{{ $t('win') }}</span>
                <span class="popup-bet-updated__val bi-header-5">
                    {{ formatWin }} <span class="bi-betty" />
                </span>
            </span>
        </div>

        <template slot="footer">
            <button
                class="popup-bet-updated__button popup-bet-updated__button--stretch"
                @click="onContinue"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Коэффициент изменился",
            "rate-from": "Было",
            "rate-to": "Стало",
            "win": "Выигрыш составит",
            "btn": "Все равно сделать ставку"
        },
        "en": {
            "header": "The odds have changed",
            "rate-from": "It was",
            "rate-to": "It is now",
            "win": "You may win",
            "btn": "Place a bet anyway"
        }
    }
</i18n>

<script>
import mixin from '@/components/Bet/mixin'

export default {
    name: 'PopupBetUpdated',

    mixins: [mixin],

    data () {
        return {
            /**
             * Был коэффициент
             */
            rateFrom: 0,
            /**
             * Стал коэффициент
             */
            rateTo: 0,
            /**
             * Выигрыш
             */
            win: 0
        }
    },

    computed: {
        formatWin () {
            return this.win.toFixed(2)
        }
    },

    mounted () {
        this.$root.$on('popup-bet-updated', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-bet-updated', this.open)
    },

    methods: {
        open ({ rateFrom, rateTo, win }) {
            this.win = win
            this.rateTo = rateTo
            this.rateFrom = rateFrom
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        },

        onContinue () {
            this.onBet()
            this.close()
        }
    }
}
</script>


<style rel="stylesheet/stylus" lang="stylus">
    .popup-bet-updated {
        &__table {
            display flex
            max-width 340px
            margin 0 auto
        }
        &__col {
            text-align center
            flex-basis 65px
            padding 0 5px

            display flex
            flex-direction column
            justify-content space-between

            &:nth-child(2) {
                border-left 1px solid $cl-disabled-light
                border-right 1px solid $cl-disabled-light
            }
            +layout-sm-and-up() {
                flex-basis 85px
            }
        }
        &__label {
            font-size $font-size-base-sub
            display block
            margin-bottom 14px
            +layout-sm-and-up() {
                font-size $font-size-base
            }
        }
        &__val {
            display block
            white-space nowrap
        }
        &__button {
            button(5, 'contained', 'primary')
        }
    }
</style>
