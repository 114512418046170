<template>
    <div class="search-results">
        <div
            v-if="users.length"
            class="search-results__group"
        >
            <div class="search-results__title">
                {{ $t('users') }}
            </div>
            <div class="search-results__list">
                <div
                    v-for="item in users"
                    :key="item.id"
                    class="search-results__item"
                >
                    <SearchResultsProfile :data="item" />
                </div>
            </div>
            <div
                v-if="showMoreUsers"
                class="search-results__more"
            >
                <router-link
                    :to="searchRoute"
                    class="link-info"
                    @click.native="onClickAllUsers"
                >
                    {{ $t('all') }}
                </router-link>
            </div>
        </div>

        <div
            v-if="matches.length"
            class="search-results__group"
        >
            <div class="search-results__title">
                {{ $t('matches') }}
            </div>
            <div class="search-results__list">
                <div
                    v-for="item in matches"
                    :key="item.id"
                    class="search-results__item"
                >
                    <SearchResultsMatch :data="item" />
                </div>
            </div>
        </div>

        <div
            v-if="tournaments.length"
            class="search-results__group"
        >
            <div class="search-results__title">
                {{ $t('tournaments') }}
            </div>
            <div class="search-results__list">
                <div
                    v-for="item in tournaments"
                    :key="item.id"
                    class="search-results__item"
                >
                    <SearchResultsTournament :data="item" />
                </div>
            </div>
        </div>

        <span
            v-if="!haveResult && query"
            class="search-results__empty"
        >
            {{ $t('zero') }}
        </span>
    </div>
</template>

<i18n>
{
    "ru": {
        "users": "Пользователи",
        "matches": "Ближайшие матчи",
        "tournaments": "Турниры",
        "all": "Все пользователи",
        "zero": "По вашему запросу ничего не найдено"
    },
    "en": {
        "users": "Users",
        "matches": "Upcoming matches",
        "tournaments": "Tournaments",
        "all": "All users",
        "zero": "Nothing found"
    }
}
</i18n>

<script>
import SearchResultsProfile from '@/components/Search/SearchResultsProfile.vue'
import SearchResultsMatch from '@/components/Search/SearchResultsMatch.vue'
import SearchResultsTournament from '@/components/Search/SearchResultsTournament.vue'

export default {
    name: 'SearchResults',
    components: {
        SearchResultsProfile,
        SearchResultsMatch,
        SearchResultsTournament
    },

    props: {
        result: {
            type: Object,
            default: () => {}
        },

        query: {
            type: String,
            default: ''
        }
    },

    computed: {
        haveResult () {
            return this.users.length || this.tournaments.length || this.matches.length
        },

        users () {
            const users = this.result.users || []

            return users.slice(0, 3)
        },

        tournaments () {
            return this.result.tournaments || []
        },

        matches () {
            return this.result.matches || []
        },

        showMoreUsers () {
            const users = this.result.users || []

            return users.length > 3
        },

        searchRoute () {
            const routeTo = { name: 'search' }

            if (this.query) {
                routeTo.query = { query: this.query }
            }

            return routeTo
        }
    },

    methods: {
        onClickAllUsers () {
            this.$pivik.event('menu', 'search', 'all_users')
        }
    }
}
</script>

<style lang="stylus" scoped>
    .search-results {
        &__group {
            padding 1.8rem 2rem

            & + & {
                border-top 1px solid rgba($cl-secondary, 0.3)
            }
        }

        &__title {
            font-size 1.8rem
            line-height 2.4rem
            font-weight $font-weight-bold
            margin-bottom 1.3rem
            color $cl-text-dark
        }

        &__item {
            & + & {
                margin-top 1.4rem
            }
        }

        &__more {
            text-align center
            font-size 1.6rem
            margin-top 1.2rem
        }

        &__empty {
            display block
            color $cl-secondary
            text-align center
            padding 1.5rem
        }
    }
</style>
