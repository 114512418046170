<template>
    <UiAccordion
        :opened="opened"
        class="links"
    >
        <template #header>
            <div
                class="links__type"
                @click.stop="open"
            >
                {{ $t(type) }}
            </div>
        </template>

        <LazyHydrate when-visible>
            <div class="links__list">
                <div
                    v-for="item in list"
                    :key="item.key"
                    class="links__item"
                >
                    <router-link
                        :to="item.route"
                        class="links__title"
                        @click.native="onClickItem(type, item)"
                    >
                        {{ item.title }}
                    </router-link>

                    <template v-if="type === 'live'">
                        <span
                            v-if="item.status && active(item.status)"
                            class="links__live"
                        >
                            &nbsp;live
                        </span>

                        <span
                            v-else
                            class="links__time-prefix"
                        >
                            {{ $t('state') }}
                            <UiTimer
                                :time="item.time"
                                not-seconds
                                server-timestamp
                            />
                        </span>
                    </template>
                </div>

                <router-link
                    v-if="overallLink"
                    class="links__all"
                    :to="overallLink.route"
                    @click.native="sendEvent"
                >
                    {{ overallLink.title }}
                </router-link>
            </div>
        </LazyHydrate>
    </UiAccordion>
</template>

<i18n>
{
    "ru": {
        "live": "лайв",
        "matches": "матчи дня",
        "tipsters": "прогнозы экспертов",
        "tournaments": "турниры",
        "bookmakers": "букмекеры",
        "predictions-pages": "Прогнозы",
        "blogs": "",
        "all-matches": "Все матчи",
        "tipsters-rating": "Рейтинг капперов",
        "bookmakers-rating": "Все букмекеры",
        "read-blog": "Перейти в блог",
        "state": "через"
    },
    "en": {
        "live": "live",
        "matches": "matches",
        "tipsters": "tipsters",
        "tournaments": "tournaments",
        "bookmakers": "bookmakers",
        "predictions-pages": "",
        "blogs": "blog",
        "all-matches": "Matches",
        "tipsters-rating": "Rankings",
        "bookmakers-rating": "Bookmakers",
        "read-blog": "More articles",
        "state": "to kick-off"
    }
}
</i18n>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { isActive } from '@/utils/match'
import {
    listTypes,
    overallLinkTitlesMap,
    overallRoutesMap,
    overallClickEvents
} from './constants'

export default {
    name: 'TheFooterLinksItem',
    components: {
        LazyHydrate
    },

    props: {
        /**
         * список
         */
        list: {
            type: Array,
            default: () => []
        },

        /**
         * тип списка ['live', 'matches', 'tipsters', 'tournaments', 'bookmakers', 'blogs', 'predictions-pages']
         */
        type: {
            type: String,
            default: '',
            validator (value) {
                return listTypes.includes(value)
            }
        }
    },

    data () {
        return {
            opened: true
        }
    },

    computed: {
        isMatches () {
            return this.type === 'live' || this.type === 'matches'
        },

        overallLink () {
            if (!this.overallLinkVisible) {
                return null
            }

            return {
                title: this.$t(overallLinkTitlesMap[this.type]),
                route: overallRoutesMap[this.type]
            }
        },

        overallLinkVisible () {
            return !!overallLinkTitlesMap[this.type]
        }
    },

    mounted () {
        this.opened = ['md', 'l'].includes(this.$mq)
    },

    methods: {
        onClickItem (type, item) {
            if (this.hasAnalitics(type)) {
                this.sendEventByType(type, item)
            }
        },

        hasAnalitics (type) {
            return [
                'live',
                'matches',
                'tipsters',
                'tournaments',
                'bookmakers',
                'predictions-pages'
            ].includes(type)
        },

        sendEvent () {
            this.$pivik.event('footer', 'click', overallClickEvents[this.type])
        },

        sendEventByType (type, item) {
            let eventName = ''
            let eventValue = item.id || item.route.params.id

            if (type === 'matches' || type === 'live') {
                eventName = 'match'

                if (item.webname) {
                    eventValue += `__${item.webname}`
                }
            } else if (type === 'tipsters') {
                eventName = 'user'
            } else if (type === 'tournaments') {
                eventName = 'tournament'
            } else if (type === 'bookmakers') {
                eventName = 'bookie'
                eventValue = item.title
            } else if (type === 'predictions-pages') {
                eventName = 'predictions-pages'
            }

            this.$pivik.event('footer', 'click', eventName, eventValue)
        },

        open () {
            if (window.innerWidth < 600) {
                this.opened = !this.opened
            }
        },

        active (id) {
            return isActive(id)
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus" scoped>
    .links {
        &__type {
            font-size 1.6rem
            color $cl-secondary
            letter-spacing 0.4px
            text-transform uppercase
            padding 1.2rem 0
        }
        &__list {
            display flex
            flex-direction column
            align-items flex-start
            +layout-xs() {
                align-items center
                padding 2rem
            }
        }
        &__item {
            line-height 2rem
            margin-bottom 1.4rem
            +layout-xs() {
                text-align center
            }
        }
        &__all {
            color $cl-info
            text-decoration none
            border-bottom-color transparent
            transition border-bottom-color 0.2s, color 0.2s

            &:active,
            &:focus,
            &:hover {
                border-bottom 1px solid rgba(@color, 0.3)
                text-decoration none
            }

            &.is-active,
            &.router-link-exact-active {
                border-bottom-color transparent
                text-decoration none
            }
        }
        &__live {
            color $cl-error
        }
        &__title {
            color $cl-secondary-light
            text-decoration none
            border-bottom-color transparent
            transition border-bottom-color 0.2s, color 0.2s

            &:active,
            &:focus,
            &:hover {
                border-bottom 1px solid rgba(@color, 0.3)
                text-decoration none
            }

            &.is-active,
            &.router-link-exact-active {
                border-bottom-color transparent
                text-decoration none
            }
            +layout-xs() {
                text-align center
            }
        }
        &__time-prefix {
            color #95979D
        }
        >>> .ui-timer {
            display inline-block
            color #95979D
            font-family $font-family
        }

        >>> .ui-accordion__arrow .ui-icon {
            display none
            color #30343E
        }
        >>> .ui-accordion__header {
            +layout-xs() {
                text-align center
            }
        }
        +layout-xs() {
            >>>.ui-accordion__header {
                margin-bottom 0
            }
            >>> .ui-accordion__arrow .ui-icon {
                display block
            }
        }
    }

    link-style()
        text-decoration none
        border-bottom-color transparent
        transition border-bottom-color 0.2s color 0.2s

        &:active,
        &:focus,
        &:hover {
            border-bottom 1px solid rgba(@color, 0.3)
            text-decoration none
        }

        &.is-active,
        &.router-link-exact-active {
            border-bottom-color transparent
            text-decoration none
        }
</style>
