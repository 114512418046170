<template>
    <UiPopup ref="popup">
        <div class="popup-report">
            <PopupReportContent
                :pending="pending"
                @report="report"
            />
        </div>
    </UiPopup>
</template>

<script>
import PopupReportContent from '@/components/Popups/Report/PopupReportContent.vue'

export default {
    name: 'PopupReport',

    components: {
        PopupReportContent
    },

    data () {
        return {
            callback: null,
            pending: false
        }
    },

    mounted () {
        this.$root.$on('report', this.openPopup)
    },

    beforeDestroy () {
        this.$root.$off('report')
    },

    methods: {
        openPopup (callback) {
            this.callback = callback
            this.$pivik.event('complaint', 'click_icon')
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        },

        async report (reason) {
            this.pending = true

            try {
                if (this.callback) {
                    await this.callback(reason)
                }

                this.$pivik.event('complaint', reason.toLowerCase())
            } catch (e) {
                this.$root.$emit('popup-error')
            }

            this.pending = false
            this.close()
        }
    }
}
</script>

<style lang="stylus" scoped>
.popup-report
    padding 3rem

    +layout-xs()
        padding 2rem
</style>
