<template>
    <footer class="footer">
        <div class="footer__top">
            <TheFooterSocials />
            <BlockApps place="footer" />
        </div>

        <div class="footer__sep" />

        <TheFooterLinks />

        <div class="footer__bottom">
            <div class="footer__rules">
                <TheFooterLanguages />

                <TheFooterMenu class="footer__menu" />

                <span class="footer__copyright"> {{ copyright }} </span>
            </div>

            <TheFooterGambling
                v-if="isEn"
                class="footer__gambling"
            />
        </div>
    </footer>
</template>

<i18n>
{
    "ru": {
        "copyright": "© 2017-{currentYear} Betting Insider — социальная сеть для любителей ставок"
    },
    "en": {
        "copyright": "© 2017-{currentYear} Betting Insider — social network for tipsters"
    }
}
</i18n>

<script>
import BlockApps from '@/components/common/apps/index.vue'
import TheFooterSocials from '@/components/TheFooter/TheFooterSocials.vue'
import TheFooterLanguages from '@/components/TheFooter/TheFooterLanguages.vue'
import TheFooterMenu from '@/components/TheFooter/TheFooterMenu.vue'
import TheFooterGambling from '@/components/TheFooter/TheFooterGambling.vue'
import TheFooterLinks from '@/components/TheFooter/TheFooterLinks.vue'
import footerStore from '@/store/modules/footer'
import { IS_BETTING_COM } from '@/constants'

export default {
    name: 'TheFooter',

    components: {
        BlockApps,
        TheFooterSocials,
        TheFooterLanguages,
        TheFooterMenu,
        TheFooterGambling,
        TheFooterLinks
    },

    storeModules: {
        footer: footerStore
    },

    asyncData ({ store }) {
        const promises = [
            store.dispatch('footer/fetchMatches', { hour_range: 24 }),
            store.dispatch('footer/fetchMatches', { count_live: 5 })
        ]

        if (IS_BETTING_COM) {
            promises.push(store.dispatch('footer/fetchPost'))
        }

        return Promise.all(promises)
            .catch(() => {})
    },

    computed: {
        isEn () {
            return this.$i18n.locale === 'en'
        },

        copyright () {
            const currentYear = new Date().getFullYear()

            return this.$t('copyright', { currentYear })
        }
    }
}
</script>

<style lang="stylus" scoped>
.footer
    font-size 1.4rem
    background-color $cl-text-dark
    color $cl-white

    &__top
        margin 0 auto
        max-width 118rem
        padding 2.5rem 3rem
        display flex
        justify-content space-between
        align-items center

        +layout-sm()
            flex-direction column

    &__sep
        width 100%
        height 1px
        background #30343E

    &__bottom
        background-color #30343E

    &__rules
        padding 1.5rem 2.4rem
        display grid
        grid-template-columns max-content 1fr max-content
        align-items center
        grid-column-gap 6rem

        +layout-md()
            justify-content center
            grid-row-gap .8rem
            grid-template-columns min-content max-content

        +layout-xs()
            grid-row-gap 1.5rem
            grid-template-columns auto
            grid-template-rows max-content 1fr max-content
            padding 1.5rem 2.5rem 2.5rem 2.5rem

    &__copyright
        color #666D74
        text-align center

        +layout-md()
            grid-column 1 / 3
            grid-row 2

        +layout-xs()
            grid-column 1
            grid-row 3

    &__gambling
        padding 1.5rem 2.4rem
        border-top solid 1px $cl-text-dark
</style>
