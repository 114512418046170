<template>
    <router-link
        :to="routeTo"
        class="search-preview-match"
        @click.native="onClick"
    >
        <span class="search-preview-match__avatar">
            <UiAvatar
                class="search-preview-match__logo"
                size="xs"
                type="team"
                :src="homeTeam.logo"
            />
            <UiAvatar
                class="search-preview-match__logo"
                size="xs"
                type="team"
                :src="awayTeam.logo"
            />
        </span>
        <span class="search-preview-match__description">
            <span class="search-preview-match__name">
                <span>{{ homeTeam.name }}</span>
                <span> — </span>
                <span>{{ awayTeam.name }}</span>
            </span>
            <span class="search-preview-match__info">
                <UiDate
                    v-if="data.startTime"
                    format="fulldate"
                    :timestamp="data.startTime"
                    itemprop="startDate"
                />
                <span v-if="data.startTime && data.tournament"> | </span>
                <span v-if="data.tournament">{{ data.tournament.name }}</span>
            </span>
        </span>
    </router-link>
</template>

<script>
export default {
    name: 'SearchResultsMatch',

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        routeTo () {
            return {
                name: 'match',
                params: {
                    id: this.data.id
                }
            }
        },

        homeTeam () {
            return this.data.homeTeam && this.data.homeTeam.team ? this.data.homeTeam.team : {}
        },

        awayTeam () {
            return this.data.awayTeam && this.data.awayTeam.team ? this.data.awayTeam.team : {}
        }
    },

    methods: {
        onClick () {
            let matchId = this.data.id

            if (this.data.webname) {
                matchId += `__${this.data.webname}`
            }

            this.$pivik.event('menu', 'search', 'match', matchId)
        }
    }
}
</script>

<style lang="stylus" scoped>
    $shadowColor = #fff

    .search-preview-match {
        display flex
        align-items center
        justify-content flex-start
        color $cl-text-dark
        text-decoration none

        &__description {
            margin-left 1rem
            overflow hidden
            position relative
            flex 1 1
            &:after {
                content ""
                position absolute
                top 0
                right -2rem
                bottom 0
                width 7.5rem
                background-image linear-gradient(90deg, rgba($shadowColor,0.00) 1%, rgba($shadowColor,0.04) 7%, rgba($shadowColor,0.19) 19%, rgba($shadowColor,0.47) 38%, rgba($shadowColor,0.85) 59%, $shadowColor 76%)
            }
        }

        &__name {
            font-size 1.8rem
            line-height 2.4rem
            white-space nowrap
            display block
            +layout-sm() {
                font-size 1.6rem
                line-height 2.1rem
            }
        }

        &__info {
            color $cl-secondary
            font-size 1.6rem
            line-height 2.1rem
            display block
            white-space nowrap
            +layout-sm() {
                font-size 1.4rem
                line-height 1.9rem
            }
        }

        &__avatar {
            display flex
            margin-left -0.5rem
        }

        &__logo {
            border 1px solid #EDEFF1
            position relative
            z-index 2
            &:nth-child(2) {
                margin-left -1.6rem
                z-index 1
            }
        }
    }
</style>
