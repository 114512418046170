import Api from '@/api'
import Vue from 'vue'

import { analyticsEvent } from '@/plugins/pivik'
import facebookSDK from '@/utils/facebook'
import googleSDK from '@/utils/google'
import localStorage from '@/utils/localstorage'
import { getSessionId, setSessionId } from '@/utils/session-storage'
import vkontakteSDK from '@/utils/vkontakte'

import {
    authByEmail,
    authOneTap, changePassword, confirmEmail, confirmPhoneNumber, fetchCountriesPhoneCodes, forgotPassword, sendEmailConfirmationToken, sendPhoneConfirmationCode, signUpByEmail
} from '@/api/graphql/auth'

import { RESET_MY_PROFILE_ID } from '@/store/modules/profile'
import { getCrossDomainAuthUrl } from '@/utils/get-cross-domain-auth-url'

const isProd = process.env.NODE_ENV === 'production'

const ONE_TAP_CLOSE = 'ONE_TAP_CLOSE'
const ONE_TAP_SHOW = 'ONE_TAP_SHOW'
const ONE_TAP_CHECKED = 'ONE_TAP_CHECKED'

const ONE_TAP_CLOSED_KEY = 'betting:one-tap:closed'
const TIME_TO_SHOW_ONE_TAP_AFTER_CLOSE = 2 * 60 * 60


function getSDK (socialName) {
    if (socialName === 'vkontakte') {
        return vkontakteSDK
    }

    if (socialName === 'facebook') {
        return facebookSDK
    }

    if (socialName === 'google') {
        return googleSDK
    }

    return null
}

function getSocialMutation (socialName) {
    if (socialName === 'vkontakte') {
        return 'authByVk'
    }

    if (socialName === 'facebook') {
        return 'authByFacebook'
    }

    if (socialName === 'google') {
        return 'authByGoogle'
    }

    return ''
}

/**
 * Получение домена из урла (удаляет https://, слеши и тд)
 * @param url - урл сайта
 * @return {string}
 */
function getDomainFromUrl (url) {
    const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i)

    return matches && matches[1]
}

export default {
    namespaced: true,

    getters: {
        isAuth: state => state.isAuth,
        isChecked: state => state.checked,
        userId: state => state.userId,
        hasOneTapFinished: state => (!state.oneTapMustShow && state.oneTapChecked) || state.oneTapClosed,
        oneTapMustShow: state => state.oneTapMustShow,
        phoneCodes: state => state.countryPhoneCodes.map(item => ({
            id: item.countryCode,
            code: item.phoneCode,
            name: item.countryName
        }))
    },

    state () {
        return {
            /**
             * Состояние авторизации
             * type: Boolean
             */
            isAuth: false,

            userId: '',

            /**
             * Проверенна ли авторизация
             */
            checked: false,

            account: {
                confirmed: false,
                phoneConfirmed: false,
                id: '',
                type: ''
            },

            oneTapClosed: false,

            oneTapMustShow: true,

            oneTapChecked: false,

            countryPhoneCodes: []
        }
    },

    actions: {
        setApmUserContext ({ state, rootGetters }, shouldClear = false) {
            if (Vue.prototype.$apm) {
                const data = {
                    id: '',
                    username: '',
                    email: ''
                }

                if (!shouldClear) {
                    const myProfile = rootGetters['profile/my']

                    data.id = state.userId || ''
                    data.email = (state.account && state.account.id) || ''
                    data.username = (myProfile && myProfile.name) || ''
                }

                Vue.prototype.$apm.setUserContext(data)
            }
        },

        fetchProfile ({ state, dispatch }) {
            return dispatch('profile/fetchProfile', { id: state.userId, isMy: true }, { root: true })
        },

        async createPassword ({ commit, dispatch }, { token, password }) {
            const queryString = changePassword({ token, password })
            const result = await Api.graphql(queryString, { type: 'mutation' })

            const session = (result && result.changePassword && result.changePassword.session) || {}

            commit('session', { userId: session.userID, sessionId: session.token })

            await dispatch('check')
        },

        async sendConfirmEmail ({ state }) {
            const queryString = sendEmailConfirmationToken({
                email: state.account.id,
                source: getDomainFromUrl(process.env.DOMAIN_URL)
            })

            await Api.graphql(queryString, { type: 'mutation' })
        },

        async confirmEmail ({ commit, dispatch }, { token }) {
            const queryString = confirmEmail({ token })
            const result = await Api.graphql(queryString, { type: 'mutation' })

            const session = (result && result.confirmEmail && result.confirmEmail.session) || {}

            // Автоматически авторизовываем пользователя после подтвержления почты
            commit('session', { userId: session.userID, sessionId: session.token })

            await dispatch('check')
        },

        sendConfirmPhone (context, { phoneNumber }) {
            const queryString = sendPhoneConfirmationCode({ phoneNumber })

            return Api.graphql(queryString, { type: 'mutation' })
        },

        confirmPhone ({ dispatch }, { phoneNumber, code }) {
            const queryString = confirmPhoneNumber({ phoneNumber, code })

            return Api.graphql(queryString, { type: 'mutation' })
                .then(result => {
                    dispatch('check')

                    return result
                })
        },

        async fetchCountriesPhoneCodes ({ state, commit }) {
            if (!state.countryPhoneCodes.length) {
                const queryString = fetchCountriesPhoneCodes()

                const result = await Api.graphql(queryString)

                if (result && result.list) {
                    commit('setCountryCodes', result.list)
                }
            }

            return Promise.resolve()
        },

        async check ({ commit, state, dispatch }) {
            const sessionId = getSessionId()

            if (sessionId) {
                try {
                    const { validateToken: data } = await Api.graphql(`{
                        validateToken (token: "${sessionId}") {
                            session { token userID }
                            accounts { id type confirmed phoneConfirmed }
                        }
                    }`, {
                        instant: true,
                        type: 'query'
                    })

                    commit('account', data.accounts[0])
                    commit('session', {
                        userId: data.session.userID,
                        sessionId: data.session.token
                    })

                    if (!state.isAuth) {
                        await dispatch('fetchProfile')

                        dispatch('favorites/fetchFavorites', {}, { root: true })
                    }

                    commit('auth', true)
                } catch (e) {
                    dispatch('signOut')
                }
            }

            dispatch('setApmUserContext')
            commit('checked')
        },

        checkCrossDomainAuth ({ commit, dispatch }) {
            // Выключили кросс-доменную авторизацию для повышения производительности.
            // Если к 01.06.2022 никто про нее не вспомнит, нужно пушить ее полный выпил.
            const iframe = document.createElement('iframe')
            const src = getCrossDomainAuthUrl()

            const { userAgent } = window.navigator
            const isSafari = userAgent.indexOf('like Gecko) Version/') >= 0

            if (!isProd || !src || isSafari) {
                dispatch('setApmUserContext')
                commit('checked')

                return
            }

            iframe.setAttribute('src', src)

            const messageHandler = message => {
                if (message.source !== iframe.contentWindow || src.indexOf(message.origin)) {
                    return
                }

                if (message && message.data && message.data.event === 'user:auth') {
                    const { sessionId } = message.data

                    if (sessionId) {
                        commit('session', { sessionId })
                        dispatch('check')
                    } else {
                        dispatch('setApmUserContext')
                        commit('checked')
                    }

                    document.body.removeChild(iframe)
                    window.removeEventListener('message', messageHandler)
                }
            }

            window.addEventListener('message', messageHandler)
            document.body.appendChild(iframe)
        },

        async remind (ctx, { email }) {
            const queryString = forgotPassword({
                email,
                source: getDomainFromUrl(process.env.DOMAIN_URL)
            })

            await Api.graphql(queryString, { type: 'mutation' })
        },

        async social ({ commit, dispatch }, { socialName }) {
            try {
                const SDK = getSDK(socialName)
                const mutation = getSocialMutation(socialName)

                if (SDK) {
                    const loginSocialData = await SDK.login()

                    let data = `token: "${loginSocialData.token}"`
                    /**
                     * VK авторизация работает немного по своему,
                     * она возвращает code и redirect_url
                     */
                    if (socialName === 'vkontakte') {
                        data = `code: "${loginSocialData.code}"
                                redirectUri: "${loginSocialData.redirect_uri}"`
                    }

                    const result = await Api.graphql(`{
                            ${mutation}(${data}) {
                                session {
                                    userID
                                    token
                                }
                                created
                            }
                        }`, { type: 'mutation' })

                    const resData = result[mutation]

                    commit('session', {
                        userId: resData.session.userID,
                        sessionId: resData.session.token
                    })

                    await dispatch('check')

                    return {
                        type: resData.created ? 'reg' : 'auth'
                    }
                }
            } catch (err) {
                return Promise.reject(err)
            }

            return Promise.resolve()
        },

        /**
         * Авторизация по email/password
         * @param commit
         * @param dispatch
         * @param email
         * @param password
         * @returns {Promise}
         */
        async signin ({ commit, dispatch }, { email, password }) {
            const queryString = authByEmail({ email, password })
            const result = await Api.graphql(queryString, { type: 'mutation' })

            const session = (result && result.authByEmail && result.authByEmail.session) || {}

            commit('session', { userId: session.userID, sessionId: session.token })

            await dispatch('check')
        },

        /**
         * Регистрация профиля
         * @param commit
         * @param dispatch
         * @param email
         * @param password
         * @returns {Promise}
         */
        async signup ({ commit, dispatch }, { email, password }) {
            const queryString = signUpByEmail({
                email,
                password,
                source: getDomainFromUrl(process.env.DOMAIN_URL)
            })
            const result = await Api.graphql(queryString, { type: 'mutation' })

            const session = (result && result.signUpByEmail && result.signUpByEmail.session) || {}

            commit('session', { userId: session.userID, sessionId: session.token })

            await dispatch('check')
        },

        signOut ({ commit, dispatch }) {
            commit('auth', false)
            dispatch('oneTapClearLS')
            dispatch('setApmUserContext', true)
            // Делаем задержку для того что бы карточка профиля успела перевернуться
            setTimeout(() => {
                commit(`profile/${RESET_MY_PROFILE_ID}`, null, { root: true })
                commit('session', {})
                commit('account', {})
            }, 1000)
        },

        oneTapCheck ({ commit, state }) {
            if (state.isAuth) {
                commit(ONE_TAP_SHOW, { needToShow: false })
                commit(ONE_TAP_CHECKED)

                return true
            }

            const oneTapClosedObject = localStorage.get(ONE_TAP_CLOSED_KEY)

            if (!oneTapClosedObject) {
                commit(ONE_TAP_SHOW, { needToShow: true })
            } else {
                const timeLeft = Date.now() / 1000 - oneTapClosedObject.date
                const needToShow = timeLeft > TIME_TO_SHOW_ONE_TAP_AFTER_CLOSE

                commit(ONE_TAP_SHOW, { needToShow })
            }

            commit(ONE_TAP_CHECKED)

            return true
        },

        oneTapClose ({ commit }) {
            localStorage.set(ONE_TAP_CLOSED_KEY, {
                date: Date.now() / 1000
            })

            commit(ONE_TAP_CLOSE)
        },

        oneTapClearLS () {
            localStorage.remove(ONE_TAP_CLOSED_KEY)
        },

        oneTapLogIn ({ commit, dispatch }, { credential }) {
            return Api.graphql(authOneTap(credential), { type: 'mutation' })
                .then(({ authByGoogle }) => {
                    if (authByGoogle && authByGoogle.session && authByGoogle.session.token) {
                        commit('session', {
                            userId: authByGoogle.session.userID,
                            sessionId: authByGoogle.session.token
                        })

                        analyticsEvent(
                            'social',
                            authByGoogle.created ? 'reg' : 'auth',
                            'one_tap'
                        )

                        commit(ONE_TAP_SHOW, { needToShow: false })
                        dispatch('check')
                    }
                })
        }
    },

    mutations: {
        checked (state) {
            state.checked = true
        },

        auth (state, isAuth) {
            state.isAuth = isAuth
        },

        session (state, { userId, sessionId }) {
            state.userId = userId

            // Устанавливаем авторизационный заголовок
            Api.setSid(sessionId)

            setSessionId(sessionId)
        },

        account (state, accountData = {}) {
            state.account.id = accountData.id || ''
            state.account.type = accountData.type || ''
            state.account.confirmed = accountData.confirmed || false
            state.account.phoneConfirmed = accountData.phoneConfirmed || false
        },

        [ONE_TAP_CLOSE] (state) {
            state.oneTapClosed = true
        },

        [ONE_TAP_SHOW] (state, { needToShow }) {
            state.oneTapMustShow = needToShow
        },

        [ONE_TAP_CHECKED] (state) {
            state.oneTapChecked = true
        },

        setCountryCodes (state, codes) {
            state.countryPhoneCodes = codes
        }
    }
}
