<template>
    <div class="ui-switch bi-hide-scroll">
        <div
            v-for="(item, i) in items"
            :key="i"
            :class="{ 'is-active': value === item.value, 'is-disable': item.disabled }"
            class="ui-switch__item"
            @click="onSelect(item)"
        >
            <component
                :is="item.to ? 'router-link' : 'span'"
                :to="item.to"
                :class="getLinkClass(item)"
                class="ui-switch__trigger"
            >
                {{ item.title }}
            </component>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiSwitch',

    props: {
        items: {
            type: Array,
            default: () => []
        },

        value: {
            type: [String, Number, Boolean],
            default: null
        }
    },

    methods: {
        onSelect (item) {
            if (!item.disabled) {
                this.$emit('input', item.value)
            }
        },

        getLinkClass (item) {
            if (item.disabled) {
                return 'ui-switch__item--disabled'
            }

            if (this.value === item.value) {
                return 'link-primary-inner'
            }

            return 'link-dark-inner'
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-switch {
    user-select none
    display flex

    &__item {
        cursor pointer
        text-align center
        transition border-bottom-color $swift-ease-out-duration

        &:not(:last-child) {
            margin-right 1.6rem
        }

        &.is-active {
            pointer-events none
            z-index 5
        }

        &.is-disable {
            pointer-events none
        }

        & &--disabled {
            color $cl-disabled
        }
    }

    &__trigger {
        transition padding 0.3s, background-color 0.3s
        position relative
        z-index 1
        white-space nowrap
    }
}
</style>
