export default [
    {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/chat/index.vue')
    },
    {
        path: '/match/:id/chat',
        name: 'chat-match',
        component: () => import('@/views/chat/index.vue')
    }
]
