<template>
    <div
        :class="{ 'show-more--has-secondary': !!$slots.secondary }"
        class="show-more"
    >
        <button
            v-if="!!$slots.primary"
            class="show-more__btn show-more__btn--primary bi-button"
            @click="$emit('primary-action')"
        >
            <slot name="primary" />
        </button>
        <span class="show-more__second-line">
            <button
                v-if="!!$slots.secondary"
                class="show-more__btn show-more__btn--secondary bi-button"
                @click="$emit('secondary-action')"
            >
                <slot name="secondary" />
            </button>
        </span>
    </div>
</template>

<script>
export default {
    name: 'UiShowMore'
}
</script>

<style lang="stylus" scoped>
.show-more
    position relative
    display flex
    width 100%
    z-index 1
    overflow hidden
    text-align center
    padding-bottom .4rem
    padding-top .4rem
    align-items center

    &::before
        content ''
        flex 1 1 100%
        width 100%
        height 2px
        background $cl-secondary-light
        z-index 1
        margin 1.2rem 0

    &__second-line
        flex 1 1 100%
        width 100%
        display flex
        align-items center

        &::before
            content ''
            display block
            flex 1 1 100%
            width 100%
            height 2px
            background $cl-secondary-light
            z-index 1

    &__btn
        cursor pointer
        display inline-block
        user-select none
        white-space nowrap
        padding-left 2rem
        padding-right 2rem
        border none
        background transparent
        height 3rem
        outline none

        &--primary
            color $cl-link

        &--secondary
            color $cl-secondary

    @media (max-width: $breakpoint-xs)
        &--has-secondary::before
            display none
</style>
