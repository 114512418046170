function match ({ firstOddsCount } = {}) {
    return `
      id
      sport {
        code
      }
      title
      dateStart
      state
      currentMinute
      result {
        homeScore
        awayScore
        summaryText
        winType
        winnerType
      }
      homeTeam {
        team {
          name
        }
      }
      awayTeam {
        team {
          name
        }
      }
      betsCount
      odds ${firstOddsCount ? `(first: ${firstOddsCount})` : ''} {
        id
        line {
          type
          name
          description
        }
        name
        type
        resultType
        oddValue
        feedType
        rate
        fullName
      }
      tournament {
        id
        name
        customName
      }
`
}

export function fetchSports () {
    return `{
        sports {
            code
            name
            h1
            description
            title
        }
    }`
}

export function fetchTournaments ({ sportId, date, timezone, isLive = false }) {
    return `{
        newSportTournamentsConnection (sportID: "${sportId}") {
          edges {
            node {
              id
              name
              customName
              matches (
                date: "${date}"
                timezone: "${timezone}"
                first: 999
                filter: { isLive: ${isLive} }
              ) {
                edges {
                  node {
                    ${match({ firstOddsCount: 10 })}
                  }
                }
              }
            }
          }
        }
    }`
}

export function createNewSportsBet ({ oddsID, amount, rate, description, parentID }) {
    return `{betMutations {createNewSportBet(
      bet: {
        oddsID: "${oddsID}"
        amount: ${amount}
        rate: ${rate}
        ${description ? `description: "${description}"` : ''}
        ${parentID ? `parentID: "${parentID}"` : ''}
      }
    ) {
        id user {id balance}
      }
    }}`
}

export function updateNewSportBet ({ id, description, top, topInMatch, facts = [] }) {
    return `{
      betMutations {
        updateNewSportBet(bet: {
          id: "${id}",
          description: "${description}",
          top: ${top},
          topInMatch: ${topInMatch},
          facts: [${facts.length ? `"${facts.join('","')}"` : ''}]
        }) {id description top topInMatch facts}
      }
    }`
}

export function fetchMatch ({ id }) {
    return `{
      newSportMatch(matchID: ${id}) {
        ${match()}
      }
    }`
}
