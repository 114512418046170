import Api from '@/api'
import Vue from 'vue'
import logger from '@/utils/logger'

import {
    fetchList,
    fetchItem,
    fetchListIds
} from '@/api/graphql/blog'
import { fetchPostIdsRecommendations } from '@/api/graphql/post-recommendations'

import isArray from '@/utils/isArray'
import localStorage from '@/utils/localstorage'
import {
    TEN_MINUTES_CACHE_CONTROL,
    THIRTY_MINUTES_CACHE_CONTROL,
    TWENTY_FOUR_HOURS_CACHE_CONTROL
} from '@/constants'

const ONE_DAY_TIME_MS = 24 * 60 * 60 * 1000
const ONE_WEEK_TIME_MS = ONE_DAY_TIME_MS * 7

const NUMBER_OF_POST_IDS_TO_LOAD = 100
const RECOMMENDATIONS_POST_FEED_TEST_KEY = 'betting:split-test:recommendations-post-feed:group'

function getCacheControlValue (ctime) {
    const timeDiff = Date.now() - (ctime * 1000)

    if (timeDiff < ONE_DAY_TIME_MS) {
        return TEN_MINUTES_CACHE_CONTROL
    }

    if (timeDiff > ONE_DAY_TIME_MS && timeDiff < ONE_WEEK_TIME_MS) {
        return THIRTY_MINUTES_CACHE_CONTROL
    }

    return TWENTY_FOUR_HOURS_CACHE_CONTROL
}

function postAdapter (post) {
    // согласно BIW-54 меняем нейминг только на фронте для раздела "Исследования"
    // на бэке это 'research' на фронте теперь 'bettingdomik'
    if (post.subsection === 'research') {
        post.subsection = 'bettingdomik'
    }

    return post
}

function postsListAdapter (posts) {
    if (isArray(posts)) {
        return posts.map(postAdapter)
    }

    return posts
}

export default {
    namespaced: true,

    state () {
        return {
            feed: {
                items: [],
                count: 20,
                pageInfo: {
                    totalCount: 0,
                    hasNextPage: false,
                    endCursor: null
                }
            },
            postList: [],

            postsFeedTestGroup: '',
            postIdsToLoadNext: [],
            usedPostsIds: {},
            postFeedPageInfo: {
                hasNextPage: true,
                endCursor: null
            },

            errors: {
                feed: false
            }
        }
    },

    getters: {
        posts (state) {
            return state.feed.items
        },
        postsTotalPages (state) {
            return Math.ceil(state.feed.pageInfo.totalCount / state.feed.count)
        },
        postList (state) {
            return state.postList
        },
        isRecommendationsFeed (state) {
            return state.postsFeedTestGroup === 'test'
        }
    },

    actions: {
        /**
         * Получаем ленту блога
         *
         * @param options - опции запроса
         * @param options.reset - очистить предыдущее состояние списка
         * @returns {Promise}
         * @param page
         * @param section
         * @param subsection
         */
        async fetchList ({ commit, state }, { page = 1, section, subsection }) {
            try {
                const after = page === 1 ? null : (page - 1) * state.feed.count

                const queryString = fetchList({
                    first: state.feed.count,
                    after,
                    section,
                    subsection
                })

                const result = await Api.graphql(`{${queryString}}`)

                result.list = postsListAdapter(result.list)

                commit('feed', result.list)
                commit('pageInfo', result.pageInfo)

                return result.list
            } catch (err) {
                logger.error(err)

                return Promise.reject(err)
            }
        },

        async fetchNextPost ({ commit, dispatch }) {
            const nextId = await dispatch('getNextPostId')
            const queryString = fetchItem({ postId: nextId })

            let nextPost = await Api.graphql(queryString)

            if (nextPost) {
                nextPost = postAdapter(nextPost)

                commit('postAdd', nextPost)
                commit('addUsedPostId', nextPost.id)

                return nextPost
            }
        },

        async fetchItem ({ commit, state }, { id }) {
            let fetchedPost = state.postList.find(post => post.id === id)

            if (!fetchedPost) {
                try {
                    const queryString = fetchItem({ postId: id })
                    const result = await Api.graphql(queryString)

                    fetchedPost = postAdapter(result)
                } catch (err) {
                    return Promise.reject({ code: 404 })
                }
            }

            commit('postSet', fetchedPost)
            commit('addUsedPostId', id)

            return fetchedPost
        },

        fetchFeedPostIds ({ getters, dispatch }) {
            if (getters.isRecommendationsFeed) {
                return dispatch('fetchRecommendationsFeedPostIds')
            }

            return dispatch('fetchRegularFeedPostIds')
        },

        async fetchRecommendationsFeedPostIds ({ state, rootState, dispatch, commit }) {
            dispatch('widgets/postRecommendations/checkUserClientId', null, { root: true })

            const clientId = rootState['widgets/postRecommendations'].userClientId

            const queryString = fetchPostIdsRecommendations({
                first: NUMBER_OF_POST_IDS_TO_LOAD,
                // top = first x 4 обеспечивает шаффлинг рекоммендаций
                top: NUMBER_OF_POST_IDS_TO_LOAD * 4,
                clientId
            })

            const result = await Api.graphql(queryString)

            if (result && result.length) {
                let postIds = result.map(item => item.id)

                postIds = postIds.filter(id => !state.usedPostsIds[id])

                commit('setPostIdsToLoadNext', postIds)
            }
        },

        async fetchRegularFeedPostIds ({ state, commit }) {
            const queryString = fetchListIds({
                first: NUMBER_OF_POST_IDS_TO_LOAD,
                after: state.postFeedPageInfo.endCursor,
                section: 'blog'
            })

            const result = await Api.graphql(queryString)

            if (result) {
                if (result.list) {
                    let postIds = result.list.map(item => item.id)

                    postIds = postIds.filter(id => !state.usedPostsIds[id])

                    commit('setPostIdsToLoadNext', postIds)
                }

                if (result.pageInfo) {
                    commit('setPostFeedPageInfo', result.pageInfo)
                }
            }
        },

        async getNextPostId ({ state, commit, dispatch }) {
            let postId = state.postIdsToLoadNext[0]

            if (!postId) {
                await dispatch('fetchFeedPostIds');

                [postId] = state.postIdsToLoadNext
            }

            commit('setPostIdsToLoadNext', state.postIdsToLoadNext.slice(1))

            return postId
        },

        checkPostsFeedTestGroup ({ commit }) {
            let group = localStorage.get(RECOMMENDATIONS_POST_FEED_TEST_KEY)

            if (!group) {
                group = Math.random() > 0.5 ? 'control' : 'test'
                localStorage.set(RECOMMENDATIONS_POST_FEED_TEST_KEY, group)
            }

            commit('setPostsFeedTestGroup', group)
        },

        setPostCacheControl ({ dispatch }, post = {}) {
            const cacheControl = getCacheControlValue(post.ctime)

            dispatch('page/setCacheControlHeader', { value: cacheControl }, { root: true })
        },

        setHasError ({ commit }, { key, value }) {
            commit('setHasError', { key, value })
        }
    },

    mutations: {
        postSet (state, data) {
            Vue.set(state, 'postList', [data])
        },
        postAdd ({ postList }, data) {
            postList.push(data)
        },
        feed (state, list) {
            state.feed.items = list
        },
        pageInfo (state, pageInfo = {}) {
            state.feed.pageInfo = pageInfo
        },
        addUsedPostId ({ usedPostsIds }, postId) {
            usedPostsIds[postId] = true
        },
        setPostIdsToLoadNext (state, postIdsToLoadNext) {
            state.postIdsToLoadNext = postIdsToLoadNext
        },
        setPostFeedPageInfo (state, postFeedPageInfo) {
            state.postFeedPageInfo = postFeedPageInfo
        },
        setPostsFeedTestGroup (state, postsFeedTestGroup) {
            state.postsFeedTestGroup = postsFeedTestGroup
        },

        setHasError (state, { key, value }) {
            state.errors[key] = value
        }
    }
}
