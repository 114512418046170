<template>
    <div
        v-if="editMode"
        class="curtain"
        :class="{ 'curtain--header-has-ad': isDesktopBrandingVisible }"
    >
        <div class="curtain__controls">
            <button
                class="curtain__btn curtain__btn--toggle-curtain"
                @click="toggleCurtain(curtainIsOpened)"
            >
                <IconArrowRound
                    class="curtain__icon"
                    :class="{
                        'curtain__icon--rotate': !curtainIsOpened
                    }"
                />
            </button>

            <!-- <button
                class="curtain__btn curtain__btn--chat"
                :class="publicChatButtonClass"
                @click="changeMode('public-chat')"
            >
                <IconChatComment class="curtain__icon" />
            </button> -->

            <button
                v-if="editMode"
                class="curtain__btn curtain__btn--bet"
                :class="betEditButtonClass"
                @click="changeMode('bet')"
            >
                <IconOdds class="curtain__icon" />
            </button>

            <!-- <button
                class="curtain__btn curtain__btn--match-chat"
                :class="matchesChatButtonClass"
                @click="clickMatchSelector"
            >
                <IconChatMatches class="curtain__icon" />
            </button> -->
        </div>

        <div
            class="curtain__content"
            :class="{
                'curtain__content--show': curtainIsOpened
            }"
        >
            <!-- <ChatWrapper v-show="isEuroChat || isMatchChat || isPublicChat || isMatchSelector" /> -->

            <BetEditor v-if="isBetEditor" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import IconArrowRound from '@/components/icons/arrow-round.vue'
// import IconChatComment from '@/assets/svg/comment.icon.svg'
// import IconChatMatches from '@/assets/svg/chat-matches.icon.svg'
// import ChatWrapper from '@/components/Chat/ChatWrapper.vue'
import BetEditor from '@/components/Bet/BetEditor.vue'

export default {
    name: 'Curtain',

    components: {
        IconArrowRound,
        // IconChatComment,
        // IconChatMatches,
        // ChatWrapper,
        BetEditor
    },

    data () {
        return {
            existNewMessage: false
        }
    },

    computed: {
        ...mapState('bet/select', ['editMode']),
        ...mapState('chat', ['messages']),

        ...mapState('curtain', {
            curtainIsCustom: 'custom',
            curtainIsOpened: 'isOpened',
            savedMatchSelector: 'savedMatchSelector'
        }),

        ...mapState('chat/matches', {
            isChatMatchesChanged: 'isChanged',
            selectedMatch: 'selectedMatch'
        }),

        ...mapGetters('curtain', [
            'isPublicChat',
            'isMatchChat',
            'isEuroChat',
            'isMatchSelector',
            'isBetEditor'
        ]),

        ...mapGetters('ads', ['isDesktopBrandingVisible']),

        publicChatButtonClass () {
            const hasNotification = this.existNewMessage && (!this.curtainIsOpened || !this.isPublicChat)

            return {
                'curtain__btn--notification': hasNotification,
                'curtain__btn--disabled': this.curtainIsOpened && this.isPublicChat
            }
        },

        betEditButtonClass () {
            const hasNotification = this.editMode && (!this.curtainIsOpened || this.isPublicChat)

            return {
                'curtain__btn--notification': hasNotification,
                'curtain__btn--disabled': this.curtainIsOpened && this.isBetEditor
            }
        },

        matchesChatButtonClass () {
            return {
                'curtain__btn--notification': this.isChatMatchesChanged,
                'curtain__btn--disabled': this.curtainIsOpened && (this.isMatchSelector || this.isMatchChat)
            }
        }
    },

    watch: {
        curtainIsOpened (newValue) {
            const eventValue = newValue ? 'open' : 'close'

            this.$pivik.event('right_block', eventValue)
        },

        messages () {
            this.existNewMessage = !this.curtainIsOpened
        },

        $route (route) {
            if (['match', 'predictions-item'].includes(route.name)) {
                if (
                    this.isMatchSelector
                    || (this.selectedMatch && this.selectedMatch.id === this.$route.params.id)
                ) {
                    return
                }

                this.setChatMatchesChangedFlag(true)
            }
        }
    },

    mounted () {
        // if (this.savedMatchSelector) {
        //     this.curtainChangeMode({ mode: 'match-selector' })
        // }

        // this.$nextTick(() => {
        //     if (!this.curtainIsCustom && window.innerWidth >= 1880) {
        //         this.curtainChangeOpenStatus(true)
        //     }
        // })
    },

    methods: {
        ...mapActions({
            setChatMatchesChangedFlag: 'chat/matches/setChangedFlag',
            curtainChangeOpenStatus: 'curtain/changeOpenStatus',
            curtainChangeMode: 'curtain/changeMode',
            updateBetOpenedFromReminder: 'bet/select/updateBetOpenedFromReminder'
        }),

        toggleCurtain (status) {
            if (!status) {
                this.existNewMessage = false
            }

            this.updateBetOpenedFromReminder(true)
            this.curtainChangeOpenStatus(!status)
        },

        changeMode (mode) {
            if (mode === 'public-chat') {
                this.$pivik.event('right_block', 'main-chat', 'click_icon')
            }

            if (mode === 'bet' && (!this.showContent || this.isPublicChat)) {
                this.$pivik.event('right_block', 'reminder', 'click_icon')
                this.updateBetOpenedFromReminder(true)
            }

            this.curtainChangeMode({ mode })
        },

        clickMatchSelector () {
            this.$pivik.event('right_block', 'top-matches', 'click_icon')

            // if (this.isChatMatchesChanged) {
            //     this.changeMode('match-selector')

            //     this.setChatMatchesChangedFlag(false)
            // } else {
            //     if (this.selectedMatch && !this.isMatchChat) {
            //         return this.changeMode('match-chat')
            //     }

            //     return this.changeMode('match-selector')
            // }

            return null
        }
    }
}
</script>

<style lang="stylus" scoped>
.curtain
    position fixed
    top $header-height
    right 0
    z-index 20
    height 100%
    display flex

    &--header-has-ad
        top $header-height-with-ad

        & .curtain__content
            height "calc(100vh - %s)" % ($header-height-with-ad)

    &__controls
        padding-top 2.4rem
        width 5rem
        height 100%
        display flex
        flex-direction column
        align-items center
        background $cl-white
        border-left .1rem solid $cl-secondary-light

    &__btn
        position relative
        margin-bottom 5.2rem
        padding 0
        font-size 0
        background transparent
        border 0
        outline none
        transition all .2s linear
        cursor pointer

        &:last-child
            margin-bottom 0

        &--notification::after
            content ''
            position absolute
            right 0
            top 0
            display block
            width .8rem
            height @width
            transform translate3d(40%, -20%, 0)
            border-radius 50%
            background-color $cl-error
            transition all .2s linear

        &--disabled
            cursor default
            opacity .5

        &:hover:not(&--disabled)
            &__icon
                opacity .5

    &__icon
        width 2.4rem
        height @width
        color $cl-text-dark
        transform rotateZ(0)
        transition all .2s linear

        &--rotate
            transform rotateZ(-180deg)

    &__content
        position fixed
        width 34.5rem
        height "calc(100vh - %s)" % ($header-height)
        background-color $cl-white
        transform translateX(0)
        transition .3s ease-out
        box-shadow 0 0 15px 0 rgba(186, 195, 198, .47)
        z-index -1

        &--show
            transform translateX(-100%)
</style>
