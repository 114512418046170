<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        {{ $t('text') }}

        <template slot="footer">
            <button
                class="popup__button popup__button--stretch"
                @click="close"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Матч закончился",
            "text": "Увы, вы немного не успели. Матч уже закончился.",
            "btn": "В Матч-центр"
        },
        "en": {
            "header": "The match is over",
            "text": "Sorry, you're a little late. The game is already over",
            "btn": "Back to matches"
        }
    }
</i18n>

<script>
export default {
    name: 'PopupBetTimeFinished',

    mounted () {
        this.$root.$on('popup-bet-finished', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-bet-finished', this.open)
    },

    methods: {
        open () {
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus">
.popup
    &__button
        button(5, 'contained', 'primary')

</style>
