import isValidHex from '@/utils/hex'

/**
 * адаптация цвета в пригодный для css формат
 * (добавляет # к хексу если его там нет)
 *
 * @param {String} color - цвет
 *
 * @returns {String} - провалидированный цвет
 */
export default function adaptColor (color) {
    if (!isValidHex(color)) {
        return color
    }

    if (color.substring(0, 1) === '#') {
        return color
    }

    return `#${color}`
}
