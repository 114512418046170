<template>
    <UiPopup
        ref="popup"
        class="ban-popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>
        <input
            v-model="days"
            :placeholder="$t('placeholder')"
            class="ban-popup__input"
            type="number"
        >
        <div class="ban-popup__text">
            {{ $t('text') }}
        </div>
        <template slot="footer">
            <button
                :pending="pending"
                class="ban-popup__button ban-popup__button--stretch"
                @click="ban"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
{
    "ru": {
        "header": "На сколько дней забанить?",
        "text": "Оставьте поле пустым, чтобы забанить навсегда",
        "placeholder": "Кол-во",
        "btn": "Забанить"
    },
    "en": {
        "header": "How many days to block?",
        "text": "Keep field empty for permament ban",
        "placeholder": "Days",
        "btn": "Ban"
    }
}
</i18n>

<script>
import adminStore from '@/store/modules/admin'

export default {
    name: 'PopupBan',

    storeModules: {
        admin: adminStore
    },

    data () {
        return {
            days: null,
            pending: false,
            userId: null
        }
    },

    watch: {
        days () {
            if (+this.days > 365) {
                this.days = 365
            }

            if (this.days === '0') {
                this.days = 1
            }
        }
    },

    mounted () {
        this.$root.$on('popup-ban', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-ban', this.open)
    },

    methods: {
        open ({ userId }) {
            this.userId = userId
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        },

        ban () {
            if (!this.pending) {
                this.pending = true
                const seconds = this.days > 0 ? this.days * 24 * 60 * 60 : null

                this.$store.dispatch('admin/user/ban', { id: this.userId, seconds })
                    .then(() => {
                        this.pending = false
                        this.$store.dispatch('profile/fetchProfile', { id: this.userId })
                        this.close()
                    })
                    .catch(() => {
                        this.pending = false
                        // eslint-disable-next-line no-alert
                        alert('Error')
                    })
            }
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus" scoped>
    .ban-popup
        &__input
            background-color #F9F9F9
            box-shadow inset 0 .2rem .4rem 0 rgba(0,0,0,0.1)
            -webkit-appearance none
            outline none
            border-radius .4rem
            border none
            padding .5rem 1rem
            width 11rem
            &::placeholder
                color $cl-secondary
        &__text
            margin-top 1rem
            color $cl-secondary

        &__button
            button(5, 'contained', 'primary')
</style>
