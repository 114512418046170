import Api from '@/api'
import logger from '@/utils/logger'
import { fetchMatch } from '@/api/graphql/new-sports'
import {
    FIFTEEN_MINUTES_CACHE_CONTROL,
    ONE_HOUR_CACHE_CONTROL
} from '@/constants'
import prepareLinesForMatchStat from '@/utils/prepare-lines-for-match-stat'

const SET_MATCH = 'SET_MATCH'
const SET_ODDS = 'SET_ODDS'

const DEFAULT_CACHE_CONTROL_VALUE = 'max-age=60'
const ONE_HOUR_MS = 1 * 60 * 60 * 1000
const ONE_DAY_TIME_MS = 24 * 60 * 60 * 1000

function getCacheControlValue ({
    startTimeMS
}) {
    const currentDateMS = Date.now()
    const timeUntilStartMS = startTimeMS - currentDateMS

    if (timeUntilStartMS > ONE_DAY_TIME_MS) {
        return ONE_HOUR_CACHE_CONTROL
    }

    if (ONE_DAY_TIME_MS >= timeUntilStartMS && timeUntilStartMS > ONE_HOUR_MS) {
        return FIFTEEN_MINUTES_CACHE_CONTROL
    }

    if ((currentDateMS - startTimeMS) > ONE_HOUR_MS * 3) {
        return ONE_HOUR_CACHE_CONTROL
    }

    return DEFAULT_CACHE_CONTROL_VALUE
}

export default {
    namespaced: true,

    state () {
        return {
            match: {},
            odds: []
        }
    },

    actions: {
        async fetch ({ commit }, { id }) {
            try {
                const query = fetchMatch({ id })
                const result = await Api.graphql(query)

                commit(SET_ODDS, prepareLinesForMatchStat(result.odds))

                result.odds = result.odds.slice(0, 3)

                commit(SET_MATCH, result)

                return result
            } catch (err) {
                logger.error(err)

                return Promise.reject({ code: 404 })
            }
        },

        setMatchCacheControl ({ state, dispatch }) {
            const cacheControl = getCacheControlValue(state.match.dateStart * 1000)

            dispatch('page/setCacheControlHeader', { value: cacheControl }, { root: true })
        }
    },

    mutations: {
        [SET_MATCH] (state, match) {
            state.match = match
        },

        [SET_ODDS] (state, odds) {
            state.odds = odds
        }
    }
}
