// on statTeam
function TeamStatShortFragment () {
    return `
        id
        name
        logo {
            main
        }
        lastFive {
            match {
                id
                idPerform
                ubersetzer {
                    sportsId
                    sportradarId
                    sportsTag
                }
            }
        }`
}

// on statPlayer
function StatPlayer () {
    return `
        id
        avatar{
            main
        }
        name
        firstName
        lastName
        fieldPosition
        nationality{
            name
            code
            picture(productType: TRIBUNA,format: PNG){
                main
            }
        }`
}

// on ProbableMatchLineUpPlayer
function PlayerFragment () {
    return `
        isInjured
        isSuspended
        name
        number
        player {
            id
            avatar {
                main
            }
            name
            firstName
            lastName
            fieldPosition
            nationality {
                name
                code
                picture(productType: TRIBUNA,format: PNG) {
                    main
                }
            }
        }
        position`
}

// on statLineupLine
function TeamLineUpFragment () {
    return `
        jersey_number
        position
        lineupStarting
        type
        player{
            ${StatPlayer()}
        }
        stat{
            substitutedIn
            substitutedOut
            redCards
            yellowCards
            yellowRedCards
        }`
}

// on statTeamMatch
function LineupStatTeamMatch () {
    return `
        team {
            ${TeamStatShortFragment()}
        }
        lineup {
            ${TeamLineUpFragment()}
        }
        current_lineup {
            ${TeamLineUpFragment()}
        }
        manager{
            name
            avatar{
                main
            }
            id
            firstName
            lastName
            nationality{
                name
                code
                picture(productType: TRIBUNA,format: PNG){
                    main
                }
            }
        }`
}

// запрос за основными составами
export function MatchLineups (matchId) {
    return `{
        stat_match(id: ${matchId}, source: SPORTS) {
            __typename
            home {
                ${LineupStatTeamMatch()}
            }
            away {
                ${LineupStatTeamMatch()}
            }
        }
    }`
}

// запрос за предварительными составами
export function ProbableMatchLineups (matchId) {
    return `{
        probableMatchLineUp(matchID: ${matchId}) {
            __typename
            firstTeam {
                team {
                    ${TeamStatShortFragment()}
                }
                players {
                    ${PlayerFragment()}
                }
            }
            secondTeam {
                team {
                    ${TeamStatShortFragment()}
                }
                players {
                    ${PlayerFragment()}
                }
            }
        }
    }`
}

// запрос за составами из предыдущего матча
export function LastMatchesLineups (homeMatchId, awayMatchId) {
    return `{
        homeTeamStat: stat_match(id: ${homeMatchId}, source: SPORTS){
            home{
                ${LineupStatTeamMatch()}
            }
            away{
                ${LineupStatTeamMatch()}
            }
        }
        awayTeamStat: stat_match(id:${awayMatchId}, source: SPORTS){
            home{
                ${LineupStatTeamMatch()}
            }
            away{
                ${LineupStatTeamMatch()}
            }
        }
    }`
}

export function HomeTeamLastMatchesLineups (homeMatchId) {
    return `{
        stat_match(id: ${homeMatchId}, source: SPORTS){
            home{
                ${LineupStatTeamMatch()}
            }
            away{
                ${LineupStatTeamMatch()}
            }
        }
    }`
}

export function AwayTeamLastMatchesLineups (awayMatchId) {
    return `{
        stat_match(id:${awayMatchId}, source: SPORTS){
            home{
                ${LineupStatTeamMatch()}
            }
            away{
                ${LineupStatTeamMatch()}
            }
        }
    }`
}
