<template>
    <div
        :class="{ 'is-active': active, 'is-disabled': disabled }"
        class="ui-counter"
    >
        <div
            v-if="$slots.default"
            class="ui-counter__icon"
        >
            <slot />
        </div>

        <span class="ui-counter__value t-bold">{{ value | number }}</span>
    </div>
</template>

<script>
import number from '@/filters/number'

export default {
    name: 'UiCounter',

    filters: {
        number
    },

    props: {
        value: {
            type: Number,
            default: 0
        },

        active: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-counter
    display flex
    align-items center
    cursor pointer
    user-select none
    color $cl-text-dark

    &:hover &__value,
    &:active &__value,
    &.is-active &__value,
    &.is-active &__icon
    &.is-active:hover &__value
        color $cl-primary
        opacity 1

    &:hover &__icon,
    &.is-active:hover &__icon
        color $cl-primary
        opacity .3

    &:active &__icon
        opacity 1

    &.is-active:active &__value,
    &.is-active:active &__icon
        color $cl-text-dark
        opacity 1

    &__icon
        opacity .3
        margin-right .4rem
        width 2.4rem
        height @width
        transition-property opacity, color
        transition-duration .4s
        transform-origin 0 100%

    &__value
        transition-property opacity, color
        transition-duration .4s
        font-family $font-family-content
        font-size 1.4rem
</style>
