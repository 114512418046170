export default {
    computed: {
        authorized () {
            return this.$store.state.auth.isAuth
        }
    },

    methods: {
        authOpenPopup () {
            this.$root.$emit('auth')
        },

        authSignOut () {
            this.$store.dispatch('auth/signOut')

            this.$pivik.event('social', 'logout')
        }
    }
}
