import Api from '@/api'
import logger from '@/utils/logger'
import { fetchMatchTopBets, fetchTopBets } from '@/api/graphql/statistics'
import isArray from '@/utils/isArray'

const SET_TOP_BETS = 'SET_TOP_BETS'

// Приводим данные матча из рест-апи к виду, в котором получаем аналогичные из graphql
// Нужно для совместимости с topOddsStat
function insertMatchDataToOdd (item, match) {
    item.match = {
        id: match.id,
        webname: match.webname,

        away: {
            team: {
                name: match.home_team.team.name,
                logo: {
                    resize: match.home_team.team.logo_small
                }
            }
        },

        home: {
            team: {
                name: match.away_team.team.name,
                logo: {
                    resize: match.away_team.team.logo_small
                }
            }
        }
    }
}

export default {
    namespaced: true,

    state () {
        return {
            topBets: []
        }
    },

    actions: {
        async fetchMatchTopBets ({ commit, rootState }, { id }) {
            try {
                let result = []
                const queryString = fetchMatchTopBets(id)

                const data = await Api.graphql(queryString)
                const { match } = rootState.match

                if (data && isArray(data.popularOdds)) {
                    result = data.popularOdds.map(item => {
                        insertMatchDataToOdd(item, match)

                        return item
                    })
                }

                commit(SET_TOP_BETS, result)
            } catch (err) {
                logger.error(err)

                commit(SET_TOP_BETS)
            }
        },

        async fetchTopBets ({ commit }) {
            try {
                const queryString = fetchTopBets()

                const data = await Api.graphql(queryString)

                commit(SET_TOP_BETS, data)
            } catch (err) {
                logger.error(err)

                commit(SET_TOP_BETS)
            }
        }
    },

    mutations: {
        [SET_TOP_BETS] (state, odds = []) {
            state.topBets = odds
        }
    }
}
