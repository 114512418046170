const DayTimestamp = 1000 * 60 * 60 * 24

/**
 * Получение даты
 * @param timestamp
 * @returns {Date|*}
 */
function getDate (timestamp) {
    if (typeof timestamp === 'number') {
        return new Date(timestamp)
    }

    if (typeof timestamp === 'object' && timestamp.constructor.name === 'Date') {
        return timestamp
    }

    return new Date()
}

/**
 * Получает данные о дне
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, month, day, year}}
 */
export function dayData (inputTimestamp) {
    const date = getDate(inputTimestamp)

    return {
        timestamp: date.getTime(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        year: date.getFullYear()
    }
}

/**
 * Получает данные о месяце
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, month, day, year}}
 */
export function monthData (inputTimestamp) {
    return dayData(inputTimestamp)
}

/**
 * Получает данные о предыдущем месяце
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, month, day, year}}
 */
export function prevMonth (inputTimestamp) {
    const date = getDate(inputTimestamp)

    date.setMonth(date.getMonth() - 1)

    return monthData(date)
}

/**
 * Получает данные о следующем месяце
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, month, day, year}}
 */
export function nextMonth (inputTimestamp) {
    const date = getDate(inputTimestamp)

    date.setMonth(date.getMonth() + 1)

    return monthData(date)
}

/**
 * Получает номер недели в году
 * https://weeknumber.net/how-to/javascript
 * @param inputTimestamp
 * @returns {number}
 */
export function numberWeekInYear (inputTimestamp) {
    const date = getDate(inputTimestamp)

    date.setHours(0, 0, 0, 0)

    // Thursday in current week decides the year.
    date.setDate((date.getDate() + 3) - ((date.getDay() + 6) % 7))

    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4)

    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
}

/**
 * Получает данные о неделе
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, first, last, weekYear}}
 */
export function weekData (inputTimestamp) {
    const date = getDate(inputTimestamp)
    const timestamp = date.getTime()
    let weekDay = date.getDay()

    if (weekDay === 0) {
        weekDay = 7
    }

    const firstDay = new Date(timestamp - ((weekDay - 1) * DayTimestamp))
    const lastDay = new Date(timestamp + ((7 - weekDay) * DayTimestamp))
    const firstDayInfo = dayData(firstDay)
    const lastDayInfo = dayData(lastDay)

    return {
        timestamp,
        first: firstDayInfo,
        last: lastDayInfo,
        number: numberWeekInYear(date)
    }
}

/**
 * Получает данные о предыдущей неделе
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, first, last, weekYear}}
 */
export function prevWeek (inputTimestamp) {
    const date = getDate(inputTimestamp)

    date.setDate(date.getDate() - 7)

    return weekData(date)
}

/**
 * Получает данные о следующей неделе
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, first, last, weekYear}}
 */
export function nextWeek (inputTimestamp) {
    const date = getDate(inputTimestamp)

    date.setDate(date.getDate() + 7)

    return weekData(date)
}

/**
 * Получает данные о следующем дне
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, month, day, year}}
 */
export function nextDay (inputTimestamp) {
    const date = getDate(inputTimestamp)

    return dayData(date.getTime() + DayTimestamp)
}

/**
 * Получает данные о предыдущем дне
 * @param {Number | Date} inputTimestamp
 * @returns {{timestamp, month, day, year}}
 */
export function prevDay (inputTimestamp) {
    const date = getDate(inputTimestamp)

    return dayData(date.getTime() - DayTimestamp)
}

/**
 * Alternative get total number of days in month
 * @param {Number} year
 * @param {Number} month
 * @return {Number}
 */
export function daysInMonth (year, month) {
    return new Date(year, month + 1, 0).getDate()
}

/**
 * Получает день в формате yyyy-mm-dd
 * @param {Number} year год
 * @param {Number} month месяц
 * @param {Number} date день
 * @return {String}
 */
export function formatDateToUrl ({ year, month, date }) {
    let result = `${year}-`

    result += (`0${month + 1}-`).slice(-3)
    result += (`0${date}`).slice(-2)

    return result
}

export function areTheSameDate (dateA, dateB) {
    return dateA.getDate() === dateB.getDate()
        && dateA.getMonth() === dateB.getMonth()
        && dateA.getFullYear() === dateB.getFullYear()
}

export function isToday (date) {
    const today = new Date()

    return areTheSameDate(today, date)
}

export function isTomorrow (date) {
    const dayBeforeDate = new Date(+date - 1000 * 60 * 60 * 24)
    const today = new Date()

    return areTheSameDate(today, dayBeforeDate)
}

export function timeMinutesToString (time) {
    let result = ''
    const hours = Math.floor(time / 60)
    const minutes = time % 60

    result += hours > 9 ? hours : `0${hours}`
    result += ':'
    result += minutes > 9 ? minutes : `0${minutes}`

    return result
}

export default {
    timeMinutesToString,
    getDate,
    monthData,
    prevMonth,
    nextMonth,
    weekData,
    prevWeek,
    nextWeek,
    numberWeekInYear,
    dayData,
    nextDay,
    prevDay,
    formatDateToUrl,
    daysInMonth,
    areTheSameDate,
    isToday,
    isTomorrow
}
