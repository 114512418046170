import Vue from 'vue'
import Api from '@/api'
import logger from '@/utils/logger'

import {
    fetchPredictions,
    fetchSmartPredictions,
    fetchSubscriptionsPredictions,
    fetchTomorrowPredictions,
    fetchWeekendPredictions,
    fetchByOddId
} from '@/api/graphql/predictions-football'
import { fetchNewSportsPredictions } from '@/api/graphql/predictions-new-sports'
import { PAGE_STATUSES } from '@/constants'
import prediction from './prediction'
import recommendations from './recommendations'
import listByOddsId from './listByOddsId'

const defaultFilter = () => ({
    count: 15,
    page: 1,
    userIds: [],
    matchId: '',
    oddId: false,
    tournamentId: '',
    hasDescription: false,
    topPredictionsFirst: false,
    hasError: false
})


export default {
    namespaced: true,

    modules: {
        prediction,
        listByOddsId,
        recommendations
    },

    state () {
        return {
            predictions: {},
            predictionIds: [],
            total: 0,
            hasNextPage: false,
            predictionsListStatus: PAGE_STATUSES.loading,
            filter: {
                ...defaultFilter()
            }
        }
    },

    getters: {
        filter (state) {
            return state.filter
        },
        total (state) {
            return state.total
        },
        pageCount (state) {
            return state.filter.count
        },
        predictions (state) {
            return state.predictionIds.map(id => state.predictions[id])
        },
        firstSponsoredPredictionIndex (state) {
            return state.predictionIds.findIndex(predictionId => state.predictions[predictionId].sponsored)
        },
        isAllPredictions (state) {
            return state.predictionIds.length === state.total
        },
        lastId (state) {
            return state.predictionIds.length ? state.predictionIds[state.predictionIds.length - 1] : 0
        }
    },

    actions: {
        /**
         * Обновление данных фильтра, после изменения значений будет
         * осуществлен запрос актуальных данных ленты
         *
         * @param state
         * @param dispatch
         * @param commit
         * @param {Object} updates - объект данных фильтра для изменения
         * @param {Boolean} reset - сбросить значения фильтра на (по умолчанию) до присваивания новых значений
         * @param {Boolean} smart - Запрашивать умную ленту
         * @param {Boolean} tomorrow
         * @param {Boolean} weekend
         * @param {Boolean} byOddId
         * @param {Boolean} my
         * @returns {Promise}
         */
        filter ({ dispatch, commit }, {
            updates = {},
            reset = false,
            smart = false,
            tomorrow = false,
            weekend = false,
            byOddId = false,
            my = false,
            isNewSports = false,
            offset = 0
        }) {
            if (reset) {
                commit('resetFilter')
            }

            commit('updateFilter', updates)

            let fetchKey = ''

            if (isNewSports) {
                fetchKey = 'fetchNewSports'
            } else if (smart) {
                fetchKey = 'fetchSmart'
            } else if (tomorrow) {
                fetchKey = 'fetchTomorrow'
            } else if (weekend) {
                fetchKey = 'fetchWeekend'
            } else if (byOddId) {
                fetchKey = 'fetchByOddId'
            } else if (my) {
                fetchKey = 'fetchMy'
            } else {
                fetchKey = 'fetch'
            }

            return dispatch(fetchKey, { reset, offset })
        },


        /**
         * Запрос ленты с ранее установленными фильтрами
         *
         * @param state
         * @param commit
         * @param getters
         * @param options - опции запроса
         * @param options.reset - сбросить текущий список
         * @returns {Promise}
         */
        fetch ({ state, dispatch, getters }, { reset = false }) {
            const params = {
                matchId: state.filter.matchId,
                first: state.filter.count,
                hasDescription: state.filter.hasDescription,
                tournamentId: state.filter.tournamentId,
                userIds: state.filter.userIds.join()
            }

            params.after = reset ? 0 : getters.lastId

            const query = `{${fetchPredictions(params)}}`

            return dispatch('fetchPredictionsByQuery', { query, reset })
        },

        fetchMy ({ state, getters, dispatch }, { reset = false }) {
            const params = {
                first: state.filter.count,
                after: reset ? 0 : getters.lastId
            }

            const query = `{${fetchSubscriptionsPredictions(params)}}`

            return dispatch('fetchPredictionsByQuery', { query, reset })
        },

        /**
         * Запрос умной ленты с ранее установленными фильтрами
         *
         * @param state
         * @param commit
         * @param getters
         * @param options - опции запроса
         * @param options.reset - сбросить текущий список
         * @returns {Promise}
         */
        fetchSmart ({ state, dispatch }, { reset = false }) {
            const params = {
                first: state.filter.count,
                topPredictionsFirst: state.filter.topPredictionsFirst,
                offset: reset ? 0 : state.predictionIds.length
            }

            if (state.filter.matchId) {
                params.matchId = state.filter.matchId
            }

            if (state.filter.tournamentId) {
                params.tournamentId = state.filter.tournamentId
            }

            const query = `{${fetchSmartPredictions(params)}}`

            return dispatch('fetchPredictionsByQuery', { query, reset })
        },

        /**
         * Запрос ленты с прогнозами на завтра
         *
         * @param context
         * @param options - опции запроса
         * @param options.reset - сбросить текущий список
         * @returns {Promise}
         */
        fetchTomorrow ({ state, rootState, dispatch }, { reset = false }) {
            const params = {
                first: state.filter.count,
                offset: reset ? 0 : state.predictionIds.length,
                timezone: rootState.page.timezone
            }

            const query = fetchTomorrowPredictions(params)

            return dispatch('fetchPredictionsByQuery', { query, reset })
        },

        /**
         * Запрос ленты с прогнозами на завтра
         *
         * @param context
         * @param options - опции запроса
         * @param options.reset - сбросить текущий список
         * @returns {Promise}
         */
        fetchWeekend ({ state, rootState, dispatch }, { reset = false }) {
            const params = {
                first: state.filter.count,
                offset: reset ? 0 : state.predictionIds.length,
                timezone: rootState.page.timezone
            }
            const query = fetchWeekendPredictions(params)

            return dispatch('fetchPredictionsByQuery', { query, reset })
        },

        /**
         * Запрос ленты с прогнозами по ставке
         *
         * @param context
         * @param options - опции запроса
         * @param options.reset - сбросить текущий список
         * @returns {Promise}
         */
        async fetchByOddId ({ state, dispatch }, { reset = false }) {
            const params = {
                first: state.filter.count,
                offset: reset ? 0 : state.predictionIds.length,
                oddId: state.filter.oddId
            }
            const query = fetchByOddId(params)

            const predictions = await dispatch('fetchPredictionsByQuery', { query, reset })

            if (predictions[0]?.odds) {
                dispatch(
                    'predictions/listByOddsId/setOddFromPrediction',
                    predictions[0],
                    { root: true }
                )
            } else {
                return []
            }

            return predictions
        },

        fetchNewSports ({ state, dispatch, rootState }, { reset = false }) {
            const params = {
                first: state.filter.count,
                userIds: state.filter.userIds.join('","'),
                matchId: state.filter.matchId,
                offset: reset ? 0 : state.predictionIds.length,
                sportId: rootState.newSports.selectedSportId
            }
            const query = fetchNewSportsPredictions(params)

            return dispatch('fetchPredictionsByQuery', { query, reset })
        },

        async fetchPredictionsByQuery ({ commit }, { query, reset }) {
            try {
                const result = await Api.graphql(query)

                let predictions = null

                if (result.list) {
                    predictions = result.list.filter(({ isPaywalled }) => !isPaywalled)
                } else {
                    predictions = result.edges.map(({ node }) => node)
                }

                const predictionIds = predictions.map(({ id }) => id)

                commit('updatePredictionIds', { predictionIds, reset })
                commit('updatePredictionsMap', { predictions })
                commit('total', result.pageInfo.totalCount)
                commit('setHasNextPage', result.pageInfo.hasNextPage)

                return predictions
            } catch (err) {
                logger.error(err)

                commit('updatePredictionIds', { predictionIds: [], reset })
                commit('total', 0)
                commit('setHasNextPage', false)

                return Promise.reject(err)
            }
        },

        setPredictionsListStatus ({ commit }, status) {
            commit('setPredictionsListStatus', status)
        },

        updatePrediction ({ commit }, { id, description, top, topInMatch, facts }) {
            commit('updatePrediction', { id, description, top, topInMatch, facts })
        },

        report ({ commit }, id) {
            commit('remove', id)
        },

        updatePredictionsMap ({ commit }, { predictions }) {
            commit('updatePredictionsMap', { predictions })
        }
    },

    mutations: {
        resetFilter (state) {
            state.filter = {
                ...defaultFilter()
            }
        },

        updateFilter (state, updates = {}) {
            Object.keys(updates).forEach(key => {
                state.filter[key] = updates[key]
            })
        },

        updatePredictionsMap (state, { predictions }) {
            predictions.forEach(item => {
                Vue.set(state.predictions, item.id, item)
            })
        },

        updatePredictionIds (state, { predictionIds, reset = false }) {
            if (reset) {
                state.predictionIds = predictionIds
            } else {
                state.predictionIds = state.predictionIds.concat(predictionIds)
            }
        },

        updatePrediction (state, { id, description, top, topInMatch, facts }) {
            const predictionItem = state.predictions[id]

            if (predictionItem) {
                Vue.set(predictionItem, 'description', description)
                Vue.set(predictionItem, 'top', top)
                Vue.set(predictionItem, 'topInMatch', topInMatch)
                Vue.set(predictionItem, 'facts', facts)
            }
        },

        total (state, total) {
            state.total = total
        },

        setHasNextPage (state, hasNextPage) {
            state.hasNextPage = hasNextPage
        },

        remove (state, id) {
            const index = state.predictionIds.indexOf(id)

            if (index > -1) {
                state.predictionIds.splice(index, 1)
            }
        },

        setPredictionsListStatus (state, predictionsListStatus) {
            state.predictionsListStatus = predictionsListStatus
        }
    }
}
