import Api from '@/api'
import {
    fetchFavorites,
    subscribe,
    unsubscribe
} from '@/api/graphql/favorites'

const SET_FAVORITES_LIST = 'SET_FAVORITES_LIST'
const ADD_TO_FAVORITES_LIST = 'ADD_TO_FAVORITES_LIST'
const REMOVE_FAVORITE_ITEM = 'REMOVE_FAVORITE_ITEM'

export default {
    namespaced: true,
    state () {
        return {
            favorites: {
                USER: {},
                TOURNAMENT: {},
                MATCH: {}
            }
        }
    },

    getters: {
        favoriteInfo: (state) => ({ id, type = 'USER' }) => {
            const favorites = state.favorites[type]

            return favorites ? favorites[id] : null
        },

        hasFavorite: (state) => ({ id, type = 'USER' }) => {
            const map = state.favorites[type] || {}

            return !!map[id]
        }
    },

    actions: {
        fetchFavorites ({ commit, rootGetters }, { type = 'USER' }) {
            const profile = rootGetters['profile/my']
            const userId = profile ? profile.id : null
            const query = fetchFavorites(userId, type)

            if (userId) {
                return Api.graphql(query)
                    .then(result => result.list)
                    .catch(() => Promise.resolve([]))
                    .then(list => commit(SET_FAVORITES_LIST, { type, list }))
            }

            return Promise.resolve()
        },

        toggleFavorites ({ dispatch, getters }, { id, type }) {
            if (getters.hasFavorite({ id, type })) {
                return dispatch('unsubscribeFavorites', { id, type })
            }

            return dispatch('subscribeFavorites', { id, type })
        },

        subscribeFavorites ({ commit }, { id, type }) {
            const query = subscribe(id, type)

            return Api.graphql(`{${query}}`, { type: 'mutation' }).then(result => {
                const list = result.userFavoriteMutations.add.favorites

                commit(ADD_TO_FAVORITES_LIST, { type, list })
            })
        },

        unsubscribeFavorites ({ commit }, { id, type }) {
            const query = unsubscribe(id, type)

            return Api.graphql(`{${query}}`, { type: 'mutation' }).then(() => {
                commit(REMOVE_FAVORITE_ITEM, { id, type })
            })
        }
    },

    mutations: {
        [SET_FAVORITES_LIST] (state, { type, list = [] }) {
            state.favorites[type] = {}
            list.forEach(el => {
                state.favorites[type][el.id] = el
            })
        },

        [ADD_TO_FAVORITES_LIST] (state, { type, list = [] }) {
            list.forEach((el) => {
                state.favorites[type][el.id] = el
            })

            state.favorites[type] = { ...state.favorites[type] }
        },

        [REMOVE_FAVORITE_ITEM] (state, { type, id }) {
            if (state.favorites[type][id]) {
                delete state.favorites[type][id]
            }

            state.favorites[type] = { ...state.favorites[type] }
        }
    }
}
