import { mapState, mapGetters } from 'vuex'

export default {
    data () {
        return {
            subscribePending: false
        }
    },

    computed: {
        ...mapState('auth', ['isAuth']),
        ...mapGetters('favorites', ['favoriteInfo', 'hasFavorite'])
    },

    methods: {
        isSubscribed (id, type = 'USER') {
            return this.hasFavorite({ id, type })
        },

        subscribeToggle (id, type) {
            if (this.isSubscribed(id, type)) {
                return this.unsubscribe(id, type)
            }

            return this.subscribe(id, type)
        },

        subscribe (id, type = 'USER') {
            this.subscribePending = true

            if (!this.isAuth) {
                this.subscribePending = false

                // вызов попапа авторизации попыткой подписаться на юзера
                this.$pivik.event('social', 'start', 'subscribe_user')

                this.$root.$emit('auth')

                return Promise.reject()
            }

            return this.$store.dispatch('favorites/subscribeFavorites', { type, id })
                .then(() => {
                    this.subscribePending = false
                })
                .catch((e) => {
                    this.subscribePending = false

                    return Promise.reject(e)
                })
        },

        unsubscribe (id, type = 'USER') {
            this.subscribePending = true

            if (!this.isAuth) {
                this.subscribePending = false

                this.$root.$emit('auth')

                return Promise.reject()
            }

            return this.$store.dispatch('favorites/unsubscribeFavorites', { type, id })
                .then(() => {
                    this.subscribePending = false
                })
                .catch((e) => {
                    this.subscribePending = false

                    return Promise.reject(e)
                })
        }
    }
}
