import Api from '@/api'
import logger from '@/utils/logger'
import {
    fetchSports
} from '@/api/graphql/new-sports'
import { SPORT_TYPE_FOOTBALL } from '@/constants'

const SET_SPORTS = 'SET_SPORTS'
const SET_SELECTED_SPORT_ID = 'SET_SELECTED_SPORT_ID'


export default {
    namespaced: true,

    state () {
        return {
            sports: [],
            selectedSportId: SPORT_TYPE_FOOTBALL.id
        }
    },

    getters: {
        isFootballSelected (state) {
            return state.selectedSportId === SPORT_TYPE_FOOTBALL.id
        },

        selectedSport (state) {
            return state.sports.find(sport => sport.id === state.selectedSportId) || {}
        }
    },

    actions: {
        async fetchSportsIfEmpty ({ state, commit }) {
            if (state.sports.length > 1) {
                return state.sports
            }

            try {
                const query = fetchSports()
                const result = await Api.graphql(query)

                const sports = [
                    SPORT_TYPE_FOOTBALL,
                    ...result.map(sport => ({
                        ...sport,
                        id: sport.code
                    }))
                ]

                commit(SET_SPORTS, sports)

                return sports
            } catch (err) {
                logger.error(err)

                commit(SET_SPORTS, [SPORT_TYPE_FOOTBALL])
            }
        },

        setSelectedSportId ({ commit }, sportId) {
            commit(SET_SELECTED_SPORT_ID, sportId)
        }
    },

    mutations: {
        [SET_SPORTS] (state, sports) {
            state.sports = sports
        },

        [SET_SELECTED_SPORT_ID] (state, sportId) {
            state.selectedSportId = sportId
        }
    }
}
