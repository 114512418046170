<template>
    <nav class="menu-mobile">
        <router-link
            v-for="(item, index) in items"
            :key="`${item.key}-${index}`"
            :to="item.to"
            class="menu-mobile__link"
            @click.native="sendAnalytic(item.key)"
        >
            {{ item.titleMobile || item.title }}
        </router-link>

        <span class="menu-mobile__link">
            <DailyBonusesMobileButton
                :current-bonus="currentDailyBonus"
                @click="$emit('close')"
            />
        </span>

        <template v-if="isAuth">
            <router-link
                :to="mySubscriptionsRoute"
                class="menu-mobile__link"
                @click.native="sendAnalytic('subscriptions')"
            >
                {{ $t('my-subscriptions') }}
            </router-link>

            <router-link
                v-if="!account.confirmed"
                :to="settingsRoute"
                class="menu-mobile__link"
            >
                {{ $t('confirm-email') }}
            </router-link>

            <span
                class="menu-mobile__link menu-mobile__link--red"
                @click="authSignOut"
            >
                {{ $t('signout') }}
            </span>
        </template>

        <OddsFormatSelectorMobile v-if="isEn" />
    </nav>
</template>

<i18n>
{
    "ru": {
        "confirm-email": "Подтвердить почту",
        "confirm-phone": "Подтвердить телефон",
        "my-subscriptions": "Лента подписок",
        "stop-merchant": "Перестать быть продавцом",
        "signout": "Выйти"
    },
    "en": {
        "confirm-email": "Confirm email",
        "confirm-phone": "Confirm phone",
        "my-subscriptions": "My subscriptions",
        "stop-merchant": "Stop being a seller",
        "signout": "Sign out"
    }
}
</i18n>

<script>
import { mapState } from 'vuex'
import OddsFormatSelectorMobile from '@/components/common/OddsFormatSelector/OddsFormatSelectorMobile.vue'
import auth from '@/mixins/auth'
import DailyBonusesMobileButton from '@/components/DailyBonuses/DailyBonusesMobileButton.vue'

export default {
    name: 'TheHeaderMenuMobile',

    components: {
        DailyBonusesMobileButton,
        OddsFormatSelectorMobile
    },

    mixins: [auth],

    props: {
        items: {
            type: Array,
            default: () => []
        },

        promo: {
            type: Object,
            default: () => ({})
        },

        currentDailyBonus: {
            type: Object,
            default: () => ({})
        }
    },

    data () {
        return {
            settingsRoute: {
                name: 'account-settings'
            },

            mySubscriptionsRoute: {
                name: 'predictions-my'
            }
        }
    },

    computed: {
        ...mapState('auth', ['account', 'isAuth']),

        isEn () {
            return this.$i18n.locale === 'en'
        }
    },

    methods: {
        sendAnalytic (key) {
            this.$pivik.event('menu', 'click', key)
        },

        openConfirmPhonePopup () {
            return this.$root.$emit('popup-confirm-phone')
        }
    }
}
</script>

<style lang="stylus" scoped>
.menu-mobile
    width 100%

    &__link
        margin 0 2rem
        height 5rem
        display flex
        align-items center
        line-height 2.1rem
        font-size 1.6rem
        letter-spacing .05rem
        color $cl-white
        text-decoration none
        cursor pointer
        border-top .1rem solid rgba($cl-white, .1)

        &--red
            color $cl-error

        &.router-link-exact-active
            position relative
            color $cl-white

            &::after
                content ''
                position absolute
                left -1.8rem
                top 20%
                width .2rem
                height 60%
                border-radius 1px
                background-color $cl-primary

</style>
