<template>
    <div
        class="ui-flag"
        :class="classes"
        :style="styles"
    >
        <div
            :style="flagWingStyles"
            class="ui-flag__top-wing"
        />

        <div
            :style="flagWingStyles"
            class="ui-flag__bottom-wing"
        />
        <!-- @slot Слот для контента внутри флага -->
        <slot />
    </div>
</template>

<script>
export default {
    name: 'UiFlag',

    props: {
        /**
         * Тема флажка
         * [accent], [primary], [warn], [gray]
         */
        theme: {
            type: String,
            default: 'accent'
        },

        // Стили флажка
        styles: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        classes () {
            return {
                [`theme-${this.theme}`]: true
            }
        },

        flagWingStyles () {
            const styles = {}

            if (this.styles.backgroundColor) {
                styles.borderColor = this.styles.backgroundColor
            }

            return styles
        }
    }
}
</script>

<style lang="stylus" scoped>
    theme($bg-color, $text-color)
        background-color $bg-color
        color $text-color

        .ui-flag__bottom-wing,
        .ui-flag__top-wing
            border-left-color $bg-color

    .ui-flag
        position relative
        display inline-flex
        min-width 4.5rem
        min-height 2.8rem
        padding-top 0.4rem
        padding-bottom 0.4rem
        padding-right 1rem
        padding-left 2rem
        text-align center
        align-items center
        margin-right 1.5rem
        box-sizing border-box
        font-weight $font-weight-bold

        +layout-sm()
            padding-left 1.2rem

        +layout-md()
            padding-left 1.5rem

        .ui-flag__bottom-wing,
        .ui-flag__top-wing
            position absolute
            left 100%
            content ""
            border-left-width 0.6rem
            border-left-style solid
            margin-left -1px

        .ui-flag__top-wing
            top 0
            border-bottom 21px solid transparent !important

        .ui-flag__bottom-wing
            bottom 0
            border-top 21px solid transparent !important

        &.theme-accent
            theme($cl-success-light, #fff)

        &.theme-primary
            theme($cl-primary, #fff)

        &.theme-warn
            theme($cl-error, #fff)

        &.theme-gray
            theme($cl-secondary, #fff)
</style>
