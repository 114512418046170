<template>
    <div
        class="text-roll"
        :class="classes"
    >
        <div
            ref="text"
            class="text"
            :style="style"
            @transitionend="onEnd"
        >
            <slot />
            <div
                v-if="showBtn"
                class="expand"
                @click.prevent.stop="onToggle"
            >
                <span class="expand__line">
                    <span class="link-info-inner">
                        {{ buttonText }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "expand": "Еще",
        "read-more": "Читать далее",
        "collapse": "Свернуть"
    },
    "en": {
        "expand": "Expand",
        "read-more": "Expand",
        "collapse": "Collapse"
    }
}
</i18n>

<script>
export default {
    name: 'UiTextRoll',

    props: {
        center: {
            type: Boolean,
            default: false
        },

        expand: {
            type: Boolean,
            default: false
        },

        light: {
            type: Boolean,
            default: false
        },

        backRoll: {
            type: Boolean,
            default: false
        },

        maxHeight: {
            type: Number,
            default: 11.5
        },

        size: {
            type: String,
            default: ''
        },

        sizeType: {
            type: String,
            default: 'rem'
        }
    },

    data () {
        return {
            clip: true,
            duration: 200,
            hidden: true,
            collapseble: false
        }
    },

    computed: {
        classes () {
            return {
                'is-center': this.center,
                'is-clip': this.clip,
                'is-full': !this.clip,
                'is-light': this.light,
                'is-size-sm': this.size === 'sm'
            }
        },

        showBtn () {
            return (this.clip && this.collapseble) || (this.backRoll && this.collapseble)
        },

        style () {
            let height = this.collapseHeight

            if (!this.$isServer && !this.clip) {
                height = `${this.originalHeight}px`
            }

            return {
                'max-height': this.hidden ? height : 'inherit',
                overflow: this.hidden ? 'hidden' : 'visible'
            }
        },

        collapseHeight () {
            return this.maxHeight + this.sizeType
        },

        originalHeight () {
            return this.$refs.text.scrollHeight
        },

        buttonText () {
            let textKey = ''

            if (this.clip) {
                textKey = this.center ? 'read-more' : 'expand'
            } else {
                textKey = 'collapse'
            }

            return this.$t(textKey)
        }
    },

    mounted () {
        if ((this.originalHeight <= this.$refs.text.clientHeight) || this.expand) {
            this.clip = false
        } else {
            this.collapseble = true
        }
    },

    methods: {
        onToggle () {
            this.hidden = true

            if (this.clip) {
                this.$emit('expand')
                this.clip = false
            } else {
                this.$emit('collapse')
                this.clip = true
            }
        },

        onEnd () {
            this.hidden = this.clip
        }
    }
}
</script>

<style lang="stylus" scoped>
    .text-roll {
        font-size $font-size-base-sub
        line-height 1.4
        max-width 100%
    }

    .text {
        position relative
        transition max-height 0.3s ease-out
        font-family $font-family-content
    }

    >>> .text {
        & p {
            margin 1.6rem 0

            &:first-child {
                margin-top 0
            }

            &:last-child {
                margin-bottom 0
            }
        }

        & h2,
        & h3 {
            margin 3.2rem 0 1.6rem

            font-family $font-family
            font-weight $font-weight-medium
            font-size 1.8rem
            line-height 2.4rem
            letter-spacing 0.015rem
        }

        & a {
            text-decoration none
            color $cl-info
            border-bottom 1px solid rgba($cl-primary, .3)
            transition border-bottom-color .2s, color .2s

            &:active,
            &:focus,
            &:hover {
                border-bottom-color transparent
                text-decoration none
            }
        }

        & iframe {
            margin 3rem 0!important
            width 100%
        }

        & img {
            margin 1rem 0
            width 100%
            height auto
        }
    }

    .expand {
        position absolute
        bottom 0
        right 0
        background-color $cl-background
        cursor pointer
        box-shadow -17px 0px 9px 4px rgb($cl-background)
        line-height 2.1rem
    }

    .is-center .expand {
        left 0
        width 100%
        height 5.5rem
        display flex
        align-items flex-end
        justify-content center
        background-image linear-gradient(0deg, $cl-background 2%, $cl-background 43%, rgba($cl-background, 0.87) 62%, rgba($cl-background, 0.70) 80%, rgba(246, 246, 246, 0.00) 100%)
        right initial
        background-color initial
        box-shadow initial

        &__line {
            position relative
            display block
            width 100%
            z-index 1
            overflow hidden
            text-align center
            padding-bottom .4rem

            &:before, &:after {
                content ''
                display inline-block
                width 100%
                height 2px
                background $cl-secondary-light
                z-index 1
                position relative
                vertical-align middle
            }

            &:before {
                right 1.9rem
                margin 0 0 0 -100%
            }

            &:after {
                left 1.9rem
                margin 0 -100% 0 0
            }
        }
    }

    .is-light .expand {
        box-shadow -17px 0px 9px 4px rgb(#fff)
        background-color #fff
    }

    .is-full .expand {
        position static
        box-shadow none
        background-color transparent
        float right
    }
</style>
