<template>
    <div
        class="search"
        :class="searchClass"
    >
        <div
            class="search__container"
            :style="{ width: widthContainer }"
        >
            <div
                class="search__toggle"
                @click="onToggle"
            >
                <IconSearch class="search__pointer" />
                <IconClose class="search__close" />
            </div>

            <transition>
                <div
                    v-if="isOpen"
                    class="search__body"
                >
                    <input
                        ref="input"
                        v-model="query"
                        :placeholder="$t('placeholder')"
                        class="search__input"
                        @input="onSearch"
                    >
                </div>
            </transition>
        </div>

        <transition name="drop">
            <div
                v-if="isOpen && result"
                :style="{ width: widthResults }"
                class="search__results-wrap"
            >
                <SearchResults
                    :result="result"
                    :query="query"
                    class="search__results"
                />
            </div>
        </transition>

        <CorePortal>
            <div
                v-if="isOpen"
                class="search__overlay"
                @touchmove.stop
                @wheel.stop
                @scroll.stop
                @touchstart="onClose"
                @click="onClose"
            />
        </CorePortal>
    </div>
</template>

<i18n>
{
    "ru": {
        "placeholder": "Что найти?"
    },
    "en": {
        "placeholder": "Enter your request"
    }
}
</i18n>

<script>
import { mapActions, mapState } from 'vuex'
import CorePortal from '@/ui/core/portal/index.vue'
import IconSearch from '@/components/icons/search.vue'
import SearchResults from '@/components/Search/SearchResults.vue'
import searchStore from '@/store/modules/search'

const SEARCH_HORIZONTAL_OFFSET = 54

export default {
    name: 'Search',

    components: {
        CorePortal,
        IconSearch,
        SearchResults
    },

    storeModules: {
        search: searchStore
    },

    data () {
        return {
            isOpen: false,
            query: '',
            widthContainer: '',
            widthResults: '',
            result: null
        }
    },

    computed: {
        ...mapState('search', ['preview']),
        ...mapState('auth', ['isAuth']),

        searchClass () {
            return {
                'is-open': this.isOpen
            }
        }
    },

    watch: {
        $route () {
            this.isOpen = false
        },

        isAuth () {
            this.onSearch()
        }
    },

    methods: {
        ...mapActions({
            fetchSearchResults: 'search/fetchSearchResults'
        }),

        onToggle () {
            if (!this.isOpen) {
                this.onSearch()
                this.isOpen = true
                this.widthContainer = this.getWidthContainer()
                this.widthResults = this.getWidthResults()
                this.$nextTick(() => this.focus())
            } else {
                this.isOpen = false
                this.onClose()
            }
        },

        onClose () {
            this.isOpen = false
            this.widthContainer = ''
            this.widthResults = ''
        },

        focus () {
            if (this.$refs.input) {
                this.$refs.input.focus()
            }
        },

        getWidthContainer () {
            let width
            const wWidth = window.innerWidth

            if (wWidth <= 800) {
                width = wWidth - SEARCH_HORIZONTAL_OFFSET
            }

            return width ? `${width}px` : ''
        },

        getWidthResults () {
            let width
            const wWidth = window.innerWidth

            if (wWidth <= 800 && wWidth > 600) {
                width = wWidth - SEARCH_HORIZONTAL_OFFSET
            } else if (wWidth <= 600) {
                width = wWidth
            }

            return width ? `${width}px` : ''
        },

        async onSearch () {
            this.result = await this.fetchSearchResults({
                query: this.query,
                counts: {
                    users: 4,
                    matches: 2,
                    tournaments: 1
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
    .search
        position relative
        width 3.4rem
        height 100%
        z-index 5
        display flex
        align-items center

        &__toggle
            width 3.4rem
            height @width
            padding 0 .3rem
            display flex
            align-items center
            justify-content center
            color #fff
            transition color .3s
            transition-delay .3s
            cursor pointer

        &__close
            display none

        &.is-open &__toggle
            color $cl-text-dark
            transition-delay 0

        &.is-open &__close
            +layout-sm()
                display block

        &.is-open &__pointer
            +layout-sm()
                display none

        &__container
            position absolute
            top 0
            bottom 0
            left 0
            width 4rem
            height 3.4rem
            margin auto
            display flex
            align-items center
            border-radius 1.7rem
            background-color transparent

            transition background-color .3s, width .3s
            transition-delay 0.3s

        &.is-open &__container
            width 26rem
            background-color #fff
            transition-delay 0

        &__body
            flex 1 1

        &__input
            padding 0
            width 100%
            border 0 none
            background transparent
            outline none
            font-size 1.6rem
            letter-spacing .05rem

            &::placeholder
                color $cl-secondary

        &__results
            letter-spacing 0

            &-wrap
                box-sizing border-box
                position absolute
                top 100%
                left 0
                width 38rem
                border 1px solid $cl-disabled-light
                border-top 0 none
                color $cl-text-dark
                background-color #fff

                +layout-xs()
                    left -4.2rem

        &__overlay
            position fixed
            top 0
            bottom 0
            left 0
            right 0
            z-index 10

    .drop-enter-active,
    .drop-leave-active
        transition opacity .3s

        .search__results
            transition transform .3s

    .drop-enter-active
        transition-delay .3s

        .search__results
            transition transform .3s
            transition-delay .3s

    .drop-enter,
    .drop-leave-to
        opacity 0

        .search__results
            transform translate(0, -1rem)
</style>
