<template>
    <div>
        <AdFox
            :placement="placement"
            @receive="(data) => $emit('receive', data)"
            @render="$emit('render')"
        />
        <div
            v-show="false"
            :id="id"
        />
    </div>
</template>

<script>
import dfp from '@/mixins/dfp'
import AdFox from '@/components/DFP/AdFox.vue'
import { mapState } from 'vuex'

const BANNER_VISIBLE_FOR_REFRESH_DURATION = 2000

export default {
    name: 'DFP',

    components: {
        AdFox
    },

    mixins: [dfp],

    data () {
        return {
            afterLoadTimeChecked: false,
            visibilityTimeChecked: false,
            trackView: '',
            trackViewCustom: '',
            isVisible: false
        }
    },

    computed: {
        ...mapState('bookmakersRating', ['geo'])
    },

    beforeDestroy () {
        clearTimeout(this.visibilityBannerTimeout)
        clearTimeout(this.afterLoadTimeout)
        if (this.bannersObserver) {
            this.bannersObserver.unobserve(this.$el)
        }
    },

    mounted () {
        console.info({ geo: this.geo })
        this.init()
        this.$on('need-refresh', this.refresh)
        this.$on('render', this.renderHandler)
        this.$on('receive', this.receiveHandler)

        if (this.refreshTimer) {
            this.bannersObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.bannerIsVisible()
                    } else {
                        this.bannerIsLeftView()
                    }
                })
            })

            this.bannersObserver.observe(this.$el)
        }
    },

    methods: {
        renderHandler () {
            if (this.isVisible) {
                this.bannerIsVisible()
            }

            if (this.refreshTimer) {
                this.initRefreshCountdown()
                this.afterLoadTimeChecked = false
                this.visibilityTimeChecked = false
            }
        },

        /**
         * Проставляем сслыки на трек-пикслели
         * trackView - гугловая ссылка
         * trackViewCustom наша кастомная от рекламодателя
         */
        receiveHandler ({ trackView, trackViewCustom }) {
            this.trackView = trackView
            this.trackViewCustom = trackViewCustom
        },

        refresh () {
            if (document.hidden) {
                this.waitForFocus(this.update)
            } else {
                this.update()
            }
        },

        bannerIsVisible () {
            this.isVisible = true
            if (!this.visibilityTimeChecked) {
                this.visibilityBannerTimeout = setTimeout(() => {
                    this.visibilityTimeChecked = true
                    this.checkRefresh()
                }, BANNER_VISIBLE_FOR_REFRESH_DURATION)
            } else {
                this.checkRefresh()
            }
        },

        bannerIsLeftView () {
            this.isVisible = false
            clearTimeout(this.visibilityBannerTimeout)
        },

        initRefreshCountdown () {
            this.afterLoadTimeout = setTimeout(() => {
                this.afterLoadTimeChecked = true
                this.checkRefresh()
            }, this.refreshTimer * 1000)
        },

        checkRefresh () {
            if (this.afterLoadTimeChecked
                && this.visibilityTimeChecked
                && this.isVisible
            ) {
                this.refresh()
            }
        },

        waitForFocus (cb) {
            const handler = () => {
                cb()
                document.removeEventListener('visibilitychange', handler)
            }

            document.addEventListener('visibilitychange', handler)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .bi-dfp
        box-sizing border-box
        font-size 0
        height 0rem
        text-align center

        &__ad
            display inline-block

        &__track-pixel
            display none

        &.is-overflow
            overflow hidden
</style>
