<template>
    <PopupRefundForm>
        <template slot="title">
            {{ $t('header') }}
        </template>

        <div class="popup-refund-success__text">
            {{ $t('text') }}<br>
            {{ $t('luck') }}
        </div>

        <button
            class="popup-refund-success__button popup-refund-success__button--stretch"
            @click="close"
        >
            {{ $t('btn') }}
        </button>
    </PopupRefundForm>
</template>

<i18n>
{
    "ru": {
        "header": "Баланс пополнен",
        "text": "Вы получили еще 5 000 B, успехов в ставках.",
        "luck": "Играйте с умом!",
        "btn": "Спасибо"
    },
    "en": {
        "header": "Refill successful",
        "text": "Your account has been successfully recharged.",
        "luck": "Good luck!",
        "btn": "Thanks"
    }
}
</i18n>

<script>
import PopupRefundForm from '@/components/Popups/Refund/PopupRefundForm.vue'

export default {
    name: 'PopupRefundSuccess',

    components: {
        PopupRefundForm
    },

    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>

<style lang="stylus" scoped>
    .popup-refund-success {
        &__text {
            margin-bottom 3.5rem
        }

        &__button {
            button(5, 'contained', 'primary')
            margin-bottom 2rem
        }
    }
</style>
