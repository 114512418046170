<template>
    <div class="ui-calendar">
        <header class="ui-calendar__header">
            <a
                :href="hrefRouteTo(prevMonthFirstDay)"
                :class="{ 'ui-calendar__nav--disabled': !canGoPrev }"
                class="ui-calendar__nav"
                @click.prevent="onPreviousMonth"
            >
                <IconArrowLeft />
            </a>

            <span class="ui-calendar__header-title">{{ pageMonth }} {{ pageYear }}</span>

            <a
                :href="hrefRouteTo(nextMonthFirstDay)"
                :class="{ 'ui-calendar__nav--disabled': !canGoNext }"
                class="ui-calendar__nav"
                @click.prevent="onNextMonth"
            >
                <IconArrowRight />
            </a>
        </header>

        <div class="ui-calendar__day-name">
            <span
                v-for="i in 7"
                :key="i"
                class="ui-calendar__title"
            ><span>{{ $t(`dayweek-${i - 1}`) }}</span></span>
        </div>

        <div class="ui-calendar__main-wrap">
            <div
                v-if="hasRoute"
                class="ui-calendar__main"
            >
                <router-link
                    v-for="day in days"
                    :key="day.timestamp"
                    :to="routeTo(day)"
                    class="ui-calendar__day"
                    :class="{ 'is-today': day.isToday, 'ui-calendar__day--ghost': day.isGhost }"
                    @click.native="onSelect(day)"
                >
                    <span>{{ day.date }}</span>
                </router-link>
            </div>

            <div
                v-else
                class="ui-calendar__main"
            >
                <span
                    v-for="day in days"
                    :key="day.timestamp"
                    class="ui-calendar__day"
                    :class="{ 'is-today': day.isToday, 'ui-calendar__day--ghost': day.isGhost }"
                    @click="onSelect(day)"
                >
                    <span>{{ day.date }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<i18n src="@/i18n/time.json"></i18n>

<script>
import objectAssignDeep from 'deep-assign'
import { daysInMonth } from '@/utils/calendar'

export default {
    name: 'UiCalendarDay',

    props: {
        value: {
            type: Number,
            default: null
        },

        route: {
            type: Object,
            default: null
        },

        routeDateKey: {
            type: String,
            default: 'date'
        }
    },

    data () {
        return {
            mondayFirst: true,
            pageDate: null
        }
    },

    computed: {
        hasRoute () {
            return !!this.route
        },

        currentDate () {
            return new Date()
        },

        days () {
            return this.prevDays.concat(this.currentDays, this.nextDays)
        },

        currentDays () {
            const d = this.pageDate
            const dObj = new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes())
            const daysNumbers = daysInMonth(dObj.getFullYear(), dObj.getMonth())

            const days = []

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < daysNumbers; i++) {
                days.push(this.getDayData(dObj))
                dObj.setDate(dObj.getDate() + 1)
            }

            return days
        },

        /**
         * Returns the day number of the week less one for the first of the current month
         * Used to show amount of empty cells before the first in the day calendar layout
         * @return {Array}
         */
        prevDays () {
            const d = this.pageDate
            let lastDays = d.getDay()
            const dObj = new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes())
            const days = []

            if (this.mondayFirst) {
                lastDays = dObj.getDay() > 0 ? dObj.getDay() - 1 : 6
            }

            // eslint-disable-next-line no-plusplus
            for (let i = lastDays; i--;) {
                dObj.setDate(dObj.getDate() - 1)
                const data = this.getDayData(dObj)

                data.isGhost = true
                days.unshift(data)
            }

            return days
        },

        nextDays () {
            const dObj = new Date(this.currentDays[this.currentDays.length - 1].timestamp)
            let lastDays = 0
            const days = []

            if (this.mondayFirst) {
                lastDays = dObj.getDay() > 0 ? 7 - dObj.getDay() : 0
            }

            // eslint-disable-next-line no-plusplus
            for (let i = lastDays; i--;) {
                dObj.setDate(dObj.getDate() + 1)
                const data = this.getDayData(dObj)

                data.isGhost = true
                days.push(data)
            }

            return days
        },

        prevMonthFirstDay () {
            const d = this.pageDate
            const month = new Date(d.getFullYear(), d.getMonth() - 1, 1)

            return this.getDayData(month)
        },

        nextMonthFirstDay () {
            const d = this.pageDate
            const month = new Date(d.getFullYear(), d.getMonth() + 1, 1)

            return this.getDayData(month)
        },

        pageMonth () {
            return this.$t(`months-${this.pageDate.getMonth() + 1}`)
        },

        pageYear () {
            return this.pageDate.getFullYear()
        },

        limits () {
            const nowPlusFourteenMonths = new Date()

            nowPlusFourteenMonths.setMonth(nowPlusFourteenMonths.getMonth() + 14)

            return {
                start: {
                    // Декабрь 2016
                    year: 2016,
                    month: 11
                },

                end: {
                    // +14 месяцев от текущей даты
                    year: nowPlusFourteenMonths.getFullYear(),
                    month: nowPlusFourteenMonths.getMonth()
                }
            }
        },

        canGoNext () {
            return !(this.nextMonthFirstDay.month === this.limits.end.month && this.nextMonthFirstDay.year === this.limits.end.year)
        },

        canGoPrev () {
            return !(this.prevMonthFirstDay.month === this.limits.start.month && this.prevMonthFirstDay.year === this.limits.start.year)
        }
    },

    created () {
        this.pageDate = new Date()
    },

    methods: {
        // eslint-disable-next-line consistent-return
        routeTo (day) {
            if (this.hasRoute) {
                const routeTo = objectAssignDeep({ params: {} }, this.route)

                routeTo.params[this.routeDateKey] = this.getDateParamByDay(day)

                return routeTo
            }
        },

        // eslint-disable-next-line consistent-return
        hrefRouteTo (day) {
            if (this.hasRoute) {
                const route = this.$router.resolve(this.routeTo(day), this.$route)

                return route.href
            }
        },

        getDateParamByDay (dayObj) {
            const month = (`0${dayObj.month + 1}`).slice(-2)
            const day = (`0${dayObj.date}`).slice(-2)

            return `${dayObj.year}-${month}-${day}`
        },

        onPreviousMonth () {
            this.changeMonth(-1)
        },

        onNextMonth () {
            this.changeMonth(+1)
        },

        onSelect (dateObject) {
            this.$emit('input', dateObject)
        },

        getDayData (dObj) {
            return {
                date: dObj.getDate(),
                month: dObj.getMonth(),
                year: dObj.getFullYear(),
                timestamp: dObj.getTime(),
                isToday: dObj.toDateString() === this.currentDate.toDateString()
            }
        },

        changeMonth (incrementBy) {
            const date = this.pageDate

            date.setMonth(date.getMonth() + incrementBy)
            this.pageDate = new Date(date.getTime())
        }
    }
}
</script>

<style lang="stylus">
.ui-calendar {
    user-select none

    &__header {
        display flex
        background-color $cl-secondary-light
        min-height 45px
        align-items center

        &-title {
            flex 1 1
            font-size $font-size-base
            font-weight $font-weight-bold
            text-align center
        }

        a {
            font-size 0
        }
    }

    &__nav {
        display flex
        justify-content center
        align-items center
        width 6.4rem
        height 4.4rem

        .ui-icon {
            color $cl-secondary
        }

        &--disabled {
            opacity 0
            pointer-events none
        }
    }

    &__day-name {
        background-color $cl-secondary-light
        padding 0 8px
        font-size 0
    }

    &__main {
        font-size 0
        padding 8px
    }

    &__main-wrap {
        background-color #fff
    }

    &__title,
    &__day {
        position relative
        width (100 / 7) %
        padding-bottom (100 / 7) %
        display inline-block
        font-size $font-size-base

        span {
            width 80%
            height 80%
            position absolute
            display flex
            top 0
            bottom 0
            right 0
            left 0
            justify-content center
            align-items center
            margin auto
            background-color transparent
            line-height 2.2
        }
    }

    &__day {
        cursor pointer
        font-family $font-family-content
        color $cl-text-dark

        span {
            width 80%
            height 80%
            position absolute
            display flex
            top 0
            bottom 0
            right 0
            left 0
            border-radius 50%
            transition 0.4s background-color
        }

        &--ghost {
            color $cl-secondary
        }

        &.is-today {
            color $cl-primary
        }

        &:hover {
            color $cl-text-dark

            span {
                background-color #F5F5F5
            }
        }

        &.router-link-active span {
            background-color $cl-primary
            color #fff
        }
    }

    &__title {
        color $cl-secondary
        font-size 1.6rem
        text-transform uppercase
    }
}
</style>
