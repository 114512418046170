<template>
    <div class="ad-disclaimer">
        <UiTooltip
            :drop="tooltipDrop"
        >
            <AdDisclaimerIcon />

            <template #drop>
                <span class="ad-disclaimer__text">
                    {{ text }}
                </span>
            </template>
        </UiTooltip>
    </div>
</template>

<script>
import AdDisclaimerIcon from '@/assets/svg/ad-disclaimer.icon.svg'

export default {
    name: 'AdDisclaimer',

    components: {
        AdDisclaimerIcon
    },

    props: {
        text: {
            type: String,
            default: ''
        },

        tooltipDrop: {
            type: String,
            default: 'bottom'
        }
    }
}
</script>

<style lang="stylus">
    .ad-disclaimer
        display inline-block
        vertical-align middle
        height 2.4rem

        &__text
            display inline-block
            max-width 25rem

</style>
