export const listTypes = [
    'live',
    'matches',
    'tipsters',
    'tournaments',
    'bookmakers',
    'predictions-pages',
    'blogs'
]

export const overallLinkTitlesMap = {
    live: 'all-matches',
    matches: 'all-matches',
    tipsters: 'tipsters-rating',
    blogs: 'read-blog',
    bookmakers: 'bookmakers-rating'
}

export const overallRoutesMap = {
    live: {
        name: 'match-center',
        params: {
            sport: 'football'
        }
    },
    matches: {
        name: 'match-center',
        params: {
            sport: 'football'
        }
    },
    tipsters: {
        name: 'rating'
    },
    blogs: {
        name: 'blog',
        params: {
            section: 'blog'
        }
    },
    bookmakers: {
        name: 'bookmakers-default'
    }
}

export const overallClickEvents = {
    bookmakers: 'bookies',
    live: 'live_all',
    matches: 'today_all',
    tipsters: 'cappers_all'
}

export const predictionsPagesLinks = [{
    title: 'Прогнозы на футбол',
    route: {
        name: 'predictions',
        params: {
            sport: 'football'
        }
    }
},
{
    title: 'Прогнозы на хоккей',
    route: {
        name: 'predictions-new-sports',
        params: {
            sport: 'ice-hockey'
        }
    }
},
{
    title: 'Прогнозы на баскетбол',
    route: {
        name: 'predictions-new-sports',
        params: {
            sport: 'basketball'
        }
    }
},
{
    title: 'Прогнозы на волейбол',
    route: {
        name: 'predictions-new-sports',
        params: {
            sport: 'volleyball'
        }
    }
},
{
    title: 'Прогнозы на теннис',
    route: {
        name: 'predictions-new-sports',
        params: {
            sport: 'tennis'
        }
    }
}]
