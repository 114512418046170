<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        {{ $t('text') }}

        <template slot="footer">
            <button
                class="popup-error__button popup-error__button--stretch"
                @click="close"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Ошибка",
            "text": "Попробуйте выполнить действие снова или немного позже.",
            "btn": "Окей, понятно"
        },
        "en": {
            "header": "Error",
            "text": "Please try again or check back shortly.",
            "btn": "Okay, got it"
        }
    }
</i18n>

<script>
export default {
    name: 'PopupError',

    mounted () {
        this.$root.$on('popup-error', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-error', this.open)
    },

    methods: {
        open () {
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        }
    }
}
</script>

<style lang="stylus" scoped>
.popup-error
    &__button
        button(5, 'contained', 'primary')

</style>
