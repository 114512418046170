<template>
    <div class="apps">
        <div
            class="apps__btn app-store"
            @click="open(apps.ios, 'ios_store')"
        >
            <div class="apps__btn-wrapper" />
            <component :is="appStore" />
        </div>

        <div
            class="apps__btn google-play"
            @click="open(apps.android, 'android_store')"
        >
            <div class="apps__btn-wrapper" />
            <component :is="googlePlay" />
        </div>
    </div>
</template>

<script>
import IconAppStoreEn from '@/components/icons/app-store.en.vue'
import IconAppStoreRu from '@/components/icons/app-store.ru.vue'
import IconGooglePlayEn from '@/components/icons/google-play.en.vue'
import IconGooglePlayRu from '@/components/icons/google-play.ru.vue'
import config from '@/config.json'


export default {
    name: 'BlockApps',

    components: {
        IconAppStoreEn,
        IconAppStoreRu,
        IconGooglePlayEn,
        IconGooglePlayRu
    },

    props: {
        place: {
            type: String,
            default: ''
        }
    },

    computed: {
        apps () {
            return config.apps || {}
        },

        /**
         * Получение имени компонента для GooglePlay в зависимости от локали
         * @return {string} - имя компонента
         */
        googlePlay () {
            return `icon-google-play-${this.$i18n.locale}`
        },

        /**
         * Получение имени компонента для AppStore в зависимости от локали
         * @return {string} - имя компонента
         */
        appStore () {
            return `icon-app-store-${this.$i18n.locale}`
        }
    },

    methods: {
        open (href, key) {
            window.open(href, '_blank')
            this.$pivik.event(this.place, 'click', key)
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus" scoped>
    .apps {
        min-height 5.4rem
        display grid
        grid-column-gap 1.8rem
        grid-row-gap 1.2rem
        grid-template-columns auto auto
        +layout-sm() {
            margin-top 2rem
        }

        +layout-xxs() {
            grid-template-columns 1fr
            grid-template-rows 1fr 1fr
        }

        &__btn {
            height 5rem
            position relative
            display flex
            justify-content center
            align-items center
            cursor pointer
            color $cl-text-dark
            transition color .2s linear
            +layout-sm() {
                justify-content flex-start
            }
            &.google-play {
                width 16.4rem
                &:hover {
                    .apps__btn-wrapper {
                        transform scale(1.02, 1.1)
                        +layout-xs() {
                            transform scale(1, 1)
                        }
                    }
                }
            }
            &.app-store {
                width 14.8rem
                &:hover {
                    .apps__btn-wrapper {
                        transform scale(1.04, 1.1)
                        +layout-xs() {
                            transform scale(1, 1)
                        }
                    }
                }
                +layout-sm() {
                    width 16.4rem
                }
            }
            &-wrapper {
                position absolute
                width 100%
                height @width
                border-radius .8rem
                background $cl-secondary-light
                transform scale(1)
                transition transform .4s cubic-bezier(0.5, 0.86, 0.38, 1.45), background .2s linear
            }
            &:hover {
                color $cl-secondary-light
                +layout-xs() {
                    color $cl-text-dark
                }
                .apps__btn-wrapper {
                    background #000000
                    +layout-xs() {
                        transform scale(1)
                        background $cl-secondary-light
                    }
                }
            }
        }
        svg {
            z-index 2
        }
    }
</style>
