import { TWENTY_FOUR_HOURS_CACHE_CONTROL } from '@/constants'

const Document = () => import('@/views/doc/index.vue')

export default [
    {
        path: 'user_agreement',
        name: 'user-agreement',
        component: Document,
        meta: {
            key: 'agreement',
            cacheControl: TWENTY_FOUR_HOURS_CACHE_CONTROL
        }
    },
    {
        path: 'privacy_policy',
        name: 'privacy-policy',
        component: Document,
        meta: {
            key: 'policy',
            cacheControl: TWENTY_FOUR_HOURS_CACHE_CONTROL
        }
    },
    {
        path: 'rules',
        name: 'rules',
        component: Document,
        meta: {
            key: 'rules',
            cacheControl: TWENTY_FOUR_HOURS_CACHE_CONTROL
        }
    },
    {
        path: 'fivereasons',
        name: 'fivereasons',
        component: Document,
        meta: {
            key: 'fivereasons',
            cacheControl: TWENTY_FOUR_HOURS_CACHE_CONTROL
        }
    },
    {
        path: 'contacts',
        name: 'contacts',
        component: Document,
        meta: {
            key: 'contacts',
            cacheControl: TWENTY_FOUR_HOURS_CACHE_CONTROL
        }
    }
]
