<template>
    <UiPopup
        ref="popup"
        class="ban-error-popup"
        type="message"
    >
        <template #header>
            <div class="ban-error-popup__header">
                <IconCancel class="ban-error-popup__icon" />

                <h2 class="ban-error-popup__title">
                    {{ $t('banned') }}
                </h2>
            </div>
        </template>

        <template #default>
            <span class="ban-error-popup__text">{{ causeType }}</span>
        </template>
    </UiPopup>
</template>

<i18n>
{
    "ru": {
        "banned": "Вы забанены",
        "cause": "причина бана: %{cause}",
        "spam": "спам",
        "swears": "оскорбления"
    },
    "en": {
        "banned": "Account is banned",
        "cause": "ban reason: %{cause}",
        "spam": "spam",
        "swears": "swears"
    }
}
</i18n>

<script>
import IconCancel from '@/assets/svg/cancel.icon.svg'

export default {
    name: 'PopupBanError',

    components: {
        IconCancel
    },

    data () {
        return {
            cause: ''
        }
    },

    computed: {
        causeType () {
            if (this.cause) {
                const cause = this.cause.toLocaleLowerCase()

                return this.$t('cause', { cause: this.$t(cause) })
            }

            return ''
        }
    },

    mounted () {
        this.$root.$on('popup-ban-error', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-ban-error', this.open)
    },

    methods: {
        open ({ cause }) {
            this.cause = cause

            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        }
    }
}
</script>

<style lang="stylus" scoped>
.ban-error-popup
    &__header
        display flex
        justify-content center
        align-items center
        color $cl-error

    &__title
        margin 0
        font-size 2.1rem
        font-weight $font-weight-bold

    &__icon
        margin-right 1rem

    &__text
        font-size 1.6rem
</style>
