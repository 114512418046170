<template>
    <router-view id="app" />
</template>

<i18n src="@/i18n/base.json"></i18n>

<script>
import config from "@/config.json";
import { mapState } from "vuex";

export default {
    name: "App",

    data() {
        return {
            $isLoading: false,
        };
    },

    head() {
        const head = {
            meta: [
                {
                    id: "description",
                    name: "description",
                    content: this.description,
                },
                { id: "og:title", property: "og:title", content: this.title },
                {
                    id: "og:description",
                    property: "og:description",
                    content: this.description,
                },
                { id: "og:image", property: "og:image", content: this.image },
                {
                    id: "og:image:type",
                    property: "og:image:type",
                    content: "image/jpeg",
                },
                {
                    id: "og:image:width",
                    property: "og:image:width",
                    content: "1200",
                },
                {
                    id: "og:image:height",
                    property: "og:image:height",
                    content: "630",
                },
                { id: "og:type", property: "og:type", content: "website" },
                {
                    id: "og:site_name",
                    property: "og:site_name",
                    content: "BettingInsider",
                },
                { id: "og:url", property: "og:url", content: this.canonical },
                {
                    id: "og:locale",
                    property: "og:locale",
                    content: this.$t("locale"),
                },
                {
                    id: "fb:app_id",
                    property: "fb:app_id",
                    content: config.facebookAppId,
                },
                {
                    id: "twitter:site",
                    name: "twitter:site",
                    content: "@bettinginsider",
                },
                {
                    id: "twitter:url",
                    name: "twitter:url",
                    content: this.canonical,
                },
                {
                    id: "twitter:creator",
                    name: "twitter:creator",
                    content: "@sports_topbet",
                },
                {
                    id: "twitter:card",
                    property: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    id: "twitter:title",
                    property: "twitter:description",
                    content: this.title,
                },
                {
                    id: "twitter:description",
                    property: "twitter:description",
                    content: this.description,
                },
                {
                    id: "apple-itunes-app",
                    name: "apple-itunes-app",
                    content: "app-id=1356073739",
                },
            ],

            link: [
                { id: "canonical", rel: "canonical", href: this.canonical },
                { id: "image_src", rel: "image_src", href: this.image },
            ],

            title: this.title,
        };

        // не проставляем hreflang для 404 и пагинации
        if (
            this.code === 200 &&
            !this.page &&
            !this.isBookmakers &&
            !this.isBlog &&
            !this.isMatch
        ) {
            head.link.push(
                {
                    id: "hreflang-en",
                    rel: "alternate",
                    href: this.canonicalEn,
                    hreflang: "en",
                },
                {
                    id: "hreflang-ru",
                    rel: "alternate",
                    href: this.canonicalRu,
                    hreflang: "ru",
                },
                {
                    id: "hreflang-ru-RU",
                    rel: "alternate",
                    href: this.canonicalRu,
                    hreflang: "ru-RU",
                },
                {
                    id: "hreflang-ru-BY",
                    rel: "alternate",
                    href: this.canonicalRu,
                    hreflang: "ru-BY",
                },
                {
                    id: "hreflang-ru-UA",
                    rel: "alternate",
                    href: this.canonicalRu,
                    hreflang: "ru-UA",
                },
                {
                    id: "hreflang-ru-KZ",
                    rel: "alternate",
                    href: this.canonicalRu,
                    hreflang: "ru-KZ",
                }
            );
        }

        return head;
    },

    computed: {
        ...mapState("page", ["code"]),

        title() {
            return this.$t("title");
        },

        description() {
            return this.$t("description");
        },

        canonical() {
            return this.$canonical.get(this.$route.path);
        },

        canonicalRu() {
            return this.$canonical.get(this.$route.path, "ru");
        },

        canonicalEn() {
            return this.$canonical.get(this.$route.path, "en");
        },

        isBlog() {
            return (
                this.$route.name === "blog" || this.$route.name === "blog-post"
            );
        },

        isBookmakers() {
            return (
                this.$route.name === "bookmaker" ||
                this.$route.name === "bookmakers"
            );
        },

        isMatch() {
            return this.$route.name === "match";
        },

        page() {
            return this.$route.params.page;
        },

        lang() {
            return this.$i18n.locale;
        },

        image() {
            if (this.lang === "en") {
                return "https://pictures.betting.team/image/7c43e091-4e30-42a4-83f7-6b6c02d641ae";
            }

            return "https://pictures.betting.team/image/7e5772f7-2e45-415f-b2b1-fdb5af42c428";
        },
    },

    created() {
        // I really need reactive data in router
        // eslint-disable-next-line no-underscore-dangle
        const descr = Object.getOwnPropertyDescriptor(this._data, "$isLoading");

        Object.defineProperty(this.$router, "$isLoading", descr);
    },

    mounted() {
        this.$store.dispatch("auth/check");
    },
};
</script>

<style rel="stylesheet/stylus" lang="stylus">
@import "assets/styles/main.styl"
</style>
