/**
 *  Валидация цвета в hex
 * @param color {string} - цвет в hex
 * @returns {boolean}
 */
export default function isValidHex (color) {
    if (!color || typeof color !== 'string') {
        return false
    }

    let localColor = color

    if (localColor.substring(0, 1) === '#') {
        localColor = localColor.substring(1)
    }

    switch (localColor.length) {
        case 3:
            return /^[0-9A-F]{3}$/i.test(localColor)
        case 6:
            return /^[0-9A-F]{6}$/i.test(localColor)
        default:
            return false
    }
}
