import user from './user'
import predictions from './predictions'
import betEditorial from './betEditorial'

export default {
    namespaced: true,
    modules: {
        user,
        predictions,
        betEditorial
    }
}
