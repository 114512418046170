/**
 * Получение таблицы группового этапа
 * @param {Number} championshipId - ID Чемпионата
 * @returns {string}
 */
export function fetchTable ({ championshipId }) {
    return `{
        stat_season(id: "${championshipId}") {
          id
          stages{
            name
            teamStanding{
              total{
                points
                played
                win
                loss
                draw
                points
                goalsFor
                goalsAgainst
                groupName
                rank
                team{
                  id
                  name
                  picture(productType: TRIBUNA, format: LOGO) {
                    resize (width: "32", height: "32")
                  }
                }
              }
            }
          }
        }
      }`
}

/**
 * Получение виджета конкурса прогнозистов
 * @param {Number} contestId - ID конкурса
 * @returns {string}
 */
export function contestWidgetById ({ contestId }) {
    return `{
      contest(contestID: "${contestId}") {
        prizes {
          place
          prize
        }
        params {
          ... on RoiContestParams {
              tournamentID
              numberOfBetsToGetPrize
              numberOfBetsForParticipation
          }
        }
        participants(first: 10) {
          edges {
            node {
              user {
                id
                name
                avatar
              }
              rank
              points
              statistics {
                ... on ParticipantRoiStatistics {
                  numberOfBets
                }
              }
            }
          }
        }
      }
    }`
}
