<template>
    <div
        v-scroll.window="onScroll"
        class="subheader"
        :class="subheaderClass"
    >
        <slot />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import scroll from '@/directives/scroll'

export default {
    name: 'TheSubheaderWrapper',

    directives: {
        scroll
    },

    data () {
        return {
            isFixed: false,
            maxScroll: 0,
            lastScrollPosition: 0,
            lastScrollDirection: 1
        }
    },

    computed: {
        ...mapGetters('ads', ['isDesktopBrandingVisible']),
        subheaderClass () {
            return {
                'subheader--hide': this.isHideContainer,
                'subheader--header-has-ad': this.isDesktopBrandingVisible
            }
        },

        isHideContainer () {
            return this.lastScrollDirection > 0 && this.lastScrollPosition > 280
        }
    },

    watch: {
        // При смене маршрута отображаем TheSubheader
        $route () {
            this.lastScrollPosition = 0
        }
    },

    methods: {
        onScroll () {
            const { scrollTop } = document.scrollingElement

            // Для того что бы шапка не уезжала при закрытии мобильного меню
            if (scrollTop !== 0) {
                this.lastScrollDirection = scrollTop > this.lastScrollPosition ? 1 : -1
                this.lastScrollPosition = scrollTop
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
    .subheader
        position sticky
        top $header-height
        z-index 10
        display flex
        align-items center
        background $cl-white
        box-shadow 0 0 1rem 0 rgba(0, 0, 0, .08)
        transition transform .4s ease-out

        &--header-has-ad
            top $header-height-with-ad

        @media(max-width 800px)
            top 5rem

        &--hide
            transform translate(0, -100%)
            transition transform .4s ease

            @media(max-width 600px)
                transform translate(0, -200%)
</style>
