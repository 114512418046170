import { TEN_MINUTES_CACHE_CONTROL } from '@/constants'

export default [
    {
        path: '',
        name: 'home',
        components: {
            default: () => import('@/views/home/index.vue'),
            right: () => import('@/views/home/right.vue')
        },
        meta: {
            pageType: 'main',
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    }
]
