/* eslint-disable no-underscore-dangle */
import throttle from 'throttleit'

function getThrottle (el) {
    let value = 50

    if (el && el.attributes && el.attributes.throttle) {
        value = +el.attributes.throttle.value
    }

    return value
}

function getTarget (args = {}) {
    if (args.window) {
        return window
    }

    return null
}

function unbind (el) {
    if (!el._onScroll) {
        return
    }

    const { event, options, target, touchmove } = el._onScroll

    target.removeEventListener('scroll', event, options)

    if (touchmove) {
        target.removeEventListener('touchmove', event, options)
    }

    delete el._onScroll
}

function inserted (el, binding) {
    const callback = binding.value
    const options = binding.options || { passive: true }
    const throttleVal = getThrottle(el)
    const target = getTarget(binding.modifiers) || el
    let touchmove = false

    if (binding.modifiers && binding.modifiers.touchmove) {
        touchmove = true
    }

    el._onScroll = {
        event: throttle(() => callback(), throttleVal),
        callback,
        options,
        target,
        touchmove
    }

    target.addEventListener('scroll', el._onScroll.event, options)

    if (touchmove) {
        target.addEventListener('touchmove', el._onScroll.event, options)
    }
}

export default {
    name: 'scroll',
    inserted,
    unbind
}
