<template>
    <section
        v-show="isOpen"
        class="popup__overlay"
    >
        <div
            v-if="isOpen"
            class="popup__popup"
        >
            <div class="popup__header">
                <slot name="header" />

                <CloseIcon
                    class="popup__close"
                    @click.native="close"
                />
            </div>

            <slot />
        </div>
    </section>
</template>

<script>
import CloseIcon from '@/assets/svg/close-bold.icon.svg'
import setDisableScroll from '@/utils/set-disable-scroll'

export default {
    name: 'UiPopup',

    components: {
        CloseIcon
    },

    data () {
        return {
            isOpen: false
        }
    },

    methods: {
        close () {
            this.isOpen = false
            setDisableScroll({
                disabled: false
            })

            this.$emit('close')
        },

        open () {
            this.isOpen = true
            setDisableScroll({
                disabled: true
            })
        }
    }
}
</script>

<style lang="stylus">
.popup
    &__overlay
        position fixed
        top 0
        left 0
        height 100%
        width 100%
        background-color rgba(0, 0, 0, .65)
        z-index 999
        display flex
        align-items center
        justify-content center

    &__popup
        background $cl-white
        border-radius un(1)
        overflow hidden

        +layout-sm()
            width 100%
            align-self flex-end
            border-radius un(2) un(2) 0 0

    &__header
        width 100%
        overflow hidden
        position relative
        min-height un(4)
        border-bottom .1rem solid $cl-secondary-light

    &__close
        position absolute
        right un(1)
        top un(1)
        fill $cl-text-dark
        z-index 3
        cursor pointer

</style>
