<template>
    <div class="ui-tph">
        <div
            v-for="i in rows"
            :key="i"
            class="ui-tph__row"
        >
            <span class="line" />
        </div>

        <core-media
            :mq="['xxs', 'xs']"
            class="ui-tph__row"
        >
            <span class="line line--small" />
        </core-media>
    </div>
</template>

<script>
export default {
    name: 'UiTextPlaceholder',
    props: {
        rows: {
            type: Number,
            default: 1
        }
    }
}
</script>

<style lang="stylus" scoped>
    .ui-tph {
        &__row {
            display flex
            margin-bottom 1.1rem
            align-items center

            &:last-child {
                margin-bottom 0
            }
        }

        .row-1 .line:nth-child(1) {
            flex 0 1 15%
        }
    }

    .line {
        background-color $cl-secondary-light
        height 1.2rem
        flex 0 1 85%
        border-radius .6rem

        +layout-sm() {
            height 1rem
            flex 0 1 100%
        }

        &--small {
            flex 0 1 55%
        }
    }
</style>
