import { TEN_MINUTES_CACHE_CONTROL } from '@/constants'

const scheduledTournamentsAliases = [
    'belarusian-premier-league',
    'championsleagie',
    'europaleague',
    'rpl',
    'epl',
    'primera',
    'seriaa',
    'liga1',
    'hollandpl',
    'portugalprimera',
    'turkeysuperliga',
    'fnl',
    'championship',
    'ukrainapl',
    'bundesliga',
    'uefa-euro'
].join('|')

const tournamentRoute = {
    components: {
        default: () => import('@/views/tournament/index.vue'),
        right: () => import('@/views/tournament/right.vue')
    },
    meta: {
        cacheControl: TEN_MINUTES_CACHE_CONTROL
    }
}

export default [
    {
        path: ':sport(football)/tournament/:alias',
        name: 'tournament',
        ...tournamentRoute
    },

    {
        path: `:sport(football)/tournament/:alias(${scheduledTournamentsAliases})/calendar`,
        name: 'tournament-schedule',
        components: {
            default: () => import('@/views/tournament/schedule/index.vue'),
            right: () => import('@/views/tournament/right.vue')
        },
        meta: {
            cacheControl: TEN_MINUTES_CACHE_CONTROL
        }
    },
    {
        path: ':sport(football)/tournament/:alias/page/:page(\\d{1,3})?',
        name: 'tournament-page',
        redirect: {
            name: 'tournament'
        }
    },
    {
        path: 'tournament/:alias',
        redirect: to => ({
            name: 'tournament',
            params: {
                ...to.params,
                sport: 'football'
            }
        })
    },
    {
        path: 'tournament/:alias/page/:page(\\d{1,3})?',
        redirect: to => ({
            name: 'tournament-page',
            params: {
                ...to.params,
                sport: 'football'
            }
        })
    }
]
