<template>
    <span>{{ bet }}</span>
</template>

<script>
import { mapState } from 'vuex'
import betFilter from '@/filters/bet'

export default {
    name: 'BetRate',

    props: {
        rate: {
            type: [Number, String],
            default: 0
        }
    },

    computed: {
        ...mapState('bet', ['oddsFormat']),

        bet () {
            return betFilter(this.rate, this.oddsFormat)
        }
    }
}
</script>
