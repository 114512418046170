import {
    PREDICTION_SMALL_DESCRIPTION_TRESHOLD_ID
} from '@/constants'

const HIDE_PREDICTIONS_BEFORE_DATE = +new Date(2020, 0, 1)

/**
 * Проверяет нужно ли отдавать 404 для страницы прогноза
 *
 * @param {Object} prediction - Объект прогноза
 * @returns {Boolean}
 */
export function predictionPageIs404 ({ ctime }) {
    return ctime * 1000 < HIDE_PREDICTIONS_BEFORE_DATE
}

/**
 * Проверяет нужно ли переадресовывать запрос на страницу профиля
 *
 * @param {Object} prediction - Объект прогноза
 * @returns {Boolean}
 */
export function shouldReirectToProfile ({ description, id }) {
    if (id <= PREDICTION_SMALL_DESCRIPTION_TRESHOLD_ID && !description) {
        return true
    }

    if (id > PREDICTION_SMALL_DESCRIPTION_TRESHOLD_ID && description.length <= 100) {
        return true
    }

    return false
}

/**
 * Проверяет доступна ли для прогноза отдельная страница
 *
 * @param {Object} prediction - Объект прогноза
 * @returns {Boolean}
 */
export default function hasFootballPredictionPage ({ id, description, ctime }) {
    if (predictionPageIs404({ ctime })) {
        return false
    }

    if (shouldReirectToProfile({ id, description })) {
        return false
    }

    return true
}
