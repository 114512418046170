<template>
    <component
        :is="tag"
        :to="to"
        class="ui-avatar"
        :class="classes"
    >
        <span
            role="img"
            :aria-label="alt"
            :style="style"
            :class="{ 'default': !src }"
            class="ui-avatar__img"
        />

        <slot />
    </component>
</template>

<script>
import { addGetArguments } from '@/ui/utils/url-api'

export default {
    name: 'UiAvatar',

    props: {
        /**
         * URL аватарки
         */
        src: {
            type: String,
            default: ''
        },

        /**
         * aria-label описание изображения
         */
        alt: {
            type: String,
            default: ''
        },

        /**
         * Размер аватарки
         * `xxs, xs, sm, normal, md, l`
         */
        size: {
            type: String,
            default: 'sm',
            validator (value) {
                return ['xxs', 'xs', 'sm', 'md', 'l', 'xl'].includes(value)
            }
        },

        /**
         * [Ссылка](https://router.vuejs.org/ru/api/#%D0%B2%D1%85%D0%BE%D0%B4%D0%BD%D1%8B%D0%B5-%D0%BF%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D1%8B-router-link)
         */
        to: {
            type: Object,
            default: null
        },

        /**
         * Тип аватарки
         * [user | team]
         */
        type: {
            type: String,
            default: 'user'
        }
    },

    computed: {
        tag () {
            if (this.to) {
                return 'router-link'
            }

            return 'span'
        },

        style () {
            if (this.src) {
                return {
                    'background-image': `url(${this.srcImage})`
                }
            }

            return null
        },

        srcImage () {
            return addGetArguments(this.src, this.sizeArguments)
        },

        classes () {
            const classes = [
                `is-${this.type}`
            ]

            if (this.size) {
                classes.push(`is-size--${this.size}`)
            }

            return classes
        },

        sizeArguments () {
            // хорошо бы использовать window.devicePixelRatio
            // но на бэке нет window и это может привести к тому что дом не будут сообветствовать с тем что получим на клиенте
            // поэтому заложимся всегда на ретину 2x
            const RETINA_PIXEL_RATIO = 2
            const size = this.sizeValue * RETINA_PIXEL_RATIO

            return `w=${size}&h=${size}&c=1`
        },

        sizeValue () {
            const sizes = {
                xl: 120,
                l: 64,
                md: 40,
                sm: 32,
                xs: 24,
                xxs: 16
            }

            return sizes[this.size]
        }
    }
}
</script>

<style lang="stylus" scoped>
$avatar-size--xl = 8rem
$avatar-size--l = 6.4rem
$avatar-size--md = 4rem
$avatar-size--sm = 3.2rem
$avatar-size--xs = 2.4rem
$avatar-size--xxs = 1.6rem

$user = '~/source/assets/images/avatar-zero-data-user.svg'
$team = '~/source/assets/images/avatar-zero-data-team.svg'
$tournament = '~/source/assets/images/avatar-zero-data-tournament.svg'

.ui-avatar
    display inline-flex
    align-items center
    justify-content center
    overflow hidden
    color $cl-secondary
    position relative

    &.is-tournament
        border-radius 50%

        & .ui-avatar__img.default
            background-image url($tournament)

    &.is-team
        border-radius 50%

        & .ui-avatar__img.default
            top 52%
            background-image url($team)
            border-radius .8rem

    &.is-user
        border-radius .8rem

        & .ui-avatar__img.default
            background-image url($user)
            border-radius .8rem

    &__img
        display block
        width 100%
        height 100%
        background-position top center
        background-size cover

    &.is-size--xl
        width $avatar-size--xl
        height $avatar-size--xl

    &.is-size--l
        width $avatar-size--l
        height $avatar-size--l

    &.is-size--md
        width $avatar-size--md
        height $avatar-size--md

    &.is-size--sm
        width $avatar-size--sm
        height $avatar-size--sm

    &.is-size--xs
        width $avatar-size--xs
        height $avatar-size--xs

    &.is-size--xxs
        width $avatar-size--xxs
        height $avatar-size--xxs

</style>
