import Api from '@/api'
import Vue from 'vue'

export default {
    namespaced: true,
    state () {
        return {
            matches: {}
        }
    },
    getters: {
        events: (state) => ({ id }) => state.matches[id] || []
    },
    actions: {
        fetch ({ commit }, { id }) {
            const params = { id, include: 'player,team' }

            return Api.trbna({ path: 'match/events', params })
                .then(json => commit('events', { id, events: json.serialized }))
                .catch(() => Promise.resolve())
        }
    },
    mutations: {
        events (state, { events, id }) {
            if (!state.matches[id]) {
                Vue.set(state.matches, id, events)
            } else {
                state.matches[id] = events
            }
        }
    }
}

