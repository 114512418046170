import { mapActions, mapState, mapGetters } from 'vuex'
import {
    EMAIL_NOT_CONFIRMED,
    PHONE_NOT_CONFIRMED,
    USER_IS_BANNED
} from '@/constants'

export default {
    computed: {
        ...mapGetters('profile', ['my']),
        ...mapState('bet/select', ['odd', 'saved', 'editMode', 'betOpenedFromReminder']),
        ...mapState('auth', ['isAuth'])
    },

    methods: {
        ...mapActions({
            getProfile: 'profile/getMyProfile',
            makeBet: 'bet/select/bet',
            curtainChangeMode: 'curtain/changeMode',
            curtainChangeToPrevMode: 'curtain/changeToPrevMode'
        }),

        onBet (place = 'other') {
            if (!this.isAuth) {
                this.$pivik.event('social', 'start', 'bet')

                return this.$root.$emit('auth')
            }

            const action = this.odd.parent_id ? 'repeat' : 'bet'

            this.$root.$emit('popup-bet-success', {
                pending: true,
                promoLink: this.odd.promo
            })

            return this.makeBet()
                .then(() => {
                    this.curtainChangeToPrevMode()
                    this.onBetSuccess(place, action)
                })
                .catch(this.onBetError)
        },

        onBetSuccess (place, action) {
            this.$pivik.event('bet', place, action)

            this.$root.$emit('popup-bet-success-close')
            this.$root.$emit('popup-bet-success', {
                promoLink: this.odd.promo,
                pending: false
            })
        },

        async onBetError (err) {
            // Обработка ошибок
            this.$root.$emit('popup-bet-success-close')

            if (err.extensions && err.extensions.code) {
                const { code } = err.extensions

                // Если сменился кэф
                if (code === 'rate_changed') {
                    const rateFrom = this.odd.rate
                    const newRate = err.extensions.new_rate
                    const win = newRate * this.odd.amount

                    // TODO переделать на action
                    this.$store.commit('bet/select/update', { rate: newRate })

                    return this.$root.$emit('popup-bet-updated', {
                        win,
                        rateFrom,
                        rateTo: this.odd.rate
                    })
                }

                // если недостаточно средств
                if (code === 'not_enough_credits') {
                    await this.getProfile()

                    return this.$root.$emit('popup-bet-balance', {
                        balance: this.my.balance,
                        lacks: Math.abs(this.my.balance - this.odd.amount)
                    })
                }

                // если матч закончился
                if (code === 'match_finished') {
                    return this.$root.$emit('popup-bet-finished')
                }

                // если отсутствует ставка
                if (code === 'not_active_odds') {
                    return this.$root.$emit('popup-bet-noactive', { matchId: this.odd.match_id })
                }

                // Лимит на один и тот же кэф
                if (code === 'bet_limit_for_odds') {
                    return this.$root.$emit('popup-bet-limit')
                }

                // email не подтвержден
                if (code === EMAIL_NOT_CONFIRMED) {
                    return this.$root.$emit('popup-confirm-email')
                }

                // телефон не подтвержден
                if (code === PHONE_NOT_CONFIRMED) {
                    return this.$root.$emit('popup-confirm-phone')
                }

                if (code === USER_IS_BANNED) {
                    const { banInfo } = err.extensions

                    this.$root.$emit('popup-ban-error', banInfo)
                }

                return ''
            }

            return this.$root.$emit('popup-error')
        }
    }
}
