<template>
    <div class="ui-timer">
        <template v-if="days < 1">
            <span class="ui-timer__h">{{ hours | zero }}</span>
            <span class="ui-timer__m">:{{ min | zero }}</span>
            <span
                v-if="!notSeconds"
                class="ui-timer__s"
            >:{{ sec | zero }}</span>
        </template>

        <span
            v-else
            class="ui-timer__days"
        >{{ $tc('days', days) }}</span>
    </div>
</template>

<i18n>
{
    "ru": {
        "days": "{n} день | {n} дня | {n} дней"
    },
    "en": {
        "days": "{n} day | {n} days"
    }
}
</i18n>

<script>
export default {
    name: 'UiTimer',

    filters: {
        zero (value) {
            return value < 10 ? (`0${value}`) : value
        }
    },

    props: {
        /**
         * Время остановки таймера
         */
        // eslint-disable-next-line vue/require-default-prop
        time: Number,
        /**
         * Если true, то time умножится на 1000
         */
        serverTimestamp: {
            type: Boolean,
            default: false
        },

        /**
         * Показывать секунды
         */
        notSeconds: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            interval: null,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0
        }
    },

    watch: {
        time () {
            this.start()
        }
    },

    created () {
        this.update()
    },

    mounted () {
        this.start()
    },

    beforeDestroy () {
        this.stop()
    },

    methods: {
        start () {
            this.stop()
            if (!this.$isServer) {
                this.update()
                this.interval = setInterval(() => this.update(), 1000)
            }
        },

        stop () {
            clearInterval(this.interval)
        },

        update () {
            const now = Date.now()
            const finish = this.serverTimestamp ? (this.time * 1000) : this.time
            const left = (finish - now) / 1000

            if (left <= 0) {
                this.days = 0
                this.hours = 0
                this.min = 0
                this.sec = 0
            } else {
                this.days = Math.floor(left / (60 * 60 * 24))
                this.hours = Math.floor((left / (60 * 60)) % 24)
                this.min = Math.floor((left / 60) % 60)
                this.sec = Math.floor(left % 60)
            }
        }
    }
}
</script>

<style lang="stylus">
.ui-timer
    font-family $font-family-content
</style>
