<template>
    <div
        class="the-header-drop-menu"
        :class="classes"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'TheHeaderDropMenu',

    props: {
        direction: {
            type: String,
            default: 'left'
        }
    },

    computed: {
        classes () {
            return {
                [`dir-${this.direction}`]: true
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.the-header-drop-menu
    min-width 20rem
    display flex
    flex-direction column
    background-color $cl-text-dark

    &.dir-right
        text-align right

        > *
            &::after
                right .2rem
                left auto

    > *
        position relative
        height 5.2rem
        padding 0 2rem
        line-height @height
        color #fff
        white-space nowrap
        border-top .1rem solid rgba($cl-white, .1)
        transition color .3s
        cursor pointer
        text-decoration none

        &:hover
            color rgba(#fff, .7)
            text-decoration none

            &::after
                opacity 1

        &::after
            content ""
            position absolute
            top 20%
            left .2rem
            width .2rem
            height 60%
            background-color $cl-primary
            border-radius .1rem
            transition opacity .3s
            opacity 0
</style>
