<template>
    <div
        v-if="text && link"
        class="pr-get-freebet"
        @mouseleave="onLeave"
        @mouseenter="onHover"
        @click="goToPromo"
    >
        <div class="pr-get-freebet__wrap">
            <span
                :style="{ color: textColor }"
                class="pr-get-freebet__text"
            >
                {{ text }}
            </span>

            <div
                v-if="color"
                :style="{ color: color }"
                class="pr-get-freebet__icon"
            >
                <GiftBoxIcon />
            </div>
        </div>


        <transition name="freebet-popup">
            <FreebetPopup
                v-if="show || !isClosed"
                :closed="isClosed"
                :link="link"
                :logo="secondaryImage"
                :text="secondaryText"
                :marker="marker"
                :text-color="secondaryTextColor"
                :price="thirdText"
                :disclaimer="disclaimer"
                class="pr-get-freebet__popup"
                @close="closePromo"
            >
                <button
                    :style="popupButtonStyles"
                    class="pr-get-freebet__btn bi-button"
                    @click.stop="goToPromo"
                >
                    {{ buttonTitle }}
                </button>
            </FreebetPopup>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FreebetPopup from '@/components/DFP/DFPPromoGetFreebetPopup.vue'
import GiftBoxIcon from '@/assets/svg/giftbox.icon.svg'

export default {
    name: 'DFPPromoGetFreebet',

    components: {
        FreebetPopup,
        GiftBoxIcon
    },

    props: {
        link: {
            type: String,
            default: ''
        },

        text: {
            type: String,
            default: ''
        },

        color: {
            type: String,
            default: ''
        },

        textColor: {
            type: String,
            default: ''
        },

        secondaryImage: {
            type: String,
            default: ''
        },

        secondaryText: {
            type: String,
            default: ''
        },

        secondaryTextColor: {
            type: String,
            default: ''
        },

        buttonTitle: {
            type: String,
            default: ''
        },

        buttonTitleColor: {
            type: String,
            default: ''
        },

        buttonBackgroundColor: {
            type: String,
            default: ''
        },

        thirdText: {
            type: String,
            default: ''
        },

        disclaimer: {
            type: String,
            default: ''
        },

        marker: {
            type: String,
            default: ''
        },

        bookmaker: {
            type: String,
            default: 'unknown'
        }
    },

    data () {
        return {
            isClosed: true,
            show: false,
            showImg: false,
            placement: 'notification_desktop'
        }
    },

    computed: {
        ...mapState('storage', ['showGetFreebet']),

        popupButtonStyles () {
            return {
                color: this.buttonTitleColor,
                backgroundColor: this.buttonBackgroundColor
            }
        }
    },

    mounted () {
        console.info({ marker: this.marker })
        this.isClosed = new Date(this.showGetFreebet) > Date.now()
        this.$pivik.event('dfp_show', this.placement, this.bookmaker)
    },

    methods: {
        closePromo () {
            this.isClosed = true
            this.show = false

            const date = new Date()

            this.$store.commit('storage/set', {
                showGetFreebet: new Date(date.setDate(date.getDate() + 7))
            })
        },

        goToPromo () {
            this.$pivik.event('dfp_click', this.placement, this.bookmaker)
            window.open(this.link, '_blank')
        },

        onLeave () {
            this.show = false
        },

        onHover () {
            this.show = true
        }
    }
}
</script>

<style lang="stylus" scoped>
    .pr-get-freebet
        position relative
        padding 0 2.4rem
        display flex
        align-items center
        justify-content center
        user-select none
        text-decoration none
        background $cl-text-dark

        &__wrap
            box-sizing border-box
            padding 0 1.2rem
            height 4rem
            display flex
            flex-direction row
            align-items center
            border-radius .4rem
            background rgba($cl-secondary-dark, .1)
            text-align center
            cursor pointer

        &__text
            flex 1
            overflow hidden
            white-space nowrap

        &__icon
            z-index 2
            margin-left 1rem
            transform translateY(-.2rem)
            width 1.4rem
            height 2rem

            img
                width 100%
                height auto

        &__popup
            width 20.5rem
            position absolute
            top 100%
            left 50%
            transform translateX(-50%)
            margin-left auto
            margin-right auto

        &__btn
            align-self stretch
            margin-top 1.6rem
            border-radius .8rem
            height 4rem
            color $cl-white
            background #FF6A13
            border none
            outline none
            text-align center
            cursor pointer


    .freebet-popup-enter, .freebet-popup-leave-to
        opacity 0
        transform translate(-50%, -100%)

    .freebet-popup-enter-active, .freebet-popup-leave-active
        transition transform .3s cubic-bezier(.25, .45, .45, .94), opacity .3s
        z-index -2
</style>
