export function createComplaint ({ type, id, objectType }) {
    return `createComplaint(
                complaint: {
                    type: ${type},
                    objectID: "${id}",
                    objectClass: ${objectType}
                }
            ) {
                complaint {
                    id
                    type
                }
            }`
}
