<template>
    <section>
        <UiPopup
            ref="popup"
            type="message"
            :disabled="pending"
        >
            <template slot="header">
                <span v-if="pending">{{ $t('header-wait') }}</span>
                <span v-else>{{ $t('header') }}</span>
            </template>

            <span v-if="pending">{{ $t('text-wait') }}</span>
            <span v-else>{{ $t('text') }}</span>

            <Loader
                v-if="pending"
                class="popup-bet-success__pending"
            />
            <div
                v-else
                class="popup-bet-success__complete"
            />
            <section
                v-if="advertisement.link"
                class="popup-bet-adv"
            >
                <AdDisclaimer
                    v-if="advertisement.disclaimer"
                    :text="advertisement.disclaimer"
                    class="popup-bet-adv__disclaimer"
                    @click.native.stop
                />

                <div
                    class="popup-bet-adv__text"
                    :style="advTextStyle"
                >
                    {{ advertisement.text }}
                </div>
                <a
                    :href="advertisement.link"
                    :style="advButtonStyle"
                    target="_blank"
                    class="popup-bet-adv__button popup-bet-adv__button--stretch"
                    @click="creativeClickHandler"
                >
                    {{ advertisement.buttonTitle }}
                </a>
                <span
                    class="bi-subtitle-3"
                >
                    {{ advertisement.marker }}
                </span>
            </section>
        </UiPopup>
        <RefreshableDfp
            v-if="isAdNeeded"
            :placement="placement"
            @receive="prepareAd"
        />
    </section>
</template>

<i18n>
{
    "ru": {
        "header": "Ставка сделана",
        "header-wait": "Подождите",
        "text": "Желаем удачи!",
        "text-wait": "Ваша ставка обрабатывается"
    },
    "en": {
        "header": "Successful",
        "header-wait": "Wait",
        "text": "Good luck!",
        "text-wait": "Your bet in process"
    }
}
</i18n>

<script>
import Loader from '@/components/common/loader/index.vue'
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'
import AdDisclaimer from '@/components/DFP/AdDisclaimer.vue'
import adaptColor from '@/utils/adapt-color'

export default {
    name: 'PopupBetSuccess',

    components: {
        Loader,
        RefreshableDfp,
        AdDisclaimer
    },

    data () {
        return {
            pending: true,
            promoLink: '',
            isAdNeeded: false,
            advertisement: {},
            placement: 'web_tip'
        }
    },

    computed: {
        advButtonStyle () {
            return {
                color: this.advertisement.buttonTitleColor,
                backgroundColor: this.advertisement.buttonBackgroundColor
            }
        },

        advTextStyle () {
            return {
                color: this.advertisement.textColor
            }
        }
    },

    watch: {
        pending (value) {
            if (!value) {
                this.$pivik.event('dfp_show', this.placement, this.advertisement.bookmaker)
            }
        }
    },

    mounted () {
        this.$root.$on('popup-bet-success', this.open)
        this.$root.$on('popup-bet-success-close', this.close)
        this.$root.$on('popup-bet-init', this.requestAd)
    },

    beforeDestroy () {
        this.$root.$off('popup-bet-success', this.open)
        this.$root.$off('popup-bet-success-close', this.close)
        this.$root.$off('popup-bet-init', this.requestAd)
    },

    methods: {
        open ({ promoLink, pending }) {
            this.promoLink = promoLink
            this.pending = pending
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        },

        requestAd () {
            this.isAdNeeded = true
        },

        /**
         * Сохраняем только нужные поля.
         * @param {Object} message
         * @param {string} message.link
         * @param {string} message.text
         * @param {string} message.textColor
         * @param {string} message.buttonBackgroundColor
         * @param {string} message.buttonTitle
         * @param {string} message.buttonTitleColor
         */
        prepareAd (message) {
            this.advertisement.link = message.link
            this.advertisement.text = message.text
            this.advertisement.textColor = adaptColor(message.textColor)
            this.advertisement.buttonBackgroundColor = adaptColor(message.buttonBackgroundColor)
            this.advertisement.buttonTitle = message.buttonTitle
            this.advertisement.buttonTitleColor = adaptColor(message.buttonTitleColor)
            this.advertisement.disclaimer = message.disclaimer
            this.advertisement.bookmaker = message.bookmakerName || 'unknown'
            this.advertisement.marker = message.marker
        },

        creativeClickHandler () {
            this.$pivik.event('dfp_click', this.placement, this.advertisement.bookmaker)
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus">
.popup-bet-success
    &__complete
        height 4rem

    &__pending
        height 4rem
        margin 6rem auto

.popup-bet-adv
    &__text
        margin-bottom 1rem
        text-align center
        font-size $font-size-base
        color $cl-text-dark

    &__disclaimer
        position absolute
        left 1rem
        top 1rem

    &__button
        button(5, 'contained', 'primary')

</style>
