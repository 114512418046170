export default function prepareLinesForMatchStat (lines, type) {
    const result = []

    lines.forEach(item => {
        if (type) {
            if (type === item.line.type) {
                result.push(item)
            }
        } else {
            const lineIndex = result.findIndex(line => line.type === item.line.type)

            if (lineIndex > -1) {
                result[lineIndex].items.push(item)
            } else {
                result.push({
                    type: item.line.type,
                    name: item.line.name,
                    items: [item]
                })
            }
        }
    })

    return result
}
