import Api from '@/api'

export default {
    namespaced: true,

    actions: {
        deleteMessages (context, { id }) {
            return Api.trbna({
                method: 'post',
                path: 'user/delete_content',
                params: { data: { id } }
            })
        },

        deleteComments (context, { id, isBanned = true }) {
            return Api.graphql(`{banUserComments(userID: "${id}", isBanned: ${isBanned}) {success}}`, { type: 'mutation' })
        },

        ban (context, { id, seconds }) {
            return Api.graphql(`{banUser(userID: "${id}" ${seconds ? `, banUntil: ${seconds}` : ''}){
                        profile {
                            id
                            name
                            avatar
                    }}}`, { type: 'mutation' })
        },

        unban (context, { id }) {
            return Api.graphql(`{
                    unbanUser(userID: "${id}"){
                        profile {
                            id
                            name
                            avatar
                    }}}`, { type: 'mutation' })
        }
    }
}
