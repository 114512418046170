<template>
    <UiPopup
        ref="popup"
        @close="onClose"
    >
        <template #header>
            <div class="auth-form__tabs">
                <button
                    :class="getTabClasses('register')"
                    @click="setForm('register')"
                >
                    {{ $t('sign-up') }}
                </button>

                <button
                    :class="getTabClasses('log-in')"
                    @click="setForm('log-in')"
                >
                    {{ $t('sign-in') }}
                </button>
            </div>
        </template>

        <section class="auth-form__body">
            <form
                class="auth-form"
                @submit.prevent="submitForm"
            >
                <div class="input-wrapper input-wrapper--email">
                    <label
                        class="input-label"
                        for="auth-email"
                    >
                        E-mail
                    </label>
                    <input
                        id="auth-email"
                        v-model="email"
                        :class="{ 'input--error': emailError }"
                        :placeholder="$t('email-placeholder')"
                        class="input"
                        type="email"
                        name="email"
                        autocomplete="email"
                    >
                    <span
                        v-if="emailError"
                        class="input__error-label"
                    >
                        {{ emailError }}
                    </span>
                </div>
                <div class="input-wrapper input-wrapper--password">
                    <label
                        class="input-label"
                        for="auth-password"
                    >
                        {{ $t('password') }}
                    </label>
                    <div class="password-container">
                        <PasswordInput
                            v-model="password"
                            input-id="auth-password"
                            :has-error="!!passwordError"
                            :autocomplete="isAuthForm ? 'current-password' : 'new-password'"
                            :placeholder="$t('password-placeholder')"
                            name="password"
                            @blur="validatePassword"
                        />
                    </div>

                    <span
                        v-if="passwordError"
                        class="input__error-label"
                    >
                        {{ passwordError }}
                    </span>
                </div>

                <div
                    v-if="isRegisterForm"
                    class="input-wrapper input-wrapper--confirm"
                >
                    <label
                        class="input-label"
                        for="auth-password-repeat"
                    >
                        {{ $t('repeat-password') }}
                    </label>
                    <div class="password-container">
                        <PasswordInput
                            v-model="confirm"
                            :has-error="!!confirmError"
                            :placeholder="$t('password-placeholder')"
                            input-id="auth-password-repeat"
                            name="password"
                            autocomplete="new-password"
                            @blur="validateConfirm"
                        />
                    </div>
                    <span
                        v-if="confirmError"
                        class="input__error-label"
                    >
                        {{ confirmError }}
                    </span>
                </div>

                <div v-if="isAuthForm">
                    <button
                        class="link-styled-button"
                        type="button"
                        @click="recoveryPassword"
                    >
                        {{ $t('forgot-password') }}
                    </button>
                </div>

                <button
                    :disabled="isActionButtonDisabled"
                    class="action-button"
                >
                    {{ actionText }}
                </button>

                <span
                    v-if="error"
                    class="input__error-label"
                >
                    {{ error }}
                    <a
                        v-if="errorType === 'user_is_banned'"
                        href="mailto:support@betting.team"
                    >support@betting.team</a>
                </span>

                <p
                    v-if="isRegisterForm"
                    class="auth-form__description"
                >
                    {{ $t('terms-agreement') }}
                    <router-link
                        class="link-info-inner"
                        :to="policyPageRoute"
                    >
                        {{ $t('policy') }}
                    </router-link>
                </p>

                <div class="auth-form__footer">
                    <button
                        class="social-button"
                        type="button"
                        @click="social('vkontakte')"
                    >
                        <VkIcon
                            class="svg-icon"
                        />
                    </button>
                    <button
                        class="social-button"
                        type="button"
                        @click="social('google')"
                    >
                        <GoogleIcon
                            class="svg-icon"
                        />
                    </button>
                </div>
            </form>

            <div
                v-if="isRegisterForm"
                class="auth-form__advantages"
            >
                <div
                    v-for="index in [1,2,3,4]"
                    :key="`auth-advantage-${index}`"
                    class="auth-form__advantage"
                >
                    <span
                        :class="`auth-form__icon--${index}`"
                        class="auth-form__icon"
                    />
                    <p class="auth-form__advantage-heading">
                        {{ $t(`advantage-${index}-heading`) }}
                    </p>
                    <p class="auth-form__advantage-text">
                        {{ $t(`advantage-${index}-text`) }}
                    </p>
                </div>
            </div>
        </section>
    </UiPopup>
</template>

<i18n>
{
    "ru": {
        "email-placeholder": "Введите e-mail",
        "password": "Пароль",
        "repeat-password": "Повторите пароль",
        "password-placeholder": "Введите пароль",
        "log-in": "Войти",
        "register": "Зарегистрироваться",
        "sign-up": "Регистрация",
        "sign-in": "Вход",
        "forgot-password": "Забыли пароль?",
        "reset-password": "Сбросить пароль",
        "password-recovery": "Восстановление пароля",
        "terms-agreement": "При регистрации вы принимаете",
        "policy": "политику конфиденциальности",
        "advantage-1-heading": "Прогнозы без риска",
        "advantage-2-heading": "Реальные призы за прогнозы",
        "advantage-3-heading": "Общение с капперами",
        "advantage-4-heading": "Честная статистика",
        "advantage-1-text": "игра на виртуальную валюту",
        "advantage-2-text": "конкурсы каждый месяц",
        "advantage-3-text": "советы на любую игру в прематче или лайве",
        "advantage-4-text": "автоматический трек-рекорд капперов",
        "fb-meta-info": "* – социальная сеть принадлежит компании Meta Platforms Inc., которая признана экстремистской организацией и запрещена на территории РФ"
    },
    "en": {
        "email-placeholder": "E-mail",
        "password": "Password",
        "repeat-password": "Confirm password",
        "password-placeholder": "Password",
        "log-in": "Log in",
        "register":  "Register",
        "sign-up": "Sign up",
        "sign-in": "Sign in",
        "terms-agreement": "By clicking 'Sign up' you confirm that you've agreed with",
        "policy": "the service policy",
        "forgot-password": "Forgot your password?",
        "reset-password": "Reset password",
        "password-recovery": "Password recovery",
        "advantage-1-heading": "Risk-free tips",
        "advantage-2-heading": "Receive real prizes for your tips",
        "advantage-3-heading": "Chat with tipsters",
        "advantage-4-heading": "Reliable stats",
        "advantage-1-text": "Bet using virtual currency",
        "advantage-2-text": "Competitions every month",
        "advantage-3-text": "Tips for every game – pre-match and live",
        "advantage-4-text": "Tipsters’ auto track-record",
        "fb-meta-info": "* - the social network belongs to Meta Platforms Inc., which is recognized as an extremist organization and banned in the territory of the Russian Federation"
    }
}
</i18n>

<script>
import { mapActions } from 'vuex'
import UiPopup from '@/ui/components/Popup/Popup.vue'
import PasswordInput from '@/components/common/PasswordInput/PasswordInput.vue'
import facebookSDK from '@/utils/facebook'
import googleSDK from '@/utils/google'
import ErrorMessage from '@/mixins/error-message'
// import FacebookIcon from '@/assets/svg/login-facebook.icon.svg'
import VkIcon from '@/assets/svg/login-vk.icon.svg'
import GoogleIcon from '@/assets/svg/login-google.icon.svg'

const FORM_TYPES = new Set(['log-in', 'register'])

export default {
    name: 'NewAuthForm',

    components: {
        UiPopup,
        // FacebookIcon,
        VkIcon,
        GoogleIcon,
        PasswordInput
    },

    mixins: [ErrorMessage],

    data () {
        return {
            pending: false,
            pendingSocial: '',
            email: '',
            password: '',
            confirm: '',
            currentForm: 'log-in',
            emailError: '',
            passwordError: '',
            confirmError: '',
            error: '',
            errorType: '',
            policyPageRoute: {
                name: this.isRu ? 'user-agreement' : 'privacy-policy'
            }
        }
    },

    computed: {
        isRegisterForm () {
            return this.currentForm === 'register'
        },

        isAuthForm () {
            return this.currentForm === 'log-in'
        },

        isRu () {
            return this.$i18n.locale === 'ru'
        },

        actionText () {
            return this.isRegisterForm ? this.$t('sign-up') : this.$t('sign-in')
        },

        hasEmptyFields () {
            if (!this.email || !this.password) {
                return true
            }

            if (this.isRegisterForm && !this.confirm) {
                return true
            }

            return false
        },

        isActionButtonDisabled () {
            return this.hasEmptyFields
        }
    },

    mounted () {
        this.$root.$on('auth', this.open.bind(this, 'log-in'))
        this.$root.$on('register', this.open.bind(this, 'register'))
    },

    methods: {
        ...mapActions('auth', {
            authByEmail: 'signin',
            authBySocial: 'social'
        }),

        setForm (formType) {
            this.error = ''
            this.emailError = ''
            this.passwordError = ''
            this.confirmError = ''

            if (FORM_TYPES.has(formType)) {
                this.currentForm = formType
            }
        },

        getTabClasses (tabType) {
            return {
                'auth-form__tab': true,
                'auth-form__tab--active': tabType === this.currentForm,
                'auth-form__tab--inactive': tabType !== this.currentForm,
                [`auth-form__tab--${tabType}`]: true
            }
        },

        recoveryPassword () {
            this.close()
            this.$root.$emit('password-reset')
        },

        open (type) {
            this.setForm(type)
            this.$refs.popup.open()

            // todo: можно подумать как это можно оптимизировать, например не грузить в хроме
            // Инициализируем sdk для того что бы в некоторых браузерах (например safari)
            // открывались попапы авторизации
            // не грузим вк, тк авторизация реализованна без использования sdk
            facebookSDK.ready()
            googleSDK.ready()
        },

        close () {
            this.$refs.popup.close()
        },

        submitForm () {
            if (!this.validatePassword() || !this.validateConfirm()) {
                return
            }

            this.error = ''
            this.emailError = ''
            this.passwordError = ''
            this.confirmError = ''

            if (this.isAuthForm) {
                this.auth()
            }

            if (this.isRegisterForm) {
                this.register()
            }
        },

        onClose () {
            this.email = ''
            this.password = ''
            this.confirm = ''
        },

        async auth () {
            if (this.pending) {
                return
            }

            this.pending = true

            this.$pivik.event('social', 'start', 'button')

            try {
                await this.authByEmail({
                    loc: this.$i18n.locale,
                    email: this.email,
                    password: this.password
                })

                this.pending = false

                this.$pivik.event('social', 'auth', 'email')

                this.close()
            } catch (errors) {
                this.showError(errors)
                this.pending = false
            }
        },

        async register () {
            this.pending = true

            try {
                await this.$store.dispatch('auth/signup', {
                    loc: this.$i18n.locale,
                    password: this.password,
                    email: this.email
                })

                this.error = ''
                this.pending = false

                this.$pivik.event('social', 'reg', 'email')

                this.close()
            } catch (errors) {
                this.showError(errors)
                this.pending = false
            }
        },

        async social (socialName) {
            if (this.pendingSocial) {
                return
            }

            this.pendingSocial = socialName

            try {
                const result = await this.authBySocial({
                    socialName,
                    loc: this.$i18n.locale
                })

                this.pendingSocial = null

                this.$pivik.event('social', result.type, socialName)

                this.close()
            } catch (errors) {
                this.pendingSocial = null
            }
        },

        validatePassword () {
            if (this.password.length < 6) {
                this.passwordError = this.getErrorMessageByCode('invalid_short_password')

                return false
            }

            this.passwordError = ''

            return true
        },

        validateConfirm () {
            if (this.isRegisterForm && this.password !== this.confirm) {
                this.confirmError = this.getErrorMessageByCode('invalid_confirm_password')

                return false
            }

            this.confirmError = ''

            return true
        },

        showError (errors) {
            const code = (((errors || []) || {}).extensions || {}).code || ''
            const errorText = this.getErrorMessageByCode(code)

            this.errorType = code

            if (['invalid_input', 'email_in_use'].indexOf(code) > -1) {
                this.emailError = errorText
            } else if (['invalid_short_password'].indexOf(code) > -1) {
                this.passwordError = errorText
            } else if (['invalid_confirm_password'].indexOf(code) > -1) {
                this.confirmError = errorText
            } else {
                this.error = errorText
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.facebook {
    &__text {
        font-size 10px
    }
}
.input
    input()
    width 100%

.input-label
    input-label()
    margin-top un(2)

.password-container
    position relative

.eye-icon
    position absolute
    top 12px
    right 15px

.auth-form
    padding un(3)
    box-sizing border-box
    width un(50)
    max-width 100%

    +layout-xs()
        padding-top 0
        order 1

    &__footer
        display flex
        justify-content space-between
        margin-top un(3)

    &__tabs
        display flex
        justify-content center
        max-width un(50)

        +layout-xs()
            max-width 100%

    &__tab
        &--inactive
            button(5, 'text', 'text')
            border-bottom 1px solid transparent
            color $cl-secondary-dark

        &--active
            button(5, 'text', 'primary')
            border-bottom 1px solid $cl-primary

    // не вместе с предыдущим блоком, так как нужно повысить специфичность
    &__tab
        letter-spacing 1px
        padding 0 un(5)

    &__advantage
        box-shadow 0 0 20px rgba(0, 0, 0, 0.06)
        height 95px
        width 100%
        margin un(2) 0
        border-radius un(1)
        padding un(1.5)
        box-sizing border-box

        +layout-xs()
            // stylus не хочет работать с un внутри calc
            width calc(50% - 8px)
            height auto

            &:nth-child(2n)
                margin-left un(2)


    &__advantage-heading
        font-size $fs-normal
        font-weight $font-weight-bold
        margin 0

    &__advantage-text
        font-size un(1.5)
        margin 0

    &__advantages
        width un(38)
        padding-right un(3)

        +layout-xs()
            display flex
            justify-content space-between
            width 100%
            flex-wrap wrap
            padding-right 0
            padding-top un(1.5)
            order 0

    &__description
        text-align center

    &__body
        display flex
        overflow auto

        +layout-xs()
            flex-direction column
            max-height un(50)
            max-width un(50)
            margin 0 auto

    &__icon
        width un(3)
        height un(3)
        display block
        background no-repeat left top / 100% 100%
        margin-bottom un(1)
        &--1
            background-image url("@/assets/images/register/point1.png")
        &--2
            background-image url("@/assets/images/register/point2.png")
        &--3
            background-image url("@/assets/images/register/point3.png")
            width un(3.5)
            height un(3.5)
        &--4
            background-image url("@/assets/images/register/point4.png")


.link-styled-button
    button-link()
    font-size $fs-normal
    margin-top un(1)

.action-button
    button(5, 'contained')
    margin-top un(3)

.social-button
    button(5, 'contained')
    background-color rgba($cl-primary, 5%)
    width 100%

    &__facebook {
        color #000
    }

    &:not(:first-child)
        margin-left un(2)

    &:hover
        box-shadow 0 2px 5px rgba(0, 0, 0, .12)

    &:active
        background-color rgba($cl-primary-overlaid, 5%)

.svg-icon
    vertical-align middle
    position relative
    top -1px

</style>
