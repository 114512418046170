import Api from '@/api'
import logger from '@/utils/logger'
import { fetchNews } from '@/api/graphql/blog'
import { EURO2020_BLOG_SECTION_NAME } from '@/constants'

const SET_NEWS = 'SET_NEWS'

export default {
    namespaced: true,

    state () {
        return {
            news: []
        }
    },

    actions: {
        async fetch ({ commit }) {
            try {
                const queryString = fetchNews({ first: 6, section: EURO2020_BLOG_SECTION_NAME })
                const result = await Api.graphql(queryString)

                commit(SET_NEWS, result.list)
            } catch (err) {
                logger.error(err)

                commit(SET_NEWS, [])

                return Promise.reject(err)
            }

            return Promise.resolve()
        }
    },

    mutations: {
        [SET_NEWS] (state, news) {
            state.news = news
        }
    }
}
