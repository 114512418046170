<template>
    <div class="ui-slider">
        <span class="ui-slider__min">{{ min | number }}</span>

        <RangeSlider
            :value="val"
            :step="step"
            :min="min"
            :max="max"
            class="slider"
            @input="update"
        >
            <IconMenu slot="knob" />
        </RangeSlider>

        <span class="ui-slider__max">{{ max | number }}</span>
    </div>
</template>

<script>
import RangeSlider from 'vue-range-slider'
import IconMenu from '@/ui/icons/menu.vue'
import number from '@/filters/number'

export default {
    name: 'UiSlider',

    filters: {
        number
    },

    components: {
        RangeSlider,
        IconMenu
    },

    props: {
        /**
         * Значение ползунка, допускается использовать v-model
         */
        // eslint-disable-next-line vue/require-default-prop
        value: [Number, String],
        /**
         * Максимальное значение ползунка
         */
        max: {
            type: Number,
            default: 1000
        },

        /**
         * Минимальное значение ползунка
         */
        min: {
            type: Number,
            default: 0
        },

        /**
         * Шаг
         */
        step: {
            type: Number,
            default: 1
        }
    },

    data () {
        return {
            val: 0
        }
    },

    watch: {
        value (val) {
            this.val = val
        }
    },

    mounted () {
        this.val = this.value
    },

    methods: {
        update (val) {
            this.$emit('input', val.toFixed())
        }
    }
}
</script>

<style lang="stylus">
    $slider-height = 15px
    $rail-height = 3px
    $knob-size = 29px

    $rail-color = #e2e2e2
    $knob-color = linear-gradient(147deg, #FBFBFC 0%, #F6F6F7 100%)

    $knob-border = 1px solid #f5f5f5
    $knob-shadow = 1px 1px rgba(0, 0, 0, 0.2)

    .ui-slider {
        position relative
        padding 15px 0 3px
        font-size $font-size-base-sub

        &__min,
        &__max {
            position absolute
            top 0
            color $cl-secondary
            font-family $font-family-content
        }
        &__min {
            left 0
        }
        &__max {
            right 0
        }

        .range-slider {
            display inline-block
            padding 0
            height $slider-height
            width 100%
        }

        .range-slider-inner {
            display inline-block
            position relative
            height 100%
            width 100%
        }

        .range-slider-rail,
        .range-slider-fill {
            display block
            position absolute
            top 50%
            left 0
            height $rail-height
            transform translateY(-50%)
            border-radius 2px / 1.5px
        }

        .range-slider-rail {
            width 100%
            background-color $rail-color
        }

        .range-slider-fill {
            background-color $cl-primary
        }

        .range-slider-knob {
            display block
            position absolute
            top 50%
            left 0
            box-sizing border-box
            height $knob-size
            width $knob-size
            line-height $knob-size
            border $knob-border
            border-radius 50%
            border 1px solid $cl-white
            background-image $knob-color
            box-shadow 0 2px 8px 0 #E2E2E2
            margin-top: -($knob-size / 2)
            margin-left: -($knob-size / 2)
            cursor pointer
            text-align center
            transition transform 0.2s

            &:active {
                transform scale(1.2)
            }

            .ui-icon {
                width 13px
                height 13px
                pointer-events none
                color $cl-disabled-light
            }
        }

        .range-slider-hidden {
            display none
        }
    }
</style>
