export default [
    {
        path: 'search/:type?',
        name: 'search',
        components: {
            default: () => import('@/views/search/index.vue'),
            right: () => import('@/views/search/right.vue')
        }
    }
]
