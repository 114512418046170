import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { IS_BETTING_COM } from '@/constants'

import authStore from '@/store/modules/auth'
import betStore from '@/store/modules/bet'
import blogStore from '@/store/modules/blog'
import bookmakersRatingStore from '@/store/modules/bookmakers-rating'
import curtainStore from '@/store/modules/curtain'
import matchStore from '@/store/modules/match'
import newSportsStore from '@/store/modules/new-sports'
import newSportsMatchStore from '@/store/modules/new-sports-match'
import pageStore from '@/store/modules/page'
import predictionsStore from '@/store/modules/predictions'
import profileStore from '@/store/modules/profile'
import reportStore from '@/store/modules/report'
import searchStore from '@/store/modules/search'
import storageStore from '@/store/modules/storage'
import tournamentsStore from '@/store/modules/tournaments'
import widgetsStore from '@/store/modules/widgets'
import favoritesStore from '@/store/modules/favorites'
import euro2020Store from '@/store/modules/euro2020'
import adsStore from '@/store/modules/ads/ads'

// Ключи переменных из state которые сохраняются в localStorage
const PERSISTED_STATE_LIST = [
    'auth.userId',
    'curtain.custom',
    'curtain.isOpened',
    'curtain.savedMatchSelector',
    'storage',
    'bet.select.odd',
    'bet.select.saved'
]

Vue.use(Vuex)

export function createStore () {
    const debug = process.env.NODE_ENV !== 'production'
    const plugins = []
    const modules = {}

    try {
        if (!Vue.prototype.$isServer) {
            // Используем плагин vuex-persistedstate только на клиент
            plugins.push(createPersistedState({ paths: PERSISTED_STATE_LIST }))

            modules.storage = storageStore
        }
    } catch (err) {
        console.log(err)
    }

    modules.auth = authStore
    modules.bet = betStore
    modules.blog = blogStore
    modules.bookmakersRating = bookmakersRatingStore
    modules.curtain = curtainStore
    modules.match = matchStore
    modules.page = pageStore
    modules.predictions = predictionsStore
    modules.profile = profileStore
    modules.report = reportStore
    modules.search = searchStore
    modules.tournaments = tournamentsStore
    modules.widgets = widgetsStore
    modules.favorites = favoritesStore
    modules.euro2020 = euro2020Store
    modules.ads = adsStore
    modules.newSports = newSportsStore

    if (!IS_BETTING_COM) {
        modules.newSportsMatch = newSportsMatchStore
    }

    return new Vuex.Store({
        strict: debug,
        plugins,
        modules
    })
}

/**
 * Переопределет значение ключей __INITIAL_STATE__ на значения из localStorage
 * @param serverState __INITIAL_STATE__
 * @param clientState
 * @returns {__INITIAL_STATE__}
 */
export function setPersistedState (serverState, clientState) {
    PERSISTED_STATE_LIST.forEach(path => {
        const statePathKeys = path.split('.')

        let localServerState = serverState
        let localClientState = clientState

        statePathKeys.forEach((state, index) => {
            if (index === statePathKeys.length - 1) {
                localServerState[state] = localClientState[state]
            } else {
                localServerState = localServerState[state]
                localClientState = localClientState[state]
            }
        })
    })

    return serverState
}
