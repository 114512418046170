import {
    ONE_HOUR_CACHE_CONTROL,
    CONTEST_PAGE_ROUTE_NAME
} from '@/constants'

export default [
    {
        path: 'rating/overall',
        redirect: {
            name: 'rating'
        }
    },
    {
        path: 'rating',
        name: 'rating',
        components: {
            default: () => import('@/views/rating/index.vue'),
            right: () => import('@/views/rating/right.vue'),
            subheader: () => import('@/views/rating/subheader.vue')
        },
        meta: {
            pageType: 'rating',
            pageId: 'all',
            cacheControl: ONE_HOUR_CACHE_CONTROL
        }
    },
    {
        path: 'rating/month',
        name: 'rating-month',
        components: {
            default: () => import('@/views/rating/tipster/index.vue'),
            right: () => import('@/views/rating/right.vue'),
            subheader: () => import('@/views/rating/subheader.vue')
        },
        meta: {
            pageType: 'rating',
            pageId: 'month',
            cacheControl: ONE_HOUR_CACHE_CONTROL
        }
    },
    {
        path: 'rating/stars',
        name: 'rating-stars',
        components: {
            default: () => import('@/views/rating/stars/index.vue'),
            right: () => import('@/views/rating/right.vue'),
            subheader: () => import('@/views/rating/subheader.vue')
        },
        meta: {
            pageType: 'rating',
            pageId: 'stars',
            cacheControl: ONE_HOUR_CACHE_CONTROL
        }
    },
    {
        path: 'rating/:webname',
        name: CONTEST_PAGE_ROUTE_NAME,
        components: {
            default: () => import('@/views/rating/contest/index.vue'),
            right: () => import('@/views/rating/right.vue'),
            subheader: () => import('@/views/rating/subheader.vue')
        },
        meta: {
            pageType: 'rating',
            cacheControl: ONE_HOUR_CACHE_CONTROL
        }
    }
]
