// https://github.com/intlify/vue-i18n-loader
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default function createI18n () {
    return new VueI18n({
        // default locale
        locale: 'ru',

        pluralizationRules: {
            ru (number, choicesLength) {
                const count = Math.abs(number)
                let result = 0

                if (count.toString().indexOf('.') > -1) {
                    result = 1
                } else if (!count) {
                    result = choicesLength === 4 ? 3 : 2
                } else if (count % 10 === 1 && count % 100 !== 11) {
                    result = 0
                } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
                    result = 1
                } else {
                    result = 2
                }

                return result
            },

            en (number, choicesLength) {
                let result = 0

                if (number === 1) {
                    result = 0
                } else if (number === 0) {
                    result = choicesLength === 3 ? 2 : 0
                } else {
                    result = 1
                }

                return result
            }
        }
    })
}
