const bookmakers = [
    'bkwinline',
    'bkbetcity',
    'pari',
    'bkleon',
    'bkmarathonbet',
    'mostbet',
    'bkvulkanbet',
    'tennisi'
].join('|')

export default {
    EN: [
        // BI-2966: Редирект с liobet профилей на пост в блоге
        {
            path: '/profile/(131808|155899)',
            redirect: '/blog/lionsbet'
        }
    ],
    RU: [
        // BIW-335: редирект на букмекеров
        {
            path: `/blog/:webname(${bookmakers})`,
            redirect: '/bookmakers/:webname'
        },
        {
            path: '/wiki/:webname(bettery|lootbetbk)',
            redirect: '/bookmakers/:webname'
        },
        {
            path: '/blog/(1xstavka|odinxstavka)',
            redirect: '/bookmakers/1xstavka'
        }
    ]
}
