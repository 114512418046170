/**
 * Добавление get аргументов
 * @param url - url
 * @param tail - хвост
 * @returns {string}
 */
export function addGetArguments (url, tail) {
    const splitUrl = url.split('?', 2)

    if (splitUrl[1]) {
        // eslint-disable-next-line no-param-reassign
        tail = `${splitUrl[1]}&${tail}`
    }

    return `${splitUrl[0]}?${tail}`
}

export default {
    addGetArguments
}
