<template>
    <UiPopup
        ref="popup"
        type="message"
    >
        <template slot="header">
            {{ $t('header') }}
        </template>

        {{ $t('text') }}

        <template slot="footer">
            <button
                class="popup__button popup__button--stretch"
                :pending="pending"
                @click="resend"
            >
                {{ $t('btn') }}
            </button>
        </template>
    </UiPopup>
</template>

<i18n>
    {
        "ru": {
            "header": "Подтвердите E-mail",
            "text": "Пройдите по ссылке из письма, отправленного вам при регистрации",
            "btn": "Выслать заново"
        },
        "en": {
            "header": "Confirm your email address",
            "text": "Please follow the link in the message to activate the account",
            "btn": "Resend"
        }
    }
</i18n>

<script>
export default {
    name: 'PopupConfirmEmail',

    data () {
        return {
            pending: false
        }
    },

    mounted () {
        this.$root.$on('popup-confirm-email', this.open)
    },

    beforeDestroy () {
        this.$root.$off('popup-confirm-email', this.open)
    },

    methods: {
        open () {
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        },

        async resend () {
            this.pending = true
            try {
                await this.$store.dispatch('auth/sendConfirmEmail')
                this.close()
                this.pending = false

                setTimeout(() => {
                    this.$root.$emit('popup-confirm-email-success')
                }, 400)
            } catch (e) {
                this.pending = false
            }
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus">
.popup
    &__button
        button(5, 'contained', 'primary')

</style>
