import Api from '@/api'

export default {
    namespaced: true,
    state () {
        return {
            matches: []
        }
    },
    actions: {
        fetch ({ commit }, { matchId, count = 5 }) {
            const params = {
                include: 'team,tournament,odds',
                id: matchId,
                count
            }

            return Api.trbna({ path: 'matches/head_to_head', params, maps: ['home_team', 'away_team'] })
                .then(json => commit('matches', json.serialized))
                .catch(() => {
                    commit('matches', [])

                    return Promise.resolve()
                })
        }
    },
    mutations: {
        matches (state, matches = []) {
            state.matches = matches
        }
    }
}

