<template>
    <PopupRefundForm>
        <template slot="title">
            {{ $t('header') }}
        </template>

        <template v-if="defaultError">
            <!-- eslint-disable vue/no-v-html -->
            <div
                class="popup-refund-error__text"
                v-html="$t('default')"
            />
            <!-- eslint-disable vue/no-v-html -->
            <button
                class="popup-refund-error__button popup-refund-error__button--stretch"
                @click="refund"
            >
                {{ $t('again') }}
            </button>
        </template>

        <template v-else>
            <div
                class="popup-refund-error__text"
                v-html="$t(error)"
            />
            <button
                class="popup-refund-error__button popup-refund-error__button--stretch"
                @click="close"
            >
                {{ $t('close') }}
            </button>
        </template>
    </PopupRefundForm>
</template>

<i18n>
{
    "ru": {
        "header": "Ошибка",
        "again": "Попробовать снова",
        "close": "Понятно",
        "default": "Бывает, что-то идет не так. Попробуйте снова или напишите нам о проблеме на <a class='link-info' href='mailto:support@betting.team'>support@betting.team</a> и мы вам поможем.",
        "email_not_confirmed": "Вы забыли подтвердить почту. Если письма от Betting Insider нет во входящих, проверьте папку спам. Долго не приходит подтверждение? Напишите нам <a class='link-info' href='mailto:support@betting.team'>support@betting.team</a>",
        "cannot_refund": "Вы и так богач! Чтобы пополнить счет, баланс должен быть меньше 1000 и отсутствовали незавершенные ставки"
    },
    "en": {
        "header": "Error",
        "again": "Try again",
        "close": "Ok",
        "default": "Something went wrong. Try again or contact us: <a href='mailto:support@betting.team'>support@betting.team</a>",
        "email_not_confirmed": "You must confirm your email. If you do not receive the confirmation message within a few minutes of signing up, please check your Junk Spam folder. If you have any problems or questions, please do not hesitate to contact us via email <a class='link-info' href='mailto:support@betting.team'>support@betting.team</a>",
        "cannot_refund": "Baby, you're a rich man! You can add virtual currency if you have less than 1000 coins and no pending bets"
    }
}
</i18n>

<script>
import PopupRefundForm from '@/components/Popups/Refund/PopupRefundForm.vue'

export default {
    name: 'PopupRefundError',

    components: {
        PopupRefundForm
    },

    props: {
        error: {
            type: String,
            default: ''
        }
    },

    computed: {
        defaultError () {
            return this.$t(this.error) === this.error
        }
    },

    methods: {
        close () {
            this.$emit('close')
        },

        refund () {
            this.$emit('refund')
        }
    }
}
</script>

<style lang="stylus" scoped>
    .popup-refund-error {
        &__text {
            margin-bottom 3.5rem
            text-align center
            line-height 2rem
        }
        &__button {
            button(5, 'contained', 'primary')
        }
    }
</style>
