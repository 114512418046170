import throttle from 'throttleit'

export default {
    data () {
        return {
            resizeEvent: null,
            resizeThrottle: 60
        }
    },
    methods: {
        onResize () {}
    },
    mounted () {
        this.resizeEvent = throttle(() => this.onResize(), this.resizeThrottle)
        window.addEventListener('resize', this.resizeEvent)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resizeEvent)
    }
}
