import Api from '@/api'
import { fetchSmartPredictions } from '@/api/graphql/predictions-football'
import logger from '@/utils/logger'

const SET_RECOMMENDATION_IDS = 'SET_RECOMMENDATION_IDS'


export default {
    namespaced: true,

    state () {
        return {
            recommendationsIds: []
        }
    },

    getters: {
        recommendations (state, getters, rootState) {
            return state.recommendationsIds.map(id => rootState.predictions.predictions[id])
        }
    },

    actions: {
        async fetch ({ commit, dispatch }, options = {}) {
            const exclude = options.exclude || []

            const params = {
                include: 'match,match.team,user,match.tournament,odds',
                first: 5 + exclude.length
            }

            try {
                const result = await Api.graphql(`{${fetchSmartPredictions(params)}}`)

                const predictions = result.list
                    .filter(({ id, isPaywalled }) => !exclude.includes(id) && !isPaywalled)
                    .slice(0, 5)

                dispatch('predictions/updatePredictionsMap', {
                    predictions
                }, { root: true })

                commit(SET_RECOMMENDATION_IDS, predictions.map(({ id }) => id))
            } catch (err) {
                logger.error(err)

                return Promise.reject(err)
            }
        }
    },

    mutations: {
        [SET_RECOMMENDATION_IDS] (state, recommendationsIds) {
            state.recommendationsIds = recommendationsIds
        }
    }
}
