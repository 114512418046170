/**
 * Записываем в объект window pageType и pageId
 *
 * @param {Object} routeTo - новый роут
 * @param {Object} store - объект стора приложения
 */
export default function setWindowPageData (routeTo, store) {
    let pageId = ''

    if (routeTo.name === 'match') {
        const { match } = store.state.match

        pageId = match.id + (match.webname ? `__${match.webname}` : '')
    }

    if (routeTo.name === 'match-center') {
        pageId = routeTo.params.date || 'today'
    }

    if (['blog-page', 'blog'].includes(routeTo.name)) {
        const isBlogOrWiki = ['blog', 'wiki'].includes(routeTo.params.section)

        if (isBlogOrWiki) {
            pageId = routeTo.params.subsection || 'all'
        } else {
            pageId = routeTo.params.section
        }
    }

    if (routeTo.name === 'blog-post') {
        pageId = routeTo.params.alias
    }

    if (routeTo.name === 'rating-contest') {
        pageId = routeTo.params.webname
    }

    if (['bookmakers', 'bookmakers-default'].includes(routeTo.name)) {
        const bookmakersRating = store.state.bookmakersRating.rating

        pageId = bookmakersRating && bookmakersRating.webname
    }

    if ([
        'bookmaker',
        'bookmaker-reviews',
        'bookmaker-bonuses'
    ].includes(routeTo.name)) {
        pageId = routeTo.params.webname
    }

    if ([
        'profile',
        'profile-stat',
        'predictions-item',
        'predictions-item-old'
    ].includes(routeTo.name)) {
        pageId = routeTo.params.id
    }

    window.bettingInsider = {
        pageData: {
            pageType: routeTo.meta.pageType,
            pageId: pageId || routeTo.meta.pageId
        }
    }
}
