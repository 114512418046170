/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue'
/* eslint-disable no-underscore-dangle */
import 'es6-promise/auto'
import { setPersistedState } from '@/store'
import Api from '@/api'
import setWindowPageData from '@/utils/set-window-page-data'
import logger from '@/utils/logger'
import { createApp, recurseAsyncData, registerModuleByComponents } from './app'
import Config from '../web.conf.json'

// получение текущей локали
const locale = document.documentElement.lang || Config['loc-default']
// eslint-disable-next-line vue/require-name-property
const { app, router, store } = createApp({ locale })

// переопределение значений ключей __INITIAL_STATE__ на значения из localStorage
if (window.__INITIAL_STATE__) {
    const state = setPersistedState(window.__INITIAL_STATE__, store.state)

    store.replaceState(state)
}

router.onReady(() => {
    // устанавливаем локаль по умолчанию
    Api.setLoc(locale)
    app.$i18n.locale = locale
    const startComponents = router.getMatchedComponents()

    setWindowPageData(router.currentRoute, app.$store)

    registerModuleByComponents(store, startComponents)

    router.beforeResolve((to, from, next) => {
        // массив компонентов сопоставленные для текущего маршрута
        const matchedComponents = router.getMatchedComponents(to)

        if (app.$Progress.state.timer === null) {
            app.$Progress.start()
        }

        router.$isLoading = true

        registerModuleByComponents(store, matchedComponents)

        const promises = []

        matchedComponents.forEach((component) => {
            promises.push(...recurseAsyncData(
                {
                    store,
                    route: to,
                    isServer: false
                },
                component
            ))
        })

        return Promise.all(promises)
            .then(() => {
                router.$isLoading = false
                if (to.name === '404') {
                    const error = new Error(`Page not found (path ${to.fullPath}`)

                    error.code = 404

                    throw error
                }

                app.$store.dispatch('page/setStatusCode', 200)

                setWindowPageData(to, app.$store)

                setTimeout(() => (app.$Progress.finish()), 600)

                requestAnimationFrame(next)
            })
            .catch((err = {}) => {
                router.$isLoading = false

                if (err.code === 301 && err.route) {
                    return next({ ...err.route })
                }

                if (err.code === 301 && err.url) {
                    router.push(err.url)

                    return
                }

                app.$Progress.fail()

                if (err.code) {
                    app.$store.dispatch('page/setStatusCode', err.code)
                }

                logger.error({ err, to, msg: 'Routing error' })

                next()
            })
    })

    return Promise.all([
        registerModuleByComponents(store, startComponents)
    ]).then(() => {
        if (!store.state.page.mountOff) {
            console.info('mount')
            app.$mount('#app')
        } else {
            console.info('not mount')
            app.$Progress.finish()
        }
    })
}, (err) => {
    logger.error(err)
})

router.onError((err) => {
    logger.error(err)
})

if (module.hot) {
    // eslint-disable-next-line global-require
    const api = require('vue-hot-reload-api')

    api.install(Vue)

    if (!api.compatible) {
        throw new Error(
            'vue-hot-reload-api is not compatible with the version of Vue you are using.'
        )
    }

    module.hot.accept()
}
