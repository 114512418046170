/* eslint-disable no-console */
import {
    GPT,
    cmd,
    addEventListener,
    removeEventListener,
    destroySlots,
    addBanner,
    removeBanner
} from '@/utils/gpt'
import configs from '@/adv.config.json'

let bannerIndex = 0

function parseSize (size = '') {
    const result = size.split('x')

    return [+result[0], +result[1]]
}

function parseSizes (sizes = []) {
    return sizes.map(parseSize)
}

function parseSizeMap (map = []) {
    return [parseSize(map[0]), map[1].map(parseSize)]
}

function createMapping (mapping = []) {
    const result = window.googletag.sizeMapping()

    mapping.forEach(item => {
        result.addSize(parseSizeMap(item))
    })

    return result.build()
}

export default {
    props: {
        overflow: {
            type: Boolean
        },
        placement: {
            type: String,
            required: true
        },
        targeting: {
            type: Object,
            default: () => {}
        },
        refreshTimer: {
            type: Number,
            default: 0
        }
    },
    data () {
        bannerIndex += 1

        return {
            index: bannerIndex,
            slot: null,
            isLoaded: false,
            isRendered: false
        }
    },
    computed: {
        id () {
            return `bi-dfp-${this.index}`
        },

        config () {
            const config = configs[this.placement]

            if (config) {
                return config
            }

            return null
        }
    },
    methods: {
        init () {
            if (this.config && this.config.unit) {
                GPT()
                    .then(() => this.initBanner())
                    .catch(err => console.log('[DFP] error', err))
            } else {
                console.warn(
                    '[DFP] Warning: There is no banner with such a config:',
                    this.$el
                )
            }
        },

        initBanner () {
            try {
                this.defineBannerSlotAndLoad()
            } catch (err) {
                console.log(err)
            }
        },

        async defineBannerSlotAndLoad () {
            const targeting = await this.getTargeting()

            cmd(() => {
                let slot

                if (this.config.outOfPage) {
                    slot = window.googletag.defineOutOfPageSlot(
                        this.config.unit,
                        this.id
                    )
                } else {
                    const sizes = parseSizes(this.config.sizes)

                    slot = window.googletag.defineSlot(
                        this.config.unit,
                        sizes,
                        this.id
                    )
                }

                if (slot) {
                    this.setTargeting(targeting, slot)

                    this.slot = slot.addService(window.googletag.pubads())

                    if (this.config.mapping) {
                        const mapping = createMapping(this.config.mapping)

                        this.slot.defineSizeMapping(mapping)
                    }

                    this.listeners()
                    window.googletag.display(this.id)
                    addBanner(this)
                }
            })
        },

        getTargeting () {
            const isMobile = window && window.innerWidth < 660
            const isStage = (window?.location?.host || '').match(
                /(dev\.|stage\.|localhost:12100)/g
            ) && true
            const targeting = {
                lang: (this.$i18n || {}).locale,
                site: 'bettingInsider',
                screen: isMobile ? 'mob' : 'desc'
            }

            if (isStage) {
                targeting.site = 'prebidTest'
            }

            return {
                ...targeting,
                ...this.targeting
            }
        },

        setTargeting (targeting = {}, slot) {
            Object.entries(targeting).forEach(([key, value]) => {
                slot.setTargeting(key, value)
            })
        },

        update () {
            this.isLoaded = false
            this.isRendered = false

            cmd(() => {
                window.googletag.pubads().refresh([this.slot])
            })
        },

        updateAll () {
            cmd(() => {
                window.googletag.pubads().refresh()
            })
        },

        listeners () {
            addEventListener('slotOnload', this.onSlotLoad)
            addEventListener('slotRenderEnded', this.onSlotRendered)
        },

        onSlotLoad (e) {
            if (e.slot === this.slot) {
                this.isLoaded = true
            }
        },

        onSlotRendered (e) {
            if (e.slot === this.slot) {
                console.info(
                    `AdUnit ${this.config.unit} has creative id: ${e.creativeId}, line item id: ${e.lineItemId}, advertiser id: ${e.advertiserId}, campaign(order) id: ${e.campaignId}`
                )

                if (e.isEmpty) {
                    this.isRendered = false
                    console.warn(
                        `Empty banner in ad unit: ${this.config.unit}`
                    )
                } else {
                    this.isRendered = true
                    this.$emit('render', e)
                }
            }
        }
    },

    beforeDestroy () {
        if (this.slot) {
            destroySlots([this.slot])
        }

        removeBanner(this)

        removeEventListener('slotOnload', this.onSlotLoad)
        removeEventListener('slotRenderEnded', this.onSlotRendered)
    }
}
