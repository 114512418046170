<template>
    <nav class="menu-desktop">
        <router-link
            v-for="item in items"
            :key="item.key"
            :to="item.to"
            class="menu-desktop__link"
            :class="{ 'menu-desktop__link--home': item.isHome }"
            @click.native="sendAnalytics(item.key)"
        >
            {{ item.title }}
        </router-link>

        <OddsFormatSelectorDesktop
            v-if="isEn"
            class="menu-desktop__odds menu-desktop__link"
        />

        <div class="menu-desktop__more">
            <IconDots />

            <TheHeaderDropMenu class="menu-desktop__drop">
                <router-link
                    v-for="item in dropItems"
                    :key="item.key"
                    :to="item.to"
                    @click.native="sendAnalytics(item.key)"
                >
                    {{ item.title }}
                </router-link>
                <OddsFormatSelectorDesktop v-if="isEn" />
            </TheHeaderDropMenu>
        </div>

        <DFPPromoGetFreebet
            v-if="promo.link"
            :link="promo.link"
            :text="promo.text"
            :color="promo.color"
            :image="promo.image"
            :text-color="promo.textColor"
            :secondary-image="promo.secondaryImage"
            :secondary-text="promo.secondaryText"
            :secondary-text-color="promo.secondaryTextColor"
            :button-title="promo.buttonTitle"
            :button-title-color="promo.buttonTitleColor"
            :button-background-color="promo.buttonBackgroundColor"
            :third-text="promo.thirdText"
            :disclaimer="promo.disclaimer"
            :marker="promo.marker"
            :bookmaker="promo.bookmaker"
        />
    </nav>
</template>

<script>
import OddsFormatSelectorDesktop from '@/components/common/OddsFormatSelector/OddsFormatSelectorDesktop.vue'
import TheHeaderDropMenu from '@/components/TheHeader/TheHeaderDropMenu.vue'
import DFPPromoGetFreebet from '@/components/DFP/DFPPromoGetFreebet.vue'
import IconDots from '@/components/icons/dots.vue'

export default {
    name: 'TheHeaderMenuDesktop',

    components: {
        OddsFormatSelectorDesktop,
        TheHeaderDropMenu,
        DFPPromoGetFreebet,
        IconDots
    },

    props: {
        items: {
            type: Array,
            default: () => []
        },

        promo: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        dropItems () {
            return this.items.slice(3)
        },

        isEn () {
            return this.$i18n.locale === 'en'
        }
    },

    methods: {
        sendAnalytics (key) {
            this.$pivik.event('menu', 'click', key)
        }
    }
}
</script>

<style lang="stylus" scoped>
.menu-desktop
    height 100%
    display flex
    align-items stretch
    position relative

    &__odds
        width 19.5rem

    &__link,
    &__more
        padding 0 1.2rem
        display flex
        align-items center
        color #fff
        background-color $cl-text-dark

    &__more
        display none
        position relative

        &:hover
            color $cl-primary

    &__more:hover &__drop
        opacity 1
        transform translate(0, 0)

    &__drop
        position absolute
        top 100%
        left 0
        opacity 0
        transform translate(0, -100%)
        transition transform .3s cubic-bezier(.25, .45, .45, .94), opacity .3s
        z-index -1

        > *
            display none

    &__link
        position relative
        text-decoration none
        transition background-color .2s
        cursor pointer

        &:not(.menu-desktop__link--home).router-link-exact-active
            color $cl-primary-light

        &:active
            background-color #30343D

        &:hover
            &::after
                opacity 1

        &::after
            content ""
            position absolute
            bottom -0.4rem
            left 0
            width 100%
            height .2rem
            opacity 0
            border-radius .1rem
            background $cl-primary
            transition opacity .4s

@media(max-width: 1280px)
    .menu-desktop__link:nth-child(n+6)
        display none

    .menu-desktop__more
        display flex

    .menu-desktop__drop > *:nth-child(n+3)
        display block

@media(max-width: 1120px)
    .menu-desktop__link:nth-child(5)
        display none

    .menu-desktop__more
        display flex

    .menu-desktop__drop > *:nth-child(2)
        display block

@media(max-width: 1080px)
    .menu-desktop__link:nth-child(4)
        display none

    .menu-desktop__drop > *:nth-child(1)
        display block

@media(max-width: 1030px)
    .menu-desktop__link:nth-child(1)
            display none
</style>
