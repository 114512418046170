/* eslint-disable no-underscore-dangle */
import './polyfills'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueProgressBar from 'vue-progressbar'
import VueDOMPurifyHTML from 'vue-dompurify-html'

// eslint-disable-next-line import/extensions
import BettingUi from '@/ui/main.js'
import CoreMedia from 'core-media'

import VuePivik from '@/plugins/pivik'
import Canonical from '@/plugins/canonical'
import logger from '@/utils/logger'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import VueDate from '@/plugins/date'

import App from '@/app.vue'
import createRouter from './router'
import { createStore } from './store'
import createI18n from './i18n'


const isProd = process.env.NODE_ENV === 'production'

Vue.config.ignoredElements = ['noindex']

// https://www.npmjs.com/package/vue-dompurify-html
Vue.use(VueDOMPurifyHTML)

// https://github.com/nuxt/vue-meta
Vue.use(VueMeta, {
    keyName: 'head',
    tagIDKeyName: 'id',
    refreshOnceOnNavigation: true
})

// https://github.com/hilongjw/vue-progressbar
Vue.use(VueProgressBar, {
    color: '#0A80EF',
    failedColor: '#E05555',
    height: '2px',
    transition: {
        speed: '0.6s',
        opacity: '0.6s',
        termination: 600
    }
})

// https://git.tribuna.com/betting-insider/core-media
Vue.use(CoreMedia, {
    breakpoints: {
        xxs: 411,
        xs: 641,
        sm: 881,
        md: 1201,
        l: Infinity
    },
    defaultBreakpoint: 'sm'
})

BettingUi(Vue)

export function createApp (options = {}) {
    const router = createRouter()
    const store = createStore()
    const i18n = createI18n()

    if (isProd && !Vue.prototype.$isServer) {
        const isActive = Math.random() <= 0.7
        const disabledList = [
            'page-load',
            'history',
            'eventtarget',
            'xmlhttprequest',
            'fetch'
        ]

        const isDevEnvironment = /https?:\/\/(dev|stage)/.test(process.env.DOMAIN_URL)

        Vue.use(ApmVuePlugin, {
            router,
            config: {
                serviceName: 'bi-static-client-vue',
                serverUrl: 'https://apm-server.trbna.com',
                secretToken: process.env.ELASTIC_APM_SECRET_TOKEN,
                environment: isDevEnvironment ? 'dev' : 'prod',
                breakdownMetrics: isActive,
                disableInstrumentations: isActive ? [] : disabledList,
                ignoreTransactions: [/.*\.yandex\.ru\/webvisor.*/]
            }
        })

        logger.setVueInstance(Vue)

        Vue.prototype.$apm.setCustomContext({
            initialPageUrl: window.location.href
        })
    }

    Canonical(Vue, {
        hostname: process.env.DOMAIN_URL
    })

    VuePivik(Vue)

    Vue.prototype.$pivik.setProject('Betting')
    Vue.prototype.$pivik.disabled(Vue.prototype.$isServer)

    Vue.prototype.$date = VueDate
    Vue.prototype.$date.init(options.locale)

    // eslint-disable-next-line vue/require-name-property
    const app = new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    })

    return { app, router, store }
}

export function registerModule (store, name, module) {
    // Модуль еще не был зарегистрирован
    if (!store._modules.root._children[name]) {
        let preserveState = false
        // Если в state уже есть данные, то сохраняем их

        if (store.state[name]) {
            preserveState = true
        }

        store.registerModule(name, module, { preserveState })
    }
}

export function registerModuleByComponents (store, componentList) {
    componentList.forEach(({ storeModules = {}, components }) => {
        Object.entries(storeModules).forEach(([name, child]) => {
            registerModule(store, name, child)
        })

        if (components) {
            registerModuleByComponents(
                store,
                Object.values(components)
            )
        }
    })
}

export function recurseAsyncData (ctx, component) {
    const promises = []

    if (component.asyncData) {
        promises.push(component.asyncData(ctx))
    }

    if (component.components) {
        Object.values(component.components).forEach((child) => {
            promises.push(...recurseAsyncData(ctx, child))
        })
    }

    return promises
}
