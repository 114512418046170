<template>
    <DFP
        :key="bannerRefreshableKey"
        :placement="placement"
        :overflow="overflow"
        @receive="$emit('receive', $event)"
        @render="$emit('render', $event)"
    />
</template>

<script>
import DFP from '@/components/DFP/DFP.vue'

export default {
    name: 'RefreshableDfp',

    components: {
        DFP
    },

    props: {
        placement: {
            type: String,
            default: ''
        },

        overflow: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        bannerRefreshableKey () {
            const { fullPath = '', path = '' } = this.$route || {}

            return `bi-dfp${fullPath || path || new Date().toString()}`
        }
    }
}
</script>
