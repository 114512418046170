/**
 * Смена пароля с помощью токена
 * @param token - токен пользователя
 * @param password - новый пароль
 * @return {string}
 */
export function changePassword ({ token, password }) {
    return `{
        changePassword(token: "${token}" password: "${password}") {
            session {
                userID
                token
            }
            success
        }
    }`
}

/**
 * Отправка письма с токеном на e-mail
 * @param email - email пользователя
 * @param source - домен
 * @return {string}
 */
export function sendEmailConfirmationToken ({ email, source }) {
    return `{
        sendEmailConfirmationToken(email: "${email}", source: "${source}") {
            success
        }
    }`
}

/**
 * Отправка письма для восстановления пароля
 * @param email - email пользователя
 * @param source - домен
 * @return {string}
 */
export function forgotPassword ({ email, source }) {
    return `{
        forgotPassword(email: "${email}", source: "${source}") {
            success
        }
    }`
}

/**
 * Подтверждение электронной почты
 * @param token - токен пользователя
 * @return {string}
 */
export function confirmEmail ({ token }) {
    return `{
        confirmEmail (token: "${token}") {
            session {
                userID
                token
            }
        }
    }`
}

/**
 * Регистрация по электронной почте
 * @param email - email пользователя
 * @param password - пароль
 * @param source - домен
 * @return {string}
 */
export function signUpByEmail ({ email, password, source }) {
    return `{
        signUpByEmail(email: "${email}" password: "${password}", source: "${source}") {
            session {
                userID
                token
            }
            created
        }
    }`
}

/**
 * Аутентификация по почте
 * @param email - email пользователя
 * @param password - пароль
 * @return {string}
 */
export function authByEmail ({ email, password }) {
    return `{
        authByEmail(email: "${email}" password: "${password}") {
            session {
                userID
                token
            }
            created
        }
    }`
}

export function authOneTap (credential) {
    return `{ authByGoogle(idToken: "${credential}") {
        session {
            userID
            token
        }
        created
    }}`
}

/**
 * Верификация по номеру телефона
 * @param {object} options - объект опций
 * @parem {string} options.phoneNumber - номер телефона пользователя
 * @return {string}
 */
export function sendPhoneConfirmationCode ({ phoneNumber }) {
    return `{
        sendCodeToPhoneNumber(phoneNumber: "${phoneNumber}") {
            __typename
            code
        }
    }`
}
/**
 * Получение списка кодов телефона
 * @return {string}
 */

export function fetchCountriesPhoneCodes () {
    return `{
        countriesPhoneCodes {
            list {
                countryCode
                phoneCode
                countryName
            }
        }
    }`
}


/**
 * Верификация по номеру телефона
 * @param {object} options - объект опций
 * @parem {string} options.phoneNumber - номер телефона пользователя
 * @parem {string} options.code - код
 * @return {string}
 */
export function confirmPhoneNumber ({ phoneNumber, code }) {
    return `{
        confirmPhoneNumber(phoneNumber: "${phoneNumber}", code: "${code}") {
            __typename
        }
    }`
}
