import logger from '@/utils/logger'

/**
 * Готовит данные для записи в localStorage
 *
 * @access private
 * @param {*} data - Даные для записи
 * @returns {String}
 */
const stringifyData = (data, time = Date.now()) => JSON.stringify({
    time,
    data
})


/**
 * Парсит JSON
 *
 * @access private
 * @param {String} data - Данные из localStorage
 * @returns {*}
 */
const parseData = (data) => {
    try {
        return JSON.parse(data)
    } catch (err) {
        return data
    }
}

export default {
    set (key, value) {
        try {
            window.localStorage.setItem(key, stringifyData(value))
        } catch (err) {
            logger.error(err)
        }
    },

    setNoTimeUpdate (key, value) {
        try {
            const oldValue = window.localStorage.getItem(key)
            let date = Date.now()

            if (oldValue) {
                date = parseData(oldValue).time || Date.now()
            }

            window.localStorage.setItem(key, stringifyData(value, date))
        } catch (err) {
            logger.error(err)
        }
    },

    get (key) {
        try {
            const data = parseData(window.localStorage.getItem(key))

            if (!data || this.terminateKeyIfNeeded(key)) {
                return null
            }

            return data.data
        } catch (err) {
            logger.error(err)
        }
    },

    terminateKeyIfNeeded (key) {
        const terminating = parseData(window.localStorage.getItem('terminating-list')) || {}
        const terminationDateMs = terminating[key]

        if (terminationDateMs && Date.now() > terminationDateMs) {
            this.remove(key)

            delete terminating[key]
            window.localStorage.setItem('terminating-list', JSON.stringify(terminating))

            return true
        }

        return false
    },

    /**
     * Удалят данные по ключу
     *
     * @access public
     * @param {String} key - название ключа
     * @return {Boolean|LocalStorage}
     */
    remove (key) {
        try {
            window.localStorage.removeItem(key)
        } catch (err) {
            logger.error(err)
        }
    },

    /**
     * Добавляет данные по ключу на определенный период
     *
     * @access public
     * @param {String} key - название ключа
     * @param {String} значение - название ключа
     */
    setItemForAWhile (key, value, validFor) {
        this.set(key, value)
        const terminating = parseData(window.localStorage.getItem('terminating-list')) || {}
        let time = parseFloat(validFor)

        switch (true) {
            case validFor.search(/m/) > 0:
                time *= 60
                break
            case validFor.search(/h/) > 0:
                time *= 60 * 60
                break
            case validFor.search(/d/) > 0:
                time *= 60 * 60 * 24
                break
            default:
                break
        }

        terminating[key] = Date.now() + time * 1000
        window.localStorage.setItem('terminating-list', JSON.stringify(terminating))
    },

    /**
     * Удаляет все данные из локального хранилища
     *
     * @access public
     */
    clearAll () {
        try {
            window.localStorage.clear()
        } catch (err) {
            logger.error(err)
        }
    }
}
