// example: https://developers.google.com/api-client-library/javascript/features/authentication
// https://developers.google.com/api-client-library/javascript/reference/referencedocs
import loadScript from '@/utils/loadScript'

export default {
    GAPISdk: 'https://apis.google.com/js/api.js',
    sdk: 'https://accounts.google.com/gsi/client',

    WEB_CLIENT_ID: '47020827275-f64j582k86u2llp8oulmrosajv479gu4.apps.googleusercontent.com',

    ready () {
        if (!this.internal_readyPromise) {
            this.internal_readyPromise = new Promise((resolve, reject) => {
                (async () => {
                    try {
                        await loadScript(this.sdk)
                        await loadScript(this.GAPISdk)
                        const SDK = window.google.accounts.oauth2

                        gapi.load('client', () => {
                            gapi.client.init({
                            })
                        })
                        
                        const client = SDK.initTokenClient({
                            client_id: this.WEB_CLIENT_ID,
                            scope: 'profile',
                            ux_mode: 'popup',
                            prompt: 'select_account',
                            callback: ''
                        })

                        resolve(client)
                    } catch (err) {
                        reject(err)
                    }
                })()
            })
        }

        return this.internal_readyPromise
    },

    async logout () {
        const SDK = await this.ready()
        console.info('logout')

        return SDK.auth2.getAuthInstance().signOut() // ???
    },

    async login () {
        const SDK = await this.ready()

        await new Promise((resolve, reject) => {
            try {
              SDK.callback = (resp) => {
                if (resp.error !== undefined) {
                  reject(resp);
                }
                resolve(resp);
              };
              SDK.requestAccessToken({ prompt: 'consent' });
            } catch (err) {
              console.log(err)
            }
          });
    

        return {
            uid: Date.now(),
            token: window.gapi.client.getToken().access_token
        }
    },

    // async getProfileInfo () {
    //     const SDK = await this.ready()
    //     const currentUser = await SDK.auth2.getAuthInstance().currentUser.get()
    //     const basicProfile = currentUser.getBasicProfile()

    //     return {
    //         name: basicProfile.getName(),
    //         avatar: basicProfile.getImageUrl(),
    //         email: basicProfile.getEmail()
    //     }
    // }
}
