<template>
    <div class="select">
        <div
            v-for="(item, index) in items"
            :key="index"
            class="select__item"
            :class="{ 'select__item--selected': index === selectedIndex }"
            @click="select(index)"
        >
            {{ item.text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupReportSelect',

    props: {
        items: {
            type: Array,
            default: () => []
        },

        value: {
            type: [String, null],
            default: '',
            validator: value => typeof value === 'string' || value === null
        }
    },

    data () {
        return {
            selectedIndex: null
        }
    },

    methods: {
        select (index) {
            const isSame = index === this.selectedIndex

            this.selectedIndex = isSame ? null : index

            this.$emit('input', isSame ? null : this.items[index].value)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .select
        display flex
        justify-content center
        flex-wrap wrap

        &__item
            padding .4rem 1.3rem
            margin .2rem
            border .1rem solid rgba(83, 166, 210, .30)
            border-radius .4rem
            color $cl-primary
            font-size $font-size-base-sub
            cursor pointer

            &:hover:not(&--selected)
                background rgba(83, 166, 210, .10)

            &--selected
                color $cl-secondary
                background-color #F9F9F9
                border-color $cl-secondary-light
</style>
