export function fetchComments ({ objectId, objectClass, order, first, after }) {
    return `commentsList(
                objectID: "${objectId}",
                order: ${order},
                first: ${first},
                objectClass: ${objectClass},
                after: ${after ? `"${after}"` : null}
            ) {
                list {
                    id
                    text
                    ctime
                    user {
                     id
                      name
                      avatar
                      roi
                      winRate
                      betsForm {
                        state
                      }
                    }
                    parentComment {
                        id
                        text
                        user {
                         id
                          name
                          avatar
                          roi
                          winRate
                          betsForm {
                            state
                          }
                        }
                    }
                }
                pageInfo {
                    totalCount
                    hasNextPage
                    endCursor
                }
            }`
}

export function addComment ({ objectId, objectClass, comment, userId, parentId = '' }) {
    return `addComment(
                comment: {
                    objectID: "${objectId}",
                    objectClass: ${objectClass},
                    text: "${comment}",
                    userID: "${userId}",
                    parentId: "${parentId}"
                }
            ) {
                id
                text
                ctime
                user {
                 id
                  name
                  avatar
                  roi
                  winRate
                  betsForm {
                    state
                  }
                }
                parentComment {
                    id
                    text
                    user {
                      id
                      name
                      avatar
                      roi
                      winRate
                      betsForm {
                        state
                      }
                    }
                }
            }`
}

export function removeComment ({ commentId }) {
    return `{
      banComment(commentID: "${commentId}", isBanned: true) {
        success
      }
    }`
}
