import Api from '@/api'
import logger from '@/utils/logger'
import { getBookmakersWidget } from '@/api/graphql/bookmakers-rating'
import _get from 'lodash.get'

const SET_BOOKMAKERS_LIST = 'SET_BOOKMAKERS_LIST'

function bookmakerAdapter (bookmaker) {
    // список используемых бонусов, остальные отметаем
    const BONUSES_TYPE = ['MONEY', 'PERCENT']

    bookmaker.bonuses = bookmaker.bonuses.filter(item => BONUSES_TYPE.includes(item.type));

    [bookmaker.biRatings] = bookmaker.featureRatings.map(
        feature => feature.score / 20
    )

    // остальные оценки не нужны
    delete bookmaker.featureRatings

    return bookmaker
}

export default {
    namespaced: true,

    getters: {
        getBookmakers: state => excludeWebname => state.list
            .filter(item => item.webname !== excludeWebname)
            .slice(0, 10)
    },

    state () {
        return {
            list: []
        }
    },

    actions: {
        async fetchList ({ rootState, commit }) {
            let result

            try {
                const countryCode = rootState.bookmakersRating.geo || 'RU'
                const data = await Api.graphql(
                    getBookmakersWidget(countryCode)
                )

                const bookmakers = _get(data, 'items[0].bookmakers', [])

                result = bookmakers.map(el => bookmakerAdapter(el.bookmaker))

                commit(SET_BOOKMAKERS_LIST, result)

                return Promise.resolve()
            } catch (err) {
                logger.error(err)

                return Promise.reject(err)
            }
        }
    },

    mutations: {
        [SET_BOOKMAKERS_LIST] (state, bookmakers = []) {
            state.list = bookmakers
        }
    }
}
