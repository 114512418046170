<template>
    <div class="layout">
        <TheHeader />

        <div class="l-content">
            <router-view />
        </div>

        <PopupAuth />
        <PopupPasswordReset />
        <PopupConfirmEmail />
        <PopupConfirmEmailSuccess />
        <PopupReport />
        <PopupBan />
        <PopupBanError />
    </div>
</template>

<script>
import TheHeader from '@/components/TheHeader/TheHeader.vue'

import PopupAuth from '@/components/Popups/PopupAuth.vue'
import PopupPasswordReset from '@/components/Popups/PopupPasswordReset.vue'
import PopupConfirmEmail from '@/components/Popups/ConfirmEmail/PopupConfirmEmail.vue'
import PopupConfirmEmailSuccess from '@/components/Popups/ConfirmEmail/PopupConfirmEmailSuccess.vue'

import PopupReport from '@/components/Popups/Report/PopupReport.vue'
import PopupBan from '@/components/Popups/PopupBan.vue'
import PopupBanError from '@/components/Popups/PopupBanError.vue'

export default {
    name: 'MainAppChatLayout',

    components: {
        TheHeader,
        PopupAuth,
        PopupPasswordReset,
        PopupConfirmEmail,
        PopupConfirmEmailSuccess,
        PopupReport,
        PopupBan,
        PopupBanError
    }
}
</script>

<style lang="stylus" scoped>
    .layout
        min-height 100vh
        display flex
        flex-direction column
        background-color $cl-background

    .l-content
        box-sizing border-box
        width 100%
        display flex
        flex-direction column
</style>
