<template>
    <UiPopup ref="popup">
        <template #header>
            <div class="recovery-popup__heading">
                {{ $t('password-recovery') }}
            </div>
        </template>

        <form
            v-if="isRecoveryForm"
            class="recovery-popup__form"
            @submit.prevent="submit"
        >
            <p class="auth-form__description">
                {{ $t('text') }}
            </p>

            <div class="input-wrapper">
                <label
                    class="input-label"
                    for="forgot-email"
                >
                    E-mail
                </label>
                <input
                    id="forgot-email"
                    v-model="email"
                    :placeholder="$t('email-placeholder')"
                    :class="{ 'input--error': error }"
                    class="input"
                    type="email"
                    name="email"
                    autocomplete="email"
                >
                <span
                    v-if="error"
                    class="input__error-label"
                >
                    {{ error }}
                </span>
            </div>

            <button class="action-button">
                {{ $t('reset-password') }}
            </button>
        </form>
        <div
            v-else
            class="recovery-popup__form"
        >
            <p>{{ $t('success') }}</p>

            <button
                class="action-button"
                @click="close"
            >
                {{ $t('ok') }}
            </button>
        </div>
    </UiPopup>
</template>

<i18n>
{
    "ru": {
        "email-placeholder": "Введите e-mail",
        "reset-password": "Сбросить пароль",
        "password-recovery": "Восстановление пароля",
        "text": "На указанный вами email будут отправлены инструкции для восстановления аккаунта",
        "success": "На ваш email отправлено письмо с инструкциями по восстановлению пароля",
        "ok": "Ок"
    },
    "en": {
        "email-placeholder": "E-mail",
        "reset-password": "Reset password",
        "password-recovery": "Password recovery",
        "text": "The instructions on how to recover your account will be sent to your email",
        "success": "We've send you an email explaining how you can recover your password",
        "ok": "Okay"
    }
}
</i18n>

<script>
import UiPopup from '@/ui/components/Popup/Popup.vue'
import ErrorMessage from '@/mixins/error-message'

export default {
    name: 'PasswordResetPopup',

    components: {
        UiPopup
    },

    mixins: [ErrorMessage],

    data () {
        return {
            error: '',
            pending: false,
            email: '',
            isRecoveryForm: true
        }
    },

    mounted () {
        this.$root.$on('password-reset', this.open)
    },

    methods: {
        async submit () {
            if (this.pending) {
                return
            }

            this.pending = true

            try {
                this.error = ''

                await this.$store.dispatch('auth/remind', {
                    loc: this.$i18n.locale,
                    email: this.email
                })

                this.pending = false
                this.isRecoveryForm = false
            } catch (res) {
                this.pending = false
                this.error = this.getErrorMessageGQ(res)
            }
        },

        open () {
            this.$refs.popup.open()
        },

        close () {
            this.$refs.popup.close()
        }
    }
}
</script>

<style lang="stylus">
.recovery-popup
    &__heading
        font-size $fs-heading
        letter-spacing 1px
        line-height un(5)
        padding-left un(2)
        padding-top un(1)

    &__form
        width un(50)
        max-width 100%
        padding un(2)
        box-sizing border-box


.input
    input()
    width 100%

.input-label
    input-label()
    margin-top un(2)

.action-button
    button(5, 'contained')
    margin-top un(3)

</style>
