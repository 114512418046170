<template>
    <div
        id="one-tap"
        class="one-tap-container"
    />
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import loadScript from '@/utils/loadScript'
import googleConfig from '@/utils/google'

function repeatUntil (fn, callback) {
    if (fn()) {
        callback()
    } else {
        setTimeout(() => repeatUntil(fn, callback), 600)
    }
}

function waitForClose (notification) {
    return new Promise((resolve) => {
        repeatUntil(() => notification.getMomentType() !== 'display', resolve)
    })
}

export default {
    name: 'OneTapAuth',

    data () {
        return {
            notification: null
        }
    },

    computed: {
        ...mapState('auth', ['isAuth']),
        ...mapGetters({
            oneTapMustShow: 'auth/oneTapMustShow',
            isAuthChecked: 'auth/isChecked'
        })
    },

    watch: {
        isAuthChecked (value) {
            if (value) {
                this.init()
            }
        }
    },

    methods: {
        ...mapActions('auth', ['oneTapClose', 'oneTapLogIn', 'oneTapCheck']),

        async init () {
            await this.oneTapCheck()

            if (this.oneTapMustShow) {
                await loadScript('https://accounts.google.com/gsi/client')

                // https://developers.google.com/identity/gsi/web/reference/js-reference#PromptMomentNotification
                window.google.accounts.id.initialize({
                    client_id: googleConfig.WEB_CLIENT_ID,
                    callback: this.oneTapLogIn,
                    prompt_parent_id: 'one-tap',
                    cancel_on_tap_outside: true
                })

                window.google.accounts.id.prompt((notification) => {
                    this.notification = notification
                    if (this.notification.isDisplayMoment() && this.notification.isDisplayed()) {
                        waitForClose(notification)
                            .then(this.oneTapHasClosed)
                    } else {
                        this.oneTapClose()
                    }
                })
            }
        },

        oneTapHasClosed () {
            if (this.notification.isSkippedMoment()) {
                this.oneTapClose()
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.one-tap-container
    +layout-sm-and-up()
        position absolute
        z-index 1001
        right 15.6rem
</style>
