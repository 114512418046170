<template>
    <div class="the-footer-languages">
        <a
            v-for="item in languages"
            :key="item.name"
            :href="item.url"
            :class="getLanguagesClass(item.name)"
            @click="sendAnalytic(item.name)"
        >
            {{ item.name }}
        </a>
    </div>
</template>

<script>
export default {
    name: 'TheFooterLanguages',

    data () {
        return {
            languages: [
                {
                    name: 'ru',
                    url: 'https://betting.team'
                },
                {
                    name: 'en',
                    url: 'https://betting-insider.com'
                }
            ]
        }
    },

    methods: {
        getLanguagesClass (lang) {
            return {
                'the-footer-languages__item': true,
                'the-footer-languages__item--active': lang === this.$i18n.locale
            }
        },

        sendAnalytic (lang) {
            this.$pivik.event('footer', 'click', `language_${lang}`)
        }
    }
}
</script>

<style lang="stylus" scoped>
.the-footer-languages
    display grid
    grid-template-columns max-content max-content
    grid-column-gap 2.4em
    justify-content center

    &__item
        color #666D74
        text-decoration none
        text-transform uppercase
        transition color .3s

        &:hover,
        &:focus
            color $cl-white

        &--active
            color $cl-white
</style>
