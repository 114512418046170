export default {
    ru: [
        {
            title: 'Прогнозы Черданцева',
            route: {
                name: 'profile',
                params: {
                    id: 130837
                }
            }
        }, {
            title: 'Прогнозы Генича',
            route: {
                name: 'profile',
                params: {
                    id: 6637
                }
            }
        }, {
            title: 'Прогнозы Андронова',
            route: {
                name: 'profile',
                params: {
                    id: 6478
                }
            }
        }, {
            title: 'Прогнозы Уткина',
            route: {
                name: 'profile',
                params: {
                    id: 6420
                }
            }
        }
    ],
    en: [
        {
            title: 'Paul Mersons tips',
            route: {
                name: 'profile',
                params: {
                    id: 110174
                }
            }
        }, {
            title: 'Mark Lawrensons tips',
            route: {
                name: 'profile',
                params: {
                    id: 113120
                }
            }
        }, {
            title: 'Michael Owens tips',
            route: {
                name: 'profile',
                params: {
                    id: 324101
                }
            }
        }, {
            title: 'Rivaldos tips',
            route: {
                name: 'profile',
                params: {
                    id: 464163
                }
            }
        }, {
            title: 'Charlie Nicholass tips',
            route: {
                name: 'profile',
                params: {
                    id: 120145
                }
            }
        }
    ]
}
