<template>
    <router-link
        :to="tournamentRoute"
        class="search-preview-tournament"
        @click.native="onClick"
    >
        <UiAvatar
            :src="data.logo"
            size="sm"
            type="tournament"
            class="search-preview-tournament__avatar"
        />

        <div class="search-preview-tournament__description">
            <div class="search-preview-tournament__name">
                {{ data.name }}
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'SearchResultsTournament',

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        tournamentRoute () {
            return {
                name: 'tournament',
                params: {
                    alias: this.data.webname,
                    sport: 'football'
                }
            }
        }
    },

    methods: {
        onClick () {
            this.$pivik.event('menu', 'search', 'tournament', this.data.id)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .search-preview-tournament {
        display flex
        align-items center
        justify-content flex-start
        text-decoration none
        color $cl-text-dark

        &__description {
            margin-left 2rem
        }
        &__name {
            font-size 1.8rem
            line-height 2.4rem
        }
    }
</style>
