<template>
    <div class="ui-dots">
        <button
            v-for="(num, index) in count"
            :key="num"
            :class="getClass(index)"
            class="ui-dots-item"
            @[eventName]="onDotClick(num, index)"
        >
            <span class="ui-dots-item__inner" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'UiDots',

    props: {
        count: {
            type: Number,
            default: 0
        },

        value: {
            type: Number,
            default: 0
        }
    },

    computed: {
        hasListener () {
            return typeof this.$listeners['dot-click'] === 'function'
        },

        eventName () {
            if (this.hasListener) {
                return 'click'
            }

            return null
        }
    },

    methods: {
        getClass (index) {
            return {
                'is-active': index === this.value,
                'with-action': this.hasListener
            }
        },

        onDotClick (num, index) {
            this.$emit('dot-click', { num, index })
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-dots
    display flex
    justify-content center
    align-items center
    margin 0
    padding 0
    list-style none

.ui-dots-item
    margin 0
    padding .3rem
    width .6rem
    height @width
    border none
    outline none
    background transparent
    box-sizing content-box

    &.with-action
        cursor pointer

    &__inner
        display block
        width 100%
        height @width
        background-color $cl-secondary
        transition background-color .3s
        border-radius 50%

        ^[0].is-active &
            background-color $cl-primary
</style>
