import Api from '@/api'
import logger from '@/utils/logger'
import { fetchReviews } from '@/api/graphql/bookmakers-rating'


export default {
    namespaced: true,

    state () {
        return {
            list: [],
            pageInfo: {
                totalCount: 0,
                hasNextPage: false,
                endCursor: null
            }
        }
    },

    getters: {
        reviews (state) {
            return state.list
        },
        reviewsCount (state) {
            return state.list.length
        },
        allReviewsCount (state) {
            return state.pageInfo.totalCount
        },
        isAllReviews (state) {
            return state.pageInfo.totalCount === state.list.length
        }
    },

    actions: {
        async fetch ({ rootState, commit, dispatch }, options = {}) {
            let result
            const reviews = []

            const queryString = fetchReviews({
                countryCode: rootState.bookmakersRating.geo,
                webname: options.webname,
                first: options.first,
                offset: options.offset
            })

            try {
                result = await Api.graphql(`{${queryString}}`)

                dispatch('bookmakersRating/setHasError', { key: 'reviews', value: false }, { root: true })
            } catch (err) {
                logger.error(err)

                return Promise.reject(err)
            }

            if (!result) {
                result = {
                    reviews: {
                        items: [],
                        pageInfo: {}
                    }
                }
            }

            // Если необходимо очистить список отзывов
            if (options.reset) {
                commit('clear')
                commit('resetPageInfo')
            }

            result.reviews.items.forEach(review => {
                reviews.push({
                    id: review.id,
                    score: review.score,
                    description: review.description,
                    createTime: review.createTime,
                    user: review.user
                })

                commit('bookmakersRating/comments/SAVE_COMMENTS', {
                    id: review.id,
                    comments: review.comments.list,
                    pageInfo: review.comments.pageInfo
                }, {
                    root: true
                })
            })

            commit('reviews', { list: reviews })
            commit('pageInfo', result.reviews.pageInfo)

            return Promise.resolve()
        }
    },
    mutations: {
        resetPageInfo (state) {
            state.pageInfo.totalCount = 0
            state.pageInfo.hasNextPage = false
            state.pageInfo.endCursor = null
        },

        pageInfo (state, updates = {}) {
            Object.keys(updates).forEach(key => {
                state.pageInfo[key] = updates[key]
            })
        },

        reviews (state, { list }) {
            state.list = state.list.concat(list)
        },

        clear (state) {
            state.list = []
        },

        review (state, { review }) {
            state.list.unshift(review)
        },

        update (state, { review }) {
            if (state.list.length) {
                const index = state.list.findIndex(item => item.id === review.id)

                if (index > -1) {
                    state.list[index].score = review.score
                    state.list[index].description = review.description
                }
            }
        }
    }
}
