/**
 * Преобразование английского коэффициента ставки в выбранный тип
 *
 * @param value {String|Number} - английский коэффициент
 * @param type {String} - тип коэффициента, к которому нужно преобразовать
 * @returns {String|Number}
 */
export default function betFilter (value, type = 'european') {
    if (type === 'american') {
        if (value < 2) {
            const rate = Math.ceil((100 / (value - 1)))

            return `-${rate}`
        }

        const rate = Math.ceil((100 * (value - 1)))

        return `+${rate}`
    }

    return value
}
