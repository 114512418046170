import { IS_BETTING_COM } from '@/constants'

export function getCrossDomainUrl () {
    const currentDomain = IS_BETTING_COM ? 'betting-insider.com' : 'betting.team'
    const crossDomain = IS_BETTING_COM ? 'betting.team' : 'betting-insider.com'
    const domainIndex = window.location.href.indexOf(currentDomain)

    if (domainIndex === -1) {
        return null
    }

    let url = window.location.href.slice(0, domainIndex)

    url += crossDomain

    return url
}

export function getCrossDomainAuthUrl () {
    const url = getCrossDomainUrl()

    if (!url) {
        return null
    }

    return `${url}/cross-auth`
}
