export default {
    ru: [
        {
            title: 'Российская Премьер-лига',
            route: {
                name: 'tournament',
                params: {
                    alias: 'rpl',
                    sport: 'football'
                }
            }
        }, {
            title: 'Английская Премьер-лига',
            route: {
                name: 'tournament',
                params: {
                    alias: 'epl',
                    sport: 'football'
                }
            }
        }, {
            title: 'Испания. Ла Лига',
            route: {
                name: 'tournament',
                params: {
                    alias: 'primera',
                    sport: 'football'
                }
            }
        }, {
            title: 'Лига чемпионов',
            route: {
                name: 'tournament',
                params: {
                    alias: 'championsleagie',
                    sport: 'football'
                }
            }
        }, {
            title: 'Бундеслига',
            route: {
                name: 'tournament',
                params: {
                    alias: 'bundesliga',
                    sport: 'football'
                }
            }
        }
    ],
    en: [
        {
            title: 'England. Premier League',
            route: {
                name: 'tournament',
                params: {
                    alias: 'epl',
                    sport: 'football'
                }
            }
        }, {
            title: 'Bundesliga',
            route: {
                name: 'tournament',
                params: {
                    alias: 'bundesliga',
                    sport: 'football'
                }
            }
        }, {
            title: 'UEFA Champions League',
            route: {
                name: 'tournament',
                params: {
                    alias: 'championsleagie',
                    sport: 'football'
                }
            }
        }, {
            title: 'Spain. Primera Division',
            route: {
                name: 'tournament',
                params: {
                    alias: 'primera',
                    sport: 'football'
                }
            }
        }, {
            title: 'UEFA Europa League',
            route: {
                name: 'tournament',
                params: {
                    alias: 'europaleague',
                    sport: 'football'
                }
            }
        }
    ]
}
