import { render, staticRenderFns } from "./calendar.vue?vue&type=template&id=387491a6&"
import script from "./calendar.vue?vue&type=script&lang=js&"
export * from "./calendar.vue?vue&type=script&lang=js&"
import style0 from "./calendar.vue?vue&type=style&index=0&id=387491a6&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/time.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbetting-insider%2Fbi-frontend%2Fsource%2Fui%2Fcomponents%2FCalendar%2Finner%2Fcalendar.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports