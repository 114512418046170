const DEFAULT_AVATAR_URL = 'https://pictures.trbna.com/image/10abdbc4-000d-41b7-a32d-dcfe35b4a09c?width=100'

const LINEUP_TYPES_TO_META = {
    ProbableMatchLineUp: 'hasProbableLineup',
    statMatch: 'hasMainLineup',
    lastMatchStat: 'hasLastMatchLineup'
}

const PROBABLE_LINEUP_PLAYERS_KEY = 'players'
const LINEUP_KEY = 'lineup'
const CURRENT_LINEUP_KEY = 'current_lineup'

// мапа для парсинга статистики матча и получения составов
const TEAM_TYPES = {
    firstTeam: {
        teamType: 'home',
        playersKeys: [PROBABLE_LINEUP_PLAYERS_KEY]
    },
    secondTeam: {
        teamType: 'away',
        playersKeys: [PROBABLE_LINEUP_PLAYERS_KEY]
    },
    home: {
        teamType: 'home',
        playersKeys: [LINEUP_KEY, CURRENT_LINEUP_KEY]
    },
    away: {
        teamType: 'away',
        playersKeys: [LINEUP_KEY, CURRENT_LINEUP_KEY]
    },
    homeTeamStat: {
        teamType: 'home',
        playersKeys: [CURRENT_LINEUP_KEY]
    },
    awayTeamStat: {
        teamType: 'away',
        playersKeys: [CURRENT_LINEUP_KEY]
    }
}

/**
 * Функция для добавления к урлу картинки параметров
 * определяющих размер получаемого изображения
 *
 * @param {string} imgUrl
 * @param {(string | number)} width
 * @param {?(string | number)} height
 *
 * @returns обработанную строку с адресом картинки
 */
function setImageSizeToUrl (imgUrl, width, height) {
    if (!imgUrl) {
        return imgUrl
    }

    if (!height) {
        return `${imgUrl}?width=${width}`
    }

    return `${imgUrl}?width=${width}&height=${height}`
}

/**
 *
 * @param {Object} data
 * @param {string} data.id - id команды
 * @param {Object} data.logo - объект с лого команды
 * @param {string} data.name - название команды
 * @param {Object[]} data.lastFive - данные о последних пяти матчах команды
 *
 * @returns отформатированный объект с данными о команде
 * {
 *   id,
 *   logo,
 *   name,
 *   lastFive: [{
 *     id,
 *     sportsId
 *   }]
 * }
 */
export function teamAdapter ({ id, logo = {}, name, lastFive = [] } = {}) {
    const result = {
        id,
        logo: logo.main,
        name
    }

    result.lastFive = lastFive.map(({ match }) => ({
        id: match.id,
        sportsId: match.ubersetzer.sportsId
    }))

    return result
}

/**
 * Функция для обработки и приведения к общему виду объекта с данными о игроке
 *
 * @param {Object} data - данные игрока
 *
 * @returns унифицированный объект игрока
 * {
 *   id,
 *   name,
 *   avatar,
 *   nationalityImage,
 *   nationalityName,
 *   nationalityCode,
 *   jerseyNumber,
 *   lineupStarting (в стартовом составе)
 *   position,
 *   isInjured,
 *   isSuspended,
 *   substitutedIn (вышел на замену),
 *   substitutedOut (заменен),
 *   redCards,
 *   yellowCards,
 *   yellowRedCards
 * }
 */
export function playerAdapter (data) {
    if (!data.player) {
        return data
    }

    const {
        id,
        avatar,
        name,
        nationality
    } = data.player
    const stat = data.stat || {}

    const playerStatistics = {
        isInjured: !!data.isInjured,
        isSuspended: !!data.isSuspended,
        substitutedIn: stat.substitutedIn || 0,
        substitutedOut: stat.substitutedOut || 0,
        redCards: stat.redCards || 0,
        yellowCards: stat.yellowCards || 0,
        yellowRedCards: stat.yellowRedCards || 0
    }

    const playerNationality = nationality[0] || { picture: {} }

    const result = {
        id,
        name,
        avatar: setImageSizeToUrl(avatar.main, 100) || DEFAULT_AVATAR_URL,
        nationalityImage: setImageSizeToUrl(playerNationality.picture.main, 80, 60),
        nationalityName: playerNationality.name,
        nationalityCode: playerNationality.code,
        jerseyNumber: data.number || data.jersey_number,
        lineupStarting: data.lineupStarting === undefined ? null : data.lineupStarting,
        position: data.type || data.position,
        ...playerStatistics
    }

    return result
}

/**
 * Функция для получения отформатированных данных о составах двух команд,
 * домашней и гостей
 *
 * @param {Object[]} matchStatData - данные о командах матча
 *
 * @returns возвращает объект с отформатированными составами
 * и информацией какие составы были обработаны
 * {
 *   lineups: [{ type: 'home' | 'away', players: Array }],
 *   meta: {
 *     hasProbableLineup: boolean,
 *     hasMainLineup: boolean,
 *     hasLastMatchLineup: boolean
 *   }
 * }
 */
export function getLineupsFromMatchStat (matchStatData) {
    //  массив объектов с игроками и типом команды (home/away)
    // [{ type: 'home' | 'away', players: [] }]
    const lineups = []
    const meta = {
        hasMainLineup: false,
        hasProbableLineup: false,
        hasLastMatchLineup: false
    }

    if (!Array.isArray(matchStatData)) {
        return {
            lineups,
            meta
        }
    }

    let metaKey = ''

    matchStatData
        .filter(Boolean)
        .forEach(teamData => {
            const { __typename: typename, ...teamStats } = teamData

            // определяем какой тип состава разбираем
            // предварительный/текущий/состав прошлой игры
            metaKey = LINEUP_TYPES_TO_META[typename]
            meta[metaKey] = false

            Object.entries(teamStats).forEach(([teamStatKey, teamStat]) => {
                const { teamType, playersKeys } = TEAM_TYPES[teamStatKey]

                // пробуем вытащить игроков состава из ответа сервера
                playersKeys.forEach(metaPlayersKey => {
                    const players = teamStat[metaPlayersKey]

                    if (Array.isArray(players) && players.length) {
                        const isCurrentLineup = metaPlayersKey === CURRENT_LINEUP_KEY
                        const parsedPlayers = players.map(player => {
                            const result = playerAdapter(player)

                            if (isCurrentLineup) {
                                // для обработки кейса когда не пришла статистика по игроку
                                // при этом он есть в текущем составе но не в стартовом
                                // получается что игрок попал в текущий стостав из запасных после замены

                                const {
                                    lineupStarting,
                                    substitutedIn,
                                    substitutedOut
                                } = result

                                if (!lineupStarting && !substitutedOut && !substitutedIn) {
                                    result.substitutedIn = 1
                                }
                            }

                            return result
                        })
                            // отфильтровываем тренера, у него нет номера и позиции/типа игрока
                            .filter(player => player.jerseyNumber && player.position)

                        if (!parsedPlayers.length) {
                            return
                        }

                        lineups.push({
                            type: teamType,
                            players: parsedPlayers,
                            metaPlayersKey
                        })

                        meta[metaKey] = parsedPlayers.some(player => !player.isInjured && !player.isSuspended)

                        // если пришел текущий состав, то он используется
                        // в качестве основоного источника информации
                        // от предварительного состава оставляем только травмированных
                        // и дисквалифицированных игроков
                        // эта информация доступна только из предварительного состава
                        if (
                            isCurrentLineup
                            && meta.hasProbableLineup
                            && (teamStatKey === 'home' || teamStatKey === 'away')
                        ) {
                            const probableLineup = lineups.find(item => item.type === teamStatKey && PROBABLE_LINEUP_PLAYERS_KEY === item.metaPlayersKey)

                            probableLineup.players = probableLineup.players.filter(player => (player.isInjured || player.isSuspended))
                        }
                    }
                })
            })
        })

    return {
        lineups,
        meta
    }
}

/**
 * Функция парсит массив со статистикой по предыдущим матчам
 * домашней команды и гостей
 * и вытаскивает нужные данные о команде и игроках
 *
 * @param {Object[]} lastMatchesStat - массив со статистикой по последним матчам команд
 * @param {Number} homeTeamId - id домашней команды
 * @param {Number} awayTeamId - id команды гостей
 *
 * @return {Object} стандартный объект статистики матча
 * { home: Object, away: Object, __typename: 'lastMatchStat' }
 */
export function parseLastMatchesStat (lastMatchesStat, homeTeamId, awayTeamId) {
    if (!Array.isArray(lastMatchesStat)) {
        return {
            home: {},
            away: {},
            __typename: 'lastMatchStat'
        }
    }

    return lastMatchesStat
        .filter(Boolean)
        .reduce((acc, matchStat) => {
            Object.keys(matchStat).forEach(teamType => {
                if (!acc.home && matchStat[teamType].team.id === homeTeamId) {
                    acc.home = matchStat[teamType]

                    return
                }

                if (!acc.away && matchStat[teamType].team.id === awayTeamId) {
                    acc.away = matchStat[teamType]
                }
            })

            return acc
        }, { __typename: 'lastMatchStat' })
}
