function filter (val, sep) {
    if (typeof val !== 'number') {
        return val
    }

    const fixStr = val.toFixed().toString()

    return fixStr.replace(/\B(?=(\d{3})+(?!\d))/g, sep)
}

export default function numberFilter (value) {
    return filter(value, ' ')
}
