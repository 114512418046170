<template>
    <div class="layout">
        <vue-progress-bar v-if="isMounted" />

        <template v-if="!isDesktop && hasOneTapFinished">
            <!-- ABTest; only client -->
            <TheSmartAppBanner v-if="isMounted" />
            <!-- ABTest -->
            <BetFloat />
            <!-- <ChatFloat v-if="isMounted" /> -->
            <DFPStick placement="stick" />
        </template>

        <DFPCatfish />

        <TheHeader />

        <OneTapAuth />

        <TheSubheaderWrapper v-if="code === 200 && currentRouteComponents.subheader">
            <router-view name="subheader" />
        </TheSubheaderWrapper>

        <div class="l-content">
            <router-view
                v-if="code === 200 && currentRouteComponents.top"
                name="top"
                class="column-top"
            />

            <div class="l-columns">
                <router-view
                    v-if="code === 200"
                    class="column-main"
                />

                <Error404
                    v-else
                    :is-it-contest="!!needToShowCustom404PageForContests"
                    class="column-main"
                />

                <div
                    v-if="code === 200 && currentRouteComponents.right"
                    class="column-right"
                >
                    <router-view
                        v-if="isDesktop"
                        name="right"
                    />
                </div>
            </div>

            <TheFooter
                v-if="code === 200"
                class="column-footer"
            />
        </div>

        <core-media mq="md+">
            <Curtain class="bi-hide-sm" />
        </core-media>

        <template v-if="isAuth">
            <PopupBetSuccess />
            <PopupBetNoActive />
            <PopupBetTimeFinished />
            <PopupBetInsufficientFunds />
            <PopupBetUpdated />
            <PopupBetLimit />

            <PopupRefund />
            <PopupBan />
            <PopupBanError />
        </template>

        <PopupAuth />
        <PopupPasswordReset />

        <PopupConfirmEmail />
        <PopupConfirmEmailSuccess />

        <PopupError />

        <PopupReport />

        <DailyBonuses />

        <DFPInterstitial v-if="needToShowInterstitial" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import TheSmartAppBanner from '@/components/common/the-smart-app-banner/index.vue'

import TheHeader from '@/components/TheHeader/TheHeader.vue'
import TheSubheaderWrapper from '@/components/TheSubheader/TheSubheaderWrapper.vue'
import TheFooter from '@/components/TheFooter/TheFooter.vue'
import Curtain from '@/components/Curtain/Curtain.vue'
import DFPInterstitial from '@/components/DFP/DFPInterstitial.vue'

import PopupAuth from '@/components/Popups/PopupAuth.vue'
import PopupPasswordReset from '@/components/Popups/PopupPasswordReset.vue'
import PopupConfirmEmail from '@/components/Popups/ConfirmEmail/PopupConfirmEmail.vue'
import PopupConfirmEmailSuccess from '@/components/Popups/ConfirmEmail/PopupConfirmEmailSuccess.vue'
import PopupBetSuccess from '@/components/Popups/Bet/PopupBetSuccess.vue'
import PopupError from '@/components/Popups/PopupError.vue'
import PopupBetNoActive from '@/components/Popups/Bet/PopupBetNoActive.vue'
import PopupBetTimeFinished from '@/components/Popups/Bet/PopupBetTimeFinished.vue'
import PopupBetInsufficientFunds from '@/components/Popups/Bet/PopupBetInsufficientFunds.vue'
import PopupBetUpdated from '@/components/Popups/Bet/PopupBetUpdated.vue'
import PopupBetLimit from '@/components/Popups/Bet/PopupBetLimit.vue'
import PopupRefund from '@/components/Popups/Refund/PopupRefund.vue'
import PopupReport from '@/components/Popups/Report/PopupReport.vue'

import PopupBan from '@/components/Popups/PopupBan.vue'
import PopupBanError from '@/components/Popups/PopupBanError.vue'

// import ChatFloat from '@/components/Chat/ChatFloat.vue'
import BetFloat from '@/components/Bet/BetFloat.vue'
import DFPStick from '@/components/DFP/DFPStick.vue'
import DFPCatfish from '@/components/DFP/DFPCatfish.vue'
import OneTapAuth from '@/components/OneTapAuth/OneTapAuth.vue'
import DailyBonuses from '@/components/DailyBonuses/DailyBonuses.vue'

export default {
    name: 'MainAppLayout',

    components: {
        DFPInterstitial,
        DFPCatfish,
        TheSmartAppBanner,
        TheHeader,
        TheSubheaderWrapper,
        TheFooter,
        Curtain,
        Error404: () => import('@/views/errors/404/index.vue'),
        PopupAuth,
        PopupPasswordReset,
        PopupConfirmEmail,
        PopupConfirmEmailSuccess,
        PopupBetSuccess,
        PopupError,
        PopupBetNoActive,
        PopupBetTimeFinished,
        PopupBetInsufficientFunds,
        PopupBetUpdated,
        PopupBetLimit,
        PopupRefund,
        PopupReport,
        PopupBan,
        PopupBanError,
        // ChatFloat,
        BetFloat,
        DFPStick,
        OneTapAuth,
        DailyBonuses
    },

    data () {
        return {
            isMounted: false
        }
    },

    computed: {
        ...mapState('page', ['code']),
        ...mapState('auth', ['isAuth', 'account']),
        ...mapState('match', ['match']),
        ...mapState('bookmakersRating', ['geo']),
        ...mapState('ads', ['isCatfishVisible']),
        ...mapGetters('predictions/prediction', ['prediction']),
        ...mapGetters('auth', ['hasOneTapFinished']),
        ...mapGetters('ads', ['isDesktopBrandingVisible']),


        currentRouteComponents () {
            return this.$route.matched.reduce((acc, component) => {
                Object.keys(component.components).forEach(key => {
                    acc[key] = true
                })

                return acc
            }, {})
        },

        isDesktop () {
            return ['md', 'l'].includes(this.$mq)
        },

        needToShowInterstitial () {
            return this.isMounted && !this.isDesktop
        },

        needToShowCustom404PageForContests () {
            return this.$route.fullPath.match(/\/contest\//g)?.length
        }


    },


    watch: {
        '$route.params': {
            handler () {
                if (!this.$isServer) {
                    this.$nextTick(() => {
                        this.showCatfish()
                        this.$store.dispatch('ads/showInterstitial')
                    })
                }
            },

            immediate: true
        }
    },

    mounted () {
        console.info({ mainAppGeo: this.geo })
        if (!this.geo) {
            this.updateGeo()
        }

        this.isMounted = true
        this.showCatFishLogic()
        this.setMediaQuery(this.$mq)

        // Для прямых заходов на страницы матчей и прогнозов, открываем чат матча
        if (['match', 'predictions-item'].includes(this.$route.name)) {
            if (this.$route.name === 'match') {
                this.chatSelectMatch(this.match)
            } else if (this.$route.name === 'predictions-item') {
                this.chatSelectMatch(this.prediction.match)
            }

            this.curtainChangeMode({ mode: 'match-chat' })
        }
    },

    methods: {
        ...mapActions({
            chatSelectMatch: 'chat/matches/selectMatch',
            curtainChangeMode: 'curtain/changeMode',
            updateGeo: 'bookmakersRating/updateGeo',
            showCatfish: 'ads/showCatfish',
            setIsPageScrolledUp: 'ads/setIsPageScrolledUp',
            setMediaQuery: 'page/setMediaQuery'
        }),

        showCatFishLogic () {
            if (window) {
                const scrollEvent = () => {
                    this.setIsPageScrolledUp(window.pageYOffset > (this.isDesktopBrandingVisible ? 300 : 210))
                }

                window.addEventListener('scroll', scrollEvent, false)
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
    .layout {
        min-height 100vh
        display flex
        flex-direction column
        background-color $cl-background
    }

    .l-content {
        box-sizing border-box
        width 100%
        min-height 100vh
        display flex
        flex-direction column
    }

    .l-columns {
        box-sizing border-box
        width 100%
        max-width 3.5rem + 86rem + 2rem + 30rem + 3.5rem + 5rem // curtain
        padding 2.4rem 8.5rem 2rem 3.5rem
        margin 0 auto
        display flex

        +layout-md() {
            max-width 3.5rem + 86rem + 3.5rem + 5rem // curtain
            padding 2rem 8.5rem 2rem 3.5rem
        }

        +layout-sm() {
            padding 2rem $l-column-horizontal-padding-sm
        }

        +layout-xs() {
            padding 1.6rem $l-column-horizontal-padding-xs
        }
    }

    .column-top {
        padding-right 5rem

        +layout-sm() {
            padding-right 0
        }
    }

    .column-footer {
        padding-right 5rem

        +layout-sm() {
            padding-right 0
        }
    }

    .column-main {
        max-width 86rem
        flex 1 1
        min-width 0
    }

    .column-right {
        flex 0 30rem
        margin-left 2rem
        max-width 30rem

        +layout-md() {
            display none
        }
    }
</style>
