/**
 * Парсит объект с инклюдами, устанавливает id как ключ
 * @param include
 * @param keys
 * @return {}
 */
function parseInclude (include, keys) {
    const result = {}

    keys.forEach(key => {
        result[key] = {}

        include[key].forEach(item => {
            result[key][item.id] = item
        })
    })

    return result
}


function populate ({ object = {}, keys = [], include = {}, deep = [] }) {
    keys.forEach(key => {
        const includeKey = `${key}_id`
        const includeKeys = `${key}_ids`

        if (includeKeys in object) {
            object[`${key}s`] = object[includeKeys].map(itemId => populate({ object: include[key][itemId], keys, include, deep }))
        }

        if (includeKey in object) {
            object[key] = include[key][object[includeKey]]
            populate({ object: object[key], keys, include, deep })
        }
    })

    deep.forEach(key => {
        if (key in object) {
            populate({ object: object[key], keys, include, deep })
        }
    })

    return object
}


export default function populateResponse ({ result, state = {}, include = {} }, deep = []) {
    const keys = Object.keys(include)
    const localInclude = parseInclude(include, keys)
    let populatedResult = result

    if (result) {
        if (result.constructor.name === 'Array') {
            populatedResult = result.map(object => populate({ object, keys, include: localInclude, deep }))
        } else if (result.constructor.name === 'Object') {
            populatedResult = populate({ object: result, keys, include: localInclude, deep })
        }
    }

    const populatedState = populate({ object: state, keys, include: localInclude, deep })

    return { result: populatedResult, state: populatedState }
}
