const LOCS = {
    // eslint-disable-next-line global-require
    ru: require('@frontend/date-transform/dist/date-transform-ru'),
    // eslint-disable-next-line global-require
    en: require('@frontend/date-transform/dist/date-transform-EN')
}

let library = null

export default {
    ready: false,
    init (lang = 'en') {
        library = LOCS[lang]
        this.ready = true
    },
    transform (timestamp = Date.now(), template = 'date') {
        if (this.ready) {
            return library({
                timestamp,
                template
            })
        }

        return ''
    }
}
