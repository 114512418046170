<template>
    <div class="bi-user-avatar">
        <UiAvatar
            :size="size"
            :src="src"
            :to="to"
            type="user"
            class="bi-user-avatar__avatar"
        >
            <slot />
        </UiAvatar>
    </div>
</template>

<script>
export default {
    name: 'BiUserAvatar',

    props: {
        src: {
            type: String,
            default: null
        },

        to: {
            type: Object,
            default: null
        },

        /**
         * Размер аватарки
         * `xxs, xs, sm, normal, md, l`
         */
        size: {
            type: String,
            default: 'md'
        }
    }
}
</script>

<style lang="stylus" scoped>
.bi-user-avatar
    position relative

</style>
