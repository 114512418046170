import logger from "@/utils/logger";
import Populate from "@/utils/populate";
import * as gQLService from "@frontend/graphql-service";
import crossFetch from "cross-fetch";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as nodeURL from "url";

const DEFAULT_LOCALE = "en";

let sessionId = null;
let appLocale = DEFAULT_LOCALE;

let currentEntry = "";
let gQLServiceInstance = null;
let requests = [];
let subscribes = [];
let hashes = [];

/**
 * Установка хедера с токеном авторизации
 */

function defaultHeaders() {
    return sessionId ? { Authorization: `Bearer ${sessionId}` } : {};
}

const defaultGraphqlHeaders = { "apollographql-client-name": "betting.team" };

/**
 * Коллбэк для общих ошибок гейтвея (parse Error)
 * @param err
 */
function graphqlErrorCallback(err) {
    const data = {};

    if (typeof err !== "object") {
        data.errorMessage = err;
    } else {
        data.errorMessage = err.message;
        data.path = err.path;
    }

    logger.error({
        msg: "[Betting][API]: Gateway error",
        gatewayEntryPoint: currentEntry,
        ...data,
    });
}

function getQueryParams(req) {
    const cut = req.replace(/^{?[^({]+\(([^)]+)\)[^]+/g, "$1");

    if (cut === req) {
        return "";
    }

    return cut;
}

function graphqlRequestErrorHandler(request, err) {
    if (err && !err.fullRequestError) {
        logger.error({
            msg: "[Betting][API]: Gateway error",
            errorMessage: err.message,
            stack: err.path,
            requestedQuery: request.req[0]
                .replace(/\n/g, "")
                .replace(/^{ *(\w+).+$/g, "$1"),
            requestedQueryParams: getQueryParams(request.req[0]),
            gatewayEntryPoint: currentEntry,
        });
    }

    return request.rej ? request.rej(err) : Promise.reject(err);
}

/**
 * Установка авторизационного заголовока
 * @param id - идентификатор сессии
 */
function setSid(id) {
    sessionId = id;
    gQLServiceInstance.updateHeaders({
        ...defaultHeaders(),
        ...defaultGraphqlHeaders,
    }); // обновление заголовков без пересоздания сервиса
}

/**
 * Установка входной точки у GraphQL сервера
 * @param entry - url сервера
 */
function setEntry(entry) {
    if (currentEntry !== entry) {
        hashes.forEach((hash) => {
            gQLServiceInstance.unsubscribe(hash);
        });

        hashes = [];

        currentEntry = entry;
        gQLServiceInstance = gQLService.setEntry(entry, {
            headers: {
                ...defaultHeaders(),
                ...defaultGraphqlHeaders,
            },
            credentials: "same-origin",
            errorCallback: graphqlErrorCallback,
        });

        requests.forEach((req) => {
            gQLServiceInstance.req(...req.req).then((resp) => {
                req.res(resp);
            }, graphqlRequestErrorHandler.bind(null, req));
        });

        requests = [];

        subscribes.forEach((req) => {
            gQLServiceInstance.subscribe(...req.req);
        });

        subscribes = [];
    }
}

/**
 * Установка локали
 * @param loc - локаль
 */
function setLoc(loc) {
    appLocale = loc;

    setEntry(`${process.env.GRAPHQL_API_URL}${loc}/`); // установка входной точки у GraphQL сервера
}

/**
 * Получение локали
 * @retuns loc - локаль
 */
function getLoc() {
    return appLocale;
}

/**
 * Генерация url для trbna
 * @param path
 * @param loc
 * @param ver
 * @param service
 * @param platform
 * @return {URL}
 */
function generateUrl({
    path = "",
    loc,
    ver = 2,
    service = "betting_insider",
    platform = "web",
}) {
    const locale = loc || appLocale || DEFAULT_LOCALE;
    const pathToApi = path ? `${path}/` : "";
    const input = `${process.env.REST_API_URL}/${service}/${locale}/${platform}/v${ver}/${pathToApi}`;

    if (process.env.isServer) {
        return new nodeURL.URL(input);
    }

    return new URL(input);
}

function restRequestErrorHandler(res) {
    const data = {};

    if (typeof err !== "object") {
        data.errorMessage = res;
    } else {
        data.status = res.status;
        data.statusText = res.statusText;
        data.path = res.url;

        if (res.code) {
            data.code = res.code;
        }

        if (res.body?.message) {
            data.errorMessage = res.body.message;
        } else if (res.message) {
            data.errorMessage = res.message;
        }
    }

    // eslint-disable-next-line no-console
    logger.error({
        msg: "[Betting][API]: REST error",
        ...data,
    });
}

/**
 * Метод для отправки запросов в api.trbna
 * @param loc
 * @param method
 * @param params
 * @param options
 * @param maps
 */
function trbna(config) {
    const { method = "get", params = {}, maps = [] } = config;
    const url = generateUrl(config);

    if (method === "get") {
        Object.entries(params).forEach(([key, value]) =>
            url.searchParams.append(key, value)
        );
    } else {
        config.body = params;
    }

    config.headers = {
        ...defaultHeaders(),
    };

    return crossFetch(url, config)
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            return Promise.reject(res);
        })
        .then((json) => {
            Populate(json, maps);

            json.serialized = json.result;

            return json;
        })
        .catch((res) => {
            restRequestErrorHandler(res);

            return Promise.reject(res);
        });
}

/**
 * загрузка фотографий пользователя
 * @param file
 * @param bucket
 * @param uploadUrl
 * @param loc
 * @return {Promise<*>}
 */
// eslint-disable-next-line no-shadow
async function file({ file, bucket = "bi", loc, uploadUrl }) {
    const formData = new FormData();
    const locale = loc || appLocale || DEFAULT_LOCALE;
    const url = uploadUrl || "";

    formData.append("uploadfile", file, file.name);

    const result = await crossFetch(url, {
        method: "POST",
        mode: "cors",
        body: formData,
        contentType: "multipart/form-data",
    }).then((resp) => resp.json());
    console.info({ result });
    return result.data.url;
}

/**
 * Выполнение запроса
 */
function graphql(...args) {
    if (!gQLServiceInstance) {
        return new Promise((resolve, reject) => {
            requests.push({ resolve, reject, req: args });
        });
    }

    return gQLServiceInstance
        .req(...args)
        .then(
            (data) => data,
            graphqlRequestErrorHandler.bind(null, { req: args })
        );
}

/**
 * Подписка
 * @param args
 * @return {String} - идентификатор подписки
 */
function subscribe(...args) {
    if (!gQLServiceInstance) {
        return new Promise((resolve, reject) => {
            subscribes.push({ resolve, reject, req: args });
        });
    }

    hashes.push(gQLServiceInstance.subscribe(...args));

    return gQLServiceInstance.subscribe(...args);
}

/**
 * Отписка
 * @param hash - идентификатор подписки
 */
function unsubscribe(hash) {
    return gQLServiceInstance.unsubscribe(hash);
}

export default {
    setSid,
    setLoc,
    getLoc,
    trbna,
    file,
    graphql,
    subscribe,
    unsubscribe,
};
