<template>
    <li
        :class="bonusClasses"
        class="daily-bonuses-popup__bonus"
        @click="$emit('click')"
    >
        <span class="daily-bonuses-popup__bonus-text">
            {{ $t('day', { n: bonus.day }) }}
        </span>

        <span class="daily-bonuses-popup__bonus-icon">
            <img
                :is="iconComponent"
                class="daily-bonuses-popup__bonus-image"
            />
        </span>

        <span class="daily-bonuses-popup__bonus-text">
            {{ bonus.amount }}
        </span>
    </li>
</template>

<i18n>
{
    "ru": {
        "day": "%{n} день"
    },
    "en": {
        "day": "day %{n}"
    }
}
</i18n>


<script>
import CashIcon from '@/assets/svg/daily-cash.icon.svg'
import GiftIcon from '@/assets/svg/daily-gift.icon.svg'
import WalletIcon from '@/assets/svg/daily-wallet.icon.svg'
import ProfileIcon from '@/assets/svg/daily-profile.icon.svg'

const ICONS_TYPES_LINKS = {
    cash: 'CashIcon',
    gift: 'GiftIcon',
    wallet: 'WalletIcon',
    profile: 'ProfileIcon'
}

export default {
    name: 'DailyBonusItem',

    components: {
        CashIcon,
        GiftIcon,
        WalletIcon,
        ProfileIcon
    },

    props: {
        bonus: {
            type: Object,
            required: true
        }
    },

    computed: {
        bonusClasses () {
            return {
                'daily-bonuses-popup__bonus--current': this.bonus.current,
                'daily-bonuses-popup__bonus--available': this.bonus.available
            }
        },

        iconComponent () {
            return ICONS_TYPES_LINKS[this.bonus.type]
        }
    }
}
</script>

<style lang="stylus" scoped>
.daily-bonuses-popup
    &__bonus
        display inline-block
        list-style none
        position relative
        margin-right 1.6rem
        z-index 1
        border .2rem solid $cl-white
        transition transform 80ms linear

        &::after
            content ''
            width 1.6rem
            height .2rem
            background $cl-secondary
            position absolute
            z-index 0
            top 50%
            right -1.8rem

        &:last-child
            margin-right 0

            &:after
                display none

        &--available
            font-weight $font-weight-bold
            color $cl-success-light
            cursor pointer

        &--available &-icon
            background linear-gradient(180deg, #FC903A 0%, #F9BD2F 100%)

            &::after
                content ''
                position absolute
                border-radius 50%
                border .2rem solid $cl-success-light
                width 5.4rem
                height 5.4rem
                left -.4rem
                top -.4rem
                z-index 2

        &--current
            transform scale3d(1.2, 1.2, 1)

    &__bonus-icon
        width 5rem
        height 5rem
        border-radius 50%
        display flex
        position relative
        background $cl-secondary

    &__bonus-image
        flex 0 1 100%
        max-width 100%
        max-height 100%

    &__bonus-text
        font-weight inherit
        font-size 1.1rem
        text-align center
        display block
        line-height 2.8rem
        max-width 5rem
        overflow hidden
        text-overflow ellipsis

</style>
