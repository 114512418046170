// id от которого начинается отсчет "новых" прогнозов по задаче https://tribuna.atlassian.net/browse/BIW-169
// закрываем от индексации отдельные страницы прогнозов https://tribuna.atlassian.net/browse/BIW-277
export const PREDICTION_SMALL_DESCRIPTION_TRESHOLD_ID = 19692069

export const OPEN_CHAT_FLOAT_EVENT = 'open-chat-float'
export const OPEN_DAILY_BONUSES_EVENT = 'open-daily-bonuses'
export const AVAILABLE_DAILY_BONUSES_EVENT = 'available-daily-bonuses'

export const FOOTBALL_SPORT_ID = '208'
export const SERVER_DEVICE_ID = 'server-default-deviceId'

// Магия, без которой 'ru' !== 'ru'
export const DEFAULT_LOCALE = (process.env.RESOURCE_LOCALE).split('').join('')
export const IS_BETTING_COM = !!process.env.DOMAIN_URL.match(/betting-insider\.com/)

export const TEN_MINUTES_CACHE_CONTROL = 'max-age=600'
export const FIFTEEN_MINUTES_CACHE_CONTROL = 'max-age=900'
export const THIRTY_MINUTES_CACHE_CONTROL = 'max-age=1800'
export const ONE_HOUR_CACHE_CONTROL = 'max-age=3600'
export const TWENTY_FOUR_HOURS_CACHE_CONTROL = 'max-age=86400'
export const ONE_WEEK_CACHE_CONTROL = 'max-age=604800'
export const ONE_YEAR_CACHE_CONTROL = 'max-age=31536000'

export const BETTING_TEAM_EN_URLS = [
    '/blog/youth-players-to-watch-out-for-in-the-premier-league',
    '/blog/man-city-confirm-sergio-agueros-departure-this-summer',
    '/blog/gambling-in-the-us-vs-the-uk'
]

export const APP_STORE_URL = 'https://redirect.appmetrica.yandex.com/serve/243294592806511016'

export const CONTEST_PAGE_ROUTE_NAME = 'rating-contest'

export const CHERDANCEV_ID = 130837
export const GENICH_ID = 6637
export const UTKIN_ID = 6420

export const EMAIL_NOT_CONFIRMED = 'email_not_confirmed'
export const PHONE_NOT_CONFIRMED = 'phone_not_confirmed'
export const USER_IS_BANNED = 'user_is_banned'

export const EURO2020_TOURNAMENT_ID = '544'
export const EURO2020_WEBNAME = 'uefa-euro'
export const EURO2020_BLOG_SECTION_NAME = 'euro-2020'

export const SPORT_TYPE_FOOTBALL = {
    id: 'football',
    name: 'Футбол'
}

export const PAGE_STATUSES = {
    loading: 'loading',
    hasError: 'hasError',
    ready: 'ready'
}

const getCurrentSeason = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()

    if (
        currentDate.getMonth() > 5
        || (currentDate.getMonth() === 5 && currentDate.getDay() > 20)
    ) {
        return `${currentYear}/${currentYear + 1}`
    }

    return `${currentYear - 1}/${currentYear}`
}

export const CURRENT_SEASON = getCurrentSeason()

const NEW_SPORTS = [
    'tennis',
    'ice-hockey',
    'basketball',
    'volleyball',
    'esport',
    'american-football',
    'aussie-rules',
    'badminton',
    'baseball',
    'water-polo',
    'handball',
    'table-tennis',
    'rugby-league',
    'rugby-union',
    'snooker',
    'floorball',
    'futsal',
    'bandy'
]

export const NEW_SPORT_JOINED = NEW_SPORTS.join('|')
