export default {
    ru: [
        {
            title: 'Winline',
            route: {
                name: 'bookmaker',
                params: {
                    webname: 'bkwinline'
                }
            }
        }, {
            title: '1хСтавка',
            route: {
                name: 'bookmaker',
                params: {
                    webname: '1xstavka'
                }
            }
        }, {
            title: 'PARI',
            route: {
                name: 'bookmaker',
                params: {
                    webname: 'pari'
                }
            }
        }, {
            title: 'Betcity',
            route: {
                name: 'bookmaker',
                params: {
                    webname: 'bkbetcity'
                }
            }
        }, {
            title: 'Лига Ставок',
            route: {
                name: 'bookmaker',
                params: {
                    webname: 'ligastavok'
                }
            }
        }
    ],
    en: []
}
