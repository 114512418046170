<template>
    <span class="mc-menu__calendar">
        <IconCalendar
            class="mc-menu__calendar-icon"
            :class="{ 'mc-menu__calendar--active': isActive }"
            @click.native="onOpenCalendar"
        />

        <UiCalendarPopup
            ref="calendar"
            :route="route"
            :value="date"
            :position="position"
            :attach-to-parent="attachToParent"
            route-param="date"
            @input="onChooseDate"
        />
    </span>
</template>

<script>
import IconCalendar from '@/assets/svg/calendar.icon.svg'
import UiCalendarPopup from '@/ui/components/Calendar/popup.vue'

export default {
    name: 'UiCalendar',

    components: {
        IconCalendar,
        UiCalendarPopup
    },

    props: {
        isActive: {
            type: Boolean
        },

        date: {
            type: Number,
            required: true
        },

        route: {
            type: Object,
            default: null
        },

        position: {
            type: String,
            default: 'bottom'
        },

        attachToParent: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onOpenCalendar () {
            this.$refs.calendar.open()
        },

        onChooseDate (dateObject) {
            this.$refs.calendar.close()
            this.$emit('choose-date', dateObject)
        }
    }
}
</script>

<style lang="stylus">
.mc-menu
    &__calendar
        cursor pointer
        display inline-block
        height 2rem

        &-icon
            margin-top -.1rem
            vertical-align middle

        &--active
            color $cl-primary
</style>
