let Vue = null
/* eslint-disable no-console */
let appName = 'betting-insider-server'
const MAX_MESSAGE_LENGTH = 300
const CONSOLE_HANDLERS_MAP = {
    INFO: 'info',
    ERROR: 'error'
}

/**
 * Парсер входных данных
 * Если data не объект, приводит значение к строке
 * и передеает как поле msg
 * либо возвращает копию переданного объекта
 *
 * @param {*} data
 * @returns {Object}
 */
function parseInput (data) {
    if (typeof data === 'string') {
        return {
            msg: `${data}`.slice(0, MAX_MESSAGE_LENGTH)
        }
    }

    return data
}

/**
 * Парсер ошибок
 * Если нет ошибки вернет null
 * если error не объект, приводит к строке и кладет в поле msg
 * если error стандартный объект ошибки, то достанет stack и положит message в поле msg
 * либо вернет изначальный объект
 *
 * @param {*} error
 * @returns {Object} возвращает подготовленный объект для отправки
 */
function parseError (error) {
    if (!error) {
        return null
    }

    let result = parseInput(error)

    if (!result.msg && error.constructor.name && error.message) {
        result = {
            msg: error.message,
            stack: error.stack
        }
    }

    if (typeof result.errorObject === 'string') {
        result.errorObject = result.errorObject.slice(0, MAX_MESSAGE_LENGTH)
    }

    return result
}

/**
 * Отправляет лог в формате JSON в нужный вывод (в зависимости от поля level)
 * Добавляет время в ISO и appName
 *
 * @param {Object} data - объект для логирования
 */
function output (data) {
    data.appName = appName
    data.time = new Date().toISOString()

    try {
        const dataJson = JSON.stringify(data)

        if (Vue && Vue.config.errorHandler && data.level === 'ERROR') {
            Vue.config.errorHandler(new Error(dataJson))
        } else {
            const handler = CONSOLE_HANDLERS_MAP[data.level] || 'info'

            console[handler](dataJson)
        }
    } catch (err) {
        console.error({ err }, data)
    }
}

module.exports = {
    setVueInstance (vueInstance) {
        if (!Vue) {
            Vue = vueInstance
        }
    },
    /**
     * Отправляет в stdout лог в формате JSON
     * Добавляет время в ISO
     * Если data не объект, приводит значение к строке
     * и передеает как поле msg
     *
     * @param {*} data
     */
    log (data) {
        return output({
            level: 'INFO',
            ...parseInput(data)
        })
    },
    /**
     * Отправляет в sterr лог в формате JSON
     * Добавляет время в ISO
     * Если data не объект, приводит значение к строке
     * и передеает как поле msg
     *
     * @param {*} err
     */
    error (err) {
        const data = parseError(err)

        if (!data) {
            return null
        }

        return output({
            level: 'ERROR',
            isError: true,
            ...data
        })
    },

    setAppName (name) {
        appName = name
    }
}
