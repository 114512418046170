<template>
    <div class="socials">
        <a
            v-for="item in socialItems"
            :key="item.name"
            :href="item.href"
            rel="nofollow"
            target="_blank"
            class="socials__item"
        >
            <component :is="item.name" />
        </a>
    </div>
</template>

<script>
import config from '@/config.json'

export default {
    name: 'TheHeaderSocials',

    computed: {

        socialsByLang () {
            return config.socials[this.$i18n.locale] || {}
        },

        socialItems () {
            return Object.keys(this.socialsByLang)
                .map(key => ({
                    name: `icon-${key}`,
                    href: this.socialsByLang[key]
                }))
        }
    }
}
</script>

<style lang="stylus" scoped>
.socials
    box-sizing border-box
    width 100%
    padding 2.6rem 2rem
    display flex
    justify-content space-around
    align-items center
    background-color rgba($cl-white, .05)

    &__item
        color $cl-white
        opacity .3
</style>
